import { Locales } from 'shared/assets/localization';
import { Tag } from 'shared/types/tag';

import { DateFormatOptions } from './date-format-options';
import { Goal } from './goal';
import { ID } from './id';
import { Insights } from './insights';
import { LifeArea } from './life-area';
import { TypeSections } from './page-sections';
import {
  GoalGroupBy,
  GoalSortingMode,
  HabitGroupBy,
  HabitSortingMode,
  SortingMode,
  TodayGroupBy,
} from './sorting-mode';
import { Task } from './task';
import { TaskPriorityOptions } from './task-base';
import { ThemeModes } from './theme-modes';
import { WeekDays } from './week-days';

export type Notifications = {
  taskReminder: {
    desktop?: boolean;
    mobile?: boolean;
  };
  taskSummary: {
    desktop?: boolean;
    mobile?: boolean;
  };
  weeklyReview: {
    desktop?: boolean;
    mobile?: boolean;
  };
};

export enum FavoriteType {
  Task = 'task',
  Goal = 'goal',
  LifeArea = 'lifeArea',
  Tag = 'tag',
}

export type FavoriteReference = {
  type: FavoriteType;
  id: ID;
};

export type Favorite =
  | {
      id: ID;
      type: FavoriteType.Task;
      value: Task;
    }
  | {
      id: ID;
      type: FavoriteType.Goal;
      value: Goal;
    }
  | {
      id: ID;
      type: FavoriteType.LifeArea;
      value: LifeArea;
    }
  | {
      id: ID;
      type: FavoriteType.Tag;
      value: Tag;
    };

export enum GoalRoadmapSortingKeys {
  InProgress = 'inProgress',
  Unplanned = 'unplanned',
  Completed = 'completed',
  Archived = 'archived',
}

export type Year = string;

export type GoalRoadmapSortingOptions = GoalRoadmapSortingKeys | Year;

export enum GoalRoadmapSortingModes {
  CreatedAt = 'createdAt',
  Custom = 'custom',
}

export type GoalTabSorting = {
  mode: GoalRoadmapSortingModes;
  sorting: ID[];
};

export type UserSettings = {
  language?: Locales;
  country?: string;
  dateFormat?: DateFormatOptions;
  startOfWeekIsIosSystemManaged?: boolean;
  startOfWeek?: WeekDays;
  notifications?: Notifications;
  themePreference?: {
    desktop?: ThemeModes;
    mobile?: ThemeModes;
  };
  favorites?: FavoriteReference[];
  isTaskNotificationEnabled?: boolean;
  isCheckInNotificationEnabled?: boolean;
  isHabitNotificationEnabled?: boolean;
  isAppLockEnabled?: boolean;
  insights?: Insights;
  todoSortingMode?: SortingMode;
  todayGroupBy?: TodayGroupBy;
  todayGroupSorting?: {
    goal?: {
      // goalId: [task.id]
      sorting: Record<ID, ID[]>;
    };
    lifeArea?: {
      // lifeAreaId: [task.id]
      sorting: Record<ID, ID[]>;
    };
    priority?: {
      // high: [task.id]
      sorting: Record<TaskPriorityOptions, ID[]>;
    };
    type?: {
      // high: [task.id]
      sorting: Record<TypeSections, ID[]>;
    };
  };
  todayHideHabits?: boolean;
  allTasksHideHabits?: boolean;
  todayFilterByTags?: ID[];
  goalGroupSorting?: {
    lifeArea?: {
      sorting: Record<ID, ID[]>;
    };
  };
  todoSorting?: Record<string, ID[]>; // { '2024-12-31': ['task-id-1'], ... }
  inboxSorting?: ID[];
  inboxFilterByTags?: ID[];
  habitSorting?: ID[] | null;
  habitGroupBy?: HabitGroupBy;
  habitSortingMode?: HabitSortingMode;
  habitGroupSorting?: {
    goal?: {
      // goalId: [task.id]
      sorting: Record<ID, ID[]>;
    };
    lifeArea?: {
      // lifeAreaId: [task.id]
      sorting: Record<ID, ID[]>;
    };
    priority?: {
      // high: [task.id]
      sorting: Record<TaskPriorityOptions, ID[]>;
    };
  };
  allTasks?: {
    unlinkedPlannedTasksSorting?: ID[];
    collapsedGoals?: ID[];
    collapsedLifeAreas?: ID[];
  };
  allTasksFilterByTags?: ID[];
  goalGroupBy?: GoalGroupBy;
  goalSortingMode?: GoalSortingMode;
  goalSorting?: ID[];
  goalTabSorting?: Record<GoalRoadmapSortingOptions, GoalTabSorting>;
  goalRoadmapSorting?: Record<GoalRoadmapSortingOptions, ID[]>;
  goalRoadmapSortingMode?: GoalSortingMode;
  lifeAreaSorting?: ID[];
  tagSorting?: ID[];
};
