import { useTagById, useTagTasks } from 'features/tags';
import { useMemo } from 'react';
import { useToday } from 'shared/contexts/today';
import { useUser } from 'shared/hooks/use-user';
import { ID } from 'shared/types/id';
import { SortingMode } from 'shared/types/sorting-mode';
import { noPriorityOption, TaskPriorityOptions } from 'shared/types/task-base';
import { getSortedTasks } from 'shared/utils/get-sorted-tasks';
import { getWeekStartsOn } from 'shared/utils/get-week-starts-on';

export const useTagPriorityTaskSections = (id?: ID) => {
  const today = useToday();
  const user = useUser();
  const tag = useTagById(id);
  const tasks = useTagTasks(id);

  const sections = useMemo(
    () => [
      {
        id: TaskPriorityOptions.High,
        items: getSortedTasks(
          tasks.filter(({ priority }) => priority === TaskPriorityOptions.High),
          {
            today,
            weekStartsOn: getWeekStartsOn(user),
            mode: Array.from(
              new Set([SortingMode.DueDate, tag?.taskSortingMode]),
            ).filter(Boolean) as SortingMode[],
            order: tag?.taskSorting ?? [],
          },
        ),
      },
      {
        id: TaskPriorityOptions.Medium,
        items: getSortedTasks(
          tasks.filter(
            ({ priority }) => priority === TaskPriorityOptions.Medium,
          ),
          {
            today,
            weekStartsOn: getWeekStartsOn(user),
            mode: Array.from(
              new Set([SortingMode.DueDate, tag?.taskSortingMode]),
            ).filter(Boolean) as SortingMode[],
            order: tag?.taskSorting ?? [],
          },
        ),
      },
      {
        id: TaskPriorityOptions.Low,
        items: getSortedTasks(
          tasks.filter(({ priority }) => priority === TaskPriorityOptions.Low),
          {
            today,
            weekStartsOn: getWeekStartsOn(user),
            mode: Array.from(
              new Set([SortingMode.DueDate, tag?.taskSortingMode]),
            ).filter(Boolean) as SortingMode[],
            order: tag?.taskSorting ?? [],
          },
        ),
      },
      {
        id: noPriorityOption,
        items: getSortedTasks(
          tasks.filter(
            ({ priority }) =>
              !priority || priority === TaskPriorityOptions.None,
          ),
          {
            today,
            weekStartsOn: getWeekStartsOn(user),
            mode: Array.from(
              new Set([SortingMode.DueDate, tag?.taskSortingMode]),
            ).filter(Boolean) as SortingMode[],
            order: tag?.taskSorting ?? [],
          },
        ),
      },
    ],
    [tag, tasks, today, user],
  );

  return {
    sections,
    tasks,
  };
};
