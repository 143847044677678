import { HabitSchedule, ScheduleEntryState } from 'shared/types/habit-schedule';
import { Timestamp } from 'shared/types/timestamp';

export type Options = {
  startDate: Timestamp;
  endDate: Timestamp;
};

export const getTimeframeSchedules = (
  schedules: HabitSchedule[],
  { startDate, endDate }: Options,
) =>
  schedules.filter((schedule) => {
    const scheduleCompletions = schedule.entries.filter(
      (entry) => entry.state === ScheduleEntryState.Complete && !!entry.value,
    );
    // get the very start-point of the schedule
    const scheduleStartDate = new Date(
      Math.min(
        schedule.startDate.getTime(),
        ...scheduleCompletions.map(({ date }) => date.getTime()),
      ),
    );

    return (
      scheduleStartDate <= endDate &&
      (!schedule.endDate || schedule.endDate >= startDate)
    );
  });
