import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Check,
  IconFormIcons,
  Plus,
  Search,
  Target,
  X,
} from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import {
  Colors,
  PopupMenu,
  PopupMenuButton,
  PopupMenuInput,
  PopupMenuList,
  PopupMenuListGroup,
  PopupMenuListItem,
  PopupMenuTitle,
} from 'shared/components/ui/popup-menu';
import { useForwardedRef } from 'shared/hooks/use-forwarded-ref';
import { Goal } from 'shared/types/goal';
import { ID } from 'shared/types/id';
import { LifeArea } from 'shared/types/life-area';
import { LinkOptions } from 'shared/types/task';
import { getAutoCompleteSuggestions } from 'shared/utils/autocomplete-value-matches-input';
import { groupItems } from 'shared/utils/group-items';

import * as Styled from './linked-item-search-menu.style';

export type LinkedItemSearchMenuProps = Omit<
  React.ComponentProps<typeof PopupMenu>,
  'ref' | 'children'
> & {
  goals: Goal[];
  lifeAreas: LifeArea[];
  value?: ID | null;
  onChange: (link: { id: ID; type: LinkOptions } | null) => void;
  onCreate?: (name: string) => void;
};

export const LinkedItemSearchMenu = forwardRef<
  HTMLDivElement,
  LinkedItemSearchMenuProps
>(({ goals, lifeAreas, value, onChange, onCreate, ...rest }, ref) => {
  const containerRef = useForwardedRef(ref);
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const [containerHeight, setContainerHeight] = useState<number>();

  const [searchValue, setSearchValue] = useState('');

  const item = useMemo(
    () =>
      goals.find(({ id }) => id === value) ??
      lifeAreas.find(({ id }) => id === value),
    [goals, lifeAreas, value],
  );

  const onCreateGoal = (e: React.MouseEvent) => {
    e.preventDefault();
    onCreate?.(searchValue);
  };

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearchValue(e.target.value);

  const onClear = () => onChange(null);

  const goalSuggestions = useMemo(
    () =>
      groupItems(
        getAutoCompleteSuggestions(
          searchValue,
          goals.filter(({ archivedAt }) => !archivedAt),
          'name',
        ),
      ),
    [goals, searchValue],
  );

  const lifeAreaSuggestions = useMemo(
    () =>
      getAutoCompleteSuggestions(
        searchValue,
        lifeAreas.filter(({ archivedAt }) => !archivedAt),
        'name',
      ),
    [lifeAreas, searchValue],
  );

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  useEffect(() => {
    setContainerHeight(containerRef.current?.clientHeight);
  }, [containerRef]);

  return (
    <Styled.PopupMenu ref={containerRef} $height={containerHeight} {...rest}>
      <PopupMenuList hasBottomBorder>
        <PopupMenuListItem>
          <PopupMenuInput
            ref={inputRef}
            startIcon={Search}
            value={searchValue}
            placeholder={t('forms.goal.search.placeholder')}
            onChange={onChangeInput}
          />
        </PopupMenuListItem>
      </PopupMenuList>

      <PopupMenuListGroup hasBottomBorder={!!value} isScrollable>
        {!!goalSuggestions.length && (
          <PopupMenuList hasBottomBorder={!!lifeAreaSuggestions.length}>
            <PopupMenuTitle title={t('task.linked-item.goal.options.title')} />
            {goalSuggestions.map(({ id, name, iconName, parentIds }) => {
              const onSelectItem = () =>
                onChange(value === id ? null : { id, type: LinkOptions.Goal });

              return (
                <PopupMenuListItem key={id}>
                  <PopupMenuButton
                    start={
                      <Icon
                        icon={iconName ? IconFormIcons[iconName] : Target}
                      />
                    }
                    onClick={onSelectItem}
                    isSelected={value === id}
                    end={value === id ? <Icon icon={Check} /> : undefined}
                    tabIndex={0}
                    hasIndentation={
                      !!parentIds?.length &&
                      !!goalSuggestions.find(
                        ({ id }) => parentIds[parentIds.length - 1] === id,
                      )
                    }
                    overflowEllipsis
                  >
                    {name}
                  </PopupMenuButton>
                </PopupMenuListItem>
              );
            })}

            {!!onCreate && (
              <PopupMenuListItem>
                <PopupMenuButton
                  start={<Icon icon={Plus} />}
                  startColor={Colors.Primary}
                  textColor={Colors.Primary}
                  onClick={onCreateGoal}
                  tabIndex={0}
                >
                  {searchValue || t('forms.goal.create.title')}
                </PopupMenuButton>
              </PopupMenuListItem>
            )}
          </PopupMenuList>
        )}

        {!!lifeAreaSuggestions.length && (
          <PopupMenuList>
            <PopupMenuTitle
              title={t('task.linked-item.life-area.options.title')}
            />
            {lifeAreaSuggestions.map(({ id, name, iconName }) => {
              const onSelectItem = () =>
                onChange(
                  value === id ? null : { id, type: LinkOptions.LifeArea },
                );

              return (
                <PopupMenuListItem key={id}>
                  <PopupMenuButton
                    start={<Icon icon={IconFormIcons[iconName]} />}
                    onClick={onSelectItem}
                    isSelected={value === id}
                    end={value === id ? <Icon icon={Check} /> : undefined}
                    tabIndex={0}
                  >
                    {name}
                  </PopupMenuButton>
                </PopupMenuListItem>
              );
            })}
          </PopupMenuList>
        )}
      </PopupMenuListGroup>

      {!!value && (
        <PopupMenuList>
          <PopupMenuListItem>
            <PopupMenuButton
              start={<Icon icon={X} />}
              startColor={Colors.Error}
              textColor={Colors.Error}
              onClick={onClear}
              tabIndex={0}
            >
              {t(
                `task.linked-item.${item && 'lifeAreaId' in item ? 'goal' : 'life-area'}.options.remove.label`,
              )}
            </PopupMenuButton>
          </PopupMenuListItem>
        </PopupMenuList>
      )}
    </Styled.PopupMenu>
  );
});
