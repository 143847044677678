import { addWeeks, endOfWeek, isAfter, startOfWeek } from 'date-fns';
import React, { useCallback, useMemo, useState } from 'react';
import { weekdaysNumberMap } from 'shared/constants';
import { useToday } from 'shared/contexts/today';
import { useUser } from 'shared/hooks/use-user';
import { getWeekStartsOn } from 'shared/utils/get-week-starts-on';

import { TimeframeContext, TimeframeContextProps } from './context';

export type ProviderProps = {
  children: React.ReactNode;
};

export const Provider: React.FC<ProviderProps> = ({ children }) => {
  const today = useToday();
  const user = useUser();
  const weekStartsOn = getWeekStartsOn(user);
  const [weekDate, setWeekDate] = useState(
    startOfWeek(today, { weekStartsOn: weekdaysNumberMap[weekStartsOn] }),
  );

  const onPrev = useCallback(
    () => setWeekDate((oldDate) => addWeeks(oldDate, -1)),
    [],
  );
  const onNext = useCallback(
    () =>
      setWeekDate((oldDate) => {
        const newDate = addWeeks(oldDate, 1);
        return isAfter(newDate, endOfWeek(today)) ? oldDate : newDate;
      }),
    [today],
  );
  const onReset = useCallback(
    () =>
      setWeekDate(
        startOfWeek(today, { weekStartsOn: weekdaysNumberMap[weekStartsOn] }),
      ),
    [today, weekStartsOn],
  );

  const value = useMemo<TimeframeContextProps>(
    () => ({
      onPrev,
      onNext,
      onReset,
      weekDate,
    }),
    [onNext, onPrev, onReset, weekDate],
  );

  return (
    <TimeframeContext.Provider value={value}>
      {children}
    </TimeframeContext.Provider>
  );
};
