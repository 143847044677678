import {
  eachMonthOfInterval,
  eachWeekOfInterval,
  eachYearOfInterval,
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from 'date-fns';
import { useMemo } from 'react';
import { weekdaysNumberMap } from 'shared/constants';
import { useActiveSchedule } from 'shared/hooks/use-active-schedule';
import { useTimeframeEntries } from 'shared/hooks/use-timeframe-entries';
import { useTimeframeOccurrences } from 'shared/hooks/use-timeframe-occurrences';
import { isBetween } from 'shared/lib/date-fns';
import { FrequencyUnit, HabitSchedule } from 'shared/types/habit-schedule';
import { Timeframe } from 'shared/types/timeframe';
import { Timestamp } from 'shared/types/timestamp';
import { WeekDays } from 'shared/types/week-days';
import { getScheduleFrequency } from 'shared/utils/get-schedule-frequency';

const frequencySlotFuncsMap = {
  [FrequencyUnit.Week]: {
    startOfSlot: startOfWeek,
    endOfSlot: endOfWeek,
    eachOfInterval: eachWeekOfInterval,
  },
  [FrequencyUnit.Month]: {
    startOfSlot: startOfMonth,
    endOfSlot: endOfMonth,
    eachOfInterval: eachMonthOfInterval,
  },
  [FrequencyUnit.Year]: {
    startOfSlot: startOfYear,
    endOfSlot: endOfYear,
    eachOfInterval: eachYearOfInterval,
  },
};

export type Options = {
  startDate: Timestamp;
  endDate: Timestamp;
  weekStartsOn: WeekDays;
};

export const useSchedulesPerfectSlots = (
  schedules: HabitSchedule[],
  { startDate, endDate, weekStartsOn }: Options,
) => {
  const activeSchedule = useActiveSchedule(schedules);
  const { dateEntries } = useTimeframeEntries(schedules, {
    startDate,
    endDate,
  });
  const { occurrences } = useTimeframeOccurrences(schedules, {
    startDate,
    endDate,
  });

  return useMemo(() => {
    if (!activeSchedule) {
      return [];
    }
    // get the perfect timeframe slots functions based on the frequency
    const frequency = getScheduleFrequency(activeSchedule);
    const { startOfSlot, endOfSlot, eachOfInterval } =
      frequencySlotFuncsMap[frequency];
    const dateFnsOptions = {
      weekStartsOn: weekdaysNumberMap[weekStartsOn],
    };

    const startOfSlots = startOfSlot(startDate, dateFnsOptions);
    const endOfSlots = endOfSlot(endDate, dateFnsOptions);

    // loop over the timeframe slot units and get perfect-week options
    return eachOfInterval(
      { start: startOfSlots, end: endOfSlots },
      dateFnsOptions,
    ).reduce<Timeframe[]>((acc, slotStartDate) => {
      const slotEndDate = endOfSlot(slotStartDate, dateFnsOptions);
      const completionCount = dateEntries.reduce(
        (count, entry) =>
          count +
          (entry.value && isBetween(entry.date, slotStartDate, slotEndDate)
            ? entry.value
            : 0),
        0,
      );
      const slotTarget =
        activeSchedule.frequency?.count ??
        (occurrences.filter((occurrence) =>
          isBetween(occurrence, slotStartDate, slotEndDate),
        ).length * activeSchedule.completionTarget.count ||
          1);

      // check if the completion is greater than or similar
      if (slotTarget >= 1 && completionCount >= slotTarget) {
        acc.push({
          referenceDate: slotStartDate,
          startDate: slotStartDate,
          endDate: slotEndDate,
        });
      }

      return acc;
    }, []);
  }, [
    activeSchedule,
    dateEntries,
    endDate,
    occurrences,
    startDate,
    weekStartsOn,
  ]);
};
