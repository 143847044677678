import React, { Suspense, useEffect, useRef } from 'react';
import { TaskCreateColumn } from 'shared/components/connected/task-create-column';
import { TaskDeleteButton } from 'shared/components/connected/task-delete-button';
import { TaskDetailColumn } from 'shared/components/connected/task-detail-column';
import { TaskStatisticsButton } from 'shared/components/connected/task-statistics-button';
import { DetailColumnContainer } from 'shared/components/ui/detail-column-container';
import { useClickOutside } from 'shared/hooks/use-click-outside';
import { DataType, InteractionType } from 'shared/types/data-type';
import { ID } from 'shared/types/id';
import { InsightsTimeframes } from 'shared/types/insights';
import { CreateTask } from 'shared/types/task';
import {
  eventIsFromDataType,
  eventIsFromInteractionType,
} from 'shared/utils/event-is-from-interaction-type';

export type DesktopDetailColumnProps = {
  initialValues?: Partial<CreateTask>;
  taskId?: ID;
  showStatistics: boolean;
  statisticsTimeframe?: InsightsTimeframes;
  onClose: () => void;
};

export const MobileDetailColumn: React.FC<DesktopDetailColumnProps> = ({
  initialValues,
  taskId,
  showStatistics,
  statisticsTimeframe,
  onClose,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const closeTimerRef = useRef<number>();

  useEffect(() => {
    closeTimerRef.current && window.clearTimeout(closeTimerRef.current);
  }, [taskId]);

  useClickOutside(contentRef, (event) => {
    if (
      (!initialValues && !taskId) ||
      eventIsFromInteractionType(event, InteractionType.Task) ||
      eventIsFromDataType(event, DataType.Task)
    ) {
      return;
    }

    closeTimerRef.current = window.setTimeout(onClose, 200);
  });

  return (
    <DetailColumnContainer
      contentRef={contentRef}
      open={!!initialValues || !!taskId}
      onClose={onClose}
      actions={[
        !showStatistics && (
          <Suspense>
            <TaskStatisticsButton taskId={taskId} />
          </Suspense>
        ),
        !!taskId && <TaskDeleteButton taskId={taskId} />,
      ]}
    >
      {!!initialValues && !taskId && (
        <TaskCreateColumn initialValues={initialValues} onClose={onClose} />
      )}
      {!initialValues && !!taskId && (
        <Suspense>
          <TaskDetailColumn
            taskId={taskId}
            showStatistics={showStatistics}
            statisticsTimeframe={statisticsTimeframe}
            onClose={onClose}
          />
        </Suspense>
      )}
    </DetailColumnContainer>
  );
};
