import { useQuery } from '@tanstack/react-query';
import { createSubscriptionQuery } from 'shared/lib/@tanstack-query';
import { GetTagsOptions, getTagsQuery } from 'shared/services/backend-api';
import { ID } from 'shared/types/id';

export const queryKey = 'tags';

export const useTagsQuery = (
  uid?: ID,
  options: GetTagsOptions = {},
  enabled = true,
) =>
  useQuery({
    queryKey: [
      queryKey,
      uid ?? '',
      ...Object.entries(options)
        .filter(([, value]) => value !== undefined)
        .map(([key, value]) => `${key}: ${value.toSorted().join(',')}`),
    ],
    queryFn: createSubscriptionQuery([() => getTagsQuery(options)]),
    enabled: !!uid && enabled,
  });

export type { GetTagsOptions };
