import { useLifeAreaById } from 'features/life-areas';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconFormIcons, Inbox } from 'shared/assets/icons';
import { ListSectionLifeAreaHeader } from 'shared/components/ui/section-header-life-area';
import { ID } from 'shared/types/id';

export type LifeAreaHeaderProps = {
  lifeAreaId: ID;
  hasLargeFont?: boolean;
  hideIllustration?: boolean;
  openOnTasks?: boolean;
};

export const LifeAreaHeader: React.FC<LifeAreaHeaderProps> = ({
  lifeAreaId,
  hasLargeFont,
  hideIllustration,
  openOnTasks,
}) => {
  const { t } = useTranslation();
  const lifeArea = useLifeAreaById(lifeAreaId);

  const lifeAreaName =
    lifeArea?.name ?? t('general.sections.life-areas.other.title');
  const icon = hideIllustration
    ? undefined
    : lifeArea
      ? IconFormIcons[lifeArea.iconName]
      : Inbox;

  return (
    <ListSectionLifeAreaHeader
      id={lifeAreaId}
      icon={icon}
      name={lifeAreaName}
      color={lifeArea?.color ?? undefined}
      isFrozen={!!lifeArea?.frozenAt}
      image={hideIllustration ? undefined : lifeArea?.visionPreview?.image}
      hasLargeFont={hasLargeFont}
      openOnTasks={openOnTasks}
    />
  );
};
