import { ID } from 'shared/types/id';

import { getUser, getUserRef } from './get-user';
import { updateDocWithTimestamp } from './helpers';

export const updateAllTasksCollapsedLifeAreas = async (newOrder: ID[]) => {
  const user = await getUser();

  if (!user) {
    return;
  }

  const userRef = await getUserRef();

  if (!userRef) {
    return;
  }

  updateDocWithTimestamp(userRef, {
    'settings.allTasks.collapsedLifeAreas': Array.from(new Set(newOrder)),
  });
};
