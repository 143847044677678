import React from 'react';
import { NewTaskRow } from 'shared/components/connected/new-task-row';

import * as Styled from './empty-life-area-grouping-tasks-list.style';

export const EmptyLifeAreaGroupingTasksList: React.FC = () => (
  <Styled.Container>
    <NewTaskRow />
  </Styled.Container>
);
