import Typography from '@mui/material/Typography';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Check,
  DotsMenu,
  IconFormIcons,
  Lock,
  SlimArrowUp,
  Target,
} from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import { IconButton, Sizes } from 'shared/components/ui/icon-button';
import { TimeLeft } from 'shared/components/ui/time-left';
import { TimeProgressChart } from 'shared/components/ui/time-progress-chart';
import { DateEntry } from 'shared/lib/recharts';
import { Paths } from 'shared/routes';
import { Goal } from 'shared/types/goal';
import { LifeArea } from 'shared/types/life-area';
import { metricUnitFormatBase } from 'shared/types/metric-template';
import { Timestamp } from 'shared/types/timestamp';
import { WeekDays } from 'shared/types/week-days';
import { formatNumberShort } from 'shared/utils/format-number-short';
import { getGoalColor } from 'shared/utils/get-goal-color';
import { isNumber } from 'shared/utils/is-number';
import { replaceUrlParams } from 'shared/utils/replace-url-params';

import * as Styled from './goal-insights-card.style';

export type GoalInsightsCardProps = {
  goal: Goal;
  entries: DateEntry[];
  completionPercentage?: number;
  unitFormat?: string;
  startValue?: number;
  currentValue?: number;
  target?: number;
  timeframeDifference?: number;
  goals: Goal[];
  lifeAreas: LifeArea[];
  startDate: Timestamp;
  endDate: Timestamp;
  onPremium: () => void;
  menuButtonRef?: React.RefObject<HTMLButtonElement>;
  onMenuButton?: (e: React.MouseEvent) => void;
  onContextMenu?: (e: React.MouseEvent) => void;
  weekStartsOn: WeekDays;
  isLink?: boolean;
  isLoading?: boolean;
};

export const GoalInsightsCard: React.FC<GoalInsightsCardProps> = ({
  goal,
  entries,
  completionPercentage,
  unitFormat,
  startValue,
  currentValue,
  target,
  timeframeDifference,
  goals,
  lifeAreas,
  startDate,
  endDate,
  onPremium,
  menuButtonRef,
  onMenuButton,
  onContextMenu,
  weekStartsOn,
  isLink,
  isLoading,
}) => {
  const { t } = useTranslation();
  const color = useMemo(
    () => getGoalColor(goal.id, { goals, lifeAreas }),
    [goal.id, goals, lifeAreas],
  );

  const lastEntryValue =
    currentValue ?? entries?.[entries.length - 1]?.value ?? startValue;
  const formattedTarget = isNumber(target)
    ? formatNumberShort(target!)
    : undefined;
  const formattedLastEntryValue = formatNumberShort(lastEntryValue ?? 0);

  const _onMenuButton = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onMenuButton?.(e);
  };

  return (
    <Styled.Container
      as={!isLink || goal.frozenAt ? 'span' : undefined}
      to={
        goal.frozenAt ? '' : replaceUrlParams(Paths.GoalDetail, { id: goal.id })
      }
      onClick={goal.frozenAt ? onPremium : undefined}
      onContextMenu={goal.frozenAt ? undefined : onContextMenu}
    >
      <Styled.Header>
        <Styled.GoalInformationContainer>
          <Styled.IllustrationContainer>
            <Styled.ImageContainer>
              {goal.image ? (
                <Styled.Image
                  src={goal.image.url}
                  alt={goal.name}
                  hash={goal.image.blurHash}
                />
              ) : (
                <Icon icon={IconFormIcons[goal.iconName!] ?? Target} />
              )}
            </Styled.ImageContainer>

            {!!goal.completedAt && (
              <Styled.CompletedContainer>
                <Icon icon={Check} />
              </Styled.CompletedContainer>
            )}

            {!!color && <Styled.ColorDot $color={color} />}
          </Styled.IllustrationContainer>

          <Styled.Title
            $isFrozen={!!goal.frozenAt}
            $isCompleted={!!goal.completedAt}
          >
            {goal.name}
          </Styled.Title>
        </Styled.GoalInformationContainer>

        <Styled.IconButtonContainer>
          <IconButton
            icon={goal.frozenAt ? Lock : DotsMenu}
            onClick={goal.frozenAt ? undefined : _onMenuButton}
            size={Sizes.ExtraSmall}
            ref={menuButtonRef}
          />
        </Styled.IconButtonContainer>
      </Styled.Header>

      {isLoading ? (
        <Styled.LoadingBody />
      ) : (
        <Styled.Body $isFrozen={!!goal.frozenAt}>
          <Styled.CurrentProgress>
            {!!unitFormat ? (
              <>
                <Styled.CurrentProgressValue>
                  {formattedTarget
                    ? formattedLastEntryValue
                    : unitFormat?.replace(
                        metricUnitFormatBase,
                        formattedLastEntryValue,
                      )}
                </Styled.CurrentProgressValue>
                {formattedTarget
                  ? ` / ${unitFormat?.replace(metricUnitFormatBase, formattedTarget)}`
                  : null}
              </>
            ) : (
              t('general.labels.insights-card.no-tasks.label')
            )}
          </Styled.CurrentProgress>

          <Styled.ProgressContainer>
            <Styled.Progress>
              {!goal.completedAt && !!goal.deadline && (
                <>
                  <TimeLeft date={goal.deadline} isShort />
                  <Styled.Dot />
                </>
              )}
              <Typography variant="overline">
                {completionPercentage}%
              </Typography>
            </Styled.Progress>

            {!!timeframeDifference && (
              <Styled.Difference
                $positive={
                  isNumber(startValue) &&
                  isNumber(target) &&
                  startValue! > target!
                    ? timeframeDifference < 0
                    : timeframeDifference > 0
                }
              >
                <Styled.DifferenceIconContainer $up={timeframeDifference > 0}>
                  <Icon icon={SlimArrowUp} />
                </Styled.DifferenceIconContainer>
                {Math.abs(Math.round(timeframeDifference * 10) / 10)}%
              </Styled.Difference>
            )}
          </Styled.ProgressContainer>
        </Styled.Body>
      )}

      {isLoading ? (
        <Styled.LoadingFooter />
      ) : (
        <Styled.Footer $isFrozen={!!goal.frozenAt} $isLink={!!isLink}>
          <TimeProgressChart
            data={entries}
            startDate={goal.startDate}
            endDate={goal.deadline}
            chartStartDate={startDate}
            chartEndDate={endDate}
            startValue={startValue}
            endValue={target}
            weekStartsOn={weekStartsOn}
            hideYAxis
            hasSmallHeight
          />
        </Styled.Footer>
      )}
    </Styled.Container>
  );
};
