import { isSameDay } from 'date-fns';
import { useMemo } from 'react';
import { useToday } from 'shared/contexts/today';
import { initialWeekStartsOn } from 'shared/types/week-days';
import { getActiveSchedule } from 'shared/utils/get-active-schedule';
import { getTaskDueDate } from 'shared/utils/get-task-due';

import { useRepeatingTasks } from './use-repeating-tasks';

export const useTodaysRepeatingTasks = (weekStartsOn = initialWeekStartsOn) => {
  const today = useToday();
  const repeatingTasks = useRepeatingTasks({
    uncompletedOnly: true,
    unArchivedOnly: true,
    unFrozenOnly: true,
  });

  return useMemo(
    () =>
      repeatingTasks.filter((habit) => {
        const dueDate = getTaskDueDate(habit, { today, weekStartsOn });

        if (!dueDate) {
          return false;
        }

        const activeSchedule = getActiveSchedule(habit.schedules);
        if (!activeSchedule) {
          return false;
        }

        return isSameDay(dueDate, today);
      }),
    [repeatingTasks, today, weekStartsOn],
  );
};
