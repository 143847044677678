import { useGoals } from 'features/goals';
import { useLocalizedLifeAreas } from 'features/life-areas';
import React from 'react';
import { useParams } from 'react-router';
import { Headers, TaskSection } from 'shared/components/ui/task-section';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';
import { useActiveTaskId } from 'shared/contexts/task-detail';
import { TaskPriorityOptions } from 'shared/types/task-base';

import { useTagPriorityTaskSections } from '../../hooks/use-tag-priority-task-sections';
import * as Styled from './priority-grouping-tasks-list.style';

export const PriorityGroupingTasksList: React.FC = () => {
  const { id } = useParams();
  const { sections } = useTagPriorityTaskSections(id);

  const openPremiumDialog = useOpenPremiumDialog();

  const selectedTask = useActiveTaskId();

  const goals = useGoals();
  const lifeAreas = useLocalizedLifeAreas();

  return (
    <Styled.Container>
      {sections.map((section) => (
        <Styled.Section key={section.id}>
          <TaskSection
            goals={goals}
            lifeAreas={lifeAreas}
            onPremium={openPremiumDialog}
            tasks={section.items}
            tagIds={id ? [id] : undefined}
            selectedTask={selectedTask}
            priority={section.id as TaskPriorityOptions}
            headerToDisplay={Headers.Priority}
            canCreateTask
            canCollapse
          />
        </Styled.Section>
      ))}
    </Styled.Container>
  );
};
