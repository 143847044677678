import React from 'react';
import {
  PopupMenu,
  PopupMenuButton,
  PopupMenuList,
  PopupMenuListItem,
} from 'shared/components/ui/popup-menu';

import { useColorOptions } from './use-color-options';

export type ColorPickerContextMenuProps = Omit<
  React.ComponentProps<typeof PopupMenu>,
  'onOutside' | 'children'
> & {
  value?: string;
  onChange: (color: string) => void;
  onClose: () => void;
};

export const ColorPickerContextMenu: React.FC<ColorPickerContextMenuProps> = ({
  value,
  onChange,
  onClose,
  ...popupMenuProps
}) => {
  const colorOptions = useColorOptions();

  const _onChange = (e: React.MouseEvent) => {
    const id = e.currentTarget?.getAttribute('data-value');
    if (id) {
      onChange?.(id);
    }
  };

  return (
    <PopupMenu {...popupMenuProps} onOutside={onClose}>
      <PopupMenuList>
        {colorOptions.map((option) => (
          <PopupMenuListItem key={option.value}>
            <PopupMenuButton
              start={option.start}
              onClick={_onChange}
              data-value={option.value}
              isSelected={option.value === value}
            >
              {option.label}
            </PopupMenuButton>
          </PopupMenuListItem>
        ))}
      </PopupMenuList>
    </PopupMenu>
  );
};
