import React from 'react';
import { CustomFormSelect } from 'shared/components/ui/custom-form-select';
import { InheritColors } from 'shared/types/inherit-colors';

import * as Styled from './color-picker.style';
import { useColorOptions } from './use-color-options';

export type ColorPickerFormSelectProps = Omit<
  React.ComponentProps<typeof CustomFormSelect>,
  'options' | 'value' | 'renderValue' | 'onChange'
> & {
  color?: string;
  parentColor?: string;
  lifeAreaColor?: string;
  onChange: (color: string | undefined) => void;
};

export const ColorPickerFormSelect: React.FC<ColorPickerFormSelectProps> = ({
  color,
  onChange,
  parentColor,
  lifeAreaColor,
  ...formSelectProps
}) => {
  const colorOptions = useColorOptions({
    parentColor,
    lifeAreaColor: lifeAreaColor,
  });

  return (
    <CustomFormSelect
      {...formSelectProps}
      options={colorOptions}
      value={color}
      renderValue={({ value, label }) => (
        <Styled.SelectedColorContainer>
          <Styled.ColorCircle
            $color={
              value === InheritColors.Goal
                ? parentColor ?? ''
                : value === InheritColors.LifeArea
                  ? lifeAreaColor ?? ''
                  : value
            }
          />
          {label}
        </Styled.SelectedColorContainer>
      )}
      onChange={onChange}
    />
  );
};
