export enum InclusivityOption {
  All = '[]',
  Start = '[)',
  End = '(]',
  None = '()',
}

export const isBetween = (
  date: Date,
  from: Date,
  to: Date,
  inclusivity = InclusivityOption.All,
) => {
  if (!Object.values(InclusivityOption).includes(inclusivity)) {
    throw new Error('Inclusivity parameter must be one of (), [], (], [)');
  }

  const isBeforeEqual = inclusivity[0] === '[';
  const isAfterEqual = inclusivity[1] === ']';

  const dateTime = date.getTime();
  const fromTime = from.getTime();
  const toTime = to.getTime();

  const isBeforeCondition = isBeforeEqual
    ? dateTime >= fromTime
    : dateTime > fromTime;

  const isAfterCondition = isAfterEqual
    ? dateTime <= toTime
    : dateTime < toTime;

  return isBeforeCondition && isAfterCondition;
};
