import { useTags } from 'features/tags';
import { useClearInboxFilters } from 'features/user';
import React, { useEffect, useMemo } from 'react';
import { FilterBannerBase } from 'shared/components/ui/filter-banner-base';
import { useContextMenuState } from 'shared/hooks/use-context-menu-state';
import { useUser } from 'shared/hooks/use-user';

import { FilterContextMenu } from '../filter-context-menu';

export const ActiveFilterBanner: React.FC = () => {
  const tags = useTags();
  const user = useUser();
  const clearFilters = useClearInboxFilters();

  const {
    open: openMenu,
    close: closeMenu,
    position: menuPosition,
  } = useContextMenuState();

  const filteredTags = useMemo(
    () =>
      user?.settings?.inboxFilterByTags?.length
        ? tags.filter(({ id }) =>
            user?.settings?.inboxFilterByTags?.includes(id),
          )
        : [],
    [tags, user?.settings?.inboxFilterByTags],
  );

  useEffect(() => {
    if (!filteredTags.length) {
      closeMenu();
    }
  }, [closeMenu, filteredTags.length]);

  return (
    <>
      <FilterBannerBase
        tags={filteredTags}
        onTags={openMenu}
        onClear={clearFilters}
      />

      {!!menuPosition && (
        <FilterContextMenu onClose={closeMenu} location={menuPosition} />
      )}
    </>
  );
};
