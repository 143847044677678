import { useDeferredValue, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGoalsQuery } from 'shared/hooks/queries/use-goals-query';
import { useLifeAreasQuery } from 'shared/hooks/queries/use-life-areas-query';
import { useTagsQuery } from 'shared/hooks/queries/use-tags-query';
import { useTasksQuery } from 'shared/hooks/queries/use-tasks-query';
import { useUser } from 'shared/hooks/use-user';
import { ID } from 'shared/types/id';
import { Favorite, FavoriteType } from 'shared/types/user-settings';
import { getLocalizedStringValue } from 'shared/utils/get-localized-string-value';

export const useFavorites = () => {
  const user = useUser();
  const { i18n } = useTranslation();
  const favorites = useMemo(
    () => user?.settings?.favorites ?? [],
    [user?.settings?.favorites],
  );

  const favoriteIds = useMemo(
    () =>
      favorites.reduce<Record<FavoriteType, ID[]>>(
        (acc, { type, id }) => {
          acc[type].push(id);
          return acc;
        },
        {
          [FavoriteType.Goal]: [],
          [FavoriteType.Task]: [],
          [FavoriteType.LifeArea]: [],
          [FavoriteType.Tag]: [],
        },
      ),
    [favorites],
  );

  const deferredFavoriteIds = useDeferredValue(favoriteIds);

  const { data: favoriteGoals } = useGoalsQuery(user?.uid, {
    ids: deferredFavoriteIds[FavoriteType.Goal],
  });
  const { data: favoriteLifeAreas } = useLifeAreasQuery(user?.uid, {
    ids: deferredFavoriteIds[FavoriteType.LifeArea],
  });
  const { data: favoriteTasks } = useTasksQuery(user?.uid, {
    ids: deferredFavoriteIds[FavoriteType.Task],
  });
  // get all tags for consistency over tags
  const { data: tags } = useTagsQuery(user?.uid);

  return useMemo(
    () =>
      favorites.reduce<Favorite[]>((acc, { type, id }) => {
        switch (type) {
          case FavoriteType.Goal:
            const goal = favoriteGoals?.find((goal) => goal.id === id);
            if (goal) {
              acc.push({ id: goal.id, type, value: goal });
            }
            break;
          case FavoriteType.Task:
            const task = favoriteTasks?.find(
              (task) => task.id === id && !task.archivedAt,
            );
            if (task) {
              acc.push({ id: task.id, type, value: task });
            }
            break;
          case FavoriteType.LifeArea:
            const lifeArea = favoriteLifeAreas?.find(
              (lifeArea) => lifeArea.id === id,
            );
            if (lifeArea) {
              acc.push({
                id: lifeArea.id,
                type,
                value: {
                  ...lifeArea,
                  name: getLocalizedStringValue(lifeArea.name, i18n.language),
                },
              });
            }
            break;
          case FavoriteType.Tag:
            const tag = tags?.find((tag) => tag.id === id);
            if (tag) {
              acc.push({
                id: tag.id,
                type,
                value: tag,
              });
            }
            break;
          default:
            break;
        }

        return acc;
      }, []),
    [
      favorites,
      favoriteGoals,
      favoriteTasks,
      favoriteLifeAreas,
      tags,
      i18n.language,
    ],
  );
};
