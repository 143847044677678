import { addWeeks, eachDayOfInterval } from 'date-fns';
import { useMemo } from 'react';
import { Options } from 'shared/hooks/use-timeframe-completions';
import { useTimeframeOccurrences } from 'shared/hooks/use-timeframe-occurrences';
import { isBetween } from 'shared/lib/date-fns';
import {
  HabitSchedule,
  ScheduleCompletionActions,
  ScheduleEntryState,
  ScheduleTargetEntry,
} from 'shared/types/habit-schedule';
import { formatDateKey } from 'shared/utils/format-date-key';
import { getScheduleFrequencyDayTarget } from 'shared/utils/get-schedule-frequency-day-target';

export const useTimeframeEntries = (
  schedules: HabitSchedule[],
  { startDate, endDate }: Options,
): {
  dateEntries: ScheduleTargetEntry[];
  timeframeDateEntries: ScheduleTargetEntry[];
} => {
  // get a bigger range for extra completions to display in the month-view
  const { rangeStartDate, rangeEndDate } = useMemo(
    () => ({
      rangeStartDate: addWeeks(startDate, -5),
      rangeEndDate: addWeeks(endDate, 5),
    }),
    [endDate, startDate],
  );
  const { occurrences } = useTimeframeOccurrences(schedules, {
    startDate: rangeStartDate,
    endDate: rangeEndDate,
  });

  return useMemo(() => {
    const daysBetween = eachDayOfInterval({
      start: rangeStartDate,
      end: rangeEndDate,
    });
    const targetEntries = daysBetween.map<ScheduleTargetEntry>((date) => ({
      date,
      value: 0,
      target: 0,
      state: ScheduleEntryState.Complete,
      id: `${date.getTime()}`,
      onDateManual: false,
      showValueAsBadge: false,
    }));

    schedules.forEach((schedule) => {
      const scheduleStartDate = new Date(
        Math.min(
          schedule.startDate.getTime(),
          ...schedule.entries.map(({ date }) => date.getTime()),
        ),
      );
      const scheduleEndDate = schedule.endDate ?? rangeEndDate;
      const scheduleOccurrences = occurrences.filter((date) =>
        isBetween(date, scheduleStartDate, scheduleEndDate),
      );
      const scheduleOccurrenceDateKeys = scheduleOccurrences.map((date) =>
        formatDateKey(date),
      );
      const scheduleDateKeyEntriesMap = schedule.entries.reduce<
        Record<string, number>
      >((acc, entry, currentIndex) => {
        const key = formatDateKey(entry.date);
        acc[key] = currentIndex;
        return acc;
      }, {});

      targetEntries.forEach((entry) => {
        const entryDateKey = formatDateKey(entry.date);
        const entryOccurrence =
          scheduleOccurrenceDateKeys.includes(entryDateKey);
        const scheduleEntryIndex = scheduleDateKeyEntriesMap[entryDateKey];
        const scheduleEntry = schedule.entries[scheduleEntryIndex];
        // find occurrences or entries that match the entry date
        if (entryOccurrence || scheduleEntry) {
          entry.date = scheduleEntry?.date ?? entry.date;
          entry.id = scheduleEntry?.id ?? entry.id;
          entry.state = scheduleEntry?.state ?? entry.state;
          entry.value = scheduleEntry?.value ?? entry.value;
          entry.target = schedule.frequency
            ? getScheduleFrequencyDayTarget(schedule)
            : entryOccurrence
              ? schedule.completionTarget.count
              : entry.target;
          entry.onDateManual =
            schedule.action?.type ===
            ScheduleCompletionActions.LogCompletionValue;
          entry.showValueAsBadge =
            !!schedule.frequency &&
            ((entry.value ?? 0) > 1 ||
              !getScheduleFrequencyDayTarget(schedule));
        }
      });
    });

    return {
      dateEntries: targetEntries.filter(({ date }) =>
        isBetween(date, rangeStartDate, rangeEndDate),
      ),
      timeframeDateEntries: targetEntries.filter(({ date }) =>
        isBetween(date, startDate, endDate),
      ),
    };
  }, [
    endDate,
    occurrences,
    rangeEndDate,
    rangeStartDate,
    schedules,
    startDate,
  ]);
};
