import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconFormIcons, Target } from 'shared/assets/icons';
import { ListSectionGoalHeader } from 'shared/components/ui/list-section-goal-header';
import { Goal } from 'shared/types/goal';
import { ID } from 'shared/types/id';
import { LifeArea } from 'shared/types/life-area';
import { goalInboxID } from 'shared/types/orderable-section';
import { getGoalColor } from 'shared/utils/get-goal-color';

export type GoalHeaderProps = {
  goalId?: ID;
  goals: Goal[];
  lifeAreas: LifeArea[];
  isLink?: boolean;
  onPremium: () => void;
};

export const GoalHeader: React.FC<GoalHeaderProps> = React.memo(
  ({ goalId, goals, lifeAreas, isLink, onPremium }) => {
    const { t } = useTranslation();
    const goal = useMemo(
      () => goals.find(({ id }) => id === goalId),
      [goals, goalId],
    );

    const goalColor = useMemo(
      () =>
        goal?.id
          ? getGoalColor(goal.id, { lifeAreas, goals }) ?? undefined
          : undefined,
      [goal?.id, goals, lifeAreas],
    );

    const goalName = !goalId
      ? t('general.sections.goals.other.title')
      : goal?.name ?? '';

    return (
      <ListSectionGoalHeader
        id={goalId}
        name={goalName}
        image={goal?.image}
        imagePlaceholderIcon={
          !goalId || goalId === goalInboxID
            ? Target
            : goal?.iconName
              ? IconFormIcons[goal.iconName]
              : undefined
        }
        color={goalColor}
        onPremium={onPremium}
        isLink={!!goalId && goalId !== goalInboxID && isLink}
        isFrozen={!!goal?.frozenAt}
      />
    );
  },
);
