import { useCreateUnlimitedGoalsAllowed, useGoals } from 'features/goals';
import { useLocalizedLifeAreas } from 'features/life-areas';
import {
  useCreateHabitAllowed,
  useUpdateHabitScheduleEntry,
  useUpdateTask,
} from 'features/tasks';
import React, { memo, useMemo } from 'react';
import { Subtask } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import { TaskMetaInteraction } from 'shared/components/ui/task-meta-interaction';
import { TaskMetaInteractionDueDate } from 'shared/components/ui/task-meta-interaction-due-date';
import { TaskMetaInteractionLinkedItem } from 'shared/components/ui/task-meta-interaction-linked-item';
import { TaskMetaInteractionPriority } from 'shared/components/ui/task-meta-interaction-priority';
import { TaskMetaInteractionReminderTime } from 'shared/components/ui/task-meta-interaction-reminder-time';
import { TaskMetaInteractionRepeat } from 'shared/components/ui/task-meta-interaction-repeat';
import { useOpenCreateGoalDialog } from 'shared/contexts/goal-form';
import { useOpenEditHabitDialog } from 'shared/contexts/habit-form';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';
import { useToday } from 'shared/contexts/today';
import { useUser } from 'shared/hooks/use-user';
import { initialDateFormat } from 'shared/types/date-format-options';
import { Habit } from 'shared/types/habit';
import { ScheduleEntryState } from 'shared/types/habit-schedule';
import { ID } from 'shared/types/id';
import { RepeatingTask } from 'shared/types/repeating-task';
import { LinkOptions, Task } from 'shared/types/task';
import {
  DueDisplayOptions,
  TaskPriorityOptions,
  TaskType,
} from 'shared/types/task-base';
import { Timestamp } from 'shared/types/timestamp';
import { getTaskDueDate } from 'shared/utils/get-task-due';
import { getWeekStartsOn } from 'shared/utils/get-week-starts-on';
import { shouldDueBeShown } from 'shared/utils/should-due-be-shown';

import { TaskMetaInteractionTags } from './task-meta-interaction-tags';
import * as Styled from './task-meta-interactions.style';

const allDueDisplayOptions = Object.values(DueDisplayOptions);

export type TaskMetaInteractionsProps = {
  task: Task;
  displayDue?: DueDisplayOptions[];
  hasTooltips?: boolean;
  hideEmpty?: boolean;
  hideGoal?: boolean;
  hideLifeArea?: boolean;
  showGoalColor?: boolean;
  highlight?: boolean;
  preventInteraction?: boolean;
  isMobileOutlined?: boolean;
  isFaded?: boolean;
};

export const TaskMetaInteractions: React.FC<TaskMetaInteractionsProps> = memo(
  ({
    task,
    displayDue = allDueDisplayOptions,
    hideEmpty,
    hideGoal,
    hideLifeArea,
    showGoalColor,
    highlight,
    hasTooltips,
    preventInteraction,
    isMobileOutlined,
    isFaded,
  }) => {
    const user = useUser();
    const today = useToday();
    const goals = useGoals();
    const lifeAreas = useLocalizedLifeAreas();

    const { allowed: createGoalAllowed } = useCreateUnlimitedGoalsAllowed();
    const { allowed: createHabitAllowed } = useCreateHabitAllowed();

    const openPremiumDialog = useOpenPremiumDialog();
    const openCreateGoalDialog = useOpenCreateGoalDialog();
    const openEditHabitDialog = useOpenEditHabitDialog();

    const { submit: updateTask } = useUpdateTask();
    const updateEntry = useUpdateHabitScheduleEntry();

    const onCreateGoal = (name?: string) =>
      createGoalAllowed ? openCreateGoalDialog({ name }) : openPremiumDialog();

    // prevent edit mode on the task-card
    const onContainer = (e: React.MouseEvent) => e.stopPropagation();

    const updateDeadline = (date?: Timestamp) => {
      if (task.frozenAt) {
        return;
      }

      const newTask = {
        ...task,
        endStrategy: {
          deadline: date ?? null,
          completionCount: task.endStrategy?.completionCount ?? null,
        },
      };

      updateTask(newTask);
    };

    const updateSkip = (date?: Timestamp) => {
      if (!!task.frozenAt || !date) {
        return;
      }

      updateEntry(task as Habit, date, ScheduleEntryState.Skip);
    };

    const onChangeGoal = (link: { id: ID; type: LinkOptions } | null) =>
      !task.frozenAt &&
      updateTask({
        ...task,
        lifeAreaId: link?.type === LinkOptions.LifeArea ? link.id : null,
        goalId: link?.type === LinkOptions.Goal ? link.id : null,
      });

    const onRepeat = () =>
      openEditHabitDialog(
        {
          ...task,
          type:
            task.type === TaskType.Task
              ? createHabitAllowed
                ? TaskType.Habit
                : TaskType.Repeating
              : task.type,
        } as Habit | RepeatingTask,
        task.id,
      );

    const onChangeReminder = (reminderTime: number | undefined) =>
      !task.frozenAt &&
      updateTask({ ...task, reminderTime: reminderTime ?? null });

    const onChangePriority = (priority: TaskPriorityOptions | null) =>
      !task.frozenAt && updateTask({ ...task, priority });

    const dateFormat = user?.settings?.dateFormat ?? initialDateFormat;
    const weekStartsOn = getWeekStartsOn(user);

    const dueDate = useMemo(
      () => getTaskDueDate(task, { today, weekStartsOn }),
      [task, today, weekStartsOn],
    );

    const hideDueDate = useMemo(
      () => !dueDate || !shouldDueBeShown(dueDate, displayDue, today),
      [displayDue, dueDate, today],
    );

    return (
      <Styled.List
        $preventInteraction={!!preventInteraction}
        $isMobileOutlined={!!isMobileOutlined}
        $isFaded={!!isFaded}
        onClick={onContainer}
      >
        {!!task.childIds?.length && (
          <Styled.ListItem
            $hide={false}
            $isMobileOutlined={!!isMobileOutlined}
            $hasValue={true}
          >
            <TaskMetaInteraction
              start={<Icon icon={Subtask} />}
              label={`${task.childIds.length}`}
            />
          </Styled.ListItem>
        )}

        <Styled.ListItem
          $hasValue={!!dueDate}
          $isMobileOutlined={!!isMobileOutlined}
          $hide={!!hideEmpty && hideDueDate}
        >
          <TaskMetaInteractionDueDate
            dateFormat={dateFormat}
            weekStartsOn={weekStartsOn}
            onChange={task.schedules ? updateSkip : updateDeadline}
            isFrozen={!!task.frozenAt}
            value={dueDate}
            highlight={highlight}
            hasTooltip={hasTooltips}
            isDeadline={!task.schedules}
            isMobileOutlined={isMobileOutlined}
          />
        </Styled.ListItem>
        <Styled.ListItem
          $hasValue={!!task.schedules}
          $isMobileOutlined={!!isMobileOutlined}
          $hide={!!hideEmpty && !task.schedules}
        >
          <TaskMetaInteractionRepeat
            value={task.schedules}
            onClick={onRepeat}
            isFrozen={!!task.frozenAt}
            highlight={highlight}
            hasTooltip={hasTooltips}
            isMobileOutlined={isMobileOutlined}
          />
        </Styled.ListItem>
        <Styled.ListItem
          $hasValue={!!task.goalId || !!task.lifeAreaId}
          $isMobileOutlined={!!isMobileOutlined}
          $hide={
            (!!hideEmpty && !task.goalId && !task.lifeAreaId) ||
            (!!hideGoal && !!task.goalId) ||
            (!!hideLifeArea && !!task.lifeAreaId)
          }
        >
          <TaskMetaInteractionLinkedItem
            goals={goals}
            lifeAreas={lifeAreas}
            value={task.goalId ?? task.lifeAreaId}
            onChange={onChangeGoal}
            isFrozen={!!task.frozenAt}
            onCreate={onCreateGoal}
            showGoalColor={showGoalColor}
            highlight={highlight}
            hasTooltip={hasTooltips}
            isMobileOutlined={isMobileOutlined}
          />
        </Styled.ListItem>

        <TaskMetaInteractionTags
          id={task.id}
          hideEmpty={hideEmpty}
          isMobileOutlined={isMobileOutlined}
        />

        <Styled.ListItem
          $hasValue={!!task.reminderTime}
          $isMobileOutlined={!!isMobileOutlined}
          $hide={!!hideEmpty && !task.reminderTime}
        >
          <TaskMetaInteractionReminderTime
            value={task.reminderTime}
            onChange={onChangeReminder}
            isFrozen={!!task.frozenAt}
            highlight={highlight}
            hasTooltip={hasTooltips}
            isMobileOutlined={isMobileOutlined}
          />
        </Styled.ListItem>
        <Styled.ListItem
          $hasValue={!!task.priority}
          $isMobileOutlined={!!isMobileOutlined}
          $hide={!!hideEmpty}
        >
          <TaskMetaInteractionPriority
            value={task.priority}
            onChange={onChangePriority}
            isFrozen={!!task.frozenAt}
            highlight={highlight}
            hasTooltip={hasTooltips}
            isMobileOutlined={isMobileOutlined}
          />
        </Styled.ListItem>
      </Styled.List>
    );
  },
);
