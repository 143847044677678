import { CSS, Transform } from '@dnd-kit/utilities';
import { dragging } from 'shared/assets/styles';
import { typographyToCss } from 'shared/lib/mui';
import styled, { css } from 'styled-components';

export const DndContainer = styled.div<{
  $isDragging?: boolean;
  $transform?: Transform | null;
  $transition?: string;
}>`
  transform: ${({ $transform }) =>
    $transform && CSS.Translate.toString($transform)};
  transition: ${({ $transition }) => `background 200ms, ${$transition}`};

  ${({ $isDragging }) => $isDragging && dragging};
`;

export const Container = styled.div<{ $isDisabled: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  padding: ${({ theme }) => `0 ${theme.spacing(1)} 0 ${theme.spacing(3)}`};
  width: 100%;

  border: 1px solid ${({ theme }) => theme.palette.grey['100']};
  border-radius: ${({ theme }) => theme.spacing(3)};
  background: ${({ theme }) => theme.palette.background.default};
  box-shadow: ${({ theme }) => theme.shadowBox.card};

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      opacity: 0.5;
    `};
`;

export const Details = styled.span<{ $isFrozen: boolean }>`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};
  height: 4.8rem;
  min-width: 0;
  width: 0; // flex fixes the width, making the width 0 means that it will actually use ellipsis and not get wider than is allowed

  overflow: hidden;
  cursor: ${({ $isFrozen }) => ($isFrozen ? 'default' : 'pointer')};
`;

export const IconContainer = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 2.4rem;
  width: 2.4rem;
  min-width: 2.4rem;

  border-radius: ${({ theme }) => theme.spacing(2)};
  background: ${({ theme }) => theme.palette.brown['100']};

  font-size: 1.6rem;
  line-height: 62.5%;
  color: ${({ theme }) => theme.palette.brown['300']};
`;
export const CompletedContainer = styled.span`
  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 1.2rem;
  height: 1.2rem;

  transform: translate(33%, -33%);
  background: ${({ theme }) => theme.palette.success.main};
  border-radius: 50%;

  font-size: 0.8rem;
  color: ${({ theme }) => theme.palette.success.contrastText};
`;

export const ColorDot = styled.span<{ $color: string }>`
  position: absolute;
  bottom: ${({ theme }) => `-${theme.spacing(0.75)}`};
  right: ${({ theme }) => `-${theme.spacing(0.75)}`};

  display: block;
  height: 1rem;
  width: 1rem;

  border: ${({ theme }) =>
    `${theme.spacing(0.5)} solid ${theme.palette.background.default};`};
  border-radius: 50%;
  background: ${({ $color }) => $color};
`;

export const TitleContainer = styled.span`
  flex: 1;
  display: inline-flex;
  gap: ${({ theme }) => theme.spacing(1.5)};
  align-items: center;

  min-width: 0;
`;

export const Title = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.subtitle2)};

  overflow: hidden;

  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const TitleIconContainer = styled.span<{ $secondary?: boolean }>`
  display: inline-block;

  font-size: 1.4rem;
  line-height: 62.5%;

  ${({ $secondary, theme }) =>
    $secondary &&
    css`
      color: ${theme.palette.text.secondary};
      opacity: 0.6;
    `};
`;

export const WeeksContainer = styled.div<{ $disabled: boolean }>`
  padding: ${({ theme }) => `0 ${theme.spacing(5)}`};

  ${({ $disabled }) =>
    $disabled &&
    css`
      pointer-events: none;
    `};
`;

export const MetaContainer = styled.div<{
  $isProgress?: boolean;
  $completed?: boolean;
  $disabled?: boolean;
}>`
  ${({ theme, $completed }) =>
    typographyToCss($completed ? theme.typography.h6 : theme.typography.body2)}
  display: flex;
  padding: ${({ theme }) => `0 ${theme.spacing(3)}`};

  ${({ $isProgress, theme }) =>
    $isProgress &&
    css`
      color: ${theme.palette.primary.main};
      width: 8rem;
    `};

  ${({ $disabled }) =>
    $disabled &&
    css`
      pointer-events: none;
    `};
`;
