import styled from 'styled-components';

export const SelectedColorContainer = styled.span`
  display: inline-flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};
`;

export const ColorCircle = styled.span<{ $color?: string }>`
  display: block;
  width: 1rem;
  height: 1rem;
  background: ${({ $color }) => $color ?? 'transparent'};
  border-radius: 50%;
`;
