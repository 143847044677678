import { DndContext } from '@dnd-kit/core';
import React, { useMemo } from 'react';
import { TaskCard } from 'shared/components/connected/task-card';
import { OverlayComponentWrapper } from 'shared/components/ui/overlay-component-wrapper';
import { SortableOverlay } from 'shared/components/ui/sortable-overlay';
import { useActiveTaskId } from 'shared/contexts/task-detail';
import { useDroppableSectionControls } from 'shared/hooks/use-droppable-section-controls';
import { useSectionCollisionDetection } from 'shared/hooks/use-section-collision-detection';
import { useSortableSensors } from 'shared/hooks/use-sortable-sensors';

import { useHandleLifeAreaGoalSectionReorder } from '../../hooks/use-handle-life-area-goal-section-reorder';
import { useLifeAreaGroupingSections } from '../../hooks/use-life-area-grouping-sections';
import { EmptyLifeAreaGroupingTasksList } from '../empty-life-area-grouping-tasks-list';
import { GoalSection } from './goal-section';
import * as Styled from './life-area-grouping-tasks-list.style';
import { LifeAreaSection } from './life-area-section';

export const LifeAreaGroupingTasksList: React.FC = () => {
  const selectedTask = useActiveTaskId();

  const { sections: initialSections, tasks } = useLifeAreaGroupingSections();
  const onDragEnd = useHandleLifeAreaGoalSectionReorder(initialSections);

  const {
    handleDragStart,
    handleDragOver,
    handleDragEnd,
    handleDragCancel,
    activeId,
    sections,
  } = useDroppableSectionControls({ initialSections, onDragEnd });
  const sensors = useSortableSensors();
  const collisionStrategy = useSectionCollisionDetection(activeId, sections);

  const activeItem = useMemo(
    () => (activeId ? tasks.find((task) => task.id === activeId) : undefined),
    [activeId, tasks],
  );

  const lifeAreaSections = useMemo(
    () => sections.filter(({ goalIds }) => !!goalIds),
    [sections],
  );

  const leftOverSections = useMemo(
    () =>
      sections.filter(
        (section) =>
          !section.goalIds &&
          !sections.find(({ goalIds }) => goalIds?.includes(section.id)),
      ),
    [sections],
  );

  return !tasks.length ? (
    <EmptyLifeAreaGroupingTasksList />
  ) : (
    <DndContext
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
      sensors={sensors}
      collisionDetection={collisionStrategy}
    >
      <Styled.Container>
        {lifeAreaSections.map(({ id, items, goalIds }) => (
          <React.Fragment key={id}>
            <LifeAreaSection
              id={id}
              items={items}
              goalIds={goalIds}
              sections={sections}
            />
            <Styled.Divider />
          </React.Fragment>
        ))}

        {leftOverSections.map(({ id, items }) => (
          <GoalSection key={id} id={id} items={items} />
        ))}
      </Styled.Container>

      <SortableOverlay>
        {!!activeItem && (
          <OverlayComponentWrapper>
            <TaskCard
              task={activeItem}
              isSelected={selectedTask === activeItem.id}
              hideGoal
              hideLifeArea
              isMoving
              noHover
            />
          </OverlayComponentWrapper>
        )}
      </SortableOverlay>
    </DndContext>
  );
};
