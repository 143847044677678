import styled from 'styled-components';

export const ListContainer = styled.div``;

export const List = styled.ul<{ $isVirtualList?: boolean }>`
  position: relative;
  width: 100%;

  overflow: hidden;
  list-style: none;

  margin: 0;
  padding: 0;
`;

export const ListItem = styled.li<{ $isVirtualListItem?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;
