import { isSameDay } from 'date-fns';
import { ScheduleEntryState } from 'shared/types/habit-schedule';
import { Task } from 'shared/types/task';
import { TaskType } from 'shared/types/task-base';
import { Timestamp } from 'shared/types/timestamp';
import { getActiveSchedule } from 'shared/utils/get-active-schedule';

export const getTaskCompletedState = (
  { type, completedAt, schedules }: Task,
  today: Timestamp,
  showRepeatingAsTodo?: boolean,
) => {
  if (type === TaskType.Task) {
    return !!completedAt;
  }

  if (!showRepeatingAsTodo) {
    return false;
  }

  const activeSchedule = getActiveSchedule(schedules);
  if (!activeSchedule || completedAt) {
    return true;
  }

  return !!activeSchedule.entries.find(
    ({ state, date, value }) =>
      state === ScheduleEntryState.Complete &&
      !!value &&
      isSameDay(today, date),
  );
};
