import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Blurhash } from 'react-blurhash';
import { useBucketImage } from 'shared/hooks/use-bucket-image';

import * as Styled from './blur-hash-image.style';

export type ImageProps = React.ComponentProps<'img'> & {
  hash?: string | null;
};

export const Img: React.FC<ImageProps> = ({
  src: initialSrc,
  alt,
  hash,
  ref: _ref,
  ...rest
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const { src: bucketSrc, isBucketLink } = useBucketImage(initialSrc);
  const src = isBucketLink ? bucketSrc : initialSrc;

  useEffect(() => {
    if (src && hash) {
      const image = new Image();
      image.onload = () => setImageLoaded(true);
      image.src = src;

      return () => {
        image.onload = null;
      };
    }
  }, [src, hash]);

  if (hash && !imageLoaded) {
    return <Blurhash hash={hash} width="100%" height="100%" {...rest} />;
  }

  if (src) {
    return <Styled.Image src={src} alt={alt} {...rest} />;
  }
};

export const LoadingImg = () => (
  <Styled.PlaceholderContainer>
    <CircularProgress size="1em" />
  </Styled.PlaceholderContainer>
);
