import { endOfMonth, startOfMonth } from 'date-fns';
import { useMemo } from 'react';
import { useTasksQuery } from 'shared/hooks/queries/use-tasks-query';
import { useUser } from 'shared/hooks/use-user';
import { Timestamp } from 'shared/types/timestamp';

export const useMonthTasks = (date: Timestamp) => {
  const user = useUser();
  const monthStartDate = useMemo(() => startOfMonth(date), [date]);
  const monthEndDate = useMemo(() => endOfMonth(date), [date]);

  const { data } = useTasksQuery(user?.uid, {
    deadlineStartDate: monthStartDate,
    deadlineEndDate: monthEndDate,
    unFrozenOnly: true,
    uncompletedOnly: true,
  });

  return useMemo(
    () => data?.filter(({ archivedAt }) => !archivedAt) ?? [],
    [data],
  );
};
