import { format } from 'date-fns';
import React from 'react';
import { Timestamp } from 'shared/types/timestamp';

import * as Styled from './month-progress-preview.style';

export type HeaderDateProps = {
  date: Timestamp;
};

export const HeaderDate: React.FC<HeaderDateProps> = ({ date }) => (
  <Styled.HeaderDate>{format(date, 'EEEEE')}</Styled.HeaderDate>
);
