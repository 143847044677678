import { isBefore, startOfDay } from 'date-fns';
import React, { useMemo } from 'react';
import { Calendar } from 'shared/assets/icons';
import { Datepicker } from 'shared/components/ui/datepicker';
import { FormInput } from 'shared/components/ui/form-input';
import { RRule } from 'shared/lib/rrule';
import {
  DateFormatOptions,
  dateFormatOptionToLabelMap,
} from 'shared/types/date-format-options';
import { HabitSchedule } from 'shared/types/habit-schedule';
import { Timestamp } from 'shared/types/timestamp';
import { WeekDays } from 'shared/types/week-days';
import { getActiveSchedule } from 'shared/utils/get-active-schedule';

import * as Styled from './habit-form.style';

export type ScheduleStartDateInputProps = {
  schedules: HabitSchedule[];
  onChange: (schedules: HabitSchedule[] | null) => void;
  weekStartsOn: WeekDays;
  dateFormat: DateFormatOptions;
  today: Timestamp;
};

export const ScheduleStartDateInput: React.FC<ScheduleStartDateInputProps> = ({
  schedules,
  weekStartsOn,
  dateFormat,
  today,
  onChange,
}) => {
  const activeSchedule = useMemo(
    () => getActiveSchedule(schedules),
    [schedules],
  );

  const { interval, occurrences } = useMemo(() => {
    if (!activeSchedule?.rrule) {
      return { interval: 0, occurrences: [] };
    }

    const rrule = RRule.fromString(activeSchedule.rrule.format);

    rrule.options.dtstart = startOfDay(activeSchedule.startDate);
    rrule.options.count = 20;
    rrule.origOptions.wkst = 0;
    const occurrences = rrule.all();

    return {
      interval: rrule.options.interval,
      occurrences,
    };
  }, [activeSchedule?.rrule, activeSchedule?.startDate]);

  const onChangeDatepicker = (startDate: Date) => {
    if (isBefore(startDate, today)) {
      return;
    }

    const newSchedules = structuredClone(schedules);
    const scheduleToUpdate = getActiveSchedule(newSchedules);

    if (!scheduleToUpdate) {
      return;
    }

    scheduleToUpdate.startDate = startDate;

    onChange(newSchedules);
  };

  return (
    <Styled.DatepickerContainer $fullWidth={!interval}>
      <Datepicker
        today={today}
        weekStartsOn={weekStartsOn}
        onChange={onChangeDatepicker}
        dateFormat={dateFormat}
        selected={activeSchedule?.startDate}
        placeholderText={dateFormatOptionToLabelMap[dateFormat]}
        customInput={
          interval <= 1 ? (
            <FormInput iconRight={Calendar} canClear />
          ) : undefined
        }
        highlightDates={occurrences}
        popperProps={!interval ? { strategy: 'fixed' } : undefined}
        inline={!!interval}
        bordered={!!interval}
      />
    </Styled.DatepickerContainer>
  );
};
