import { SortMenuOptions } from 'shared/types/sort-menu-options';
import { SortingMode, TagGroupBy } from 'shared/types/sorting-mode';

export type GroupByMenuOptions = {
  id: TagGroupBy;
  value: TagGroupBy;
  label: string;
};

export const groupByOptions: GroupByMenuOptions[] = [
  {
    id: TagGroupBy.None,
    value: TagGroupBy.None,
    label: `general.labels.tasks.group-by.label.${TagGroupBy.None}`,
  },
  {
    id: TagGroupBy.Goal,
    value: TagGroupBy.Goal,
    label: `general.labels.tasks.group-by.label.${TagGroupBy.Goal}`,
  },
  {
    id: TagGroupBy.LifeArea,
    value: TagGroupBy.LifeArea,
    label: `general.labels.tasks.group-by.label.${TagGroupBy.LifeArea}`,
  },
  {
    id: TagGroupBy.Priority,
    value: TagGroupBy.Priority,
    label: `general.labels.tasks.group-by.label.${TagGroupBy.Priority}`,
  },
  {
    id: TagGroupBy.Type,
    value: TagGroupBy.Type,
    label: `general.labels.tasks.group-by.label.${TagGroupBy.Type}`,
  },
];

export const sortOptions: SortMenuOptions<SortingMode>[] = [
  {
    id: SortingMode.DueDate,
    value: SortingMode.DueDate,
    label: `general.labels.tasks.sort.options.${SortingMode.DueDate}`,
  },
  {
    id: SortingMode.Priority,
    value: SortingMode.Priority,
    label: `general.labels.tasks.sort.options.${SortingMode.Priority}`,
  },
];
