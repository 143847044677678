import { typographyToCss } from 'shared/lib/mui';
import styled, { css } from 'styled-components';

export const Title = styled.span<{ $isCompleted: boolean }>`
  ${({ theme }) => typographyToCss(theme.typography.buttonSmall)};
  flex: 1;
  min-width: 0;

  overflow: hidden;

  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${({ theme }) => theme.palette.text.primary};

  ${({ $isCompleted }) =>
    $isCompleted &&
    css`
      text-decoration: line-through;
    `};
`;

export const Container = styled.article<{ $isPreview: boolean }>`
  width: 100%;

  border-radius: ${({ theme }) => theme.spacing(3)};
  border: 1px solid ${({ theme }) => theme.palette.grey[100]};
  box-shadow: ${({ theme }) => theme.shadowBox.card};
  background: ${({ theme }) => theme.palette.backgroundExtended.card};
  transition: transform 200ms ease-in-out;
  cursor: pointer;

  ${({ $isPreview }) =>
    $isPreview &&
    css`
      cursor: default;
      filter: blur(10px);
    `};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(1)};
  padding: ${({ theme }) =>
    `${theme.spacing(4)} ${theme.spacing(4)} ${theme.spacing(2)}`};

  overflow: hidden;
  min-width: 0;
  width: 100%;
`;

export const GoalInformationContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};

  min-width: 0;
`;

export const IllustrationContainer = styled.span`
  position: relative;
  display: block;
`;

export const ImageContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 2rem;
  width: 2rem;
  min-width: 2rem;

  border-radius: ${({ theme }) => theme.spacing(1.5)};
  background: ${({ theme }) => theme.palette.brown['100']};
  overflow: hidden;

  font-size: 1.2rem;
  line-height: 62.5%;
  color: ${({ theme }) => theme.palette.brown['300']};
`;

export const CompletedContainer = styled.span`
  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 1.2rem;
  height: 1.2rem;

  transform: translate(33%, -33%);
  background: ${({ theme }) => theme.palette.success.main};
  border-radius: 50%;

  font-size: 0.8rem;
  color: ${({ theme }) => theme.palette.success.contrastText};
`;

export const ColorDot = styled.span<{ $color: string }>`
  position: absolute;
  bottom: ${({ theme }) => `-${theme.spacing(0.75)}`};
  right: ${({ theme }) => `-${theme.spacing(0.75)}`};

  display: block;
  height: 1rem;
  width: 1rem;

  border: ${({ theme }) =>
    `${theme.spacing(0.5)} solid ${theme.palette.background.default};`};
  border-radius: 50%;
  background: ${({ $color }) => $color};
`;

export const IconButtonContainer = styled.div`
  color: ${({ theme }) => theme.palette.grey['400']};
`;

export const Body = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) =>
    `${theme.spacing(0.5)} ${theme.spacing(4)} ${theme.spacing(1)}`};
  min-width: 0;

  color: ${({ theme }) => theme.palette.text.primary};
`;

export const CurrentProgress = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.subtitle2)};
  display: block;
  height: 2.8rem;

  overflow: hidden;

  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ProgressContainer = styled.span`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)};
  justify-content: space-between;
`;

export const Progress = styled.span<{ $isPositive: boolean }>`
  ${({ theme }) => typographyToCss(theme.typography.overline)};
  display: inline-flex;
  gap: ${({ theme }) => theme.spacing(1.5)};
  align-items: center;

  color: ${({ $isPositive, theme }) =>
    $isPositive ? theme.palette.success.dark : theme.palette.text.secondary};
`;

export const Difference = styled.span<{ $difference?: number }>`
  ${({ theme }) => typographyToCss(theme.typography.caption)};
  display: flex;
  align-items: center;

  color: ${({ $difference, theme }) =>
    $difference && $difference > 0
      ? theme.palette.success.dark
      : theme.palette.error.dark};
`;

export const DifferenceIconContainer = styled.span<{ $positive: boolean }>`
  font-size: 1.2rem;
  line-height: 62.5%;

  transform: rotate(${({ $positive }) => ($positive ? 0 : 180)}deg);
`;

export const Footer = styled.div<{ $smallSpacing: boolean }>`
  padding: ${({ theme, $smallSpacing }) =>
    $smallSpacing
      ? `${theme.spacing(1)} ${theme.spacing(4)} ${theme.spacing(1)}`
      : `${theme.spacing(1)} ${theme.spacing(4)} ${theme.spacing(4)}`};
`;
