import { doc, getDoc, writeBatch } from 'firebase/firestore';
import { ID, IDPrefixes } from 'shared/types/id';
import { createPrefixId } from 'shared/utils/prefix-id';

import { CollectionOptions, getCollection, getDatabase } from './helpers';

export const deleteTag = async (id: ID) => {
  const tagCollection = getCollection(CollectionOptions.Tags);
  const relationshipCollection = getCollection(CollectionOptions.Relationships);

  // Create batch
  const batch = writeBatch(getDatabase());

  // Tag document - always delete this
  const tagDocRef = doc(tagCollection, id);
  batch.delete(tagDocRef);

  // Check if relationship document exists before deleting
  const relationshipId = createPrefixId(id, IDPrefixes.Tag);
  const relationshipDocRef = doc(relationshipCollection, relationshipId);
  try {
    const relationshipDoc = await getDoc(relationshipDocRef);

    // Only add to batch if document exists
    if (relationshipDoc.exists()) {
      batch.delete(relationshipDocRef);
    }
  } catch (e) {
    // relationship does not exist
  }

  batch.commit();
};
