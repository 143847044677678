import { DndContext } from '@dnd-kit/core';
import { useCreateHabitAllowed } from 'features/tasks';
import React, { useMemo } from 'react';
import { OverlayComponentWrapper } from 'shared/components/ui/overlay-component-wrapper';
import { SortableOverlay } from 'shared/components/ui/sortable-overlay';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';
import { useOpenTaskDetail } from 'shared/contexts/task-detail';
import { useDroppableSectionControls } from 'shared/hooks/use-droppable-section-controls';
import { useSectionCollisionDetection } from 'shared/hooks/use-section-collision-detection';
import { useSortableSensors } from 'shared/hooks/use-sortable-sensors';
import { ID } from 'shared/types/id';
import { TaskPriorityOptions } from 'shared/types/task-base';

import { useWeekDate } from '../../contexts/timeframe';
import { usePriorityHabitReorder } from '../../hooks/use-priority-habit-reorder';
import { usePriorityHabitSections } from '../../hooks/use-priority-habit-sections';
import { HabitDetailCard } from '../habit-detail-card';
import { HabitSection } from '../habit-section';
import * as Styled from './priority-grouping-habits-list.style';

export const PriorityGroupingHabitsList: React.FC = () => {
  const weekDate = useWeekDate();

  const openTaskDetail = useOpenTaskDetail();

  const {
    allowed: createHabitsAllowed,
    enforced: createHabitLimitsEnforced,
    limit: createHabitLimits,
    total: totalHabits,
  } = useCreateHabitAllowed();
  const openPremiumDialog = useOpenPremiumDialog();

  const { habits, sections: initialSections } = usePriorityHabitSections();
  const onDragEnd = usePriorityHabitReorder(initialSections);

  const {
    handleDragStart,
    handleDragOver,
    handleDragEnd,
    handleDragCancel,
    activeId,
    sections,
  } = useDroppableSectionControls({ initialSections, onDragEnd });
  const sensors = useSortableSensors();
  const collisionStrategy = useSectionCollisionDetection(activeId, sections);

  const onHabit = (id: ID) => {
    const habit = habits.find((habit) => habit.id === id);
    if (habit && !habit.frozenAt) {
      openTaskDetail(id);
    }
  };

  const activeItem = useMemo(
    () => (activeId ? habits.find(({ id }) => id === activeId) : undefined),
    [activeId, habits],
  );

  const showPremiumBanner =
    !createHabitsAllowed ||
    (!createHabitLimitsEnforced && createHabitLimits < totalHabits);

  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
      sensors={sensors}
      collisionDetection={collisionStrategy}
    >
      <Styled.Container>
        {sections.map(({ id, items }, index) => (
          <Styled.Section key={id}>
            <HabitSection
              droppableId={id}
              priority={id as TaskPriorityOptions}
              habits={items}
              onHabit={onHabit}
              weekDate={weekDate}
              onPremium={
                !index && showPremiumBanner ? openPremiumDialog : undefined
              }
            />
          </Styled.Section>
        ))}
      </Styled.Container>

      <SortableOverlay>
        {!!activeItem && (
          <OverlayComponentWrapper>
            <HabitDetailCard habit={activeItem} weekDate={weekDate} />
          </OverlayComponentWrapper>
        )}
      </SortableOverlay>
    </DndContext>
  );
};
