import { useTags } from 'features/tags';
import React, { useRef, useState } from 'react';
import { Sliders } from 'shared/assets/icons';
import { IconButton, Variants } from 'shared/components/ui/icon-button';
import { useOpenMenu } from 'shared/hooks/use-open-menu';
import { useTheme } from 'styled-components';

import { FilterContextMenu } from '../filter-context-menu';
import * as Styled from './header-interactions.style';
import { MainContextMenu } from './main-context-menu';

export type HeaderInteractionsProps = {};

export const HeaderInteractions: React.FC<HeaderInteractionsProps> = () => {
  const theme = useTheme();
  const containerRef = useRef<HTMLDivElement>(null);
  const { menuOpen, openMenu, closeMenu } = useOpenMenu();
  const [showTagFilters, setShowTagFilters] = useState(false);
  const tags = useTags();

  const onFilter = tags.length ? () => setShowTagFilters(true) : undefined;

  const _onClose = () => {
    setShowTagFilters(false);
    closeMenu();
  };

  return (
    <Styled.Container ref={containerRef}>
      <IconButton
        icon={Sliders}
        onClick={openMenu}
        variant={theme.isMobile ? Variants.Outlined : Variants.Contained}
      />

      {menuOpen &&
        (showTagFilters ? (
          <FilterContextMenu
            onClose={_onClose}
            referenceElement={containerRef}
          />
        ) : (
          <MainContextMenu
            onClose={_onClose}
            referenceElement={containerRef}
            onFilter={onFilter}
          />
        ))}
    </Styled.Container>
  );
};
