import Typography from '@mui/material/Typography';
import React, { useMemo } from 'react';
import { Tag, X } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import { IconButton } from 'shared/components/ui/icon-button';
import { Tag as TagType } from 'shared/types/tag';

import * as Styled from './filter-banner-base.style';

export type FilterBannerBaseProps = {
  tags: TagType[];
  onTags: (e: React.MouseEvent) => void;
  onClear: () => void;
};

export const FilterBannerBase: React.FC<FilterBannerBaseProps> = ({
  tags,
  onTags,
  onClear,
}) => {
  const filterTagNames = useMemo(
    () => tags.map(({ name }) => name).join(', '),
    [tags],
  );

  return (
    <Styled.Container $open={!!filterTagNames.length}>
      <Styled.Content>
        <Styled.TagsContainer onClick={onTags}>
          <Styled.IconContainer>
            <Icon icon={Tag} />
          </Styled.IconContainer>
          <Typography variant="buttonSmall" component="span">
            {filterTagNames}
          </Typography>
        </Styled.TagsContainer>

        <IconButton icon={X} onClick={onClear} />
      </Styled.Content>
    </Styled.Container>
  );
};
