import { useHabits } from 'features/tasks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TitledCollapse } from 'shared/components/ui/titled-collapse';

import { useWeekDate } from '../../contexts/timeframe';
import { HabitsList } from '../habits-list';
import * as Styled from './archived-habits-list.style';

export const ArchivedHabitsList: React.FC = () => {
  const { t } = useTranslation();
  const weekDate = useWeekDate();
  const habits = useHabits({ archivedOnly: true });

  if (!habits.length) {
    return null;
  }

  return (
    <Styled.Container>
      <TitledCollapse
        title={t('pages.habits.archived-section.title')}
        titleCount={habits.length}
      >
        <HabitsList items={habits} weekDate={weekDate} />
      </TitledCollapse>
    </Styled.Container>
  );
};
