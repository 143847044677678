import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Sizes, Variants } from 'shared/components/ui/button';

import * as Styled from './tag-form-error.style';

export type TagFormErrorProps = {
  onRetry: () => void;
  onCancel: () => void;
  isEdit?: boolean;
};

export const TagFormError: React.FC<TagFormErrorProps> = ({
  onRetry,
  onCancel,
  isEdit,
}) => {
  const { t } = useTranslation();
  return (
    <Styled.Container>
      <Styled.Body>
        <Typography variant="h2">
          {t(
            isEdit
              ? 'forms.tag.retry.edit.title'
              : 'forms.tag.retry.create.title',
          )}
        </Typography>
        <Typography variant="overline">
          {t(
            isEdit
              ? 'forms.tag.retry.edit.description'
              : 'forms.tag.retry.create.description',
          )}
        </Typography>
      </Styled.Body>
      <Styled.Interactions>
        <Button
          variant={Variants.Outlined}
          size={Sizes.Medium}
          onClick={onCancel}
          type="button"
        >
          {t('forms.tag.retry.cancel.label')}
        </Button>
        {onRetry && (
          <Button size={Sizes.Medium} type="submit" onClick={onRetry}>
            {t('forms.tag.retry.save.label')}
          </Button>
        )}
      </Styled.Interactions>
    </Styled.Container>
  );
};
