import { index, Options } from 'shared/assets/styles';
import { typographyToCss } from 'shared/lib/mui';
import styled, { css, keyframes } from 'styled-components';

import { Colors } from './types';

export const positioningOffset = 24;

const inAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 21.6rem;
  width: fit-content;
  max-width: calc(100% - (${positioningOffset}px * 2));
  max-height: calc(100vh - (${positioningOffset}px * 2));
  z-index: ${index(Options.TOOLTIP)};

  border-radius: ${({ theme }) => theme.spacing(2)};
  border: 1px solid ${({ theme }) => theme.palette.divider};
  background: ${({ theme }) => theme.palette.background.default};
  box-shadow: ${({ theme }) => theme.shadowBox.menu};
  animation: ${inAnimation} 200ms linear;
  overflow: auto;
`;

const listBase = css<{ $hasBottomBorder: boolean; $isScrollable: boolean }>`
  border-bottom: ${({ theme, $hasBottomBorder }) =>
    $hasBottomBorder && `1px solid ${theme.palette.divider}`};

  ${({ $isScrollable }) =>
    $isScrollable &&
    css`
      min-height: 0;
      height: inherit;
      overflow: auto;
    `};

  &:last-child {
    border-bottom: none;
  }
`;

export const ListGroup = styled.div<{
  $hasBottomBorder: boolean;
  $isScrollable: boolean;
}>`
  ${listBase};
`;

export const List = styled.ul<{
  $hasBottomBorder: boolean;
  $isScrollable: boolean;
}>`
  ${listBase};
  margin: 0;
  padding: ${({ theme }) => theme.spacing(1)};

  list-style: none;
`;

export const ListItem = styled.li`
  margin: 0;
  padding: 0;
`;

const itemBase = css<{ $color?: Colors }>`
  ${({ theme }) => typographyToCss(theme.typography.body2)};
  display: inline-flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};

  padding: ${({ theme }) => `${theme.spacing(1.5)} ${theme.spacing(3)}`};
  margin: 0;
  width: 100%;
  min-width: 0;
  max-width: 100%;

  background: transparent;
  border: none;
  border-radius: ${({ theme }) => theme.spacing(2)};
  transition: background 200ms;

  text-align: left;
  color: ${({ theme, $color }) => {
    switch ($color) {
      case Colors.Primary:
        return theme.palette.primary.main;
      case Colors.Warning:
        return theme.palette.warning.main;
      case Colors.Error:
        return theme.palette.error.dark;
      case Colors.Info:
        return theme.palette.info.main;
      case Colors.TextSecondary:
        return theme.palette.text.secondary;
      default:
        return theme.palette.text.primary;
    }
  }};
`;

const interActiveItemBase = css<{ $color?: Colors; $noHover?: boolean }>`
  ${itemBase};
  cursor: pointer;

  ${({ $noHover }) =>
    !$noHover &&
    css`
      &:hover {
        background: ${({ theme }) => theme.palette.brown['100']};
      }
    `};
`;

export const Button = styled.button<{
  $color?: Colors;
  $isSelected: boolean;
  $hasIndentation: boolean;
}>`
  ${interActiveItemBase};

  ${({ $hasIndentation }) =>
    $hasIndentation &&
    css`
      padding-left: ${({ theme }) => theme.spacing(9)};
    `}

  ${({ $isSelected, theme }) =>
    $isSelected &&
    css`
      background: ${theme.palette.primaryExtended.opacityBackgroundBackground};
      color: ${theme.palette.primaryExtended.opacityBackgroundText};
    `};
`;

export const InputLabel = styled.label<{ $color?: Colors; $noHover?: boolean }>`
  ${interActiveItemBase};
  position: relative;
`;

export const Toggle = styled.label<{
  $color?: Colors;
  $noHover?: boolean;
}>`
  ${interActiveItemBase};
`;

export const ToggleContent = styled.span`
  flex: 1;

  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
  justify-content: space-between;
  align-items: center;
`;

export const TitleContainer = styled.span<{ $color?: Colors }>`
  ${itemBase};
`;

export const ButtonContent = styled.span<{ $overflowEllipsis: boolean }>`
  flex: 1;

  ${({ $overflowEllipsis }) =>
    $overflowEllipsis &&
    css`
      overflow: hidden;

      text-overflow: ellipsis;
      white-space: nowrap;
    `};
`;

export const Input = styled.input<{ $hidden: boolean; $hasStartIcon: boolean }>`
  ${({ theme }) => typographyToCss(theme.typography.body2)};
  position: absolute;
  top: 0;
  right: ${({ theme }) => theme.spacing(3)};
  bottom: 0;
  left: ${({ $hasStartIcon, theme }) => ($hasStartIcon ? theme.spacing(9) : 0)};

  border: none;
  background: transparent;

  color: inherit;
  outline: none;

  ${({ $hidden }) =>
    $hidden &&
    css`
      top: unset;
      right: unset;
      bottom: unset;
      left: unset;
      width: 0;
      height: 0;
      margin: 0;
      padding: 0;

      border: none;
      visibility: hidden;
    `};
`;

export const AffixContainer = styled.span<{
  $color?: Colors;
  $isSelected?: boolean;
}>`
  font-size: 1.6rem;
  line-height: 62.5%;

  color: ${({ theme, $color }) => {
    switch ($color) {
      case Colors.Primary:
        return theme.palette.primary.main;
      case Colors.Warning:
        return theme.palette.warning.main;
      case Colors.Error:
        return theme.palette.error.dark;
      case Colors.Info:
        return theme.palette.info.main;
      case Colors.TextSecondary:
        return theme.palette.text.secondary;
      default:
        return theme.palette.grey['400'];
    }
  }};

  ${({ $isSelected, theme }) =>
    $isSelected &&
    css`
      color: ${theme.palette.primaryExtended.opacityBackgroundText};
    `};
`;

export const PremiumLabel = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.overline)};
  display: block;

  padding: ${({ theme }) => `${theme.spacing(0.5)} ${theme.spacing(2)}`};

  background: ${({ theme }) => theme.palette.info.light};
  border-radius: ${({ theme }) => theme.spacing(6)};

  color: ${({ theme }) => theme.palette.info.main};
`;
