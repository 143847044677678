import { useHabits } from 'features/tasks';
import { useMemo } from 'react';
import { useToday } from 'shared/contexts/today';
import { useGoalSections } from 'shared/hooks/use-goal-sections';
import { useUser } from 'shared/hooks/use-user';
import { Habit } from 'shared/types/habit';
import { goalInboxID } from 'shared/types/orderable-section';
import { SortingMode } from 'shared/types/sorting-mode';
import { getSortedTasks } from 'shared/utils/get-sorted-tasks';
import { getWeekStartsOn } from 'shared/utils/get-week-starts-on';

export const useGoalHabitSections = () => {
  const today = useToday();
  const user = useUser();

  const habits = useHabits({ uncompletedOnly: true, unArchivedOnly: true });

  const goalSections = useGoalSections();

  const sections = useMemo(
    () =>
      goalSections
        .map(({ id }) => ({
          id,
          items: getSortedTasks(
            habits.filter(({ goalId }) =>
              id === goalInboxID ? !goalId : goalId === id,
            ),
            {
              today,
              weekStartsOn: getWeekStartsOn(user),
              mode: user?.settings?.habitSortingMode
                ? ([
                    user?.settings?.habitSortingMode,
                  ] as unknown as SortingMode[])
                : undefined,
              order:
                user?.settings?.habitGroupSorting?.goal?.sorting?.[
                  goalInboxID === id || !id ? 'null' : id
                ] ?? [],
            },
          ) as Habit[],
        }))
        .filter(({ items }) => !!items.length),
    [goalSections, habits, today, user],
  );

  return {
    sections,
    habits,
  };
};
