import { useUpdateHabitScheduleEntry } from 'features/tasks';
import React, { useCallback, useMemo, useState } from 'react';
import { HabitManualEntryLogDialog } from 'shared/dialogs/habit-manual-entry-log';
import { Habit } from 'shared/types/habit';
import { ScheduleEntryState } from 'shared/types/habit-schedule';
import { ID } from 'shared/types/id';
import { Timestamp } from 'shared/types/timestamp';

import {
  HabitManualEntryLoggingContext,
  HabitManualEntryLoggingContextProps,
} from './habit-manual-entry-logging-context';

export type HabitManualEntryLoggingProviderProps = {
  children: React.ReactNode;
};

export const HabitManualEntryLoggingProvider: React.FC<
  HabitManualEntryLoggingProviderProps
> = ({ children }) => {
  const [habitId, setHabitId] = useState<ID>();
  const [date, setDate] = useState<Timestamp>();

  const updateEntry = useUpdateHabitScheduleEntry();

  const openDialog = useCallback((habitId: ID, date: Timestamp) => {
    setHabitId(habitId);
    setDate(date);
  }, []);

  const closeDialog = useCallback(() => {
    setHabitId(undefined);
    setDate(undefined);
  }, []);

  const onSubmit = (habit: Habit, date: Timestamp, value: number) => {
    updateEntry(habit, date, ScheduleEntryState.Complete, { value });
    closeDialog();
  };

  const value = useMemo<HabitManualEntryLoggingContextProps>(
    () => ({
      openManualEntryLogging: openDialog,
    }),
    [openDialog],
  );

  return (
    <HabitManualEntryLoggingContext.Provider value={value}>
      {children}
      <HabitManualEntryLogDialog
        habitId={habitId}
        date={date}
        onClose={closeDialog}
        onSubmit={onSubmit}
      />
    </HabitManualEntryLoggingContext.Provider>
  );
};
