export type ID = string;

export type PrefixedID = string;

export enum IDPrefixes {
  Tag = 'tag',
  LifeArea = 'lifeArea',
  Goal = 'goal',
  Task = 'task',
}
