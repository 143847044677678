import { DndContext } from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import React from 'react';
import { OverlayComponentWrapper } from 'shared/components/ui/overlay-component-wrapper';
import { SortableOverlay } from 'shared/components/ui/sortable-overlay';
import { useSortableItemsControls } from 'shared/hooks/use-sortable-items-controls';
import { useSortableSensors } from 'shared/hooks/use-sortable-sensors';
import { Habit } from 'shared/types/habit';
import { ID } from 'shared/types/id';
import { Timestamp } from 'shared/types/timestamp';

import { HabitDetailCard } from '../habit-detail-card';
import { HabitsList } from '../habits-list';

export type SortableHabitsProps = {
  onReorder?: (ids: ID[]) => void;
  onHabit?: (id: ID) => void;
  habits: Habit[];
  weekDate: Timestamp;
};

export const SortableHabitsList: React.FC<SortableHabitsProps> = ({
  onReorder,
  habits,
  onHabit,
  weekDate,
}) => {
  const {
    items,
    activeItem,
    handleDragStart,
    handleDragCancel,
    handleDragEnd,
  } = useSortableItemsControls({ items: habits, onDragEnd: onReorder });
  const sensors = useSortableSensors();

  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
      sensors={sensors}
    >
      <SortableContext
        items={items.map(({ id }) => id)}
        strategy={verticalListSortingStrategy}
        disabled={!onReorder}
      >
        <HabitsList
          items={items}
          weekDate={weekDate}
          onHabit={onHabit}
          isSortable={!!onReorder}
        />

        {!!activeItem && (
          <SortableOverlay>
            <OverlayComponentWrapper>
              <HabitDetailCard
                habit={activeItem}
                onClick={() => {}}
                weekDate={weekDate}
              />
            </OverlayComponentWrapper>
          </SortableOverlay>
        )}
      </SortableContext>
    </DndContext>
  );
};
