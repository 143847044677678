import { format, isAfter, isSameDay } from 'date-fns';
import React, { useMemo, useRef } from 'react';
import {
  ArrowRightCalendar,
  CheckCalendar,
  XCalendar,
} from 'shared/assets/icons';
import { DayContextMenu } from 'shared/components/ui/day-context-menu';
import { Icon } from 'shared/components/ui/icon';
import { Tooltip } from 'shared/components/ui/tooltip';
import { useToday } from 'shared/contexts/today';
import { useOpenMenu } from 'shared/hooks/use-open-menu';
import {
  ScheduleEntryState,
  ScheduleTargetEntry,
} from 'shared/types/habit-schedule';
import { Timestamp } from 'shared/types/timestamp';
import { formatNumberShort } from 'shared/utils/format-number-short';
import { isNumber } from 'shared/utils/is-number';

import * as Styled from './habit-week.style';

export type HabitWeekDayProps = {
  date: Timestamp;
  occurrences: Timestamp[];
  dateEntries: ScheduleTargetEntry[];
  onDate: (
    date: Timestamp,
    type: ScheduleEntryState,
    options?: { value?: number; increment?: number },
  ) => void;
  onDateManual: (date: Timestamp) => void;
  isPerfectDay?: boolean;
};

export const HabitWeekDay: React.FC<HabitWeekDayProps> = ({
  date,
  occurrences,
  dateEntries,
  onDate,
  onDateManual,
  isPerfectDay,
}) => {
  const today = useToday();
  const containerRef = useRef<HTMLDivElement>(null);

  const { menuOpen, openMenu, closeMenu } = useOpenMenu();

  const isOccurrence = useMemo(
    () => !!occurrences.find((occurrence) => isSameDay(occurrence, date)),
    [date, occurrences],
  );
  const dateEntry = useMemo(
    () => dateEntries.find((entry) => isSameDay(entry.date, date)),
    [date, dateEntries],
  );

  const completionPercentage = dateEntry?.value
    ? (dateEntry.value / (dateEntry.target ?? 1)) * 100
    : 0;
  const isFailure = dateEntry?.state === ScheduleEntryState.Failure;
  const isSkipped = dateEntry?.state === ScheduleEntryState.Skip;
  const isFuture = isAfter(date, today);

  const _onDate = () =>
    dateEntry?.onDateManual
      ? onDateManual(date)
      : onDate(date, ScheduleEntryState.Complete);

  const onManualEntryLogging = () => onDateManual(date);

  const _openMenu = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    !isFuture && openMenu();
  };

  const displayDate = !dateEntry?.value;
  const displayCheck =
    !!dateEntry?.value &&
    !!dateEntry.target &&
    dateEntry.value! === dateEntry.target;

  return (
    <>
      {(isNumber(dateEntry?.target) || isNumber(dateEntry?.value)) && (
        <Tooltip
          label={[dateEntry?.value ?? 0, dateEntry?.target || undefined]
            .filter(isNumber)
            .join('/')}
          referenceElement={containerRef}
        />
      )}

      <Styled.DayContainer ref={containerRef}>
        <Styled.DayButton
          onClick={_onDate}
          onContextMenu={_openMenu}
          data-value={date.getTime()}
          $isOccurrence={isOccurrence}
          $completionPercentage={completionPercentage}
          $isPerfect={!!isPerfectDay}
          $isSkipped={isSkipped}
          $isFailure={isFailure}
          $isFuture={isFuture}
          $isToday={isSameDay(date, today)}
        >
          {!completionPercentage && (isFailure || isSkipped) ? (
            <Styled.DayIcon>
              <Icon icon={isSkipped ? ArrowRightCalendar : XCalendar} />
            </Styled.DayIcon>
          ) : (
            <Styled.DayLabel $isIcon={displayCheck}>
              {displayDate ? (
                format(date, 'EEEEE')
              ) : displayCheck ? (
                <Icon icon={CheckCalendar} />
              ) : (
                formatNumberShort(dateEntry.value!)
              )}
            </Styled.DayLabel>
          )}
        </Styled.DayButton>

        {menuOpen && !!onDate && (
          <DayContextMenu
            date={date}
            target={dateEntry?.target}
            onDate={onDate}
            onManual={onManualEntryLogging}
            referenceElement={containerRef}
            onClose={closeMenu}
          />
        )}
      </Styled.DayContainer>
    </>
  );
};
