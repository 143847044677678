import { addDays, startOfWeek } from 'date-fns';
import React, { useMemo } from 'react';
import { weekdaysNumberMap } from 'shared/constants';
import { ScheduleTargetEntry } from 'shared/types/habit-schedule';
import { Timestamp } from 'shared/types/timestamp';
import { WeekDays } from 'shared/types/week-days';

import * as Styled from './habit-week.style';
import { HabitWeekDay } from './habit-week-day';

export type HabitWeekProps = {
  startDate: Timestamp;
  weekStartsOn: WeekDays;
  occurrences: Timestamp[];
  dateEntries: ScheduleTargetEntry[];
  isPerfectWeek: boolean;
  onDate: (date: Timestamp) => void;
};

export const HabitWeek: React.FC<HabitWeekProps> = ({
  startDate,
  weekStartsOn,
  occurrences,
  dateEntries,
  isPerfectWeek,
  onDate,
}) => {
  const days = useMemo(() => {
    const weekStart = startOfWeek(startDate, {
      weekStartsOn: weekdaysNumberMap[weekStartsOn],
    });

    return [
      weekStart,
      addDays(weekStart, 1),
      addDays(weekStart, 2),
      addDays(weekStart, 3),
      addDays(weekStart, 4),
      addDays(weekStart, 5),
      addDays(weekStart, 6),
    ];
  }, [startDate, weekStartsOn]);

  return (
    <Styled.List>
      {days.map((date) => (
        <Styled.ListItem key={date.getTime()}>
          <HabitWeekDay
            date={date}
            occurrences={occurrences}
            dateEntries={dateEntries}
            onDate={onDate}
            isPerfectDay={isPerfectWeek}
          />
        </Styled.ListItem>
      ))}
    </Styled.List>
  );
};
