import { useGoals } from 'features/goals';
import { useCallback } from 'react';
import { useUpdateAllTasksCollapsedGoalsMutation } from 'shared/hooks/queries/use-update-all-tasks-collapsed-goals-mutation';
import { useUpdateAllTasksCollapsedLifeAreasMutation } from 'shared/hooks/queries/use-update-all-tasks-collapsed-life-areas-mutation';
import { useUser } from 'shared/hooks/use-user';
import { ID } from 'shared/types/id';
import {
  goalInboxID,
  unlinkedPlannedTasksID,
} from 'shared/types/orderable-section';

export const useToggleSection = () => {
  const user = useUser();
  const goals = useGoals();
  const { mutate: updateCollapsedGoals } =
    useUpdateAllTasksCollapsedGoalsMutation();
  const { mutate: updateCollapsedLifeAreas } =
    useUpdateAllTasksCollapsedLifeAreasMutation();

  return useCallback(
    (id: ID) => {
      if (
        [goalInboxID, unlinkedPlannedTasksID].includes(id) ||
        goals.find((goal) => goal.id === id)
      ) {
        const oldIds = user?.settings?.allTasks?.collapsedGoals ?? [];
        const newIds = oldIds.filter((goalId) => goalId !== id);

        if (newIds.length === oldIds.length) {
          newIds.push(id);
        }

        updateCollapsedGoals(newIds);
        return;
      }

      const oldIds = user?.settings?.allTasks?.collapsedLifeAreas ?? [];
      const newIds = oldIds.filter((lifeAreaId) => lifeAreaId !== id);

      if (newIds.length === oldIds.length) {
        newIds.push(id);
      }

      updateCollapsedLifeAreas(newIds);
    },
    [
      goals,
      updateCollapsedGoals,
      updateCollapsedLifeAreas,
      user?.settings?.allTasks?.collapsedGoals,
      user?.settings?.allTasks?.collapsedLifeAreas,
    ],
  );
};
