import { useHabits } from 'features/tasks';
import React from 'react';
import { GridContainer } from 'shared/components/ui/grid-container';
import { useUser } from 'shared/hooks/use-user';
import { HabitGroupBy } from 'shared/types/sorting-mode';

import { ArchivedHabitsList } from './components/archived-habits-list';
import { EmptyHabitsList } from './components/empty-habits-list';
import { GoalGroupingHabitsList } from './components/goal-grouping-habits-list';
import { LifeAreaGroupingHabitsList } from './components/life-area-grouping-habits-list';
import { NoGroupingHabitsList } from './components/no-grouping-habits-list';
import { PriorityGroupingHabitsList } from './components/priority-grouping-habits-list';
import { TabPanel } from './components/tab-panel';

const groupByLists: Record<HabitGroupBy, React.FC> = {
  [HabitGroupBy.None]: NoGroupingHabitsList,
  [HabitGroupBy.Goal]: GoalGroupingHabitsList,
  [HabitGroupBy.LifeArea]: LifeAreaGroupingHabitsList,
  [HabitGroupBy.Priority]: PriorityGroupingHabitsList,
};

export const Active: React.FC = () => {
  const habits = useHabits({
    uncompletedOnly: true,
    unArchivedOnly: true,
  });
  const user = useUser();

  const HabitListComponent =
    groupByLists[user?.settings?.habitGroupBy ?? HabitGroupBy.None];

  return (
    <GridContainer>
      <TabPanel>
        {!habits.length ? (
          <EmptyHabitsList />
        ) : (
          <>
            <HabitListComponent />
            <ArchivedHabitsList />
          </>
        )}
      </TabPanel>
    </GridContainer>
  );
};
