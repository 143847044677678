import React from 'react';
import { CheckTodo } from 'shared/assets/icons';
import { IconButton } from 'shared/components/ui/icon-button';
import { useOpenTaskDetail } from 'shared/contexts/task-detail';
import { ID } from 'shared/types/id';

export type TaskDetailButtonProps = {
  taskId?: ID;
};

export const TaskDetailButton: React.FC<TaskDetailButtonProps> = ({
  taskId,
}) => {
  const openTaskDetail = useOpenTaskDetail();

  const onClick = () => openTaskDetail(taskId);

  return <IconButton icon={CheckTodo} onClick={onClick} preventDrag />;
};
