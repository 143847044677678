import { isBefore, isSameDay } from 'date-fns';
import React, { useMemo, useState } from 'react';
import {
  Check,
  CheckCircle2,
  Flag,
  IconFormIcons,
  Lock,
  MoreHorizontal,
  Target,
} from 'shared/assets/icons';
import { GoalContextMenu } from 'shared/components/connected/goal-context-menu';
import { CircleProgress } from 'shared/components/ui/circle-progress';
import { Icon } from 'shared/components/ui/icon';
import { IconButton, Sizes } from 'shared/components/ui/icon-button';
import { TimeLeft } from 'shared/components/ui/time-left';
import { Timeframe } from 'shared/components/ui/timeframe';
import { useToday } from 'shared/contexts/today';
import { useContextMenuState } from 'shared/hooks/use-context-menu-state';
import { Goal } from 'shared/types/goal';
import { ID } from 'shared/types/id';
import { LifeArea } from 'shared/types/life-area';
import { countMetricOptions } from 'shared/types/metric-template';
import { getCompletionPercentage } from 'shared/utils/get-completion-percentage';
import { getGoalColor } from 'shared/utils/get-goal-color';
import { getGoalCompletion } from 'shared/utils/get-goal-completion';
import { useTheme } from 'styled-components';

import { CtaContainer } from './cta-container';
import * as Styled from './goal-list-card.style';

export type GoalListCardProps = {
  goal: Goal;
  onClick?: (id: ID) => void;
  onUpdateProgress?: (id: ID) => void;

  lifeAreas: LifeArea[];
  goals: Goal[];

  hideLifeArea?: boolean;
};

export const GoalListCard: React.FC<GoalListCardProps> = ({
  goal,
  onClick,
  onUpdateProgress,
  goals,
  lifeAreas,
  hideLifeArea,
}) => {
  const theme = useTheme();
  const today = useToday();

  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const [menuButtonUsed, setMenuButtonUsed] = useState(false);
  const {
    open: openMenu,
    close: closeMenu,
    position: menuPosition,
  } = useContextMenuState({
    disabled: !!goal.frozenAt,
    onClose: () => setMenuButtonUsed(false),
  });

  const openMenuByButton = (e: React.MouseEvent) => {
    setMenuButtonUsed(true);
    openMenu(e);
  };

  const _onUpdateProgress = (e: React.MouseEvent) => {
    e.preventDefault();
    onUpdateProgress?.(goal.id);
    closeMenu();
  };

  const color = useMemo(
    () => getGoalColor(goal.id, { goals, lifeAreas }),
    [goal.id, goals, lifeAreas],
  );

  const { start, end, current } = useMemo(
    () =>
      getGoalCompletion(goal, {
        goals,
      }),
    [goal, goals],
  );

  const completionPercentage = useMemo(
    () => getCompletionPercentage({ start, end, current }),
    [current, end, start],
  );

  const uncompletedTasks = useMemo(() => {
    const { total, completed } = goals.reduce(
      (acc, potentialSubGoal) => {
        if (
          potentialSubGoal.parentIds?.includes(goal.id) &&
          !potentialSubGoal.archivedAt
        ) {
          acc.total += potentialSubGoal.taskCount ?? 0;
          acc.completed += potentialSubGoal.completedTaskCount ?? 0;
        }
        return acc;
      },
      { total: goal.taskCount ?? 0, completed: goal.completedTaskCount ?? 0 },
    );
    return Math.max(0, total - completed);
  }, [goal.completedTaskCount, goal.id, goal.taskCount, goals]);

  const lifeArea = useMemo(
    () => lifeAreas.find(({ id }) => id === goal.lifeAreaId),
    [goal.lifeAreaId, lifeAreas],
  );

  const hasTimeframe = !goal.completedAt && !!goal.startDate;
  const started =
    !goal.startDate ||
    isBefore(goal.startDate, today) ||
    isSameDay(goal.startDate, today);

  const updateProgressDisabled =
    !!onUpdateProgress &&
    (!goal.metric?.id ||
      countMetricOptions.includes(goal.metric?.id) ||
      !!goal.frozenAt ||
      !!goal.archivedAt);

  return (
    <>
      <CtaContainer id={goal.id} isFrozen={!!goal.frozenAt} onClick={onClick}>
        <Styled.Container
          onContextMenu={openMenu}
          $isArchived={!!goal.archivedAt}
        >
          {/* image and title */}
          <Styled.DataContainer
            $type={Styled.DataTypes.Title}
            $isFrozen={!!goal.frozenAt}
          >
            <Styled.IllustrationContainer>
              <Styled.ImageContainer>
                {goal.image ? (
                  <Styled.Image
                    src={goal.image.url}
                    alt={goal.name}
                    hash={goal.image.blurHash}
                  />
                ) : (
                  <Icon icon={IconFormIcons[goal.iconName!] ?? Target} />
                )}
              </Styled.ImageContainer>

              {!!goal.completedAt && (
                <Styled.CompletedContainer>
                  <Icon icon={Check} />
                </Styled.CompletedContainer>
              )}

              {!!color && <Styled.ColorDot $color={color} />}
            </Styled.IllustrationContainer>

            <Styled.Title $isCompleted={!!goal.completedAt}>
              {goal.name}
            </Styled.Title>

            {goal.frozenAt && (
              <Styled.IconContainer>
                <Icon icon={Lock} />
              </Styled.IconContainer>
            )}
          </Styled.DataContainer>

          {/* deadline */}
          <Styled.DataContainer
            $type={Styled.DataTypes.Deadline}
            $isFrozen={!!goal.frozenAt}
          >
            {hasTimeframe &&
              (started && goal.deadline ? (
                <TimeLeft startIcon={Flag} date={goal.deadline} isMidShort />
              ) : (
                <Timeframe
                  startDate={goal?.startDate}
                  endDate={goal?.deadline}
                  isShort
                />
              ))}
          </Styled.DataContainer>

          {/* progress */}
          <Styled.DataContainer
            $type={Styled.DataTypes.Progress}
            $isFrozen={!!goal.frozenAt}
          >
            {end !== Infinity ? (
              <Styled.ProgressButton
                onClick={updateProgressDisabled ? undefined : _onUpdateProgress}
                as={updateProgressDisabled ? 'span' : undefined}
              >
                <CircleProgress
                  percentage={completionPercentage}
                  isPrimary={!goal.frozenAt}
                  isSecondary={!!goal.frozenAt}
                />
              </Styled.ProgressButton>
            ) : null}
          </Styled.DataContainer>

          {/* tasks */}
          <Styled.DataContainer
            $type={Styled.DataTypes.Tasks}
            $isFrozen={!!goal.frozenAt}
          >
            <Styled.IconContainer>
              <Icon icon={CheckCircle2} />
            </Styled.IconContainer>
            <span>{uncompletedTasks}</span>
          </Styled.DataContainer>

          {/* life area */}
          {!hideLifeArea && (
            <Styled.DataContainer
              $type={Styled.DataTypes.LifeArea}
              $isFrozen={!!goal.frozenAt}
            >
              <Styled.OverflowSpan>{lifeArea?.name || '-'}</Styled.OverflowSpan>
            </Styled.DataContainer>
          )}

          {/* context menu */}
          <Styled.DataContainer
            $type={Styled.DataTypes.Interactions}
            $isFrozen={!!goal.frozenAt}
          >
            <IconButton
              ref={buttonRef}
              icon={MoreHorizontal}
              onClick={!goal.frozenAt ? openMenuByButton : undefined}
              size={Sizes.Small}
              disabled={!!goal.frozenAt}
            />
          </Styled.DataContainer>
        </Styled.Container>
      </CtaContainer>

      {!!menuPosition && !theme.isMobile && (
        <GoalContextMenu
          id={goal.id}
          location={menuButtonUsed ? undefined : menuPosition}
          referenceElement={menuButtonUsed ? buttonRef : undefined}
          onClose={closeMenu}
        />
      )}
    </>
  );
};
