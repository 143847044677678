import { useGoalById, useGoals, useSubGoals } from 'features/goals';
import { useTasksByGoalId } from 'features/tasks';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useToday } from 'shared/contexts/today';
import { useUser } from 'shared/hooks/use-user';
import { getSortedTasks } from 'shared/utils/get-sorted-tasks';
import { getWeekStartsOn } from 'shared/utils/get-week-starts-on';
import { mapTaskToTaskCard } from 'shared/utils/mappers/map-task-to-task-card';
import { processGoalsAndTasks } from 'shared/utils/process-goals-and-tasks';
import { sortGoals } from 'shared/utils/sort-goals';

export const useActionPlanItems = () => {
  const { t } = useTranslation();
  const today = useToday();
  const { id } = useParams();

  const user = useUser();
  const goal = useGoalById(id);
  const goals = useGoals();
  const tasks = useTasksByGoalId(id);
  const subGoals = useSubGoals(id);

  const items = useMemo(() => {
    const sortedSubGoals = sortGoals(subGoals, {
      allGoals: goals,
      sortMode: goal?.goalSortingMode,
      customOrder: goal?.goalSorting,
      invertStartDateSorting: true,
    });
    const sortedTasks = getSortedTasks(tasks, {
      today,
      weekStartsOn: getWeekStartsOn(user),
      mode: goal?.taskSortingMode ? [goal?.taskSortingMode] : undefined,
      order: goal?.taskSorting ?? [],
    });

    return processGoalsAndTasks([
      ...(goal ? [goal] : []),
      ...sortedSubGoals.filter(({ archivedAt }) => !archivedAt),
      ...sortedTasks
        .filter(({ completedAt }) => !completedAt)
        .map((task) =>
          mapTaskToTaskCard(task, {
            goals,
            t,
            today,
            showDue: true,
            hideGoal: true,
            weekStartsOn: getWeekStartsOn(user),
          }),
        ),
    ]);
  }, [goal, goals, subGoals, t, tasks, today, user]);

  const archivedSubGoals = useMemo(
    () => subGoals.filter(({ archivedAt }) => !!archivedAt),
    [subGoals],
  );

  const completedTasks = useMemo(
    () =>
      tasks
        .filter((task) => task.completedAt)
        .toSorted(
          (taskA, taskB) =>
            (taskA.completedAt?.getTime() ?? 0) -
            (taskB.completedAt?.getTime() ?? 0),
        )
        .map((task) =>
          mapTaskToTaskCard(task, {
            goals,
            t,
            today,
            showDue: true,
            hideGoal: true,
            weekStartsOn: getWeekStartsOn(user),
          }),
        ),
    [goals, t, tasks, today, user],
  );

  return { items, completedTasks, tasks, archivedSubGoals };
};
