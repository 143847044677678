import { useTaskById } from 'features/tasks';
import React from 'react';
import { BusinessChart2 } from 'shared/assets/icons';
import { IconButton } from 'shared/components/ui/icon-button';
import { useOpenTaskStatistics } from 'shared/contexts/task-detail';
import { ID } from 'shared/types/id';
import { InsightsTimeframes } from 'shared/types/insights';
import { TaskType } from 'shared/types/task-base';

export type TaskStatisticsButtonProps = {
  taskId?: ID;
};

export const TaskStatisticsButton: React.FC<TaskStatisticsButtonProps> = ({
  taskId,
}) => {
  const task = useTaskById(taskId);
  const openStatistics = useOpenTaskStatistics();

  const onClick = () => openStatistics(task?.id, InsightsTimeframes.FourWeeks);

  return task?.type === TaskType.Habit ? (
    <IconButton icon={BusinessChart2} onClick={onClick} preventDrag />
  ) : null;
};
