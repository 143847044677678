import styled from 'styled-components';

export const IconContainer = styled.span<{ $color: string }>`
  color: ${({ $color }) => $color ?? 'inherit'};
  line-height: 62.5%;
`;

export const CheckIconContainer = styled.span`
  color: ${({ theme }) => theme.palette.primaryExtended.opacityBackgroundText};
  line-height: 62.5%;
`;
