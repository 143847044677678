import { useSortable } from '@dnd-kit/sortable';
import { endOfWeek, startOfWeek } from 'date-fns';
import { useWeekStatistics } from 'pages/habits-overview/hooks/use-week-statistics';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BusinessChart2,
  Check,
  ChevronLeft,
  IconFormIcons,
  IconFormNames,
  Lock,
} from 'shared/assets/icons';
import { CircleProgress } from 'shared/components/ui/circle-progress';
import { Icon } from 'shared/components/ui/icon';
import { IconButton, Sizes, Variants } from 'shared/components/ui/icon-button';
import { weekdaysNumberMap } from 'shared/constants';
import { useActiveSchedule } from 'shared/hooks/use-active-schedule';
import { Goal } from 'shared/types/goal';
import { Habit } from 'shared/types/habit';
import { ID } from 'shared/types/id';
import { LifeArea } from 'shared/types/life-area';
import { Timestamp } from 'shared/types/timestamp';
import { WeekDays } from 'shared/types/week-days';
import { getGoalColor } from 'shared/utils/get-goal-color';
import { getScheduleLabel } from 'shared/utils/get-schedule-label';
import { isNumber } from 'shared/utils/is-number';

import * as Styled from './habit-detail-mobile-card.style';
import { HabitWeek } from './habit-week';

type SortableContainerProps = React.ComponentProps<typeof Styled.Container> & {
  id: ID;
};

const SortableContainer: React.FC<SortableContainerProps> = ({
  id,
  ...rest
}) => {
  const {
    setNodeRef,
    attributes,
    listeners,
    isDragging,
    transform,
    transition,
  } = useSortable({ id });

  return (
    <Styled.Container
      {...rest}
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      role="presentation"
      $isDragging={isDragging}
      $transform={transform}
      $transition={transition}
    />
  );
};

export type HabitDetailMobileCardViewProps = {
  habit: Habit;
  goals: Goal[];
  lifeAreas: LifeArea[];
  onClick?: (id: ID) => void;
  weekDate: Timestamp;
  weekStartsOn: WeekDays;
  onDate: (id: ID, date: Timestamp) => void;
  onStatistics?: (id: ID) => void;
  isSortable?: boolean;
};

export const HabitDetailMobileCardView: React.FC<
  HabitDetailMobileCardViewProps
> = ({
  habit,
  goals,
  lifeAreas,
  onClick,
  weekDate,
  weekStartsOn,
  onDate,
  onStatistics,
  isSortable,
}) => {
  const { t } = useTranslation();
  const activeSchedule = useActiveSchedule(habit.schedules);
  const activeLinkedItem = useMemo(
    () =>
      goals.find(({ id }) => habit.goalId === id) ??
      lifeAreas.find(({ id }) => id === habit.lifeAreaId),
    [goals, lifeAreas, habit.goalId, habit.lifeAreaId],
  );
  const linkedItemColor = useMemo(
    () =>
      activeLinkedItem
        ? getGoalColor(activeLinkedItem.id, { goals, lifeAreas })
        : undefined,
    [activeLinkedItem, goals, lifeAreas],
  );

  const { weekStartDate, weekEndDate } = useMemo(
    () => ({
      weekStartDate: startOfWeek(weekDate, {
        weekStartsOn: weekdaysNumberMap[weekStartsOn],
      }),
      weekEndDate: endOfWeek(weekDate, {
        weekStartsOn: weekdaysNumberMap[weekStartsOn],
      }),
    }),
    [weekDate, weekStartsOn],
  );

  const { occurrences, dateEntries, perfectWeek, completionPercentage } =
    useWeekStatistics(habit.schedules, {
      startDate: weekStartDate,
      endDate: weekEndDate,
      weekStartsOn,
    });

  const _onClick = () =>
    !habit.frozenAt && !habit.archivedAt && onClick?.(habit.id);

  const _onDate = (date: Timestamp) =>
    !habit.frozenAt && !habit.archivedAt && onDate(habit.id, date);

  const _onStatistics = () => onStatistics?.(habit.id);

  const Container = isSortable ? SortableContainer : Styled.Container;

  return (
    <Container
      id={habit.id}
      $isDisabled={!!habit.frozenAt || !!habit.archivedAt}
    >
      <Styled.Header onClick={_onClick}>
        <Styled.IconContainer>
          <Icon
            icon={
              IconFormIcons[habit.iconName!] ??
              IconFormIcons[IconFormNames.Repeat]
            }
          />

          {!!habit.completedAt && (
            <Styled.CompletedContainer>
              <Icon icon={Check} />
            </Styled.CompletedContainer>
          )}
          {!!linkedItemColor && <Styled.ColorDot $color={linkedItemColor} />}
        </Styled.IconContainer>

        <Styled.HeaderContent>
          <Styled.TitleContainer $isFrozen={!!habit.frozenAt}>
            <Styled.Title>{habit.name}</Styled.Title>
            <Styled.ChevronContainer>
              <Icon icon={ChevronLeft} />
            </Styled.ChevronContainer>
          </Styled.TitleContainer>

          <Styled.HeaderMetaContainer>
            {isNumber(completionPercentage) && (
              <Styled.ProgressContainer>
                <CircleProgress
                  percentage={completionPercentage}
                  isSecondary
                  isSmall
                />
              </Styled.ProgressContainer>
            )}
            {(!!activeSchedule || !!activeLinkedItem) && <Styled.Dot />}
            {!!activeSchedule && (
              <Styled.MetaSpan>
                {getScheduleLabel(activeSchedule, t)}
              </Styled.MetaSpan>
            )}
            {!!activeSchedule && !!activeLinkedItem && <Styled.Dot />}
            {!!activeLinkedItem && (
              <Styled.MetaSpan>{activeLinkedItem.name}</Styled.MetaSpan>
            )}
          </Styled.HeaderMetaContainer>
        </Styled.HeaderContent>

        {!!habit.frozenAt && (
          <Styled.StateIcon>
            <Icon icon={Lock} />
          </Styled.StateIcon>
        )}
      </Styled.Header>

      {!habit.completedAt && !habit.archivedAt && (
        <Styled.WeekContainer $disabled={!!habit.frozenAt}>
          <HabitWeek
            startDate={weekDate}
            weekStartsOn={weekStartsOn}
            occurrences={occurrences}
            dateEntries={dateEntries}
            isPerfectWeek={!!perfectWeek}
            onDate={_onDate}
          />

          {!!onStatistics && (
            <IconButton
              icon={BusinessChart2}
              onClick={_onStatistics}
              size={Sizes.ExtraLarge}
              variant={Variants.Contained}
            />
          )}
        </Styled.WeekContainer>
      )}
    </Container>
  );
};
