import { Locales } from 'shared/assets/localization';
import { Goal } from 'shared/types/goal';
import { GoalFormFields } from 'shared/types/goal-form';
import { InheritColors } from 'shared/types/inherit-colors';
import { DefaultMetricOptions } from 'shared/types/metric-template';
import { getLocalizedStringValue } from 'shared/utils/get-localized-string-value';

export type Options = {
  locale: Locales;
};

export const mapGoalToGoalFields = (
  goal: Partial<Goal>,
  { locale }: Options,
): GoalFormFields => ({
  name: goal.name,
  image: goal.image ?? null,
  iconName: goal.iconName ?? null,
  startDate: goal.startDate ?? null,
  deadline: goal.deadline ?? null,
  lifeAreaId: goal.lifeAreaId ?? null,
  color:
    goal.color ??
    // when there is no goal id, it means it is a new goal, which means it should take preset colors
    ((!goal.id &&
      (goal.parentIds
        ? InheritColors.Goal
        : goal.lifeAreaId
          ? InheritColors.LifeArea
          : null)) ||
      null),
  parentIds: goal.parentIds ?? null,
  metricId: goal.metric?.id ?? null,
  metricStartValue: goal.metric?.startValue ?? null,
  metricTargetValue: goal.metric?.targetValue ?? null,
  metricUnit:
    goal.metric?.id === DefaultMetricOptions.Custom
      ? (goal.metric?.unit?.name &&
          getLocalizedStringValue(goal.metric?.unit.name, locale)) ??
        null
      : goal.metric?.unit?.id ?? null,
});
