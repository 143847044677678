import styled from 'styled-components';

export const Container = styled.div``;

export const Dot = styled.span<{ $color: string }>`
  display: block;

  width: 0.6rem;
  height: 0.6rem;

  border-radius: 50%;
  background: ${({ $color }) => $color};
`;
