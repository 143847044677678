import { useCallback } from 'react';
import { useCreateRelationshipsMutation } from 'shared/hooks/queries/use-create-relationships-mutation';
import { useUpdateRelationshipsReferencesMutation } from 'shared/hooks/queries/use-update-relationships-references-mutation';
import { useRelationships } from 'shared/hooks/use-relationships';
import { PrefixedID } from 'shared/types/id';
import { MinimalRelationship } from 'shared/types/relationship';

export type RelationshipUpdate = {
  id: PrefixedID;
  add?: PrefixedID[];
  remove?: PrefixedID[];
  replace?: PrefixedID[];
};

export const useUpdateRelationshipsReferences = () => {
  const { mutate: updateRelationships } =
    useUpdateRelationshipsReferencesMutation();
  const { mutate: createRelationships } = useCreateRelationshipsMutation();
  const relationships = useRelationships();

  return useCallback(
    (relationshipUpdates: RelationshipUpdate[]) => {
      const relationshipsToUpdate: MinimalRelationship[] = [];
      const relationshipsToCreate: MinimalRelationship[] = [];

      // loop over relationships to update them accordingly
      relationshipUpdates.forEach(({ id, add, remove, replace }) => {
        if (!(add?.length || remove?.length || replace)) {
          return;
        }

        let relationship: MinimalRelationship | undefined = relationships.find(
          (relationship) => relationship.id === id,
        );
        const createOrUpdateList = relationship
          ? relationshipsToUpdate
          : relationshipsToCreate;

        if (!relationship) {
          relationship = {
            id,
            references: [],
          };
        }

        if (add?.length) {
          relationship.references = Array.from(
            new Set([...relationship.references, ...add]),
          );
        }

        if (remove?.length) {
          relationship.references = relationship.references.filter(
            (id) => !remove.includes(id),
          );
        }

        if (replace) {
          relationship.references = replace;
        }

        createOrUpdateList.push(relationship);
      });

      if (relationshipsToCreate.length) {
        createRelationships(relationshipsToCreate);
      }

      if (relationshipsToUpdate.length) {
        updateRelationships(relationshipsToUpdate);
      }
    },
    [createRelationships, relationships, updateRelationships],
  );
};
