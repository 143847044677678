import Typography from '@mui/material/Typography';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Frequency, RRule } from 'rrule';
import { FormSelect } from 'shared/components/ui/form-select';
import { ResizableInput } from 'shared/components/ui/resizable-input';
import { TaskTypeToggle } from 'shared/components/ui/task-type-toggle';
import {
  CompletionTargetUnit,
  FrequencyUnit,
  HabitSchedule,
  RruleReferenceDate,
  ScheduleFrequency,
} from 'shared/types/habit-schedule';
import { TaskType } from 'shared/types/task-base';

import { Footer } from './footer';
import * as Styled from './form.style';
import { IntervalInput } from './interval-input';
import { defaultFrequency } from './types';

const frequencyUnits = Object.values(FrequencyUnit);

export type TargetFormProps = {
  type: TaskType;
  initialFrequency?: ScheduleFrequency | null;
  onChange: (schedule: HabitSchedule | null) => void;
  onChangeType: (type: TaskType.Repeating | TaskType.Habit) => void;
  createHabitPremium: boolean;
  showTypeToggle?: boolean;
};

export const TargetForm: React.FC<TargetFormProps> = ({
  type,
  initialFrequency,
  onChange,
  onChangeType,
  createHabitPremium,
  showTypeToggle,
}) => {
  const { t } = useTranslation();
  const [frequency, setFrequency] = useState<ScheduleFrequency>(
    initialFrequency ?? defaultFrequency,
  );

  const typeValue =
    type && type !== TaskType.Task
      ? type
      : createHabitPremium
        ? TaskType.Repeating
        : TaskType.Habit;

  const onSubmit = () => {
    if (type !== typeValue) {
      onChangeType(typeValue);
    }

    onChange({
      rrule: {
        format: new RRule({
          freq:
            frequency.unit === FrequencyUnit.Month
              ? Frequency.MONTHLY
              : Frequency.DAILY,
        }).toString(),
        referenceDate: RruleReferenceDate.StartDate,
      },
      completionTarget: {
        unit: CompletionTargetUnit.Day,
        count: 1,
      },
      frequency,
      entries: [],
      startDate: new Date(),
      endDate: null,
      // temp to keep the old app working
      completions: [],
    });
  };

  const onChangeCount = (value: number | undefined) =>
    setFrequency((odlFrequency) => ({ ...odlFrequency, count: value ?? 1 }));

  const onChangeLabel = ({ target }: React.ChangeEvent<HTMLInputElement>) =>
    setFrequency((odlFrequency) => ({
      ...odlFrequency,
      label: target.value || null,
    }));

  const onChangeUnit = ({ target }: React.ChangeEvent<HTMLSelectElement>) => {
    const unit = target.value as FrequencyUnit;
    if (!frequencyUnits.includes(unit)) {
      return;
    }

    setFrequency((odlFrequency) => ({ ...odlFrequency, unit, count: 1 }));
  };

  const onClear = () => onChange(null);

  const isValidValue = useMemo(() => frequency.count > 0, [frequency.count]);

  const frequencyOptions = useMemo(
    () => [
      {
        label: t('general.labels.habit.repeat.target.options.week.label'),
        value: FrequencyUnit.Week,
      },
      {
        label: t('general.labels.habit.repeat.target.options.month.label'),
        value: FrequencyUnit.Month,
      },
      {
        label: t('general.labels.habit.repeat.target.options.year.label'),
        value: FrequencyUnit.Year,
      },
    ],
    [t],
  );

  useEffect(() => {
    if (initialFrequency) {
      setFrequency(initialFrequency);
    }
  }, [initialFrequency]);

  return (
    <Styled.Container>
      <Styled.HeaderContainer>
        <Typography variant="body2">
          {t('general.labels.habit.repeat.target.label')}
        </Typography>
      </Styled.HeaderContainer>

      <Styled.TargetBody>
        <IntervalInput value={frequency.count} onChange={onChangeCount} />
        <ResizableInput
          value={frequency.label ?? ''}
          placeholder={t(
            'general.labels.habit.repeat.value.target.default.label',
            {
              count: frequency.count,
            },
          )}
          onChange={onChangeLabel}
          showAsSmallFormInput
        />
        <FormSelect
          options={frequencyOptions}
          value={frequency.unit}
          onChange={onChangeUnit}
          isSmall
        />
      </Styled.TargetBody>

      {!!showTypeToggle && (
        <Styled.Body>
          <TaskTypeToggle
            type={typeValue}
            onChange={onChangeType}
            isPremium={createHabitPremium}
          />
        </Styled.Body>
      )}

      <Footer onClear={onClear} onSubmit={onSubmit} isValid={isValidValue} />
    </Styled.Container>
  );
};
