import styled from 'styled-components';

export const Container = styled.div<{ $open: boolean }>`
  padding: ${({ theme }) =>
    theme.isMobile
      ? `0 ${theme.spacing(3)}`
      : `0 ${theme.spacing(3)} 0 ${theme.spacing(6)}`};
  height: ${({ $open }) => ($open ? '4rem' : '0')};

  transition: height 500ms;
  overflow: hidden;
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(2)};

  padding: ${({ theme }) => `0 ${theme.spacing(2)}`};
  height: 3.2rem;

  border-radius: ${({ theme }) => theme.spacing(2)};
  background: ${({ theme }) => theme.palette.background.paper};
`;

export const TagsContainer = styled.button`
  display: inline-flex;
  gap: ${({ theme }) => theme.spacing(1.5)};
  align-items: center;

  margin: 0;
  padding: 0;

  border: none;
  background: transparent;
  cursor: pointer;

  color: ${({ theme }) => theme.palette.text.primary};
`;

export const IconContainer = styled.span`
  color: ${({ theme }) => theme.palette.grey['400']};
  font-size: 1.6rem;
  line-height: 62.5%;
`;
