import { Axis, Orientation } from '@visx/axis';
import { Group } from '@visx/group';
import { ParentSize } from '@visx/responsive';
import React, { useState } from 'react';
import { Tooltip } from 'shared/components/ui/tooltip';
import { BarChartEntry } from 'shared/lib/recharts';

import { Bar } from './bar';
import * as Styled from './bar-chart.style';
import { useBarchartStyling } from './use-barchart-styling';
import { useXScale } from './use-x-scale';
import { useYAxisWidth } from './use-y-axis-width';
import { useYScale } from './use-y-scale';

const xAxisHeight = 28;
const chartTopPadding = 12;

type ChartProps = {
  width: number;
  height: number;

  data: BarChartEntry[];
  target?: number;
  yTicks: number[];
  yTickFormatter?: (tick: number) => string;
};

const Chart: React.FC<ChartProps> = ({
  width,
  height,
  data,
  target,
  yTicks,
  yTickFormatter,
}) => {
  const chartHeight = height - xAxisHeight - chartTopPadding;
  const yAxisWidth = useYAxisWidth({
    ticks: yTicks,
    formatter: yTickFormatter,
    target,
  });

  const xScale = useXScale({ data, width, yAxisOffset: yAxisWidth });
  const yScale = useYScale({ data, height: chartHeight, target });

  const {
    yAxisTickLabelProps,
    xAxisTickLabelProps,
    xAxisStrokeColor,
    cartesianColor,
  } = useBarchartStyling();

  const [tooltipIndex, setTooltipIndex] = useState<number>();
  const [tooltipLocation, setTooltipLocation] = useState<{
    left: number;
    top: number;
  }>();

  const showTooltip = (
    index: number,
    options?: { left: number; top: number },
  ) => {
    setTooltipIndex(options ? index : undefined);
    setTooltipLocation(options);
  };

  const hoveredBar = data[tooltipIndex!];
  const clipPathId = window.crypto.randomUUID();

  return (
    <Styled.Container>
      {!!hoveredBar?.tooltip && (
        <Tooltip label={hoveredBar.tooltip} location={tooltipLocation} />
      )}

      <Styled.SVG width="100%" height="100%">
        <defs>
          <clipPath id={clipPathId}>
            <rect
              x="0"
              y="0"
              width={width * 1000}
              height={Math.max(0, chartHeight)}
            />
          </clipPath>
        </defs>

        {/* cartesian grid */}
        <Group top={chartTopPadding}>
          <line
            x1={yAxisWidth}
            y1={0}
            x2={width}
            y2={0}
            strokeWidth={1}
            strokeDasharray="2 2"
            stroke={cartesianColor}
          />
          <line
            x1={yAxisWidth}
            y1={chartHeight / 2}
            x2={width}
            y2={chartHeight / 2}
            strokeWidth={1}
            strokeDasharray="2 2"
            stroke={cartesianColor}
          />
        </Group>

        {/* Bars */}
        <Group
          top={chartTopPadding}
          left={yAxisWidth}
          clipPath={`url(#${clipPathId})`}
        >
          {data.map(({ value, target, isPerfect, isFaded }, index) => (
            <Bar
              key={index}
              index={index}
              x={xScale(index)! + xScale.bandwidth() / 2}
              y={yScale(value)}
              maxHeight={chartHeight}
              targetHeight={yScale(target)}
              onHover={showTooltip}
              isCompleted={isPerfect}
              isFaded={isFaded}
            />
          ))}
        </Group>

        {!!yTickFormatter && (
          <>
            {/* y-axis */}
            <Axis
              scale={yScale}
              orientation={Orientation.left}
              top={chartTopPadding}
              left={yAxisWidth} // Position the axis at the calculated width
              tickLabelProps={yAxisTickLabelProps}
              tickLength={4}
              tickValues={yTicks}
              tickFormat={(tick) => yTickFormatter(tick.valueOf())}
              tickStroke="none"
              stroke="none"
            />
          </>
        )}

        {/* x-axis line */}
        <line
          x1={yAxisWidth}
          y1={chartHeight + chartTopPadding}
          x2={width}
          y2={chartHeight + chartTopPadding}
          stroke={xAxisStrokeColor}
          strokeWidth={1}
        />

        <Axis
          scale={xScale}
          left={yAxisWidth}
          tickLabelProps={xAxisTickLabelProps}
          orientation={Orientation.bottom}
          top={chartHeight + chartTopPadding}
          tickValues={data.map((_, index) => index)}
          tickFormat={(tick) => data[tick].label}
          stroke="none"
          tickStroke="none"
        />
      </Styled.SVG>
    </Styled.Container>
  );
};

export type BarChartProps = Omit<ChartProps, 'width' | 'height'> & {};

export const BarChart: React.FC<BarChartProps> = (chartProps) => (
  <ParentSize>
    {({ width, height }) => (
      <Chart {...chartProps} width={width} height={height} />
    )}
  </ParentSize>
);
