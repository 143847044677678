import { endOfDay } from 'date-fns';
import { useGoals } from 'features/goals';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useToday } from 'shared/contexts/today';
import { useUser } from 'shared/hooks/use-user';
import { isBetween } from 'shared/lib/date-fns';
import { DateSectionOptions } from 'shared/types/orderable-section';
import { SortingMode } from 'shared/types/sorting-mode';
import { TaskType } from 'shared/types/task-base';
import { TaskCardTask } from 'shared/types/task-card-task';
import { formatDateKey } from 'shared/utils/format-date-key';
import { getCustomOrderTillDate } from 'shared/utils/get-custom-order-till-date';
import { getSortedTasks } from 'shared/utils/get-sorted-tasks';
import { getTaskDueDate } from 'shared/utils/get-task-due';
import { getWeekStartsOn } from 'shared/utils/get-week-starts-on';
import { mapTaskToTaskCard } from 'shared/utils/mappers/map-task-to-task-card';

import { useTodaySections } from './use-today-sections';
import { useTodayTasks } from './use-today-tasks';

export const useNoGroupingTaskSections = () => {
  const { t } = useTranslation();
  const today = useToday();

  const tasks = useTodayTasks();
  const goals = useGoals();
  const sections = useTodaySections();
  const user = useUser();

  const filledSections = useMemo(
    () =>
      sections
        .map(({ startDate, endDate, type, ...rest }) => {
          const sectionTasks = tasks.filter((task) => {
            const dueDate = getTaskDueDate(task, {
              today,
              weekStartsOn: getWeekStartsOn(user),
            });

            if (!dueDate) {
              return false;
            }

            return isBetween(dueDate, startDate, endDate);
          });

          const sectionCustomOrder = getCustomOrderTillDate(
            user?.settings?.todoSorting ?? {},
            endDate,
          );

          const sortingModes = Array.from(
            new Set([SortingMode.DueDate, user?.settings?.todoSortingMode]),
          ).filter(Boolean) as SortingMode[];

          const orderedTasks = getSortedTasks(sectionTasks, {
            today,
            weekStartsOn: getWeekStartsOn(user),
            mode: sortingModes,
            order: sectionCustomOrder,
          });

          return {
            ...rest,
            startDate,
            endDate,
            type,
            items: orderedTasks.map((task) =>
              mapTaskToTaskCard(task, {
                today,
                goals,
                t,
                showDue: true,
                hideTodayDue: true,
                hideFutureDue:
                  type === DateSectionOptions.Day ||
                  task.type !== TaskType.Task,
                weekStartsOn: getWeekStartsOn(user),
              }),
            ),
          };
        })
        // filter out overdue if it doesn't have any items
        .filter(
          ({ id, items }) =>
            id === formatDateKey(endOfDay(today)) || !!items.length,
        ),
    [goals, sections, t, tasks, today, user],
  );

  const taskCardTasks = useMemo(
    () =>
      filledSections.reduce<TaskCardTask[]>((acc, { items }) => {
        acc.push(...items);
        return acc;
      }, []),
    [filledSections],
  );

  return {
    sections: filledSections,
    taskCardTasks,
  };
};
