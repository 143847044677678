import styled, { css } from 'styled-components';

export const Container = styled.div<{
  $isFrozen: boolean;
  $isSelected: boolean;
  $isMoving: boolean;
}>`
  width: 100%;
  padding: ${({ theme }) => `0 ${theme.spacing(3)} 0 ${theme.spacing(1)}`};

  border-radius: ${({ theme }) => theme.spacing(2)};
  background: ${({ theme }) =>
    theme.isMobile
      ? theme.palette.background.paper
      : theme.palette.background.default};
  transition: background 200ms;
  cursor: ${({ $isFrozen }) => ($isFrozen ? 'default' : 'pointer')};

  ${({ $isMoving, $isSelected, theme }) =>
    !$isMoving &&
    $isSelected &&
    css`
      background: ${theme.palette.background.paper};
    `};

  ${({ theme }) =>
    theme.isMobile &&
    css`
      padding: ${({ theme }) =>
        `${theme.spacing(1.5)} ${theme.spacing(3)} ${theme.spacing(1.5)} ${theme.spacing(1)}`};
    `};
`;

export const Body = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1.5)};

  margin: ${({ theme }) => `${theme.spacing(0.5)} 0 0`};
  padding: ${({ theme }) => `0 0 ${theme.spacing(0.5)} ${theme.spacing(6.5)}`};

  ${({ theme }) =>
    theme.isMobile &&
    css`
      padding: ${({ theme }) =>
        `0 0 ${theme.spacing(0.5)} ${theme.spacing(8.5)}`};
    `};
`;
