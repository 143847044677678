import Typography from '@mui/material/Typography';
import { useGoalById } from 'features/goals';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { EmptyChart as EmptyChartIcon } from 'shared/assets/icons';
import { Button, Colors, Sizes, Variants } from 'shared/components/ui/button';
import { useOpenEditGoalDialog } from 'shared/contexts/goal-form';

import * as Styled from './empty-chart.style';

export const EmptyChart: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const goal = useGoalById(id);

  const openEditGoalDialog = useOpenEditGoalDialog();
  const onEdit = () => {
    if (goal) {
      openEditGoalDialog(goal, goal.id);
    }
  };

  return (
    <Styled.Container $isUneditable={!!goal?.frozenAt || !!goal?.archivedAt}>
      <Styled.ImageContainer>
        <EmptyChartIcon />
      </Styled.ImageContainer>

      <Styled.Content>
        <Typography variant="h6" component="h3">
          {t('pages.goal-detail.progress.empty.title')}
        </Typography>
        <Styled.Description>
          {t('pages.goal-detail.progress.empty.description')}
        </Styled.Description>
      </Styled.Content>

      <Styled.Interactions>
        <Button
          variant={Variants.Contained}
          size={Sizes.Medium}
          color={Colors.Primary}
          onClick={goal?.frozenAt || goal?.archivedAt ? undefined : onEdit}
        >
          {t('pages.goal-detail.progress.empty.button')}
        </Button>
      </Styled.Interactions>
    </Styled.Container>
  );
};
