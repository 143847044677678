import { useGoals } from 'features/goals';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useToday } from 'shared/contexts/today';
import { useLifeAreaSections } from 'shared/hooks/use-life-area-sections';
import { useUser } from 'shared/hooks/use-user';
import { Goal } from 'shared/types/goal';
import { ID } from 'shared/types/id';
import { lifeAreaOtherID } from 'shared/types/orderable-section';
import { SortingMode } from 'shared/types/sorting-mode';
import { TaskCardTask } from 'shared/types/task-card-task';
import { getSortedTasks } from 'shared/utils/get-sorted-tasks';
import { getWeekStartsOn } from 'shared/utils/get-week-starts-on';
import { mapTaskToTaskCard } from 'shared/utils/mappers/map-task-to-task-card';

import { useTodayTasks } from './use-today-tasks';

export const useLifeAreaTaskSections = () => {
  const { t } = useTranslation();
  const today = useToday();

  const user = useUser();

  const tasks = useTodayTasks();
  const goals = useGoals();
  const lifeAreaSections = useLifeAreaSections();

  const sections = useMemo(() => {
    const filteredGoals = goals.filter(
      ({ id }) => !!tasks.find(({ goalId }) => goalId === id),
    );
    const goalsMap = filteredGoals.reduce<Record<ID, Goal>>((acc, goal) => {
      acc[goal.id] = goal;
      return acc;
    }, {});

    const otherGoalIds = goals
      .filter(({ lifeAreaId }) => !lifeAreaId)
      .map(({ id }) => id);

    return lifeAreaSections
      .map(({ id, lifeArea }) => ({
        id,
        lifeArea,
        items: getSortedTasks(
          tasks.filter(({ goalId, lifeAreaId }) =>
            id === lifeAreaOtherID
              ? (!goalId || otherGoalIds.includes(goalId)) && !lifeAreaId
              : (goalId && goalsMap[goalId]?.lifeAreaId === id) ||
                lifeAreaId === id,
          ),
          {
            today,
            weekStartsOn: getWeekStartsOn(user),
            mode: Array.from(
              new Set([SortingMode.DueDate, user?.settings?.todoSortingMode]),
            ).filter(Boolean) as SortingMode[],
            order:
              user?.settings?.todayGroupSorting?.lifeArea?.sorting?.[
                id ?? 'null'
              ] ?? [],
          },
        ).map((task) =>
          mapTaskToTaskCard(task, {
            today,
            goals,
            t,
            showDue: true,
            hideTodayDue: true,
            weekStartsOn: getWeekStartsOn(user),
          }),
        ),
      }))
      .filter(({ id, items }) => id === lifeAreaOtherID || !!items.length);
  }, [goals, lifeAreaSections, t, tasks, today, user]);

  const taskCardTasks = useMemo(
    () =>
      sections.reduce<TaskCardTask[]>((acc, { items }) => {
        acc.push(...items);
        return acc;
      }, []),
    [sections],
  );

  return {
    sections,
    taskCardTasks,
  };
};
