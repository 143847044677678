import { endOfDay, startOfDay } from 'date-fns';
import { useMemo } from 'react';
import { useToday } from 'shared/contexts/today';
import { useTasksQuery } from 'shared/hooks/queries/use-tasks-query';
import { useUser } from 'shared/hooks/use-user';

export const useTodaysTodos = () => {
  const today = useToday();
  const user = useUser();

  const { data: tasks } = useTasksQuery(user?.uid, {
    deadlineStartDate: startOfDay(today),
    deadlineEndDate: endOfDay(today),
    unFrozenOnly: true,
    uncompletedOnly: true,
    noScheduleOnly: true,
  });

  return useMemo(
    () => tasks?.filter(({ archivedAt }) => !archivedAt) ?? [],
    [tasks],
  );

  // todo: tussen-laag implementeren voor ophalen van tasks, kan niet in useTaskQuery aangezien daar de geupdaten data niet komt
  // const subTaskIds = useMemo(() => {
  //   const childIds = tasks?.map(({ childIds }) => childIds ?? []).flat(1) ?? [];
  //   return childIds.filter((id) => !tasks?.find((task) => task.id === id));
  // }, [tasks]);
  //
  // const { data: subTasks } = useTasksQuery(
  //   user?.uid,
  //   {
  //     ids: subTaskIds,
  //   },
  //   !!subTaskIds.length,
  // );
  //
  // return useMemo(
  //   () => [...(tasks ?? []), ...(subTasks ?? [])] ?? [],
  //   [subTasks, tasks],
  // );
};
