import { isBefore, isSameWeek, startOfWeek } from 'date-fns';
import { weekdaysNumberMap } from 'shared/constants';
import { HabitSchedule, ScheduleEntryState } from 'shared/types/habit-schedule';
import { Task } from 'shared/types/task';
import { Timestamp } from 'shared/types/timestamp';
import { WeekDays } from 'shared/types/week-days';
import { getActiveSchedule } from 'shared/utils/get-active-schedule';
import { getFirstUncompletedDateByRrule } from 'shared/utils/get-first-uncompleted-date-by-rrule';

export type Options = {
  today: Timestamp;
  weekStartsOn: WeekDays;
};

export const getTaskOverdue = (
  task: Task,
  { today, weekStartsOn }: Options,
) => {
  const activeSchedule = getActiveSchedule(task.schedules);
  const dueDate = activeSchedule
    ? getScheduleWeekDue(activeSchedule, { today, weekStartsOn })
    : task.endStrategy?.deadline ?? undefined;

  return dueDate && isBefore(dueDate, today);
};

const getScheduleWeekDue = (
  schedule: HabitSchedule,
  { today, weekStartsOn }: Options,
) => {
  const weekStart = startOfWeek(today, {
    weekStartsOn: weekdaysNumberMap[weekStartsOn],
  });
  const completionsThisWeek = schedule.entries
    .filter(
      ({ state, date, value }) =>
        state === ScheduleEntryState.Complete &&
        !!value &&
        isSameWeek(today, date, {
          weekStartsOn: weekdaysNumberMap[weekStartsOn],
        }),
    )
    .map(({ date }) => date);
  return (
    getFirstUncompletedDateByRrule({
      startDate: weekStart,
      completions: completionsThisWeek,
      rrule: schedule.rrule.format,
    }) ?? undefined
  );
};
