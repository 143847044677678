import React from 'react';
import { GridContainer } from 'shared/components/ui/grid-container';
import { useTrackPageVisitEvent } from 'shared/hooks/use-track-page-visit-event';
import { PageEvents } from 'shared/services/tracking';

import { CompletedTasks } from './components/completed-tasks';
import { TagDetailHeader } from './components/tag-detail-header';
import { TasksOverview } from './components/tasks-overview/tasks-overview';

export const TagDetail: React.FC = () => {
  useTrackPageVisitEvent(PageEvents.TagDetail, undefined);

  return (
    <GridContainer>
      <TagDetailHeader />
      <TasksOverview />
      <CompletedTasks />
    </GridContainer>
  );
};
