import { eachDayOfInterval, isSameMonth } from 'date-fns';
import React, { useMemo } from 'react';
import { weekdaysNumberMap } from 'shared/constants';
import {
  endOfLastWeekOfMonth,
  startOfFirstWeekOfMonth,
} from 'shared/lib/date-fns';
import {
  ScheduleEntryState,
  ScheduleTargetEntry,
} from 'shared/types/habit-schedule';
import { Timeframe } from 'shared/types/timeframe';
import { Timestamp } from 'shared/types/timestamp';
import { WeekDays } from 'shared/types/week-days';

import { Day } from './day';
import { HeaderDate } from './header-date';
import * as Styled from './month-progress-preview.style';

export type MonthProgressPreviewProps = {
  openToDate: Timestamp;
  weekStartsOn: WeekDays;
  occurrences?: Timestamp[];
  dateEntries: ScheduleTargetEntry[];
  perfectSlots?: Timeframe[];
  onChange?: (
    date: Timestamp,
    type: ScheduleEntryState,
    options?: { value?: number; increment?: number },
  ) => void;
  onDateManual: (date: Timestamp) => void;
  showSurroundingMonths?: boolean;
};

export const MonthProgressPreview: React.FC<MonthProgressPreviewProps> = ({
  openToDate,
  weekStartsOn,
  occurrences,
  dateEntries,
  perfectSlots,
  onChange,
  onDateManual,
  showSurroundingMonths,
}) => {
  const dates = useMemo(
    () =>
      eachDayOfInterval({
        start: startOfFirstWeekOfMonth(openToDate, {
          weekStartsOn: weekdaysNumberMap[weekStartsOn],
        }),
        end: endOfLastWeekOfMonth(openToDate, {
          weekStartsOn: weekdaysNumberMap[weekStartsOn],
        }),
      }),
    [openToDate, weekStartsOn],
  );

  const headerDates = useMemo(() => dates.slice(0, 7), [dates]);

  return (
    <Styled.Container>
      <Styled.HeaderContainer>
        {headerDates.map((headerDate) => (
          <HeaderDate key={headerDate.getTime()} date={headerDate} />
        ))}
      </Styled.HeaderContainer>

      <Styled.Grid>
        {dates.map((date) => (
          <Day
            key={date.getTime()}
            date={date}
            occurrences={occurrences}
            dateEntries={dateEntries}
            perfectSlots={perfectSlots}
            onDate={onChange}
            onDateManual={onDateManual}
            hidden={!showSurroundingMonths && !isSameMonth(date, openToDate)}
          />
        ))}
      </Styled.Grid>
    </Styled.Container>
  );
};
