import React from 'react';
import { SelfSortingTaskCardTasksList } from 'shared/components/ui/task-card-tasks-list';

import { TaskCardTaskSectionLayout } from './task-card-task-section-layout';

export type SelfSortingTaskCardTaskSectionProps = Omit<
  React.ComponentProps<typeof TaskCardTaskSectionLayout>,
  'children' | 'onCreateGoal'
> &
  React.ComponentProps<typeof SelfSortingTaskCardTasksList>;

export const SelfSortingTaskCardTaskSection: React.FC<
  SelfSortingTaskCardTaskSectionProps
> = ({
  tasks,
  displayDue,
  onReorder,
  selectedTask,
  hasOwnSelectedState,
  showRepeatingAsTodo,
  showDetailOnSelect,
  hideMeta,
  hideGoal,
  hideLifeArea,

  goals,
  lifeAreas,
  date,
  priority,
  goalId,
  lifeAreaId,
  parentIds,
  parentChildIds,
  headerToDisplay,
  headerIsLink,
  customHeader,
  newTaskInputRef,
  headerHasImage,
  canCreateTask,
  canCreateHabit,
  canCollapse,
  tasksDisabled,
  onPremium,
}) => (
  <>
    <TaskCardTaskSectionLayout
      goals={goals}
      lifeAreas={lifeAreas}
      date={date}
      priority={priority}
      goalId={goalId}
      lifeAreaId={lifeAreaId}
      parentIds={parentIds}
      parentChildIds={parentChildIds}
      headerToDisplay={headerToDisplay}
      headerIsLink={headerIsLink}
      customHeader={customHeader}
      newTaskInputRef={newTaskInputRef}
      canCollapse={canCollapse}
      headerHasImage={headerHasImage}
      canCreateTask={canCreateTask}
      canCreateHabit={canCreateHabit}
      hideMeta={hideMeta}
      onPremium={onPremium}
    >
      <SelfSortingTaskCardTasksList
        tasks={tasks}
        displayDue={displayDue}
        onReorder={onReorder}
        selectedTask={selectedTask}
        hasOwnSelectedState={hasOwnSelectedState}
        showRepeatingAsTodo={showRepeatingAsTodo}
        showDetailOnSelect={showDetailOnSelect}
        hideMeta={hideMeta}
        hideGoal={hideGoal}
        hideLifeArea={hideLifeArea}
        tasksDisabled={tasksDisabled}
      />
    </TaskCardTaskSectionLayout>
  </>
);
