import { doc, serverTimestamp } from 'firebase/firestore';
import { MinimalRelationship } from 'shared/types/relationship';

import {
  BatchDocuments,
  CollectionOptions,
  getCollection,
  getDatabase,
} from './helpers';

export const updateRelationshipReferences = async (
  relationships: MinimalRelationship[],
) => {
  const batchDocuments = new BatchDocuments(getDatabase());
  const relationshipCollection = getCollection(CollectionOptions.Relationships);

  relationships.forEach(({ id, references }) =>
    batchDocuments.updateDocument({
      data: { references, updatedAt: serverTimestamp() },
      ref: doc(relationshipCollection, id),
    }),
  );

  batchDocuments.commit();
};
