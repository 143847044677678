import { SortMenuOptions } from 'shared/types/sort-menu-options';
import { SortingMode, TodayGroupBy } from 'shared/types/sorting-mode';

import { GroupByMenuOptions } from './menu-options';

export const groupByOptions: GroupByMenuOptions[] = [
  {
    id: TodayGroupBy.None,
    value: TodayGroupBy.None,
    label: `general.labels.tasks.group-by.label.${TodayGroupBy.None}`,
  },
  {
    id: TodayGroupBy.Goal,
    value: TodayGroupBy.Goal,
    label: `general.labels.tasks.group-by.label.${TodayGroupBy.Goal}`,
  },
  {
    id: TodayGroupBy.LifeArea,
    value: TodayGroupBy.LifeArea,
    label: `general.labels.tasks.group-by.label.${TodayGroupBy.LifeArea}`,
  },
  {
    id: TodayGroupBy.Priority,
    value: TodayGroupBy.Priority,
    label: `general.labels.tasks.group-by.label.${TodayGroupBy.Priority}`,
  },
  {
    id: TodayGroupBy.Type,
    value: TodayGroupBy.Type,
    label: `general.labels.tasks.group-by.label.${TodayGroupBy.Type}`,
  },
];

export const sortOptions: SortMenuOptions<SortingMode>[] = [
  {
    id: SortingMode.Custom,
    value: SortingMode.Custom,
    label: `general.labels.tasks.sort.options.${SortingMode.Custom}`,
  },
  {
    id: SortingMode.DueDate,
    value: SortingMode.DueDate,
    label: `general.labels.tasks.sort.options.${SortingMode.DueDate}`,
  },
  {
    id: SortingMode.Priority,
    value: SortingMode.Priority,
    label: `general.labels.tasks.sort.options.${SortingMode.Priority}`,
  },
];
