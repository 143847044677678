import { DataType, InteractionType } from 'shared/types/data-type';
import { elementHasAttribute } from 'shared/utils/element-has-attribute';

export const eventIsFromDataType = (e: MouseEvent, dataType: DataType) =>
  !!e.target &&
  elementHasAttribute(e.target as Element, 'data-component-type', dataType);

export const eventIsFromInteractionType = (
  e: MouseEvent,
  interactionType: InteractionType,
) =>
  !!e.target &&
  elementHasAttribute(
    e.target as Element,
    'data-interaction-for-type',
    interactionType,
  );
