import React, { Suspense, useEffect, useRef } from 'react';
import { TaskDetailColumn } from 'shared/components/connected/task-detail-column';
import { TaskMoreButton } from 'shared/components/connected/task-more-button';
import { TaskStatisticsButton } from 'shared/components/connected/task-statistics-button';
import { DetailColumnContainer } from 'shared/components/ui/detail-column-container';
import { useClickOutside } from 'shared/hooks/use-click-outside';
import { DataType, InteractionType } from 'shared/types/data-type';
import { ID } from 'shared/types/id';
import { InsightsTimeframes } from 'shared/types/insights';
import {
  eventIsFromDataType,
  eventIsFromInteractionType,
} from 'shared/utils/event-is-from-interaction-type';

import { TaskDetailButton } from './task-detail-button';

export type DesktopDetailColumnProps = {
  taskId?: ID;
  showStatistics: boolean;
  statisticsTimeframe?: InsightsTimeframes;
  onClose: () => void;
};

export const DesktopDetailColumn: React.FC<DesktopDetailColumnProps> = ({
  taskId,
  showStatistics,
  statisticsTimeframe,
  onClose,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const closeTimerRef = useRef<number>();

  useEffect(() => {
    closeTimerRef.current && window.clearTimeout(closeTimerRef.current);
  }, [taskId]);

  useClickOutside(containerRef, (event) => {
    if (
      !taskId ||
      eventIsFromInteractionType(event, InteractionType.Task) ||
      eventIsFromDataType(event, DataType.Task)
    ) {
      return;
    }

    closeTimerRef.current = window.setTimeout(onClose, 200);
  });

  return (
    <DetailColumnContainer
      ref={containerRef}
      open={!!taskId}
      onClose={onClose}
      actions={[
        showStatistics ? (
          <TaskDetailButton taskId={taskId} />
        ) : (
          <Suspense>
            <TaskStatisticsButton taskId={taskId} />
          </Suspense>
        ),
        <TaskMoreButton taskId={taskId} hideEditButton={!showStatistics} />,
      ]}
    >
      {!!taskId && (
        <Suspense>
          <TaskDetailColumn
            taskId={taskId}
            showStatistics={showStatistics}
            statisticsTimeframe={statisticsTimeframe}
            onClose={onClose}
          />
        </Suspense>
      )}
    </DetailColumnContainer>
  );
};
