import { rgba } from 'polished';
import { isNumber } from 'shared/utils/is-number';
import styled, { css, DefaultTheme } from 'styled-components';

import { Colors } from './types';

const getColor = (color: Colors, theme: DefaultTheme) => {
  switch (color) {
    case Colors.Info:
      return {
        default: theme.palette.info.main,
        background: rgba(theme.palette.info.main, 0.4),
        border: theme.palette.info.main,
      };
    case Colors.Warning:
      return {
        default: theme.palette.warning.main,
        background: rgba(theme.palette.warning.main, 0.4),
        border: theme.palette.warning.main,
      };
    case Colors.Error:
      return {
        default: theme.palette.error.dark,
        background: rgba(theme.palette.error.dark, 0.4),
        border: theme.palette.error.dark,
      };
    case Colors.Success:
      return {
        default: theme.palette.success.main,
        background: rgba(theme.palette.success.main, 0.4),
        border: theme.palette.success.main,
      };
    default:
      return {
        default: theme.palette.divider,
        background: theme.palette.divider,
        border: theme.palette.primary.main,
      };
  }
};

export const Container = styled.label`
  line-height: 62.5%;
  display: inline-block;
  cursor: pointer;
`;

export const Checkbox = styled.span<{
  $color: Colors;
  $isPlaceholder: boolean;
  $isRecommended: boolean;
  $isDisabled: boolean;
  $completionPercentage?: number;
}>`
  pointer-events: none;
  position: relative;
  display: inline-block;
  width: ${({ theme }) => (theme.isMobile ? '2rem' : '1.6rem')};
  height: ${({ theme }) => (theme.isMobile ? '2rem' : '1.6rem')};
  margin: ${({ theme }) => theme.spacing(theme.isMobile ? 1.5 : 1)};

  border-radius: ${({ theme }) => theme.spacing(theme.isMobile ? 2 : 1.5)};

  transition: all 200ms;

  ${({ theme, $color, $completionPercentage }) =>
    isNumber($completionPercentage)
      ? css`
          @property --startDeg {
            syntax: '<angle>';
            inherits: false;
            initial-value: 0deg;
          }
          @property --startDegOne {
            syntax: '<angle>';
            inherits: false;
            initial-value: 0deg;
          }
          @property --endDeg {
            syntax: '<angle>';
            inherits: false;
            initial-value: 0deg;
          }

          --startDeg: ${3.6 * $completionPercentage!}deg;
          --startDegOne: ${3.6 * $completionPercentage! + 0.0001}deg;
          --endDeg: ${360 - 0.0001}deg;

          border: 0.2rem solid #0000;
          transition:
            --startDeg 500ms,
            --startDegOne 500ms,
            --endDeg 500ms;
          background:
            conic-gradient(
                ${({ theme }) => theme.palette.background.default} 0 0
              )
              padding-box,
            ${() => {
                const { background, border } = getColor($color, theme);

                if (!isNumber($completionPercentage)) {
                  return border;
                }

                return `conic-gradient(
    from 0deg at 50% 50%,
    ${border} 0deg,
    ${border} var(--startDeg),
    ${background} var(--startDegOne),
    ${background} var(--endDeg),
    ${background} 360deg
  )`;
              }}
              border-box;
          border-image-slice: 1;
        `
      : css`
          border: 0.2rem solid ${getColor($color, theme).default};
        `};

  ${({ $isPlaceholder }) =>
    $isPlaceholder &&
    css`
      border-style: dotted;
    `}

  ${({ $isRecommended, theme }) =>
    $isRecommended &&
    css`
      border-color: ${theme.palette.brown['200']};
    `};

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      opacity: 0.4;
      border-color: ${({ theme }) => theme.palette.divider};
    `};

  :hover {
    background-color: ${({ theme }) => theme.palette.brown['50']};
  }
`;

export const Checkmark = styled.span<{ $isSmall: boolean }>`
  pointer-events: none;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
  transition: opacity 200ms;

  font-size: ${({ $isSmall }) => ($isSmall ? '1rem' : '1.2rem')};
  line-height: 62.5%;
  color: ${({ theme }) => theme.palette.primary.contrastText};
`;

export const Input = styled.input`
  position: absolute;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;

  border: none;
  visibility: hidden;

  &:checked {
    + ${Checkbox} {
      background: ${({ theme }) => theme.palette.primary.main};
      border-color: ${({ theme }) => theme.palette.primary.main};

      ${Checkmark} {
        opacity: 1;
      }
    }
  }
`;

export const Label = styled.span`
  display: inline-block;
  margin-left: ${({ theme }) => theme.spacing(2)};
`;
