import { useCreateHabitAllowed } from 'features/tasks';
import { useUserHadTrial } from 'features/user';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PremiumBanner } from 'shared/components/ui/premium-banner';
import { Habit } from 'shared/types/habit';
import { ID } from 'shared/types/id';
import { TaskPriorityOptions } from 'shared/types/task-base';
import { Timestamp } from 'shared/types/timestamp';

import { SectionLayout } from '../section-layout';
import { SortableHabitsList } from '../sortable-habits-list';
import * as Styled from './habit-section.style';

export type SelfSortingHabitSectionProps = {
  habits: Habit[];
  weekDate: Timestamp;
  onHabit: (id: ID) => void;
  goalId?: ID;
  lifeAreaId?: ID;
  priority?: TaskPriorityOptions;
  onReorder?: (ids: ID[]) => void;
  onPremium?: () => void;
};

export const SelfSortingHabitSection: React.FC<
  SelfSortingHabitSectionProps
> = ({
  goalId,
  lifeAreaId,
  priority,
  habits,
  weekDate,
  onHabit,
  onReorder,
  onPremium,
}) => {
  const { t } = useTranslation();

  const userHadTrial = useUserHadTrial();
  const {
    enforced: createHabitLimitsEnforced,
    limit: createHabitLimits,
    total: totalHabits,
  } = useCreateHabitAllowed();

  return (
    <Styled.Container>
      <SectionLayout
        goalId={goalId}
        lifeAreaId={lifeAreaId}
        priority={priority}
      >
        <SortableHabitsList
          habits={habits}
          weekDate={weekDate}
          onHabit={onHabit}
          onReorder={onReorder}
        />

        {onPremium && (
          <PremiumBanner
            title={t(
              createHabitLimitsEnforced
                ? 'pages.habits.premium-banner.title'
                : 'pages.habits.premium-banner.not-enforced.title',
              { total: totalHabits, limit: createHabitLimits },
            )}
            description={t(
              createHabitLimitsEnforced
                ? userHadTrial
                  ? 'pages.habits.premium-banner.description'
                  : 'pages.habits.premium-banner.try-for-free.description'
                : 'pages.habits.premium-banner.not-enforced.description',
              { limit: createHabitLimits },
            )}
            onClick={onPremium}
            isTrial={!userHadTrial}
          />
        )}
      </SectionLayout>
    </Styled.Container>
  );
};
