import { useTagCompletedTasks } from 'features/tags';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Section } from 'shared/components/ui/section';
import { Sizes } from 'shared/components/ui/section/types';
import { TasksList } from 'shared/components/ui/tasks-list';

export const CompletedTasks: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const completedTasks = useTagCompletedTasks(id);

  const orderedCompletedTasks = useMemo(
    () =>
      completedTasks.toSorted(
        (taskA, taskB) =>
          (taskB.completedAt?.getTime() ?? 0) -
          (taskA.completedAt?.getTime() ?? 0),
      ),
    [completedTasks],
  );

  return orderedCompletedTasks.length ? (
    <Section
      title={t('pages.tag-detail.completed.title')}
      titleCount={orderedCompletedTasks.length}
      size={Sizes.Small}
      canCollapse
    >
      <TasksList tasks={orderedCompletedTasks} showRepeatingAsTodo />
    </Section>
  ) : null;
};
