import { useDeleteTag, useTagById } from 'features/tags';
import { useIsFavorite, useToggleFavorite } from 'features/user';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Edit3, Star, Trash2 } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import {
  PopupMenu,
  PopupMenuButton,
  PopupMenuList,
  PopupMenuListItem,
} from 'shared/components/ui/popup-menu';
import { useOpenEditTagDialog } from 'shared/contexts/tag-form';
import { ID } from 'shared/types/id';
import { FavoriteType } from 'shared/types/user-settings';

export type TagContextMenuProps = Omit<
  React.ComponentProps<typeof PopupMenu>,
  'children' | 'ref' | 'onOutside'
> & {
  id: ID;
  onClose: () => void;
};

export const TagContextMenu: React.FC<TagContextMenuProps> = ({
  id,
  onClose,
  ...rest
}) => {
  const { t } = useTranslation();
  const tag = useTagById(id);
  const isFavorite = useIsFavorite(id);

  const openEditDialog = useOpenEditTagDialog();
  const { submit: deleteTag } = useDeleteTag();
  const toggleFavorite = useToggleFavorite();

  const onEdit = () => {
    tag && openEditDialog(tag, tag.id);
    onClose();
  };

  const onFavorite = () => {
    tag && toggleFavorite(tag.id, FavoriteType.Tag);
    onClose();
  };

  const onDelete = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    tag && deleteTag(tag);
    onClose();
  };

  return (
    <PopupMenu onOutside={onClose} {...rest}>
      <PopupMenuList hasBottomBorder>
        <PopupMenuListItem>
          <PopupMenuButton onClick={onEdit} start={<Icon icon={Edit3} />}>
            {t('context-menus.tags.labels.edit')}
          </PopupMenuButton>
        </PopupMenuListItem>
      </PopupMenuList>

      <PopupMenuList hasBottomBorder>
        <PopupMenuListItem>
          <PopupMenuButton onClick={onFavorite} start={<Icon icon={Star} />}>
            {t(
              isFavorite
                ? 'context-menus.tags.labels.remove-favorite'
                : 'context-menus.tags.labels.add-favorite',
            )}
          </PopupMenuButton>
        </PopupMenuListItem>
      </PopupMenuList>

      <PopupMenuList>
        <PopupMenuListItem>
          <PopupMenuButton onClick={onDelete} start={<Icon icon={Trash2} />}>
            {t('context-menus.tags.labels.delete')}
          </PopupMenuButton>
        </PopupMenuListItem>
      </PopupMenuList>
    </PopupMenu>
  );
};
