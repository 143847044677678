import { useMemo } from 'react';
import { useTasksQuery } from 'shared/hooks/queries/use-tasks-query';
import { useUser } from 'shared/hooks/use-user';

export const useInboxTodos = () => {
  const user = useUser();
  const { data } = useTasksQuery(user?.uid, {
    uncompletedOnly: true,
    noGoalsOnly: true,
    noScheduleOnly: true,
  });

  return useMemo(
    () =>
      data?.filter(
        ({
          goalId,
          lifeAreaId,
          archivedAt,
          completedAt,
          parentIds,
          endStrategy,
        }) =>
          !goalId &&
          !lifeAreaId &&
          !archivedAt &&
          !completedAt &&
          !endStrategy?.deadline &&
          !parentIds?.length,
      ) ?? [],
    [data],
  );
};
