import {
  addMonths,
  addWeeks,
  addYears,
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from 'date-fns';
import { weekdaysNumberMap } from 'shared/constants';
import { isBetween } from 'shared/lib/date-fns';
import { Frequency, RRule } from 'shared/lib/rrule';
import {
  CompletionTargetUnit,
  FrequencyUnit,
  HabitSchedule,
  ScheduleEntryState,
} from 'shared/types/habit-schedule';
import { Timestamp } from 'shared/types/timestamp';
import { WeekDays } from 'shared/types/week-days';
import { getRruleFrequency } from 'shared/utils/get-rrule-dates';
import { getRruleInterval } from 'shared/utils/schedules/get-rrule-interval';

export type Options = {
  referenceDate: Timestamp;
  weekStartsOn: WeekDays;
};

type getTimeframeDatesOptions = {
  referenceDate: Timestamp;
  weekStartsOn: WeekDays;
  interval: number;
};

const getTimeframeDates = (
  frequency: FrequencyUnit,
  { referenceDate, weekStartsOn, interval }: getTimeframeDatesOptions,
) => {
  switch (frequency) {
    case FrequencyUnit.Year:
      return {
        // subtract the interval with 1 less than the interval is, as the current timeframe is already one of the intervals
        startDate: addYears(startOfYear(referenceDate), -interval + 1),
        endDate: endOfYear(referenceDate),
      };
    case FrequencyUnit.Month:
      return {
        // subtract the interval with 1 less than the interval is, as the current timeframe is already one of the intervals
        startDate: addMonths(startOfMonth(referenceDate), -interval + 1),
        endDate: endOfMonth(referenceDate),
      };
    default:
      return {
        startDate: addWeeks(
          startOfWeek(referenceDate, {
            weekStartsOn: weekdaysNumberMap[weekStartsOn],
          }),
          // subtract the interval with 1 less than the interval is, as the current timeframe is already one of the intervals
          -interval + 1,
        ),
        endDate: endOfWeek(referenceDate, {
          weekStartsOn: weekdaysNumberMap[weekStartsOn],
        }),
      };
  }
};

export const getScheduleTimeframeCompletion = (
  schedule: HabitSchedule,
  { referenceDate, weekStartsOn }: Options,
) => {
  const rrule = RRule.fromString(schedule.rrule.format);
  const interval = getRruleInterval(schedule.rrule.format);
  const frequency =
    // if frequency is present, frequency unit is used
    schedule.frequency?.unit ??
    (rrule.options.freq === Frequency.YEARLY
      ? FrequencyUnit.Year
      : rrule.options.freq === RRule.MONTHLY
        ? FrequencyUnit.Month
        : FrequencyUnit.Week);

  const { startDate: timeframeStartDate, endDate: timeframeEndDate } =
    getTimeframeDates(frequency, { referenceDate, weekStartsOn, interval });

  // get completions of the current timeframe
  const completions = schedule.entries.filter(
    ({ state, date, value }) =>
      state === ScheduleEntryState.Complete &&
      !!value &&
      isBetween(date, timeframeStartDate, timeframeEndDate),
  );

  const totalUniqueDays = Math.max(
    1,
    schedule.frequency?.count ??
      getRruleFrequency({
        format: schedule.rrule.format,
        startDate: schedule.startDate,
        referenceStartDate: timeframeStartDate,
        endDate: timeframeEndDate,
      }),
  );

  return {
    completions,
    totalUniqueDays,
    total:
      schedule.completionTarget?.unit === CompletionTargetUnit.Day
        ? totalUniqueDays * (schedule.completionTarget?.count ?? 1)
        : schedule.completionTarget?.count ?? totalUniqueDays,
  };
};
