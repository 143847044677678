import { useTagById } from 'features/tags';
import React from 'react';
import { useParams } from 'react-router';
import { TagGroupBy } from 'shared/types/sorting-mode';

import { GoalGroupingTasksList } from '../goal-grouping-tasks-list';
import { LifeAreaGroupingTasksList } from '../life-area-grouping-tasks-list';
import { NoGroupingTasksList } from '../no-grouping-tasks-list';
import { PriorityGroupingTasksList } from '../priority-grouping-tasks-list';
import { TypeGroupingTasksList } from '../type-grouping-tasks-list';

const groupByLists: Record<TagGroupBy, React.FC> = {
  [TagGroupBy.None]: NoGroupingTasksList,
  [TagGroupBy.Goal]: GoalGroupingTasksList,
  [TagGroupBy.LifeArea]: LifeAreaGroupingTasksList,
  [TagGroupBy.Priority]: PriorityGroupingTasksList,
  [TagGroupBy.Type]: TypeGroupingTasksList,
};

export const TasksOverview: React.FC = () => {
  const { id } = useParams();
  const tag = useTagById(id);

  const groupBy = tag?.taskGroupBy ?? TagGroupBy.None;
  const TaskListComponent = groupByLists[groupBy];

  return <TaskListComponent />;
};
