import {
  addDays,
  addMonths,
  addWeeks,
  endOfDay,
  endOfMonth,
  endOfWeek,
  isAfter,
  isSameDay,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import { weekdaysNumberMap } from 'shared/constants';
import { Frequency, RRule } from 'shared/lib/rrule';
import { HabitSchedule, ScheduleEntryState } from 'shared/types/habit-schedule';
import { getScheduleTimeframeCompletion } from 'shared/utils/get-schedule-timeframe-completion';
import { getUniqueDayEntries } from 'shared/utils/get-unique-day-entries';

import { frequencyToWeekAdjustmentsMap } from './constants';
import { Options } from './types';

export const isScheduleOverdue = (
  schedule: HabitSchedule,
  { today, weekStartsOn }: Options,
) => {
  // with a count it cannot be overdue
  if (schedule.frequency?.count) {
    return false;
  }

  // completion counts higher than 1 means it cannot be overdue
  if (schedule.completionTarget.count > 1) {
    return false;
  }

  // check if startDate is in the future
  const startDate = schedule.startDate;
  if (isAfter(startDate, today) || isSameDay(startDate, today)) {
    return false;
  }

  // check if the last skip is in the future
  const skips = schedule.entries.filter(
    ({ state }) => state === ScheduleEntryState.Skip,
  );
  const lastSkip = skips?.[skips.length - 1];
  if (
    lastSkip &&
    (isAfter(lastSkip.date, today) || isSameDay(lastSkip.date, today))
  ) {
    return false;
  }

  // get last complete or failure
  const completionsOrFailures = schedule.entries.filter(
    ({ state, value }) =>
      state === ScheduleEntryState.Failure ||
      (state === ScheduleEntryState.Complete && !!value),
  );
  const lastCompleteOrFailure = completionsOrFailures.toSorted(
    (entryA, entryB) => entryB.date.getTime() - entryA.date.getTime(),
  )[0];
  if (
    lastCompleteOrFailure &&
    (isAfter(lastCompleteOrFailure.date, today) ||
      isSameDay(lastCompleteOrFailure.date, today))
  ) {
    return false;
  }

  // create a rrule out of the schedule data
  const rrule = RRule.fromString(schedule.rrule.format);

  // get previous timeframe endDate to see if the previous timeframe is completed
  const previousTimeframeEndDate = [Frequency.DAILY, Frequency.WEEKLY].includes(
    rrule.options.freq,
  )
    ? endOfDay(
        endOfWeek(addWeeks(today, -1), {
          weekStartsOn: weekdaysNumberMap[weekStartsOn],
        }),
      )
    : endOfDay(addMonths(endOfMonth(today), -1));
  const {
    completions: completionsPreviousTimeframe,
    totalUniqueDays: totalAllowedCompletionsPreviousTimeframe,
  } = getScheduleTimeframeCompletion(schedule, {
    referenceDate: previousTimeframeEndDate,
    weekStartsOn,
  });

  const uniqueCompletionsPreviousTimeframe = getUniqueDayEntries(
    completionsPreviousTimeframe.map(({ date }) => date),
  );

  // get the current timeframe startDate if the old timeframe is completed
  const currentTimeframeStartDate =
    uniqueCompletionsPreviousTimeframe.length >=
    totalAllowedCompletionsPreviousTimeframe
      ? [Frequency.DAILY, Frequency.WEEKLY].includes(rrule.options.freq)
        ? endOfDay(
            startOfWeek(today, {
              weekStartsOn: weekdaysNumberMap[weekStartsOn],
            }),
          )
        : endOfDay(startOfMonth(today))
      : undefined;

  // get the latest activity within the schedule to start looking from
  const latestScheduleDate = [
    currentTimeframeStartDate,
    lastCompleteOrFailure
      ? startOfDay(addDays(lastCompleteOrFailure.date, 1))
      : undefined,
    lastSkip ? startOfDay(addDays(lastSkip.date, 1)) : undefined,
    startOfDay(startDate),
    addWeeks(
      endOfDay(today),
      frequencyToWeekAdjustmentsMap[rrule.options.freq],
    ),
  ]
    .filter(Boolean)
    .sort((dateA, dateB) => dateB!.getTime() - dateA!.getTime())[0] as Date;

  rrule.options.dtstart = startOfDay(schedule.startDate);

  const lastDueDate = rrule
    .between(
      endOfDay(addDays(latestScheduleDate, -1)),
      startOfDay(addDays(today, 1)),
      true,
    )
    .pop();

  if (!lastDueDate || isSameDay(lastDueDate, today)) {
    return false;
  }

  return endOfDay(lastDueDate);
};
