import { useToggleAllTasksFilterTag } from 'features/user';
import React, { useMemo } from 'react';
import { SelectTagsContextMenu } from 'shared/components/connected/select-tags-context-menu';
import { useUser } from 'shared/hooks/use-user';

export type FilterContextMenuProps = Omit<
  React.ComponentProps<typeof SelectTagsContextMenu>,
  'activeTags' | 'onTag'
>;

export const FilterContextMenu: React.FC<FilterContextMenuProps> = ({
  ...selectTagsContextMenuProps
}) => {
  const user = useUser();

  const toggleAllTasksFilter = useToggleAllTasksFilterTag();

  const activeTags = useMemo(
    () => user?.settings?.allTasksFilterByTags ?? [],
    [user?.settings?.allTasksFilterByTags],
  );

  return (
    <SelectTagsContextMenu
      {...selectTagsContextMenuProps}
      onTag={toggleAllTasksFilter}
      activeTags={activeTags}
    />
  );
};
