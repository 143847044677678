import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import React, { useMemo } from 'react';
import { ID } from 'shared/types/id';
import { Task } from 'shared/types/task';

import { TaskCardListItem } from './task-card-list-item';
import * as Styled from './tasks-list.style';

export type TasksListProps = Omit<
  React.ComponentProps<typeof TaskCardListItem>,
  'task' | 'hasDnd' | 'isSelected' | 'isDisabled'
> & {
  tasks: Task[];
  selectedTask?: ID;
  canReorder?: boolean;
};

export const TasksList: React.FC<TasksListProps> = ({
  tasks,
  selectedTask,
  canReorder,
  ...rest
}) => {
  const Wrapper = canReorder ? SortableContext : React.Fragment;
  const wrapperProps = useMemo(
    () =>
      canReorder
        ? {
            items: tasks.map(({ id }) => id),
            strategy: verticalListSortingStrategy,
          }
        : ({} as { items: string[] }),
    [canReorder, tasks],
  );

  return (
    <Wrapper {...wrapperProps}>
      <Styled.List>
        {tasks.map((task) => (
          <Styled.ListItem key={task.id}>
            <TaskCardListItem
              task={task}
              hasDnd={canReorder}
              isSelected={selectedTask === task.id}
              {...rest}
            />
          </Styled.ListItem>
        ))}
      </Styled.List>
    </Wrapper>
  );
};
