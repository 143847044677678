import { isBefore, isSameDay, startOfDay } from 'date-fns';
import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { characterWidth } from 'shared/assets/styles';
import { useToday } from 'shared/contexts/today';
import { DateEntry, XAxisTick } from 'shared/lib/recharts';
import { Timestamp } from 'shared/types/timestamp';
import { WeekDays } from 'shared/types/week-days';
import { formatNumberShort } from 'shared/utils/format-number-short';
import { interpolateDataEntries } from 'shared/utils/interpolate-data-entries';
import { isNumber } from 'shared/utils/is-number';
import { useTheme } from 'styled-components';

import {
  dateTickFormatterDay,
  dateTickFormatterDayInMonth,
  dateTickFormatterMonth,
  dateTickFormatterWeekDay,
} from './date-tick-formatter';
import { Dot } from './dot';
import * as Styled from './time-progress-chart.style';
import { TickRange } from './types';
import { useTickRange } from './use-tick-range';
import { useXAxisData } from './use-x-axis-data';
import { useYAxisData } from './use-y-axis-data';

export type ProgressChartProps = {
  data: DateEntry[];
  startDate?: Timestamp | null;
  endDate?: Timestamp | null;
  chartStartDate?: Timestamp | null;
  chartEndDate?: Timestamp | null;
  startValue?: number | null;
  endValue?: number | null;
  weekStartsOn: WeekDays;
  completed?: boolean;
  hideYAxis?: boolean;
  showTodayCount?: boolean;
  showTodayLine?: boolean;
  hasSmallHeight?: boolean;
};

export const TimeProgressChart: React.FC<ProgressChartProps> = memo(
  ({
    data,
    startDate,
    endDate,
    chartStartDate,
    chartEndDate,
    startValue,
    endValue,
    weekStartsOn,
    completed,
    hideYAxis,
    showTodayCount,
    showTodayLine,
    hasSmallHeight,
  }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const today = useToday();

    const dataEntries = useMemo<DateEntry[]>(() => {
      let entries = data.map(({ date, value }) => ({
        date: startOfDay(date),
        value,
      }));

      if (completed) {
        return chartStartDate && chartEndDate
          ? interpolateDataEntries(entries, chartStartDate, chartEndDate)
          : entries;
      }

      if (startDate) {
        const startDateEntry = data.find(({ date }) =>
          isSameDay(date, startDate),
        );
        const beforeStartDateEntry = data.find(({ date }) =>
          isBefore(date, startDate),
        );

        if (!startDateEntry && !beforeStartDateEntry) {
          entries.push({ date: startDate, value: startValue ?? 0 });
        }
      }

      const todayEntry = data.find(({ date }) => isSameDay(date, today));
      if (!todayEntry) {
        const lastValue =
          data.toSorted(
            (entryA, entryB) => entryA.date.getTime() - entryB.date.getTime(),
          )[data.length - 1]?.value ??
          startValue ??
          0;
        entries.push({ date: today, value: lastValue });
      }

      entries.sort(
        (entryA, entryB) => entryA.date.getTime() - entryB.date.getTime(),
      );

      return chartStartDate && chartEndDate
        ? interpolateDataEntries(entries, chartStartDate, chartEndDate)
        : entries;
    }, [
      chartEndDate,
      chartStartDate,
      completed,
      data,
      startDate,
      startValue,
      today,
    ]);

    const estimateData = useMemo(() => {
      if (!isNumber(endValue) || !startDate || !endDate) {
        return;
      }

      const data = [
        {
          date: startDate,
          value: startValue ?? 0,
        },
        { date: endDate, value: endValue! },
      ];

      const interpolatedData =
        chartStartDate && chartEndDate
          ? interpolateDataEntries(data, chartStartDate, chartEndDate)
          : data;

      return interpolatedData.map(({ date, value }) => ({
        date: date.getTime(),
        estimated: value,
      }));
    }, [
      chartEndDate,
      chartStartDate,
      endDate,
      endValue,
      startDate,
      startValue,
    ]);

    const { domain: xDomain, ticks: xTicks } = useXAxisData(dataEntries, {
      startDate: chartStartDate ?? startDate ?? undefined,
      endDate: chartEndDate ?? endDate ?? undefined,
      completed,
      strictDates: !!(chartStartDate && chartEndDate),
      weekStartsOn,
    });

    const {
      domain: yDomain,
      ticks: yTicks,
      maxCharacters: yMaxCharacters,
    } = useYAxisData(dataEntries, {
      startValue: startValue ?? undefined,
      endValue: endValue ?? undefined,
    });
    const tickRange = useTickRange(xDomain);

    const dateTickFormatter = useMemo(
      () =>
        tickRange === TickRange.Day
          ? dateTickFormatterWeekDay
          : tickRange === TickRange.Week
            ? dateTickFormatterDay
            : tickRange === TickRange.Month
              ? dateTickFormatterDayInMonth
              : dateTickFormatterMonth,
      [tickRange],
    );

    const xFormatter = (tick: number) =>
      dateTickFormatter({
        date: new Date(tick),
        today,
        t,
        weekStartsOn,
      });

    const yAxisWidth = yMaxCharacters * characterWidth;

    return (
      <ResponsiveContainer width="100%" height={hasSmallHeight ? 116 : 172}>
        <AreaChart
          data={dataEntries}
          margin={{ top: 8, bottom: 8, left: 8, right: 8 }}
          style={{ cursor: 'inherit' }}
        >
          <defs>
            <Styled.ProgressGradient id="progress" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" />
              <stop offset="100%" />
            </Styled.ProgressGradient>
            <Styled.EstimateGradient id="estimate" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" />
              <stop offset="100%" />
            </Styled.EstimateGradient>
          </defs>

          <CartesianGrid
            vertical={false}
            strokeDasharray="2 2"
            stroke={theme.palette.divider}
          />

          <YAxis
            domain={yDomain}
            axisLine={false}
            tickLine={false}
            ticks={yTicks}
            tickFormatter={formatNumberShort}
            width={hideYAxis ? 0 : yAxisWidth}
            style={{
              ...theme.typography.overline,
              fill: theme.palette.text.secondary,
            }}
          />
          <XAxis
            dataKey="date"
            domain={xDomain}
            type="number"
            scale="time"
            tick={<XAxisTick dateFormatter={xFormatter} />}
            minTickGap={-4}
            tickMargin={0}
            ticks={xTicks}
            tickLine={false}
            tickFormatter={xFormatter}
            textAnchor="right"
            stroke={theme.palette.divider}
            interval={
              tickRange === TickRange.Month ? 'preserveStartEnd' : undefined
            }
          />

          {!!estimateData?.length && (
            <Area
              dataKey="estimated"
              data={estimateData}
              dot={false}
              activeDot={false}
              stroke={theme.palette.divider}
              strokeWidth={1}
              fill="url(#estimate)"
              fillOpacity={1}
              baseValue={yDomain[0]}
              clipPath="none"
              isAnimationActive={false}
            />
          )}

          {!!startDate && startDate.getTime() !== xDomain[0] && (
            <ReferenceLine
              x={startDate.getTime()}
              stroke={theme.palette.info.main}
            />
          )}

          {!!endDate && endDate.getTime() !== xDomain[1] && (
            <ReferenceLine
              x={endDate.getTime()}
              stroke={theme.palette.error.main}
            />
          )}

          {!!showTodayLine && (
            <ReferenceLine
              x={today.getTime()}
              stroke={theme.palette.primary.main}
              strokeDasharray="2 2"
            />
          )}

          <Area
            type="linear"
            dataKey="value"
            stroke={theme.palette.primary.main}
            strokeWidth={2}
            fill="url(#progress)"
            fillOpacity={1}
            dot={
              <Dot
                data={dataEntries}
                yAxisWidth={yAxisWidth}
                showCount={showTodayCount}
              />
            }
            baseValue={yDomain[0]}
            isAnimationActive={false}
          />
        </AreaChart>
      </ResponsiveContainer>
    );
  },
);
