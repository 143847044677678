import { useMemo } from 'react';
import { useRelationshipsQuery } from 'shared/hooks/queries/use-relationships-query';
import { ID, IDPrefixes, PrefixedID } from 'shared/types/id';
import {
  createPrefixId,
  getPrefixedId,
  getPrefixedIdPrefix,
} from 'shared/utils/prefix-id';

import { useUser } from './use-user';

export const useRelationshipIdsById = (
  id: ID | undefined,
  prefixOption: IDPrefixes,
) => {
  const relationships = useRelationshipsMap();

  return useMemo(() => {
    const relationBase: Record<IDPrefixes, ID[]> = {
      [IDPrefixes.Task]: [],
      [IDPrefixes.Goal]: [],
      [IDPrefixes.LifeArea]: [],
      [IDPrefixes.Tag]: [],
    };

    if (!id) {
      return relationBase;
    }

    const relatedIds = relationships[createPrefixId(id, prefixOption)];

    return (
      relatedIds?.reduce((acc, prefixedId) => {
        const idPrefix = getPrefixedIdPrefix(prefixedId);

        if (!idPrefix) {
          return acc;
        }

        acc[idPrefix].push(getPrefixedId(prefixedId));
        return acc;
      }, relationBase) ?? relationBase
    );
  }, [id, prefixOption, relationships]);
};

export const useRelationshipsMap = () => {
  const relationships = useRelationships();

  return useMemo<Record<PrefixedID, PrefixedID[]>>(() => {
    const relationshipsMap: Record<PrefixedID, PrefixedID[]> = {};

    // First, ensure all relationships are in the map
    relationships.forEach((relationship) => {
      // Add the relationship to the map for its own ID
      const relation = (relationshipsMap[relationship.id] =
        relationshipsMap[relationship.id] ?? []);

      // Loop over the references
      relationship.references.forEach((refId) => {
        // add all references that are not present yet
        if (!relation.includes(refId)) {
          relation.push(refId);
        }

        // add the reference as relation to the relationsMap - relations are both ways
        const referenceRelation = (relationshipsMap[refId] =
          relationshipsMap[refId] ?? []);

        // add the relationship if it is not present yet
        if (!referenceRelation.includes(relationship.id)) {
          relationshipsMap[refId].push(relationship.id);
        }
      });
    });

    return relationshipsMap;
  }, [relationships]);
};

export const useRelationships = () => {
  const user = useUser();
  const { data } = useRelationshipsQuery(user?.uid);

  return useMemo(() => data ?? [], [data]);
};
