import Dialog from '@mui/material/Dialog';
import React, { Suspense, useMemo } from 'react';
import { DialogVariantOptions } from 'shared/assets/styles';
import { PremiumPurchased } from 'shared/dialogs/premium-purchased';
import {
  LoadingPurchasePremium,
  PurchasePremium,
} from 'shared/dialogs/purchase-premium';
import { useRedirectToStripeUrl } from 'shared/hooks/use-redirect-to-stripe-url';
import { useToggle } from 'shared/hooks/use-toggle';
import { QueryParamOptions, useQueryParam } from 'shared/lib/react-router-dom';
import { Price } from 'shared/types/price';

import {
  PremiumDialogContext,
  PremiumDialogContextProps,
} from './premium-dialog-context';

export type PremiumDialogProviderProps = {
  children: React.ReactNode;
};

export const PremiumDialogProvider: React.FC<PremiumDialogProviderProps> = ({
  children,
}) => {
  const [premiumQueryParam, setPremiumQueryParam] = useQueryParam(
    QueryParamOptions.Premium,
  );
  const [discountCode, setDiscountCode] = useQueryParam(QueryParamOptions.C);
  const [dialogOpen, _, toggleDialogForce] = useToggle(!!discountCode);
  const { redirect: redirectToStripeUrl, isLoading } = useRedirectToStripeUrl();

  const onSubmit = (price?: Price) => {
    if (!price) {
      return;
    }

    return redirectToStripeUrl({ interval: price.interval });
  };

  const closeDialog = () => {
    toggleDialogForce(false);
    setDiscountCode();
  };

  const clearPremiumQueryParam = () => setPremiumQueryParam();

  const value = useMemo<PremiumDialogContextProps>(
    () => ({ openPremiumDialog: () => toggleDialogForce(true) }),
    [toggleDialogForce],
  );

  return (
    <PremiumDialogContext.Provider value={value}>
      {children}
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        variant={DialogVariantOptions.Premium}
      >
        <Suspense fallback={<LoadingPurchasePremium onClose={closeDialog} />}>
          <PurchasePremium
            onClose={closeDialog}
            onSubmit={onSubmit}
            isLoading={isLoading}
          />
        </Suspense>
      </Dialog>
      <PremiumPurchased
        open={premiumQueryParam === 'true'}
        onClose={clearPremiumQueryParam}
      />
    </PremiumDialogContext.Provider>
  );
};
