import {
  useOverdueHabits,
  useOverdueRepeatingTasks,
  useOverdueTasks,
  useTodaysHabits,
  useTodaysRepeatingTasks,
  useTodaysTodos,
} from 'features/tasks';
import { useMemo } from 'react';
import { useRelationshipsMap } from 'shared/hooks/use-relationships';
import { useUser } from 'shared/hooks/use-user';
import { ID, IDPrefixes } from 'shared/types/id';
import { getWeekStartsOn } from 'shared/utils/get-week-starts-on';
import {
  createPrefixId,
  getPrefixedId,
  getPrefixedIdPrefix,
} from 'shared/utils/prefix-id';

export const useTodayTasks = () => {
  const user = useUser();
  const weekStartsOn = getWeekStartsOn(user);

  const todos = useTodaysTodos();
  const repeatingTasks = useTodaysRepeatingTasks(weekStartsOn);
  const habits = useTodaysHabits(weekStartsOn);
  const overdueTodos = useOverdueTasks();
  const overdueHabits = useOverdueHabits(weekStartsOn);
  const overdueRepeatingTasks = useOverdueRepeatingTasks(weekStartsOn);

  const relationships = useRelationshipsMap();

  return useMemo(() => {
    const tasks = user?.settings?.todayHideHabits
      ? [...overdueTodos, ...overdueRepeatingTasks, ...todos, ...repeatingTasks]
      : [
          ...overdueTodos,
          ...overdueRepeatingTasks,
          ...overdueHabits,
          ...todos,
          ...repeatingTasks,
          ...habits,
        ];

    if (!user?.settings?.todayFilterByTags?.length) {
      return tasks;
    }

    const tagTaskIds = user.settings.todayFilterByTags
      .map((tagId) =>
        relationships[createPrefixId(tagId, IDPrefixes.Tag)]
          ?.reduce<ID[]>((acc, prefixedId) => {
            if (getPrefixedIdPrefix(prefixedId) === IDPrefixes.Task) {
              acc.push(getPrefixedId(prefixedId));
            }

            return acc;
          }, [])
          .filter(Boolean),
      )
      .flat(1);

    return tasks.filter(({ id }) => tagTaskIds.includes(id));
  }, [
    user?.settings?.todayHideHabits,
    user?.settings?.todayFilterByTags,
    overdueTodos,
    overdueRepeatingTasks,
    todos,
    repeatingTasks,
    overdueHabits,
    habits,
    relationships,
  ]);
};
