import { ID, IDPrefixes, PrefixedID } from 'shared/types/id';

export const createPrefixId = (id: ID, prefix: IDPrefixes) => `${prefix}:${id}`;

export const getPrefixedId = (id: PrefixedID) =>
  id.substring(id.indexOf(':') + 1);

export const getPrefixedIdPrefix = (id: PrefixedID): IDPrefixes | undefined => {
  if (!isValidPrefixedId(id)) {
    return undefined;
  }

  return id.substring(0, id.indexOf(':')) as IDPrefixes;
};

export const isValidPrefixedId = (id: ID | PrefixedID): id is PrefixedID => {
  const colonIndex = id.indexOf(':');
  if (colonIndex === -1) {
    return false;
  }

  const prefix = id.substring(0, colonIndex);
  const baseId = id.substring(colonIndex + 1);

  return !!baseId && Object.values(IDPrefixes).includes(prefix as IDPrefixes);
};
