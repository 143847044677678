import { endOfWeek, format, startOfWeek } from 'date-fns';
import React from 'react';
import { ChevronLeft } from 'shared/assets/icons';
import { IconButton } from 'shared/components/ui/icon-button';
import { weekdaysNumberMap } from 'shared/constants';
import { Timestamp } from 'shared/types/timestamp';
import { WeekDays } from 'shared/types/week-days';

import * as Styled from './week-selector.style';

export type WeekSelectorProps = {
  onPrev: () => void;
  onPrevDisabled?: boolean;
  onNext: () => void;
  onNextDisabled?: boolean;
  onDate?: () => void;
  date: Timestamp;
  weekStartsOn: WeekDays;
};

export const WeekSelector: React.FC<WeekSelectorProps> = ({
  onPrev,
  onPrevDisabled,
  onNext,
  onNextDisabled,
  onDate,
  date,
  weekStartsOn,
}) => {
  const _onPrev = () => !onPrevDisabled && onPrev();
  const _onNext = () => !onNextDisabled && onNext();
  const _onDate = () => onDate?.();

  const startOfWeekDate = startOfWeek(date, {
    weekStartsOn: weekdaysNumberMap[weekStartsOn],
  });
  const endOfWeekDate = endOfWeek(date, {
    weekStartsOn: weekdaysNumberMap[weekStartsOn],
  });

  return (
    <Styled.Container>
      <IconButton
        icon={ChevronLeft}
        onClick={_onPrev}
        disabled={onPrevDisabled}
        noHover
      />
      <Styled.Button onClick={_onDate} as={!onDate ? 'span' : undefined}>
        {`${format(startOfWeekDate, 'LLL d')} - ${format(endOfWeekDate, 'LLL d')}`}
      </Styled.Button>
      <IconButton
        icon={ChevronLeft}
        rotation={180}
        onClick={_onNext}
        disabled={onNextDisabled}
        noHover
      />
    </Styled.Container>
  );
};
