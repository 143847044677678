import { typographyToCss } from 'shared/lib/mui';
import styled, { css } from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;

  max-height: 100%;

  overflow: auto;
`;

export const Body = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(8)};

  overflow: auto;
`;

export const Section = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: ${({ theme }) => theme.spacing(2)};
  grid-row-gap: ${({ theme }) => theme.spacing(2)};

  padding: ${({ theme }) => `0 ${theme.spacing(6)}`};
  width: 100%;
`;

export const LabelContainer = styled.span`
  display: inline-flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
`;

export const Label = styled.label<{ $disabled?: boolean; $alignTop?: boolean }>`
  ${({ theme }) => typographyToCss(theme.typography.body2)};
  min-width: 9.6rem;
  padding-top: ${({ theme }) => theme.spacing(1.5)};

  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 0.45;
    `};

  ${({ $alignTop }) =>
    $alignTop &&
    css`
      align-self: flex-start;
    `};
`;

export const InfoIconContainer = styled.span`
  padding-top: ${({ theme }) => theme.spacing(1.5)};

  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 1.6rem;
  line-height: 62.5%;
`;

export const FullSectionLine = styled.span<{
  $hasSectionLineHeight?: boolean;
  $spaceBetween?: boolean;
}>`
  grid-column: span 2;
  display: flex;
  align-items: center;

  ${({ $hasSectionLineHeight }) =>
    $hasSectionLineHeight &&
    css`
      height: 3.2rem;
    `};

  ${({ $spaceBetween }) =>
    $spaceBetween &&
    css`
      justify-content: space-between;
    `};
`;

export const Interactions = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
  justify-content: space-between;

  padding: ${({ theme }) => `${theme.spacing(5)} ${theme.spacing(6)}`};
`;

export const EditButtonContainer = styled.div``;

export const FormButtons = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};

  margin-left: auto;

  ${({ theme }) =>
    theme.isMobile &&
    css`
      display: none;
    `};
`;

export const DatepickerContainer = styled.div<{ $fullWidth: boolean }>`
  width: 100%;

  ${({ $fullWidth }) =>
    !$fullWidth &&
    css`
      display: flex;
      justify-content: flex-start;
    `};
`;
