import React, { useMemo } from 'react';
import { SectionLayout } from 'shared/components/connected/section-layout';
import { AllTasksGoalTasksList } from 'shared/components/ui/all-tasks-goal-tasks-list';
import { useUser } from 'shared/hooks/use-user';
import { ID } from 'shared/types/id';
import { Task } from 'shared/types/task';

import { useToggleSection } from '../../hooks/use-toggle-section';

export type GoalSectionProps = {
  id: ID;
  items: Task[];
  offsetTop?: number;
};

export const GoalSection: React.FC<GoalSectionProps> = ({
  id,
  items,
  offsetTop,
}) => {
  const user = useUser();
  const onToggleSection = useToggleSection();

  const initialOpen = useMemo(
    () => !user?.settings?.allTasks?.collapsedGoals?.includes(id),
    [id, user?.settings?.allTasks?.collapsedGoals],
  );

  return (
    <SectionLayout
      key={id}
      goalId={id}
      showInboxAsInbox
      onToggleCollapse={onToggleSection}
      initialOpen={initialOpen}
    >
      <AllTasksGoalTasksList id={id} tasks={items} offsetTop={offsetTop} />
    </SectionLayout>
  );
};
