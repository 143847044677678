import { useGoals } from 'features/goals';
import { useLocalizedLifeAreas } from 'features/life-areas';
import { useUpdateHabitScheduleEntry } from 'features/tasks';
import React, { useRef } from 'react';
import { TaskCardContextMenu } from 'shared/components/connected/task-card-context-menu';
import { useOpenManualEntryLogging } from 'shared/contexts/habit-manual-entry-logging';
import { useOpenTaskStatistics } from 'shared/contexts/task-detail';
import { useContextMenuState } from 'shared/hooks/use-context-menu-state';
import { useUser } from 'shared/hooks/use-user';
import { Habit } from 'shared/types/habit';
import { ScheduleEntryState } from 'shared/types/habit-schedule';
import { ID } from 'shared/types/id';
import { InsightsTimeframes } from 'shared/types/insights';
import { Timestamp } from 'shared/types/timestamp';
import { getWeekStartsOn } from 'shared/utils/get-week-starts-on';

import { HabitDetailDesktopCardView } from './habit-detail-desktop-card-view';

export type HabitDetailCardProps = {
  habit: Habit;
  weekDate: Timestamp;
  onClick?: (id: ID) => void;
  isSortable?: boolean;
};

export const HabitDetailDesktopCard: React.FC<HabitDetailCardProps> = ({
  habit,
  weekDate,
  isSortable,
  onClick,
}) => {
  const contextRef = useRef<HTMLDivElement>(null);
  const goals = useGoals();
  const lifeAreas = useLocalizedLifeAreas();
  const user = useUser();
  const weekStartsOn = getWeekStartsOn(user);
  const updateHabitEntry = useUpdateHabitScheduleEntry();
  const openManualEntryLogging = useOpenManualEntryLogging();
  const openStatistics = useOpenTaskStatistics();
  const {
    open: openMenu,
    close: closeMenu,
    position: menuPosition,
  } = useContextMenuState();

  const _onDate = (
    _: ID,
    date: Timestamp,
    type: ScheduleEntryState,
    options?: { value?: number; increment?: number },
  ) =>
    // if there is no type, the completion progress has to be reset
    updateHabitEntry(habit, date, type, options);

  const _onDateManual = (_: ID, date: Timestamp) =>
    openManualEntryLogging(habit.id, date);

  const _openStatistics = () =>
    openStatistics(habit.id, InsightsTimeframes.FourWeeks);

  return (
    <>
      <HabitDetailDesktopCardView
        habit={habit}
        goals={goals}
        lifeAreas={lifeAreas}
        weekDate={weekDate}
        weekStartsOn={weekStartsOn}
        onDate={_onDate}
        onDateManual={_onDateManual}
        onClick={onClick}
        isSortable={isSortable}
        onStatistics={_openStatistics}
        onContextMenu={openMenu}
      />
      {!!menuPosition && (
        <TaskCardContextMenu
          ref={contextRef}
          task={habit}
          onClose={closeMenu}
          location={menuPosition}
        />
      )}
    </>
  );
};
