// import { isSameDay } from 'date-fns';
import { useCallback } from 'react';
import { useOpenDeleteHabitDialog } from 'shared/contexts/delete-habit';
import { useOpenManualEntryLogging } from 'shared/contexts/habit-manual-entry-logging';
import { useOpenTaskDetail } from 'shared/contexts/task-detail';
import { useToday } from 'shared/contexts/today';
import {
  ScheduleCompletionActions,
  ScheduleEntryState,
} from 'shared/types/habit-schedule';
import { ID } from 'shared/types/id';
import { Task } from 'shared/types/task';
import { TaskType } from 'shared/types/task-base';
import { getActiveSchedule } from 'shared/utils/get-active-schedule';
import { isNumber } from 'shared/utils/is-number';

import { useCompleteTask } from './use-complete-task';
import { useDeleteTask } from './use-delete-task';
import { useUpdateHabitScheduleEntry } from './use-update-habit-schedule-entry';
import { useUpdateTask } from './use-update-task';

type Options = {
  parentTaskId?: ID;
  toggleDayEntry?: boolean;
};

export const useTaskTodayControls = ({
  parentTaskId,
  toggleDayEntry,
}: Options = {}) => {
  const { submit: onUpdate } = useUpdateTask();
  const today = useToday();
  const completeTask = useCompleteTask();
  const updateHabitEntry = useUpdateHabitScheduleEntry();
  const { submit: deleteTask } = useDeleteTask(parentTaskId);

  const deleteHabit = useOpenDeleteHabitDialog();
  const openTaskDetail = useOpenTaskDetail();
  const openManualEntryLogging = useOpenManualEntryLogging();

  const onComplete = useCallback(
    (
      task: Task,
      type = ScheduleEntryState.Complete,
      options?: { value?: number; increment?: number },
    ) => {
      if (task.type === TaskType.Task) {
        completeTask(task);
        !task.parentIds?.length && openTaskDetail(undefined);
        return;
      }

      const activeSchedule = getActiveSchedule(task.schedules);
      if (
        activeSchedule?.action?.type ===
          ScheduleCompletionActions.LogCompletionValue &&
        !(isNumber(options?.value) || isNumber(options?.increment))
      ) {
        openManualEntryLogging(task.id, today);
        return;
      }

      updateHabitEntry(task, new Date(), type, {
        ...options,
        toggle: toggleDayEntry,
      });
    },
    [
      completeTask,
      openManualEntryLogging,
      openTaskDetail,
      today,
      toggleDayEntry,
      updateHabitEntry,
    ],
  );

  const onDelete = useCallback(
    (task: Task) => {
      task.type === TaskType.Task ? deleteTask(task) : deleteHabit(task);
    },
    [deleteHabit, deleteTask],
  );

  return {
    onUpdate,
    onDelete,
    onComplete,
    openManualEntryLogging,
  };
};
