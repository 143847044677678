import { useHabits } from 'features/tasks';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOpenTaskDetail } from 'shared/contexts/task-detail';
import { Paths } from 'shared/routes';
import { ID } from 'shared/types/id';

import { useWeekDate } from '../../contexts/timeframe';
import { EmptyHabitsList } from '../empty-habits-list';
import { HabitsList } from '../habits-list';
import * as Styled from './completed-habits-list.style';

export const CompletedHabitsList: React.FC = () => {
  const weekDate = useWeekDate();
  const navigate = useNavigate();
  const habits = useHabits({ completedOnly: true, unArchivedOnly: true });

  const openTaskDetail = useOpenTaskDetail();

  const onHabit = (id: ID) => {
    const habit = habits.find((habit) => habit.id === id);
    if (habit && !habit.frozenAt) {
      openTaskDetail(id);
    }
  };

  useEffect(() => {
    if (!habits.length) {
      navigate(Paths.Habits, { replace: true });
    }
  }, [habits.length, navigate]);

  return !habits.length ? (
    <EmptyHabitsList />
  ) : (
    <Styled.Container>
      <HabitsList items={habits} onHabit={onHabit} weekDate={weekDate} />
    </Styled.Container>
  );
};
