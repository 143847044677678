import { format, isAfter, isSameDay } from 'date-fns';
import React, { useMemo, useRef } from 'react';
import { ArrowRightCalendar, XCalendar } from 'shared/assets/icons';
import { DayContextMenu } from 'shared/components/ui/day-context-menu';
import { Icon } from 'shared/components/ui/icon';
import { useToday } from 'shared/contexts/today';
import { useContextMenuState } from 'shared/hooks/use-context-menu-state';
import { isBetween } from 'shared/lib/date-fns';
import {
  ScheduleEntryState,
  ScheduleTargetEntry,
} from 'shared/types/habit-schedule';
import { Timeframe } from 'shared/types/timeframe';
import { Timestamp } from 'shared/types/timestamp';
import { useTheme } from 'styled-components';

import * as Styled from './month-progress-preview.style';

export type DayProps = {
  date: Timestamp;
  occurrences?: Timestamp[];
  dateEntries: ScheduleTargetEntry[];
  perfectSlots?: Timeframe[];
  onDate?: (
    date: Timestamp,
    type: ScheduleEntryState,
    options?: { value?: number; increment?: number },
  ) => void;
  onDateManual: (date: Timestamp) => void;
  hidden?: boolean;
};

export const Day: React.FC<DayProps> = ({
  date,
  dateEntries,
  occurrences = [],
  perfectSlots = [],
  onDate,
  onDateManual,
  hidden,
}) => {
  const today = useToday();
  const theme = useTheme();
  const containerRef = useRef<HTMLDivElement>(null);

  const { position, open, close } = useContextMenuState({
    disabled: theme.isMobile,
  });

  const dateEntry = useMemo(
    () => dateEntries.find((entry) => isSameDay(entry.date, date)),
    [date, dateEntries],
  );
  const completionPercentage = dateEntry?.value
    ? (dateEntry.value / (dateEntry.target ?? 0)) * 100
    : 0;
  const isFailure = dateEntry?.state === ScheduleEntryState.Failure;
  const isSkipped = dateEntry?.state === ScheduleEntryState.Skip;
  const isFuture = isAfter(date, today);

  const isOccurrence = useMemo(
    () => !!occurrences.find((occurrence) => isSameDay(occurrence, date)),
    [date, occurrences],
  );

  const isPerfectDay = useMemo(
    () =>
      !!perfectSlots.find(({ startDate, endDate }) =>
        isBetween(date, startDate, endDate),
      ),
    [date, perfectSlots],
  );

  const onComplete = onDate
    ? (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        dateEntry?.onDateManual
          ? onDateManual(date)
          : onDate(date, ScheduleEntryState.Complete);

        close();
      }
    : undefined;

  const onManual = () => onDateManual(date);

  const openMenu = (e: React.MouseEvent) => {
    e.preventDefault();
    !isFuture && open(e);
  };

  return (
    <Styled.DayContainer ref={containerRef} onContextMenu={openMenu}>
      <Styled.Day
        $isToday={isSameDay(date, today)}
        $isOccurrence={isOccurrence}
        $completionPercentage={completionPercentage}
        $isPerfectDay={isPerfectDay}
        $isSkipped={isSkipped}
        $isFailure={isFailure}
        $isFuture={isFuture}
        $hidden={!!hidden}
        onClick={onComplete}
        aria-label={format(date, 'MMM d')}
      >
        {!completionPercentage && (isSkipped || isFailure) && (
          <Styled.DayIcon>
            <Icon icon={isSkipped ? ArrowRightCalendar : XCalendar} />
          </Styled.DayIcon>
        )}
      </Styled.Day>

      {!!position && !!onDate && (
        <DayContextMenu
          date={date}
          target={dateEntry?.target ?? 1}
          onDate={onDate}
          onManual={onManual}
          location={position}
          onClose={close}
        />
      )}
    </Styled.DayContainer>
  );
};
