import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Check, Plus, Search, Tag, X } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import {
  Colors,
  PopupMenu,
  PopupMenuButton,
  PopupMenuInput,
  PopupMenuList,
  PopupMenuListItem,
} from 'shared/components/ui/popup-menu';
import { useForwardedRef } from 'shared/hooks/use-forwarded-ref';
import { ID } from 'shared/types/id';
import { Tag as TagType } from 'shared/types/tag';
import { getAutoCompleteSuggestions } from 'shared/utils/autocomplete-value-matches-input';

import * as Styled from './tag-search-menu.style';

export type TagSearchMenuProps = Omit<
  React.ComponentProps<typeof PopupMenu>,
  'ref' | 'children'
> & {
  tags: TagType[];
  value?: ID[] | null;
  onChange: (ids: ID[]) => void;
  onCreate?: (name: string) => void;
};

export const TagSearchMenu = forwardRef<HTMLDivElement, TagSearchMenuProps>(
  ({ tags, value, onChange, onCreate, ...rest }, ref) => {
    const containerRef = useForwardedRef(ref);
    const { t } = useTranslation();
    const inputRef = useRef<HTMLInputElement>(null);
    const [containerHeight, setContainerHeight] = useState<number>();

    const [searchValue, setSearchValue] = useState('');

    const _onCreate = (e: React.MouseEvent) => {
      e.preventDefault();
      onCreate?.(searchValue);
      setSearchValue('');
    };

    const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) =>
      setSearchValue(e.target.value);

    const onClear = () => onChange([]);

    const _onChange = (id: ID) => {
      const oldValue = value ?? [];
      const newValue = oldValue.filter((tagId) => tagId !== id);
      if (oldValue.length === newValue.length) {
        newValue.push(id);
      }

      onChange(newValue);
    };

    const tagSuggestions = useMemo(
      () => getAutoCompleteSuggestions(searchValue, tags, 'name'),
      [searchValue, tags],
    );

    useEffect(() => {
      inputRef.current?.focus();
    }, []);

    useEffect(() => {
      setContainerHeight(containerRef.current?.clientHeight);
    }, [containerRef]);

    return (
      <Styled.PopupMenu ref={containerRef} $height={containerHeight} {...rest}>
        <PopupMenuList hasBottomBorder>
          <PopupMenuListItem>
            <PopupMenuInput
              ref={inputRef}
              startIcon={Search}
              value={searchValue}
              placeholder={t('task.tags.search.placeholder')}
              onChange={onChangeInput}
            />
          </PopupMenuListItem>
        </PopupMenuList>

        <PopupMenuList hasBottomBorder={!!value?.length}>
          {tagSuggestions.map(({ id, name, color }) => {
            const onSelectItem = () => _onChange(id);

            return (
              <PopupMenuListItem key={id}>
                <PopupMenuButton
                  start={
                    <Styled.IconContainer $color={color}>
                      <Icon icon={Tag} />
                    </Styled.IconContainer>
                  }
                  onClick={onSelectItem}
                  end={value?.includes(id) ? <Icon icon={Check} /> : undefined}
                  tabIndex={0}
                  overflowEllipsis
                >
                  {name}
                </PopupMenuButton>
              </PopupMenuListItem>
            );
          })}

          {!!onCreate && (
            <PopupMenuListItem>
              <PopupMenuButton
                start={<Icon icon={Plus} />}
                startColor={Colors.Primary}
                textColor={Colors.Primary}
                onClick={_onCreate}
                tabIndex={0}
              >
                {searchValue || t('forms.tag.create.title')}
              </PopupMenuButton>
            </PopupMenuListItem>
          )}
        </PopupMenuList>

        {!!value?.length && (
          <PopupMenuList>
            <PopupMenuListItem>
              <PopupMenuButton
                start={<Icon icon={X} />}
                startColor={Colors.Error}
                textColor={Colors.Error}
                onClick={onClear}
                tabIndex={0}
              >
                {t('task.tags.options.remove.label', { count: value.length })}
              </PopupMenuButton>
            </PopupMenuListItem>
          </PopupMenuList>
        )}
      </Styled.PopupMenu>
    );
  },
);
