import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CheckCircle2,
  ForwardChevrons,
  Keyboard,
  Minus,
  Plus,
  Trash2,
  X,
} from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import {
  PopupMenu,
  PopupMenuButton,
  PopupMenuList,
  PopupMenuListItem,
} from 'shared/components/ui/popup-menu';
import { ScheduleEntryState } from 'shared/types/habit-schedule';
import { isNumber } from 'shared/utils/is-number';

export type CheckboxContextMenuProps = Omit<
  React.ComponentProps<typeof PopupMenu>,
  'children' | 'ref' | 'onOutside'
> & {
  target?: number | null;
  onOption: (
    type: ScheduleEntryState,
    options?: { value?: number; increment?: number },
  ) => void;
  onManual?: () => void;
  onClose: () => void;
};

export const CheckboxContextMenu: React.FC<CheckboxContextMenuProps> = ({
  target,
  onOption,
  onManual,
  onClose,
  ...rest
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const onComplete = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onOption(ScheduleEntryState.Complete, { value: target ?? undefined });
    onClose();
  };

  const onIncrement = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onOption(ScheduleEntryState.Complete, { increment: 1 });
    onClose();
  };

  const onDecrement = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onOption(ScheduleEntryState.Complete, { increment: -1 });
    onClose();
  };

  const _onManual = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onManual?.();
    onClose();
  };

  const onSkip = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onOption(ScheduleEntryState.Skip);
    onClose();
  };

  const onFail = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onOption(ScheduleEntryState.Failure);
    onClose();
  };

  const onClear = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onOption(ScheduleEntryState.Complete, { value: 0 });
    onClose();
  };

  return (
    <PopupMenu
      ref={containerRef}
      position="bottom-start"
      onOutside={onClose}
      {...rest}
    >
      <PopupMenuList hasBottomBorder>
        {isNumber(target) && (
          <PopupMenuListItem>
            <PopupMenuButton
              onClick={onComplete}
              start={<Icon icon={CheckCircle2} />}
            >
              {t('context-menus.calendar.day.complete.label')}
            </PopupMenuButton>
          </PopupMenuListItem>
        )}
        <PopupMenuListItem>
          <PopupMenuButton onClick={onIncrement} start={<Icon icon={Plus} />}>
            +1
          </PopupMenuButton>
        </PopupMenuListItem>
        <PopupMenuListItem>
          <PopupMenuButton onClick={onDecrement} start={<Icon icon={Minus} />}>
            -1
          </PopupMenuButton>
        </PopupMenuListItem>
        {!!onManual && (
          <PopupMenuListItem>
            <PopupMenuButton
              onClick={_onManual}
              start={<Icon icon={Keyboard} />}
            >
              {t('context-menus.calendar.day.log-progress.label')}
            </PopupMenuButton>
          </PopupMenuListItem>
        )}
      </PopupMenuList>

      <PopupMenuList hasBottomBorder>
        <PopupMenuListItem>
          <PopupMenuButton
            onClick={onSkip}
            start={<Icon icon={ForwardChevrons} />}
          >
            {t('context-menus.calendar.day.skip.label')}
          </PopupMenuButton>
        </PopupMenuListItem>
        <PopupMenuListItem>
          <PopupMenuButton onClick={onFail} start={<Icon icon={X} />}>
            {t('context-menus.calendar.day.fail.label')}
          </PopupMenuButton>
        </PopupMenuListItem>
      </PopupMenuList>

      <PopupMenuList>
        <PopupMenuListItem>
          <PopupMenuButton onClick={onClear} start={<Icon icon={Trash2} />}>
            {t('context-menus.calendar.day.clear.label')}
          </PopupMenuButton>
        </PopupMenuListItem>
      </PopupMenuList>
    </PopupMenu>
  );
};
