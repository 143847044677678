import { DefaultError, useMutation } from '@tanstack/react-query';
import { updateHabitPriorityTaskOrder } from 'shared/services/backend-api';
import { ID } from 'shared/types/id';
import { TaskPriorityOptions } from 'shared/types/task-base';

export const useUpdateHabitPriorityTaskOrderMutation = () =>
  useMutation<
    void,
    DefaultError,
    { priority: TaskPriorityOptions; taskIds: ID[] }[]
  >({
    mutationFn: async (priorities) => updateHabitPriorityTaskOrder(priorities),
  });
