import { useGoals } from 'features/goals';
import { useTagById, useTagTasks } from 'features/tags';
import { useMemo } from 'react';
import { useToday } from 'shared/contexts/today';
import { useLifeAreaSections } from 'shared/hooks/use-life-area-sections';
import { useUser } from 'shared/hooks/use-user';
import { Goal } from 'shared/types/goal';
import { ID } from 'shared/types/id';
import { lifeAreaOtherID } from 'shared/types/orderable-section';
import { SortingMode } from 'shared/types/sorting-mode';
import { getSortedTasks } from 'shared/utils/get-sorted-tasks';
import { getWeekStartsOn } from 'shared/utils/get-week-starts-on';

export const useTagLifeAreaTaskSections = (id?: ID) => {
  const today = useToday();
  const user = useUser();
  const tag = useTagById(id);
  const tasks = useTagTasks(id);
  const goals = useGoals();
  const lifeAreaSections = useLifeAreaSections();

  const sections = useMemo(() => {
    const filteredGoals = goals.filter(
      ({ id: goalId }) =>
        !!tasks.find(({ goalId: taskGoalId }) => taskGoalId === goalId),
    );
    const goalsMap = filteredGoals.reduce<Record<ID, Goal>>((acc, goal) => {
      acc[goal.id] = goal;
      return acc;
    }, {});

    const otherGoalIds = goals
      .filter(({ lifeAreaId }) => !lifeAreaId)
      .map(({ id: goalId }) => goalId);

    return lifeAreaSections
      .map(({ id: sectionId, lifeArea }) => ({
        id: sectionId,
        lifeArea,
        items: getSortedTasks(
          tasks.filter(({ goalId, lifeAreaId }) =>
            sectionId === lifeAreaOtherID
              ? (!goalId || otherGoalIds.includes(goalId)) && !lifeAreaId
              : (goalId && goalsMap[goalId]?.lifeAreaId === sectionId) ||
                lifeAreaId === sectionId,
          ),
          {
            today,
            weekStartsOn: getWeekStartsOn(user),
            mode: Array.from(
              new Set([SortingMode.DueDate, tag?.taskSortingMode]),
            ).filter(Boolean) as SortingMode[],
            order: tag?.taskSorting ?? [],
          },
        ),
      }))
      .filter(
        ({ id: sectionId, items }) =>
          sectionId === lifeAreaOtherID || !!items.length,
      );
  }, [goals, lifeAreaSections, tag, tasks, today, user]);

  return {
    sections,
    tasks,
  };
};
