import { useLifeAreaById } from 'features/life-areas';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Heart, IconFormIcons } from 'shared/assets/icons';
import { ListSectionLifeAreaHeader } from 'shared/components/ui/section-header-life-area';
import { ID } from 'shared/types/id';

export type LifeAreaHeaderProps = {
  lifeAreaId: ID;
};

export const LifeAreaHeader: React.FC<LifeAreaHeaderProps> = ({
  lifeAreaId,
}) => {
  const { t } = useTranslation();
  const lifeArea = useLifeAreaById(lifeAreaId);

  const lifeAreaName =
    lifeArea?.name ?? t('general.sections.life-areas.other.title');

  return (
    <ListSectionLifeAreaHeader
      id={lifeAreaId}
      icon={lifeArea ? IconFormIcons[lifeArea.iconName] : Heart}
      name={lifeAreaName}
      color={lifeArea?.color ?? undefined}
      isFrozen={!!lifeArea?.frozenAt}
      image={lifeArea?.visionPreview?.image}
    />
  );
};
