import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Check, Sliders } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import { IconButton, Variants } from 'shared/components/ui/icon-button';
import {
  PopupMenu,
  PopupMenuButton,
  PopupMenuList,
  PopupMenuListItem,
  PopupMenuTitle,
} from 'shared/components/ui/popup-menu';
import { useOpenMenu } from 'shared/hooks/use-open-menu';
import { HabitGroupBy, HabitSortingMode } from 'shared/types/sorting-mode';
import { useTheme } from 'styled-components';

import * as Styled from './header.style';
import { groupByOptions, sortOptions } from './sort-options';

export type HeaderSortButtonProps = {
  sort?: HabitSortingMode;
  groupBy?: HabitGroupBy;
  onSort: (sort: HabitSortingMode) => void;
  onGroupBy: (groupBy: HabitGroupBy) => void;
};

export const HeaderSortButton: React.FC<HeaderSortButtonProps> = ({
  sort,
  groupBy,
  onSort,
  onGroupBy,
}) => {
  const theme = useTheme();
  const containerRef = useRef<HTMLDivElement>(null);
  const { menuOpen, openMenu, closeMenu } = useOpenMenu();
  const { t } = useTranslation();

  const _onGroupBy = (value: HabitGroupBy) => {
    onGroupBy(value);
    closeMenu();
  };

  const _onSort = (value: HabitSortingMode) => {
    onSort(value);
    closeMenu();
  };

  return (
    <Styled.SortContainer ref={containerRef}>
      <IconButton
        icon={Sliders}
        onClick={openMenu}
        variant={theme.isMobile ? Variants.Outlined : Variants.Contained}
      />

      {menuOpen && (
        <PopupMenu
          referenceElement={containerRef}
          position="bottom-end"
          onOutside={closeMenu}
        >
          <PopupMenuList hasBottomBorder>
            <PopupMenuTitle title={t('pages.habits.group-by.title')} />

            {groupByOptions.map(({ id, label, value }) => (
              <PopupMenuListItem key={id}>
                <PopupMenuButton
                  start={groupBy === id ? <Icon icon={Check} /> : undefined}
                  onClick={() => _onGroupBy(value)}
                  hasIndentation={groupBy !== id}
                >
                  {t(label)}
                </PopupMenuButton>
              </PopupMenuListItem>
            ))}
          </PopupMenuList>
          <PopupMenuList>
            <PopupMenuTitle title={t('general.labels.habits.sort.title')} />

            {sortOptions.map(({ id, label, value }) => (
              <PopupMenuListItem key={id}>
                <PopupMenuButton
                  start={sort === id ? <Icon icon={Check} /> : undefined}
                  onClick={() => _onSort(value)}
                  hasIndentation={sort !== id}
                >
                  {t(label)}
                </PopupMenuButton>
              </PopupMenuListItem>
            ))}
          </PopupMenuList>
        </PopupMenu>
      )}
    </Styled.SortContainer>
  );
};
