import * as yup from 'yup';

export type TagForm = {
  name?: string;
  color?: string;
  favorite?: boolean | null;
};

export const validationSchema: yup.ObjectSchema<TagForm> = yup.object({
  name: yup.string().required(),
  color: yup.string().required(),
  favorite: yup.boolean().nullable(),
});

export type TagFormFields = yup.InferType<typeof validationSchema>;
