import { SortMenuOptions } from 'shared/types/sort-menu-options';
import { HabitGroupBy, HabitSortingMode } from 'shared/types/sorting-mode';

import { GroupByMenuOptions } from './menu-options';

export const groupByOptions: GroupByMenuOptions[] = [
  {
    id: HabitGroupBy.None,
    value: HabitGroupBy.None,
    label: `pages.today.group-by.label.${HabitGroupBy.None}`,
  },
  {
    id: HabitGroupBy.Goal,
    value: HabitGroupBy.Goal,
    label: `pages.today.group-by.label.${HabitGroupBy.Goal}`,
  },
  {
    id: HabitGroupBy.LifeArea,
    value: HabitGroupBy.LifeArea,
    label: `pages.today.group-by.label.${HabitGroupBy.LifeArea}`,
  },
  {
    id: HabitGroupBy.Priority,
    value: HabitGroupBy.Priority,
    label: `pages.today.group-by.label.${HabitGroupBy.Priority}`,
  },
];

export const sortOptions: SortMenuOptions<HabitSortingMode>[] = [
  {
    id: HabitSortingMode.Custom,
    value: HabitSortingMode.Custom,
    label: `general.labels.tasks.sort.options.${HabitSortingMode.Custom}`,
  },
  {
    id: HabitSortingMode.Priority,
    value: HabitSortingMode.Priority,
    label: `general.labels.tasks.sort.options.${HabitSortingMode.Priority}`,
  },
];
