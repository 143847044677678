import { Placement } from '@floating-ui/react';
import React from 'react';
import { UnsplashImagePicker } from 'shared/components/connected/unsplash-image-picker';
import { PopupMenu } from 'shared/components/ui/popup-menu';
import { Image } from 'shared/types/image';

export type UnsplashMenuProps = {
  referenceElement: React.RefObject<HTMLElement>;
  position: Placement;
  onImage: (image: Image) => void;
  onOutside: () => void;
};

export const UnsplashMenu: React.FC<UnsplashMenuProps> = ({
  referenceElement,
  position,
  onImage,
  onOutside,
}) => (
  <PopupMenu
    referenceElement={referenceElement}
    position={position}
    onOutside={onOutside}
  >
    <UnsplashImagePicker onImage={onImage} />
  </PopupMenu>
);
