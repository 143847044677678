import styled, { css, DefaultTheme } from 'styled-components';

import { IconColors } from './types';

const getColor = (color: IconColors, theme: DefaultTheme) => {
  switch (color) {
    case IconColors.Info:
      return theme.palette.info.main;
    case IconColors.Warning:
      return theme.palette.warning.main;
    case IconColors.Error:
      return theme.palette.error.dark;
    case IconColors.Success:
      return theme.palette.success.main;
    default:
      return 'inherit';
  }
};

export const CheckboxContainer = styled.span<{ $isFaded?: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  padding-top: ${({ theme }) => theme.spacing(0.25)};
  width: 2.4rem;
  height: 2.4rem;
  min-width: 2.4rem;
  min-height: 2.4rem;

  line-height: 62.5%;
  font-size: 2rem;
  color: ${({ theme }) => theme.palette.grey['400']};

  ${({ theme }) =>
    theme.isMobile &&
    css`
      width: 3.2rem;
      height: 3.2rem;
      min-width: 3.2rem;
      min-height: 3.2rem;
    `};

  ${({ $isFaded }) =>
    $isFaded &&
    css`
      opacity: 0.3;
    `};
`;

export const IconContainer = styled.span<{ $color: IconColors }>`
  line-height: 62.5%;
  color: ${({ $color, theme }) => getColor($color, theme)};
`;
