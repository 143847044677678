import { doc } from 'firebase/firestore';
import { Tag } from 'shared/types/tag';

import { dateToFirestoreTimestamp } from './converters/date-to-firestore-timestamp';
import {
  CollectionOptions,
  getCollection,
  updateDocWithTimestamp,
} from './helpers';

export const updateTag = async (tag: Partial<Tag> & Pick<Tag, 'id'>) => {
  const tagCollection = getCollection(CollectionOptions.Tags);
  const docRef = doc(tagCollection, tag.id);
  updateDocWithTimestamp(docRef, dateToFirestoreTimestamp(tag));
};
