import { isSameDay } from 'date-fns';
import { useCompleteTask, useUpdateHabitScheduleEntry } from 'features/tasks';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckCircle, ForwardChevrons, Keyboard, X } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import {
  PopupMenuButton,
  PopupMenuList,
  PopupMenuListItem,
} from 'shared/components/ui/popup-menu';
import { useOpenManualEntryLogging } from 'shared/contexts/habit-manual-entry-logging';
import { useToday } from 'shared/contexts/today';
import { Habit } from 'shared/types/habit';
import { ScheduleEntryState } from 'shared/types/habit-schedule';
import { Task } from 'shared/types/task';
import { TaskType } from 'shared/types/task-base';
import { getActiveSchedule } from 'shared/utils/get-active-schedule';

export type CompleteListProps = {
  task: Task;
  onClose: () => void;
};

export const CompleteList: React.FC<CompleteListProps> = ({
  task,
  onClose,
}) => {
  const { t } = useTranslation();
  const today = useToday();
  const completeTask = useCompleteTask();
  const updateHabitEntry = useUpdateHabitScheduleEntry();
  const openManualEntryLogging = useOpenManualEntryLogging();

  const onComplete = () => {
    completeTask(task);
    onClose();
  };

  const onSkip = () => {
    updateHabitEntry(task as Habit, new Date(), ScheduleEntryState.Skip);
    onClose();
  };

  const onFail = () => {
    updateHabitEntry(task as Habit, new Date(), ScheduleEntryState.Failure);
    onClose();
  };

  const onLog = () => {
    openManualEntryLogging(task.id, new Date());
    onClose();
  };

  const isHabit = [TaskType.Habit, TaskType.Repeating].includes(task.type);

  const skippedTodayOrAfter = useMemo(
    () =>
      getActiveSchedule(task.schedules)?.entries.find(
        (entry) =>
          entry.state === ScheduleEntryState.Skip &&
          isSameDay(entry.date, today),
      ),
    [task.schedules, today],
  );
  const failedToday = useMemo(
    () =>
      getActiveSchedule(task.schedules)?.entries.find(
        (entry) =>
          entry.state === ScheduleEntryState.Failure &&
          isSameDay(entry.date, today),
      ),
    [task.schedules, today],
  );

  return (
    <PopupMenuList hasBottomBorder>
      {!isHabit && (
        <PopupMenuListItem>
          <PopupMenuButton
            onClick={onComplete}
            start={<Icon icon={CheckCircle} />}
          >
            {t(
              task.completedAt
                ? 'context-menus.task.labels.un-complete'
                : 'context-menus.task.labels.complete',
            )}
          </PopupMenuButton>
        </PopupMenuListItem>
      )}
      {isHabit && !skippedTodayOrAfter && (
        <PopupMenuListItem>
          <PopupMenuButton
            onClick={onSkip}
            start={<Icon icon={ForwardChevrons} />}
          >
            {t('context-menus.task.labels.skip')}
          </PopupMenuButton>
        </PopupMenuListItem>
      )}
      {isHabit && !failedToday && (
        <PopupMenuListItem>
          <PopupMenuButton onClick={onFail} start={<Icon icon={X} />}>
            {t('context-menus.task.labels.fail')}
          </PopupMenuButton>
        </PopupMenuListItem>
      )}
      {isHabit && (
        <PopupMenuListItem>
          <PopupMenuButton onClick={onLog} start={<Icon icon={Keyboard} />}>
            {t('context-menus.task.labels.log-progress')}
          </PopupMenuButton>
        </PopupMenuListItem>
      )}
    </PopupMenuList>
  );
};
