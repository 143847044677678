import React, { useRef } from 'react';
import { Repeat } from 'shared/assets/icons';
import { Checkbox, Colors } from 'shared/components/ui/checkbox';
import { Icon } from 'shared/components/ui/icon';
import { useOpenMenu } from 'shared/hooks/use-open-menu';
import { ScheduleEntryState } from 'shared/types/habit-schedule';
import { TaskCheckOptions } from 'shared/types/task';
import { TaskPriorityOptions } from 'shared/types/task-base';
import { isNumber } from 'shared/utils/is-number';

import { CheckboxContextMenu } from './checkbox-context-menu';
import * as Styled from './task-detail-checkbox.style';
import { IconColors } from './types';

const priorityCheckboxColorMap: Record<TaskPriorityOptions, Colors> = {
  [TaskPriorityOptions.Low]: Colors.Info,
  [TaskPriorityOptions.Medium]: Colors.Warning,
  [TaskPriorityOptions.High]: Colors.Error,
  [TaskPriorityOptions.None]: Colors.Default,
};

const priorityIconColorMap: Record<TaskPriorityOptions, IconColors> = {
  [TaskPriorityOptions.Low]: IconColors.Info,
  [TaskPriorityOptions.Medium]: IconColors.Warning,
  [TaskPriorityOptions.High]: IconColors.Error,
  [TaskPriorityOptions.None]: IconColors.Default,
};

export type TaskDetailCheckboxProps = {
  onChange: () => void;
  checked: TaskCheckOptions;
  onContextChange?: (
    type: ScheduleEntryState,
    options?: { value?: number; increment?: number },
  ) => void;
  onContextManual?: () => void;
  completionPercentage?: number;
  target?: number | null;
  priority?: TaskPriorityOptions | null;
  isFrozen?: boolean;
  isDisabled?: boolean;
};

export const TaskDetailCheckbox: React.FC<TaskDetailCheckboxProps> = ({
  onChange,
  checked,
  onContextChange,
  onContextManual,
  completionPercentage,
  target,
  priority,
  isFrozen,
  isDisabled,
}) => {
  const containerRef = useRef<HTMLSpanElement>(null);
  const { menuOpen, openMenu, closeMenu } = useOpenMenu();

  const onContextMenu = (e: React.MouseEvent) => {
    if (!isNumber(completionPercentage) || !onContextChange) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();
    openMenu();
  };

  const _onChange = () => {
    if (isFrozen) {
      return;
    }
    onChange();
  };

  const preventPropagation = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <Styled.CheckboxContainer
      $isFaded={isFrozen || isDisabled}
      onClick={preventPropagation}
      ref={containerRef}
      onContextMenu={onContextMenu}
    >
      {[TaskCheckOptions.Unchecked, TaskCheckOptions.Checked].includes(
        checked,
      ) ? (
        <Checkbox
          checked={checked !== TaskCheckOptions.Unchecked}
          completionPercentage={completionPercentage}
          color={priority ? priorityCheckboxColorMap[priority] : undefined}
          onChange={_onChange}
          disabled={isDisabled}
        />
      ) : (
        <Styled.IconContainer
          $color={
            priority
              ? priorityIconColorMap[priority]
              : priorityIconColorMap[TaskPriorityOptions.None]
          }
        >
          <Icon icon={Repeat} />
        </Styled.IconContainer>
      )}

      {menuOpen && !!onContextChange && (
        <CheckboxContextMenu
          target={target}
          referenceElement={containerRef}
          onClose={closeMenu}
          onOption={onContextChange}
          onManual={onContextManual}
        />
      )}
    </Styled.CheckboxContainer>
  );
};
