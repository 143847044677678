import { HabitGroupBy } from 'shared/types/sorting-mode';

import { getUser, getUserRef } from './get-user';
import { updateDocWithTimestamp } from './helpers';

export const updateUserHabitGroupBy = async (groupBy: HabitGroupBy) => {
  const user = await getUser();

  if (!user) {
    return;
  }

  const userRef = await getUserRef();

  if (!userRef) {
    return;
  }

  updateDocWithTimestamp(userRef, {
    'settings.habitGroupBy': groupBy,
  });
};
