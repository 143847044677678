import { useGoalById, useGoalControls, useGoals } from 'features/goals';
import { useLocalizedLifeAreas } from 'features/life-areas';
import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Plus, Sort } from 'shared/assets/icons';
import { SelfSortingGoalSectionTaskList } from 'shared/components/ui/goal-section-task-list';
import { IconButton } from 'shared/components/ui/icon-button';
import { Section } from 'shared/components/ui/section';
import { Sizes } from 'shared/components/ui/section/types';
import { SelfSortingTaskCardTasksList } from 'shared/components/ui/task-card-tasks-list';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';
import {
  useActiveTaskId,
  useOpenTaskDetail,
} from 'shared/contexts/task-detail';
import { useHandleGoalSectionTaskChanges } from 'shared/hooks/use-handle-goal-section-task-changes';
import { useOpenMenu } from 'shared/hooks/use-open-menu';
import { ID } from 'shared/types/id';
import { TaskCardTask } from 'shared/types/task-card-task';
import { useTheme } from 'styled-components';

import { useActionPlanItems } from '../../hooks/use-action-plan-items';
import { ActionPlanContextMenu } from '../action-plan-context-menu';
import { ActionPlanSortMenu } from '../action-plan-sort-menu';
import * as Styled from './action-plan.style';

export const ActionPlan: React.FC = () => {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const theme = useTheme();
  const { t } = useTranslation();
  const selectedTask = useActiveTaskId();

  const { id } = useParams();
  const openTaskDetail = useOpenTaskDetail();
  const actionContextButtonRef = useRef<HTMLButtonElement>(null);
  const sortButtonRef = useRef<HTMLButtonElement>(null);
  const {
    menuOpen: actionContextOpen,
    openMenu: openActionContext,
    closeMenu: closeActionContext,
  } = useOpenMenu();
  const {
    menuOpen: sortMenuOpen,
    openMenu: openSortMenu,
    closeMenu: closeSortMenu,
  } = useOpenMenu();

  const lifeAreas = useLocalizedLifeAreas();
  const openPremiumDialog = useOpenPremiumDialog();

  const [selectedItem, setSelectedItem] = useState<ID>();
  const clearSelectedItem = () => setSelectedItem(undefined);

  const goal = useGoalById(id);
  const goals = useGoals();

  const mainParentIds = useMemo(
    () => [...(goal?.parentIds ?? []), goal?.id!],
    [goal],
  );
  const { items, completedTasks, archivedSubGoals } = useActionPlanItems();
  const allTasks = useMemo(
    () => items.filter((item) => 'fields' in item) as TaskCardTask[],
    [items],
  );
  const onTask = (id?: ID) => {
    setSelectedItem(id);
    const taskCardTask = allTasks.find(
      (taskCardTask) => taskCardTask.id === id,
    );
    if (taskCardTask) {
      openTaskDetail(taskCardTask.id);
    }
  };

  const onReorder = useHandleGoalSectionTaskChanges(items);
  const { onCreateSubGoal, onUpdateGoal, onUpdateGoalProgress } =
    useGoalControls(goals, {
      onCreatePresets: { lifeAreaId: goal?.lifeAreaId },
    });

  const onUpdateSection = (sectionId: ID, sectionName: string) => {
    const goal = goals.find((goal) =>
      goal.sections?.find(({ id }) => id === sectionId),
    );
    if (!goal) {
      return;
    }

    const sections = goal.sections!.map((section) => ({
      ...section,
      name: section.id === sectionId ? sectionName : section.name,
    }));

    onUpdateGoal({ id, sections });
  };

  const _onCreateSubGoal = () => {
    if (!goal) {
      return;
    }

    onCreateSubGoal([...(goal.parentIds ?? []), goal.id]);
  };

  return (
    <Styled.Container>
      <Section
        title={t('pages.goal-detail.action-plan.title')}
        actions={[
          <IconButton
            icon={Sort}
            ref={sortButtonRef}
            onClick={openSortMenu}
            size={theme.isMobile ? Sizes.Large : Sizes.Medium}
          />,
          <IconButton
            icon={Plus}
            ref={actionContextButtonRef}
            onClick={openActionContext}
            size={theme.isMobile ? Sizes.Large : Sizes.Medium}
          />,
        ]}
      >
        <SelfSortingGoalSectionTaskList
          goalId={goal?.id ?? ''}
          allGoals={goals}
          lifeAreas={lifeAreas}
          onUpdateGoalProgress={onUpdateGoalProgress}
          onPremium={openPremiumDialog}
          onUpdateSection={onUpdateSection}
          onTask={onTask}
          onUnfocus={clearSelectedItem}
          hideGoal
          selectedItem={selectedTask ?? selectedItem}
          items={items}
          onCreateSubGoal={
            mainParentIds.length > 1 ? undefined : _onCreateSubGoal
          }
          onReorder={onReorder}
          newTaskInputRef={inputRef}
          canCreateTask
          canCollapse
        />

        {!!completedTasks.length && (
          <Styled.SectionContainer>
            <Section
              title={t('pages.goal-detail.tasks.completed.title')}
              titleCount={completedTasks.length}
              size={Sizes.Small}
              canCollapse
            >
              <SelfSortingTaskCardTasksList
                tasks={completedTasks}
                selectedTask={selectedTask}
              />
            </Section>
          </Styled.SectionContainer>
        )}

        {!!archivedSubGoals.length && (
          <Styled.SectionContainer>
            <Section
              title={t('pages.goal-detail.tasks.archived.title')}
              titleCount={archivedSubGoals.length}
              size={Sizes.Small}
              canCollapse
            >
              <SelfSortingGoalSectionTaskList
                goalId={goal?.id ?? ''}
                allGoals={goals}
                lifeAreas={lifeAreas}
                onUpdateGoalProgress={onUpdateGoalProgress}
                onPremium={openPremiumDialog}
                onUpdateSection={onUpdateSection}
                onTask={onTask}
                onUnfocus={clearSelectedItem}
                hideGoal
                selectedItem={selectedTask ?? selectedItem}
                items={archivedSubGoals}
              />
            </Section>
          </Styled.SectionContainer>
        )}
      </Section>

      {sortMenuOpen && goal && (
        <ActionPlanSortMenu
          referenceElement={sortButtonRef}
          onClose={closeSortMenu}
          goal={goal}
        />
      )}
      {actionContextOpen && (
        <ActionPlanContextMenu
          referenceElement={actionContextButtonRef}
          onClose={closeActionContext}
          newTaskRef={inputRef}
          id={id}
          parentIds={mainParentIds}
          lifeAreaId={goal?.lifeAreaId}
        />
      )}
    </Styled.Container>
  );
};
