import { rgba } from 'polished';
import { typographyToCss } from 'shared/lib/mui';
import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: ${({ theme }) => theme.spacing(2)};

  padding-bottom: ${({ theme }) => theme.spacing(1)};
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: ${({ theme }) => theme.spacing(2)};
`;

export const HeaderDate = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.overline)};

  text-align: center;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const DayContainer = styled.span`
  display: block;
`;

export const Day = styled.button<{
  $isToday: boolean;
  $isOccurrence: boolean;
  $completionPercentage: number;
  $isPerfectDay: boolean;
  $isSkipped: boolean;
  $isFailure: boolean;
  $hidden: boolean;
  $isFuture: boolean;
}>`
  ${({ theme }) => typographyToCss(theme.typography.overline)};
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 1.4rem;
  height: 1.4rem;
  margin: 0 auto;
  padding: 0;

  border-radius: ${({ theme }) => theme.spacing(1)};
  border: 2px solid ${({ theme }) => theme.palette.divider};
  background: transparent;
  overflow: hidden;

  color: ${({ theme }) => theme.palette.text.secondary};

  ${({ onClick }) =>
    !!onClick &&
    css`
      cursor: pointer;

      &:hover {
        background: ${({ theme }) => theme.palette.brown['100']};
      }
    `}

  ${({ $isOccurrence, theme }) =>
    $isOccurrence &&
    css`
      border-color: ${theme.palette.brown['200']};
    `};

  ${({ theme, $isToday }) =>
    $isToday &&
    css`
      border: 2px solid ${theme.palette.text.primary};
      color: ${theme.palette.text.primary};
    `};

  ${({ $isSkipped, onClick, theme }) =>
    $isSkipped &&
    css`
      border-color: transparent;
      color: ${theme.palette.warning.main};

      ${!!onClick &&
      css`
        &:hover {
          background-color: ${theme.palette.warning.light};
        }
      `};
    `};

  ${({ $isFailure, onClick, theme }) =>
    $isFailure &&
    css`
      border-color: transparent;
      color: ${theme.palette.error.main};

      ${!!onClick &&
      css`
        &:hover {
          background-color: ${theme.palette.error.light};
        }
      `};
    `};

  ${({ theme, $completionPercentage, $isPerfectDay }) =>
    $completionPercentage > 0 &&
    css`
      background: ${rgba(
        $isPerfectDay ? theme.palette.success.dark : theme.palette.primary.main,
        0.2,
      )};
      color: ${$isPerfectDay
        ? theme.palette.success.contrastText
        : theme.palette.primary.contrastText};
      border: none;

      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        height: ${Math.min(Math.max($completionPercentage, 0), 100)}%;
        width: 100%;

        background: ${$isPerfectDay
          ? theme.palette.success.dark
          : theme.palette.primary.main};
      }
    `};

  ${({ $isFuture }) =>
    $isFuture &&
    css`
      opacity: 0.5;

      &:hover {
        background: transparent;
      }
    `};

  ${({ $hidden }) =>
    $hidden &&
    css`
      opacity: 0;
      pointer-events: none;
    `};
`;

export const DayIcon = styled.span`
  position: relative;
  font-size: 1.6rem;
  line-height: 62.5%;
`;
