import { useCallback } from 'react';
import { useCreateRelationshipsMutation } from 'shared/hooks/queries/use-create-relationships-mutation';
import { useUpdateRelationshipsReferencesMutation } from 'shared/hooks/queries/use-update-relationships-references-mutation';
import {
  useRelationshipIdsById,
  useRelationshipsMap,
} from 'shared/hooks/use-relationships';
import { ID, IDPrefixes } from 'shared/types/id';
import { MinimalRelationship } from 'shared/types/relationship';
import { createPrefixId } from 'shared/utils/prefix-id';

export const useUpdateTaskRelationships = (
  id: ID,
  relationType: IDPrefixes,
) => {
  const relationships = useRelationshipsMap();
  const taskRelationships = useRelationshipIdsById(id, IDPrefixes.Task);
  const { mutate: updateRelations } =
    useUpdateRelationshipsReferencesMutation();
  const { mutate: createRelations } = useCreateRelationshipsMutation();

  return useCallback(
    (relationTypeIds: ID[]) => {
      // get the taskRelationships that are matched with the relationType
      const oldRelationTypeIds = taskRelationships[relationType];

      // get the differences between the parameter relationTypeIds and the taskRelationships relationType relationTypeIds
      const relationTypeIdsToAdd = relationTypeIds.filter(
        (id) => !oldRelationTypeIds.includes(id),
      );
      const relationTypeIdsToFilter = oldRelationTypeIds.filter(
        (id) => !relationTypeIds.includes(id),
      );

      // If there are no changes, return early
      if (!relationTypeIdsToAdd.length && !relationTypeIdsToFilter.length) {
        return;
      }

      // Create a prefixed ID for the task
      const taskPrefixedId = createPrefixId(id, IDPrefixes.Task);
      const relationsToUpdate: MinimalRelationship[] = [];
      const relationsToCreate: MinimalRelationship[] = [];

      // Process IDs to add
      relationTypeIdsToAdd.forEach((relationTypeId) => {
        // prefix the id with relationType
        const prefixedRelationTypeId = createPrefixId(
          relationTypeId,
          relationType,
        );

        // find the relationship references
        const references = relationships[prefixedRelationTypeId];

        // if there is no relationship document yet, we have to create one
        if (!references) {
          relationsToCreate.push({
            id: prefixedRelationTypeId,
            references: [taskPrefixedId],
          });
          return;
        }

        // If the task ID is not already in the references, add it
        if (!references.includes(taskPrefixedId)) {
          relationsToUpdate.push({
            id: prefixedRelationTypeId,
            references: [...references, taskPrefixedId],
          });
        }
      });

      // Process IDs to remove
      relationTypeIdsToFilter.forEach((relationTypId) => {
        // prefix the id with relationType
        const prefixedRelationTypeId = createPrefixId(
          relationTypId,
          relationType,
        );

        // find the relationship references
        const references = relationships[prefixedRelationTypeId];

        if (!references) {
          // not possible; no relationship means we cannot remove the task from it
          return;
        }

        // If the task ID is in the references, remove it
        if (references.includes(taskPrefixedId)) {
          relationsToUpdate.push({
            id: prefixedRelationTypeId,
            references: references.filter((ref) => ref !== taskPrefixedId),
          });
        }
      });

      // update the relations that need updating
      updateRelations(relationsToUpdate);

      // create relations that need creating
      createRelations(relationsToCreate);
    },
    [
      createRelations,
      id,
      relationType,
      relationships,
      taskRelationships,
      updateRelations,
    ],
  );
};
