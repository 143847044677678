import React, { useEffect, useMemo, useRef } from 'react';
import { Tag } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import { TagSearchMenu } from 'shared/components/ui/tag-search-menu';
import { TaskMetaInteraction } from 'shared/components/ui/task-meta-interaction';
import { useOpenMenu } from 'shared/hooks/use-open-menu';
import { InteractionType } from 'shared/types/data-type';
import { ID } from 'shared/types/id';
import { Tag as TagType } from 'shared/types/tag';

import * as Styled from './task-meta-interaction-tags.style';

export type TaskMetaInteractionTagsProps = {
  tags: TagType[];
  value?: ID[] | null;
  onChange: (ids: ID[]) => void;
  onCreate: (name: string) => void;
};

export const TaskMetaInteractionTags: React.FC<
  TaskMetaInteractionTagsProps
> = ({ tags, value, onChange, onCreate }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const { menuOpen, openMenu, closeMenu } = useOpenMenu();

  const items = useMemo(
    () => tags.filter((tag) => value?.includes(tag.id)),
    [tags, value],
  );

  const _onCreate = (name: string) => {
    onCreate(name);
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, [menuOpen]);

  return (
    <Styled.Container ref={containerRef}>
      {items.length ? (
        items.map((item) => (
          <TaskMetaInteraction
            key={item.id}
            start={
              <Styled.IconContainer $color={item.color}>
                <Icon icon={Tag} />
              </Styled.IconContainer>
            }
            label={item.name}
            onClick={openMenu}
            selected={menuOpen}
          />
        ))
      ) : (
        <TaskMetaInteraction
          start={
            <Styled.IconContainer>
              <Icon icon={Tag} />
            </Styled.IconContainer>
          }
          onClick={openMenu}
          selected={menuOpen}
        />
      )}

      {menuOpen && (
        <TagSearchMenu
          tags={tags}
          onChange={onChange}
          onCreate={_onCreate}
          value={value}
          referenceElement={containerRef}
          interactionType={InteractionType.Task}
          onOutside={closeMenu}
        />
      )}
    </Styled.Container>
  );
};
