import { Goal } from 'shared/types/goal';
import { ID } from 'shared/types/id';
import { GoalSortingMode } from 'shared/types/sorting-mode';
import { getCompletionPercentage } from 'shared/utils/get-completion-percentage';
import { getGoalCompletion } from 'shared/utils/get-goal-completion';
import { sortItems } from 'shared/utils/sort-items';

export type Options = {
  allGoals: Goal[];
  sortMode?: GoalSortingMode;
  customOrder?: ID[] | null;
  invertStartDateSorting?: boolean;
};

export const sortGoals = (
  goals: Goal[],
  { allGoals, sortMode, customOrder, invertStartDateSorting }: Options,
) => {
  if (sortMode === GoalSortingMode.Custom) {
    return sortItems(goals, customOrder ?? [], 'id');
  }

  if (sortMode === GoalSortingMode.StartDate) {
    return goals.toSorted(
      ({ startDate: startDateA }, { startDate: startDateB }) => {
        if (!startDateA) {
          return 1;
        }
        if (!startDateB) {
          return -1;
        }

        const dateA = invertStartDateSorting ? startDateB : startDateA;
        const dateB = invertStartDateSorting ? startDateA : startDateB;
        return dateB.getTime() - dateA.getTime();
      },
    );
  }

  if (sortMode === GoalSortingMode.Deadline) {
    return goals.toSorted(
      ({ deadline: deadlineA }, { deadline: deadlineB }) => {
        if (!deadlineA) {
          return 1;
        }
        if (!deadlineB) {
          return -1;
        }

        return deadlineB.getTime() - deadlineA.getTime();
      },
    );
  }

  if (sortMode === GoalSortingMode.Progress) {
    return goals.toSorted((goalA, goalB) => {
      const goalACompletion = getGoalCompletion(goalA, { goals: allGoals });
      const goalBCompletion = getGoalCompletion(goalB, { goals: allGoals });

      if (goalBCompletion.end === Infinity) {
        return -1;
      }

      if (goalACompletion.end === Infinity) {
        return 1;
      }

      const completionPercentageA = getCompletionPercentage(goalACompletion);
      const completionPercentageB = getCompletionPercentage(goalBCompletion);

      return completionPercentageB - completionPercentageA;
    });
  }

  return goals;
};
