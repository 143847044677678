import { isBefore, isSameDay } from 'date-fns';
import { ID } from 'shared/types/id';
import { Timestamp } from 'shared/types/timestamp';

export const getCustomOrderTillDate = (
  orderByDate: Record<string, ID[]>,
  date: Timestamp,
) =>
  Array.from(
    Object.entries(orderByDate)
      .filter(([key]) => {
        const keyDate = new Date(key);
        return isSameDay(keyDate, date) || isBefore(keyDate, date);
      })
      // sort the entries
      .sort(
        ([keyA], [keyB]) => new Date(keyA).getTime() - new Date(keyB).getTime(),
      )
      // combine the entries into 1 set (no duplicate entries), where the position of an id is the position of the last array it came in
      .reduce((acc, [, order]) => {
        order.forEach((id) => {
          if (acc.has(id)) {
            acc.delete(id);
          }
          acc.add(id);
        });
        return acc;
      }, new Set<ID>()),
  );
