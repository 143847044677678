import React from 'react';
import { ChevronLeft } from 'shared/assets/icons';
import { Collapse } from 'shared/components/ui/collapse';
import {
  IconButton,
  Sizes as IconButtonSizes,
} from 'shared/components/ui/icon-button';
import { useToggle } from 'shared/hooks/use-toggle';
import { ID } from 'shared/types/id';
import { TaskPriorityOptions } from 'shared/types/task-base';
import { useTheme } from 'styled-components';

import { GoalHeader } from './goal-header';
import { LifeAreaHeader } from './life-area-header';
import { PriorityHeader } from './priority-header';
import * as Styled from './section-layout.style';

export type SectionLayoutProps = {
  goalId?: ID;
  lifeAreaId?: ID;
  priority?: TaskPriorityOptions;
  onToggleCollapse?: (id: ID, newState: boolean) => void;
  initialOpen?: boolean;
  hasOwnOpenState?: boolean;
  openLifeAreaOnTasks?: boolean;
  hasLargeFont?: boolean;
  hasLargeSpacing?: boolean;
  hideImage?: boolean;
  showInboxAsInbox?: boolean;
  children: React.ReactNode;
};

export const SectionLayout: React.FC<SectionLayoutProps> = ({
  goalId,
  lifeAreaId,
  priority,
  onToggleCollapse,
  initialOpen,
  hasOwnOpenState = typeof initialOpen === 'undefined',
  openLifeAreaOnTasks,
  hasLargeFont,
  hasLargeSpacing,
  hideImage,
  showInboxAsInbox,
  children,
}) => {
  const theme = useTheme();
  const [collapseOpen, toggleCollapse] = useToggle(!!initialOpen);

  const _toggleCollapse = () => {
    toggleCollapse();
    onToggleCollapse?.((goalId ?? lifeAreaId ?? priority)!, !collapseOpen);
  };

  const _open = hasOwnOpenState ? collapseOpen : !!initialOpen;

  return (
    <>
      <Styled.HeaderContainer $hasLargeSpacing={!!hasLargeSpacing}>
        <Styled.IconButtonContainer>
          <IconButton
            icon={ChevronLeft}
            rotation={_open ? 270 : theme.isMobile ? 90 : 180}
            size={
              theme.isMobile
                ? IconButtonSizes.Large
                : IconButtonSizes.ExtraSmall
            }
            onClick={_toggleCollapse}
          />
        </Styled.IconButtonContainer>

        {!!goalId && (
          <GoalHeader goalId={goalId} showInboxAsInbox={showInboxAsInbox} />
        )}
        {!!lifeAreaId && (
          <LifeAreaHeader
            lifeAreaId={lifeAreaId}
            hideIllustration={hideImage}
            hasLargeFont={hasLargeFont}
            openOnTasks={openLifeAreaOnTasks}
          />
        )}
        {!!priority && <PriorityHeader priority={priority} />}
      </Styled.HeaderContainer>

      <Collapse isOpen={_open}>
        <Styled.ChildrenContainer>{children}</Styled.ChildrenContainer>
      </Collapse>
    </>
  );
};
