import { isSameDay } from 'date-fns';
import { useHabits, useRepeatingTasks } from 'features/tasks';
import { useMemo } from 'react';
import { useToday } from 'shared/contexts/today';
import { ScheduleEntryState } from 'shared/types/habit-schedule';

export const useTodaysCompletedHabits = () => {
  const repeatingTasks = useRepeatingTasks();
  const habits = useHabits();
  const today = useToday();

  return useMemo(
    () =>
      [...habits, ...repeatingTasks].filter(
        ({ schedules, hideOnTodo }) =>
          !hideOnTodo &&
          schedules.find(
            ({ endDate, entries, completionTarget }) =>
              !endDate &&
              entries.find(
                ({ date, state, value }) =>
                  isSameDay(date, today) &&
                  state === ScheduleEntryState.Complete &&
                  (value ?? 0) >= completionTarget.count,
              ),
          ),
      ),
    [habits, repeatingTasks, today],
  );
};
