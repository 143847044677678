import { isSameDay } from 'date-fns';
import { Task } from 'shared/types/task';
import { Timestamp } from 'shared/types/timestamp';
import { WeekDays } from 'shared/types/week-days';
import { getFrequencyTimeframeCompletions } from 'shared/utils/get-frequency-timeframe-completions';
import { getScheduleFrequencyDayTarget } from 'shared/utils/get-schedule-frequency-day-target';
import { getTimeframeSchedules } from 'shared/utils/get-timeframe-schedules';

export type Options = {
  day: Timestamp;
  weekStartsOn: WeekDays;
};

export const getTaskDayCompletionPercentage = (
  task: Task,
  { day, weekStartsOn }: Options,
) => {
  // if it is not a habit, it is either completed or not
  if (!task.schedules?.length) {
    return task.completedAt ? 100 : undefined;
  }

  // with habits or repeating-tasks, get the corresponding schedule
  const schedule = getTimeframeSchedules(task.schedules ?? [], {
    startDate: day,
    endDate: day,
  }).pop();

  if (!schedule) {
    return 0;
  }

  if (!schedule.frequency) {
    if (schedule.completionTarget.count === 1) {
      return undefined;
    }

    const entry = schedule.entries.find(({ date }) => isSameDay(date, day));

    if (!entry?.value) {
      return 0;
    }

    return (entry.value / schedule.completionTarget.count) * 100;
  }

  // if it is a schedule with a completion per day, it is basically a task
  if (getScheduleFrequencyDayTarget(schedule)) {
    return undefined;
  }

  const { completionsCount, target } = getFrequencyTimeframeCompletions(
    schedule,
    { referenceDate: day, weekStartsOn },
  );

  return (completionsCount / target) * 100;
};
