import { useMemo } from 'react';
import {
  GetTagsOptions,
  useTagsQuery,
} from 'shared/hooks/queries/use-tags-query';
import { useUser } from 'shared/hooks/use-user';

export const useTags = (options?: GetTagsOptions) => {
  const user = useUser();
  const { data } = useTagsQuery(user?.uid, options);

  return useMemo(() => data ?? [], [data]);
};
