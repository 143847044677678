import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TypeSections } from 'shared/types/page-sections';

import * as Styled from './priority-section-header.style';

export type PrioritySectionHeaderProps = {
  type?: TypeSections;
  count?: number;
};

export const TypeSectionHeader: React.FC<PrioritySectionHeaderProps> =
  React.memo(({ type = TypeSections.Tasks, count }) => {
    const { t } = useTranslation();

    return (
      <Styled.Container>
        <Styled.TitleContainer>
          <Typography variant="h6" component="span">
            {t(`general.sections.type.${type}.title`)}
          </Typography>
          {count !== undefined && (
            <Styled.TitleCount>{count}</Styled.TitleCount>
          )}
        </Styled.TitleContainer>
      </Styled.Container>
    );
  });
