import React, { useMemo } from 'react';
import { SectionLayout } from 'shared/components/connected/section-layout';
import { AllTasksLifeAreaTasksList } from 'shared/components/ui/all-tasks-life-area-tasks-list';
import { useUser } from 'shared/hooks/use-user';
import { ID } from 'shared/types/id';
import { Task } from 'shared/types/task';

import { useToggleSection } from '../../hooks/use-toggle-section';
import { Section } from '../../types';
import { LifeAreaSubSections } from './life-area-sub-sections';

export type LifeAreaSectionProps = {
  id: ID;
  items: Task[];
  goalIds?: ID[];
  sections: Section[];
};

export const LifeAreaSection: React.FC<LifeAreaSectionProps> = ({
  id,
  items,
  goalIds,
  sections,
}) => {
  const user = useUser();
  const onToggleSection = useToggleSection();

  const subSections = useMemo(
    () =>
      goalIds?.length ? sections.filter(({ id }) => goalIds.includes(id)) : [],
    [goalIds, sections],
  );

  const initialOpen = useMemo(
    () => !user?.settings?.allTasks?.collapsedLifeAreas?.includes(id),
    [id, user?.settings?.allTasks?.collapsedLifeAreas],
  );

  return (
    <SectionLayout
      lifeAreaId={id}
      openLifeAreaOnTasks
      hideImage
      hasLargeFont
      onToggleCollapse={onToggleSection}
      initialOpen={initialOpen}
    >
      <AllTasksLifeAreaTasksList id={id} tasks={items} />

      <LifeAreaSubSections subSections={subSections} />
    </SectionLayout>
  );
};
