import React from 'react';
import { GridContainer } from 'shared/components/ui/grid-container';
import { useTrackPageVisitEvent } from 'shared/hooks/use-track-page-visit-event';
import { PageEvents } from 'shared/services/tracking';

import { CompletedTasks } from './components/completed-tasks';
import { TabPanel } from './components/tab-panel';
import { TasksOverview } from './components/tasks-overview';

export const Tasks: React.FC = () => {
  useTrackPageVisitEvent(PageEvents.LifeAreaDetailTasks, undefined);

  return (
    <GridContainer>
      <TabPanel noHorizontalSpacing>
        <TasksOverview />
        <CompletedTasks />
      </TabPanel>
    </GridContainer>
  );
};
