import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ArrowRight,
  Award,
  BarChart2,
  CheckCircle,
  X,
} from 'shared/assets/icons';
import { Section } from 'shared/components/ui/section';
import { Colors, StatisticCard } from 'shared/components/ui/statistic-card';
import {
  FrequencyUnit,
  ScheduleEntry,
  ScheduleEntryState,
} from 'shared/types/habit-schedule';

import * as Styled from './statistics.style';

export type StatisticsProps = {
  target?: number;
  completionPercentage?: number;
  perfectSlots: number;
  slotsType?: FrequencyUnit;
  dateEntries: ScheduleEntry[];
};

export const Statistics: React.FC<StatisticsProps> = ({
  target,
  completionPercentage,
  perfectSlots,
  slotsType = FrequencyUnit.Week,
  dateEntries,
}) => {
  const { t } = useTranslation();

  const completionsCount = useMemo(
    () =>
      dateEntries
        .filter(({ state }) => state === ScheduleEntryState.Complete)
        .reduce((acc, { value }) => acc + (value ?? 0), 0),
    [dateEntries],
  );
  const skips = useMemo(
    () => dateEntries.filter(({ state }) => state === ScheduleEntryState.Skip),
    [dateEntries],
  );
  const failures = useMemo(
    () =>
      dateEntries.filter(({ state }) => state === ScheduleEntryState.Failure),
    [dateEntries],
  );

  return (
    <Section title={t('general.labels.task-statistics.statistics.title')}>
      <Styled.StatisticsContainer>
        <StatisticCard
          value={completionsCount}
          target={target || undefined}
          icon={CheckCircle}
          iconColor={Colors.Primary}
          description={t(
            'general.labels.task-statistics.statistics.completions.label',
          )}
          isFormatted
        />

        <StatisticCard
          value={completionPercentage}
          icon={BarChart2}
          iconColor={Colors.Info}
          description={t(
            'general.labels.task-statistics.statistics.completion-rate.label',
          )}
          isPercentage
        />

        <StatisticCard
          value={perfectSlots}
          icon={Award}
          iconColor={Colors.Success}
          description={t(
            `general.labels.task-statistics.statistics.perfect-slots.${slotsType}.label`,
          )}
          isFormatted
        />

        <StatisticCard
          value={skips.length}
          icon={ArrowRight}
          iconColor={Colors.Warning}
          description={t(
            'general.labels.task-statistics.statistics.skips.label',
          )}
          isFormatted
        />

        <StatisticCard
          value={failures.length}
          icon={X}
          iconColor={Colors.Error}
          description={t(
            'general.labels.task-statistics.statistics.failures.label',
          )}
          isFormatted
        />
      </Styled.StatisticsContainer>
    </Section>
  );
};
