import React, { useMemo } from 'react';
import { Collapse } from 'shared/components/ui/collapse';
import { GoalSectionHeader } from 'shared/components/ui/goal-section-header';
import { SortableContainer } from 'shared/components/ui/sortable-container';
import { useToggle } from 'shared/hooks/use-toggle';
import { DraggableOptions } from 'shared/types/draggable-types';
import { Goal } from 'shared/types/goal';
import { GoalSection as GoalSectionType } from 'shared/types/goal-section';
import { ID } from 'shared/types/id';
import { LifeArea } from 'shared/types/life-area';
import { TaskCardTask } from 'shared/types/task-card-task';
import { isOtherSection } from 'shared/utils/goal-section-id-helpers';

import * as Styled from './goal.style';
import { GoalSectionTaskList } from './goal-section-task-list';
import { OverwriteCollapseOptions } from './types';

export type GoalSectionProps = {
  goalId: ID;
  section: GoalSectionType;
  items: (Goal | TaskCardTask)[];
  isMoving?: boolean;

  allGoals: Goal[];
  lifeAreas: LifeArea[];
  onPremium: () => void;
  onUpdateSection: (sectionId: ID, sectionName: string) => void;
  onTask?: (id?: ID) => void;
  onUnfocus?: (id: ID) => void;
  hideGoal?: boolean;
  showRepeatingAsTodo?: boolean;
  selectedItem?: ID;
  isFrozen?: boolean;

  canCreateTask?: boolean;
  overwriteCollapse?: OverwriteCollapseOptions;
  canCollapse?: boolean;
  initialOpen?: boolean;
  hasDnd?: boolean;
};

export const GoalSection: React.FC<GoalSectionProps> = ({
  goalId,
  section,
  items,
  isMoving,

  allGoals,
  lifeAreas,
  onPremium,
  onUpdateSection,
  onTask,
  onUnfocus,
  hideGoal,
  showRepeatingAsTodo,
  selectedItem,
  isFrozen,

  canCreateTask,
  overwriteCollapse = OverwriteCollapseOptions.No,
  canCollapse,
  initialOpen,
  hasDnd,
}) => {
  const [collapseOpen, toggleCollapse] = useToggle(initialOpen);

  const uncompletedTaskCount = useMemo(
    () =>
      items.filter(
        (item) =>
          section.tasks.includes(item.id) &&
          !(item as TaskCardTask)?.raw?.task?.completedAt &&
          !(item as TaskCardTask)?.raw?.task?.archivedAt,
      ).length,
    [items, section.tasks],
  );

  const Container = hasDnd ? SortableContainer : React.Fragment;
  const containerProps = hasDnd
    ? {
        id: section.id,
        type: DraggableOptions.GoalSection,
        cantDrag: isOtherSection(section.id),
        cantInteractWithType: isOtherSection(section.id)
          ? DraggableOptions.GoalSection
          : undefined,
        hasHoverStyle:
          overwriteCollapse === OverwriteCollapseOptions.No
            ? !collapseOpen
            : overwriteCollapse !== OverwriteCollapseOptions.Open,
      }
    : {};

  const CollapseContainer = canCollapse ? Collapse : React.Fragment;
  const collapseProps = canCollapse
    ? {
        isOpen:
          overwriteCollapse === OverwriteCollapseOptions.No
            ? collapseOpen
            : overwriteCollapse === OverwriteCollapseOptions.Open,
      }
    : {};

  return (
    // @ts-ignore
    <Container {...containerProps}>
      <GoalSectionHeader
        id={section.id}
        goalId={goalId}
        name={section.name}
        taskCount={uncompletedTaskCount}
        isFrozen={isFrozen}
        onUpdateSection={onUpdateSection}
        onOpen={toggleCollapse}
        isOpen={collapseOpen}
        onPremium={onPremium}
      />
      {!isMoving && (
        <CollapseContainer {...collapseProps}>
          <Styled.SublistContainer $minimalIndentation>
            <GoalSectionTaskList
              goalId={goalId}
              sectionId={section.id}
              allGoals={allGoals}
              lifeAreas={lifeAreas}
              onPremium={onPremium}
              onUpdateSection={onUpdateSection}
              onTask={onTask}
              onUnfocus={onUnfocus}
              hideGoal={hideGoal}
              showRepeatingAsTodo={showRepeatingAsTodo}
              selectedItem={selectedItem}
              canCreateTask={canCreateTask}
              canCollapse={canCollapse}
              items={items}
              hasDnd={hasDnd}
            />
          </Styled.SublistContainer>
        </CollapseContainer>
      )}
    </Container>
  );
};
