import {
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from 'date-fns';
import { weekdaysNumberMap } from 'shared/constants';
import { isBetween } from 'shared/lib/date-fns';
import {
  FrequencyUnit,
  HabitSchedule,
  ScheduleEntryState,
} from 'shared/types/habit-schedule';
import { Timestamp } from 'shared/types/timestamp';
import { WeekDays } from 'shared/types/week-days';
import { getScheduleFrequency } from 'shared/utils/get-schedule-frequency';

export type Options = {
  referenceDate: Timestamp;
  weekStartsOn: WeekDays;
};

type getTimeframeDatesOptions = {
  referenceDate: Timestamp;
  weekStartsOn: WeekDays;
};

const getTimeframeDates = (
  frequency: FrequencyUnit,
  { referenceDate, weekStartsOn }: getTimeframeDatesOptions,
) => {
  switch (frequency) {
    case FrequencyUnit.Year:
      return {
        startDate: startOfYear(referenceDate),
        endDate: endOfYear(referenceDate),
      };
    case FrequencyUnit.Month:
      return {
        startDate: startOfMonth(referenceDate),
        endDate: endOfMonth(referenceDate),
      };
    default:
      return {
        startDate: startOfWeek(referenceDate, {
          weekStartsOn: weekdaysNumberMap[weekStartsOn],
        }),
        endDate: endOfWeek(referenceDate, {
          weekStartsOn: weekdaysNumberMap[weekStartsOn],
        }),
      };
  }
};

export const getFrequencyTimeframeCompletions = (
  schedule: HabitSchedule,
  { referenceDate, weekStartsOn }: Options,
) => {
  const frequency = getScheduleFrequency(schedule);
  const { startDate, endDate } = getTimeframeDates(frequency, {
    referenceDate,
    weekStartsOn,
  });
  const completions = schedule.entries.filter(
    ({ date, state }) =>
      state === ScheduleEntryState.Complete &&
      isBetween(date, startDate, endDate),
  );

  return {
    completionsCount: completions.reduce(
      (acc, { value }) => acc + (value ?? 0),
      0,
    ),
    target: schedule.frequency?.count ?? 1,
  };
};
