import React from 'react';
import { GoalSummary } from 'shared/components/ui/goal-summary';

import * as Styled from './list-section-goal-header.style';

export type ListSectionGoalHeaderProps = React.ComponentProps<
  typeof GoalSummary
> & {
  isFrozen?: boolean;
};

export const ListSectionGoalHeader: React.FC<ListSectionGoalHeaderProps> = ({
  isFrozen,
  ...rest
}) => (
  <Styled.Container>
    <GoalSummary {...rest} isFrozen={isFrozen} />
  </Styled.Container>
);
