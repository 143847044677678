import React from 'react';
import { useTranslation } from 'react-i18next';
import { useActivePath } from 'shared/hooks/use-active-path';
import { mainMobileNavigation } from 'shared/routes/navigation';

import * as Styled from './mobile-navigation.style';
import { NavigationItem } from './navigation-item';

export const MobileNavigation: React.FC = () => {
  const { t } = useTranslation();
  const activePath = useActivePath({ level: 2 });

  return (
    <>
      <Styled.Placeholder />
      <Styled.Container>
        <Styled.List>
          {mainMobileNavigation.map((navigation) => (
            <Styled.ListItem key={navigation.id}>
              <NavigationItem
                icon={navigation.icon}
                label={t(navigation.translationLabel)}
                to={navigation.link}
                isSelected={
                  activePath && navigation.pathsToMatch.includes(activePath)
                }
              />
            </Styled.ListItem>
          ))}
        </Styled.List>
      </Styled.Container>
    </>
  );
};
