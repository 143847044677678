import { isAfter, isSameDay } from 'date-fns';
import { ScheduleEntryState } from 'shared/types/habit-schedule';
import { Task, TaskCheckOptions } from 'shared/types/task';
import { TaskType } from 'shared/types/task-base';
import { Timestamp } from 'shared/types/timestamp';
import { WeekDays } from 'shared/types/week-days';
import { getActiveSchedule } from 'shared/utils/get-active-schedule';
import { getFrequencyTimeframeCompletions } from 'shared/utils/get-frequency-timeframe-completions';
import { getScheduleDueDate } from 'shared/utils/schedules';

type Options = {
  today: Timestamp;
  showRepeatingAsTodo?: boolean;
  showRepeatingAsRepeatingOnEntry?: boolean;
  weekStartsOn: WeekDays;
};

export const getTaskCheckOption = (
  { type, completedAt, schedules }: Task,
  {
    today,
    showRepeatingAsTodo,
    showRepeatingAsRepeatingOnEntry,
    weekStartsOn,
  }: Options,
) => {
  if (type === TaskType.Task) {
    return completedAt ? TaskCheckOptions.Checked : TaskCheckOptions.Unchecked;
  }

  const activeSchedule = getActiveSchedule(schedules);
  if (completedAt || !activeSchedule) {
    return TaskCheckOptions.RepeatChecked;
  }

  if (activeSchedule.frequency) {
    if (
      showRepeatingAsTodo &&
      activeSchedule.entries.find(
        ({ state, date, value }) =>
          isSameDay(date, today) &&
          state === ScheduleEntryState.Complete &&
          (value ?? 0) >= activeSchedule.completionTarget.count,
      )
    ) {
      return TaskCheckOptions.Checked;
    }

    if (
      showRepeatingAsRepeatingOnEntry &&
      activeSchedule.entries.find(
        ({ state, date, value }) =>
          isSameDay(date, today) &&
          state === ScheduleEntryState.Complete &&
          !!value,
      )
    ) {
      return TaskCheckOptions.RepeatChecked;
    }

    const { completionsCount, target } = getFrequencyTimeframeCompletions(
      activeSchedule,
      { referenceDate: today, weekStartsOn },
    );

    return completionsCount >= target
      ? TaskCheckOptions.RepeatChecked
      : TaskCheckOptions.Unchecked;
  }

  if (
    activeSchedule.entries.find(
      ({ state, date, value }) =>
        isSameDay(date, today) &&
        state === ScheduleEntryState.Complete &&
        (value ?? 0) >= activeSchedule.completionTarget.count,
    )
  ) {
    return showRepeatingAsTodo
      ? TaskCheckOptions.Checked
      : TaskCheckOptions.RepeatSingleChecked;
  }

  const dueDate = getScheduleDueDate(activeSchedule, { today, weekStartsOn });
  if (dueDate && !isSameDay(dueDate, today) && isAfter(dueDate, today)) {
    return TaskCheckOptions.RepeatSingleChecked;
  }

  return TaskCheckOptions.Unchecked;
};
