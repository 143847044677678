import { useCallback } from 'react';
import { useUpdateTagMutation } from 'shared/hooks/queries/use-update-tag-mutation';
import { SortingMode } from 'shared/types/sorting-mode';

import { useTagById } from './use-tag-by-id';

export const useUpdateTagSortingMode = (tagId?: string) => {
  const { mutate } = useUpdateTagMutation();
  const tag = useTagById(tagId);

  return useCallback(
    (sortingMode: SortingMode) => {
      if (tag) {
        mutate({
          id: tag.id,
          taskSortingMode: sortingMode,
        });
      }
    },
    [mutate, tag],
  );
};
