import { typographyToCss } from 'shared/lib/mui';
import { QueryNavLink } from 'shared/lib/react-router-dom';
import styled, { css } from 'styled-components';

export const Container = styled.span`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1.5)};
  width: 100%;
`;

export const ImageContainer = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 2.4rem;
  width: 2.4rem;
  margin: ${({ theme }) =>
    `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(1)} 0`};
`;

export const BlurImageContainer = styled.div`
  width: 100%;
  height: 100%;

  border-radius: ${({ theme }) => theme.spacing(2)};
  overflow: hidden;
`;

export const ImageIconContainer = styled.span`
  display: block;
  padding: ${({ theme }) => theme.spacing(1)};

  background: ${({ theme }) => theme.palette.brown['100']};
  border-radius: ${({ theme }) => theme.spacing(2)};

  font-size: 1.6rem;
  line-height: 62.5%;
  color: ${({ theme }) => theme.palette.brown['300']};
`;

export const ColorDot = styled.span<{ $color: string }>`
  position: absolute;
  bottom: ${({ theme }) => `-${theme.spacing(0.75)}`};
  right: ${({ theme }) => `-${theme.spacing(0.75)}`};

  display: block;
  height: 1rem;
  width: 1rem;

  border: ${({ theme }) =>
    `${theme.spacing(0.5)} solid ${theme.palette.background.default};`};
  border-radius: 50%;
  background: ${({ $color }) => $color};
`;

export const HeaderBody = styled.span`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(0.5)};

  min-width: 0;
`;

export const LinkIconContainer = styled.span<{ $isFaded: boolean }>`
  display: block;

  transform: rotate(180deg);
  transition: opacity 200ms;

  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 1.6rem;
  line-height: 62.5%;

  ${({ $isFaded, theme }) =>
    $isFaded &&
    css`
      margin-left: ${theme.spacing(2)};
      transform: rotate(0deg);
    `};
`;

export const TitleContainer = styled(QueryNavLink)`
  display: flex;
  align-items: center;
  min-width: 0;

  text-decoration: none;
  color: inherit;
`;

export const Title = styled.span<{ $isFrozen: boolean; $isSmall: boolean }>`
  ${({ $isSmall, theme }) =>
    typographyToCss(
      $isSmall ? theme.typography.subtitle2 : theme.typography.h6,
    )};

  overflow: hidden;

  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ $isFrozen, theme }) =>
    $isFrozen &&
    css`
      color: ${theme.palette.text.secondary};
    `};
`;

export const DeadlineContainer = styled.span`
  display: inline-flex;
  gap: ${({ theme }) => theme.spacing(1.5)};
  align-items: center;

  font-size: 1.2rem;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const Date = styled.span`
  display: inline-flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(0.5)};
  white-space: nowrap;
`;

export const Dot = styled.span`
  display: inline-block;

  width: ${({ theme }) => theme.spacing(1)};
  height: ${({ theme }) => theme.spacing(1)};

  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.grey['300']};
`;
