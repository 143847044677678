import { useTags } from 'features/tags';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavigationSectionLayout } from 'shared/components/ui/navigation-section-layout';

import { AddTagButton } from './add-tag-button';
import { TagsList } from './tags-list';

export const Tags: React.FC = () => {
  const { t } = useTranslation();
  const tags = useTags();

  return tags.length ? (
    <NavigationSectionLayout
      label={t('general.menu.navigation.tags')}
      initialOpen={true}
      actions={[<AddTagButton />]}
    >
      <TagsList />
    </NavigationSectionLayout>
  ) : null;
};
