import { useTags } from 'features/tags';
import React from 'react';
import { Check, Tag } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import {
  PopupMenu,
  PopupMenuButton,
  PopupMenuList,
  PopupMenuListItem,
} from 'shared/components/ui/popup-menu';
import { ID } from 'shared/types/id';

import * as Styled from './tags-context-menu.style';

export type SelectTagsContextMenuProps = Omit<
  React.ComponentProps<typeof PopupMenu>,
  'onOutside' | 'position' | 'children'
> & {
  onTag: (id: ID) => void;
  activeTags: ID[];
  onClose: () => void;
};

export const SelectTagsContextMenu: React.FC<SelectTagsContextMenuProps> = ({
  onClose,
  onTag,
  activeTags,
  ...popupMenuProps
}) => {
  const tags = useTags();

  return (
    <PopupMenu {...popupMenuProps} onOutside={onClose} position="bottom-end">
      <PopupMenuList>
        {tags.map(({ id, name, color }) => {
          const _onTag = () => onTag(id);

          return (
            <PopupMenuListItem key={id}>
              <PopupMenuButton
                start={
                  <Styled.IconContainer $color={color}>
                    <Icon icon={Tag} />
                  </Styled.IconContainer>
                }
                end={
                  activeTags.includes(id) ? (
                    <Styled.CheckIconContainer>
                      <Icon icon={Check} />
                    </Styled.CheckIconContainer>
                  ) : undefined
                }
                onClick={_onTag}
              >
                {name}
              </PopupMenuButton>
            </PopupMenuListItem>
          );
        })}
      </PopupMenuList>
    </PopupMenu>
  );
};
