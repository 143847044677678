import { useGoals } from 'features/goals';
import {
  useTodaysCompletedHabits,
  useTodaysCompletedTasks,
} from 'features/tasks';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Section } from 'shared/components/ui/section';
import { Sizes } from 'shared/components/ui/section/types';
import { useActiveTaskId } from 'shared/contexts/task-detail';
import { useToday } from 'shared/contexts/today';
import { useUser } from 'shared/hooks/use-user';
import { DueDisplayOptions } from 'shared/types/task-base';
import { getWeekStartsOn } from 'shared/utils/get-week-starts-on';
import { mapTaskToTaskCard } from 'shared/utils/mappers/map-task-to-task-card';
import { TaskCardTasksList } from 'src/shared/components/ui/task-card-tasks-list';

const displayDue = [DueDisplayOptions.Past, DueDisplayOptions.Future];

export const CompletedTasks: React.FC = () => {
  const { t } = useTranslation();
  const today = useToday();
  const selectedTask = useActiveTaskId();

  const user = useUser();
  const goals = useGoals();
  const completedTodos = useTodaysCompletedTasks();
  const completedHabits = useTodaysCompletedHabits();

  const completedTasks = useMemo(
    () => [...completedHabits, ...completedTodos],
    [completedHabits, completedTodos],
  );

  const orderedCompletedTasks = useMemo(
    () =>
      completedTasks.toSorted(
        (taskA, taskB) =>
          (taskB.completedAt?.getTime() ?? 0) -
          (taskA.completedAt?.getTime() ?? 0),
      ),
    [completedTasks],
  );

  const taskCardTasks = useMemo(
    () =>
      orderedCompletedTasks.map((task) =>
        mapTaskToTaskCard(task, {
          goals,
          showRepeatingAsTodo: true,
          today,
          hideTodayDue: true,
          t,
          weekStartsOn: getWeekStartsOn(user),
        }),
      ),
    [goals, orderedCompletedTasks, t, today, user],
  );

  return taskCardTasks.length ? (
    <Section
      title={t('pages.today.completed.title')}
      titleCount={taskCardTasks.length}
      size={Sizes.Small}
      canCollapse
    >
      <TaskCardTasksList
        tasks={taskCardTasks}
        displayDue={displayDue}
        selectedTask={selectedTask}
        showRepeatingAsTodo
      />
    </Section>
  ) : null;
};
