import React, { useEffect } from 'react';

export const useClickOutside = (
  ref: React.RefObject<HTMLElement>,
  onClickOutside?: (event: MouseEvent) => void,
) => {
  useEffect(() => {
    if (!onClickOutside) {
      return;
    }

    const click = (event: MouseEvent) => {
      if (!ref.current || ref.current.contains(event.target as HTMLElement)) {
        return;
      }

      onClickOutside(event);
    };

    document.addEventListener('mousedown', click);
    return () => document.removeEventListener('mousedown', click);
  }, [onClickOutside, ref]);
};
