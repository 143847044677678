import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { TagFormFields, validationSchema } from 'shared/types/tag-form';

export const useTagForm = (
  submit: (values: TagFormFields) => void,
  initialValues?: TagFormFields,
) => {
  const { handleSubmit, ...formProps } = useForm<TagFormFields>({
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
    defaultValues: initialValues,
  });

  const onSubmit = handleSubmit(({ name, color, favorite }) =>
    submit({
      name,
      color,
      favorite,
    }),
  );

  return {
    ...formProps,
    onSubmit,
    errors: formProps.formState.errors,
    isValid: formProps.formState.isValid,
  };
};
