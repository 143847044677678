import { useGoals } from 'features/goals';
import { useLocalizedLifeAreas } from 'features/life-areas';
import React from 'react';
import { useParams } from 'react-router';
import { Headers } from 'shared/components/ui/task-card-task-section';
import { TaskSection } from 'shared/components/ui/task-section';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';
import { useActiveTaskId } from 'shared/contexts/task-detail';
import { goalInboxID } from 'shared/types/orderable-section';

import { useTagGoalTaskSections } from '../../hooks/use-tag-goal-task-sections';
import * as Styled from './goal-grouping-tasks-list.style';

export const GoalGroupingTasksList: React.FC = () => {
  const openPremiumDialog = useOpenPremiumDialog();
  const { id } = useParams();

  const { sections } = useTagGoalTaskSections(id);

  const selectedTask = useActiveTaskId();

  const goals = useGoals();
  const lifeAreas = useLocalizedLifeAreas();

  return (
    <Styled.Container>
      {sections.map((section) => (
        <Styled.Section key={section.id}>
          <TaskSection
            goals={goals}
            lifeAreas={lifeAreas}
            onPremium={openPremiumDialog}
            tasks={section.items}
            tagIds={id ? [id] : undefined}
            selectedTask={selectedTask}
            headerToDisplay={Headers.Goal}
            goalId={section.id !== goalInboxID ? section.id : undefined}
            hideGoal
            canCreateTask
            canCollapse
          />
        </Styled.Section>
      ))}
    </Styled.Container>
  );
};
