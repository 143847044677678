import { isAfter, isBefore } from 'date-fns';
import { useMemo } from 'react';
import { MetricEntry } from 'shared/types/goal-metric';
import { Timestamp } from 'shared/types/timestamp';

export type Options = {
  entries: MetricEntry[];
  startDate: Timestamp;
  endDate: Timestamp;
  startValue?: number;
  target?: number;
};

export const useTimeframeDifference = ({
  entries,
  startDate,
  endDate,
  startValue = 0,
  target,
}: Options) =>
  useMemo(() => {
    if (!target) {
      return 0;
    }

    const sortedEntries = entries.toSorted(
      ({ date: dateA }, { date: dateB }) => dateA.getTime() - dateB.getTime(),
    );
    const firstEntry = sortedEntries.reduce<MetricEntry | undefined>(
      (acc, entry) => (isBefore(entry.date, startDate) ? entry : acc),
      undefined,
    );
    const lastEntry = sortedEntries.reduce<MetricEntry | undefined>(
      (acc, entry) =>
        isAfter(entry.date, startDate) && isBefore(entry.date, endDate)
          ? entry
          : acc,
      undefined,
    );

    if (!firstEntry || !lastEntry || firstEntry.value === lastEntry.value) {
      return 0;
    }

    const range = target - startValue;
    const currentPercentage = (lastEntry.value / range) * 100;

    const startPercentage = (firstEntry.value / range) * 100;

    const invert = !!startValue && startValue > target;

    return (currentPercentage - startPercentage) * (invert ? -1 : 1) || 0;
  }, [target, entries, startValue, startDate, endDate]);
