import { typographyToCss } from 'shared/lib/mui';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  min-width: 0;
`;

export const Button = styled.button<{
  $placeholder: boolean;
  $hasError: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(2)};
  ${({ theme }) => typographyToCss(theme.typography.body2)};
  margin: 0;
  padding: ${({ theme }) =>
    `${theme.spacing(1.5)} ${theme.spacing(6)} ${theme.spacing(1.5)} ${theme.spacing(2)}`};
  width: 100%;
  min-width: 0;

  border-radius: ${({ theme }) => theme.spacing(2)};
  border: 1px solid ${({ theme }) => theme.palette.divider};
  background: transparent;
  cursor: pointer;
  transition: border-color 200ms;
  color: ${({ theme }) => theme.palette.text.primary};

  &:hover {
    border-color: ${({ theme }) => theme.palette.brown['200']};
  }

  ${({ $placeholder, theme }) =>
    $placeholder &&
    css`
      color: ${theme.palette.text.secondary};
    `};

  ${({ theme, $hasError }) =>
    $hasError &&
    css`
      border-color: ${theme.palette.error.main};
    `};

  ${({ theme }) =>
    theme.isMobile &&
    css`
      padding: ${({ theme }) =>
        `${theme.spacing(2.5)} ${theme.spacing(6)} ${theme.spacing(2.5)} ${theme.spacing(3)}`};
    `};
`;

export const Label = styled.span`
  overflow: hidden;

  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ChevronContainer = styled.span`
  position: absolute;
  top: 50%;
  right: ${({ theme }) => theme.spacing(2)};

  transform: translateY(-50%) rotate(270deg);
  pointer-events: none;

  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 1.6rem;
  line-height: 62.5%;
`;

export const FlexRowContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};
`;
