import { useGoals } from 'features/goals';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useToday } from 'shared/contexts/today';
import { useGoalSections } from 'shared/hooks/use-goal-sections';
import { useUser } from 'shared/hooks/use-user';
import { goalInboxID } from 'shared/types/orderable-section';
import { SortingMode } from 'shared/types/sorting-mode';
import { TaskCardTask } from 'shared/types/task-card-task';
import { getSortedTasks } from 'shared/utils/get-sorted-tasks';
import { getWeekStartsOn } from 'shared/utils/get-week-starts-on';
import { mapTaskToTaskCard } from 'shared/utils/mappers/map-task-to-task-card';

import { useTodayTasks } from './use-today-tasks';

export const useGoalTaskSections = () => {
  const { t } = useTranslation();
  const today = useToday();
  const user = useUser();

  const tasks = useTodayTasks();
  const goals = useGoals();

  const goalSections = useGoalSections();

  const sections = useMemo(
    () =>
      goalSections
        .map(({ id }) => ({
          id,
          items: getSortedTasks(
            tasks.filter(({ goalId }) =>
              id === goalInboxID ? !goalId : goalId === id,
            ),
            {
              today,
              weekStartsOn: getWeekStartsOn(user),
              mode: Array.from(
                new Set([SortingMode.DueDate, user?.settings?.todoSortingMode]),
              ).filter(Boolean) as SortingMode[],
              order:
                user?.settings?.todayGroupSorting?.goal?.sorting?.[id] ?? [],
            },
          ).map((task) =>
            mapTaskToTaskCard(task, {
              today,
              goals,
              t,
              showDue: true,
              hideTodayDue: true,
              weekStartsOn: getWeekStartsOn(user),
            }),
          ),
        }))
        .filter(({ id, items }) => id === goalInboxID || !!items.length),
    [goalSections, goals, t, tasks, today, user],
  );

  const taskCardTasks = useMemo(
    () =>
      sections.reduce<TaskCardTask[]>((acc, { items }) => {
        acc.push(...items);
        return acc;
      }, []),
    [sections],
  );

  return {
    sections,
    taskCardTasks,
  };
};
