import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronLeft, Lock } from 'shared/assets/icons';
import { BlurHashImage } from 'shared/components/connected/blur-hash-image';
import { Icon } from 'shared/components/ui/icon';
import { Paths } from 'shared/routes';
import { ID } from 'shared/types/id';
import { Image } from 'shared/types/image';
import { LocalizedString } from 'shared/types/localized-string';
import { lifeAreaOtherID } from 'shared/types/orderable-section';
import { getLocalizedStringValue } from 'shared/utils/get-localized-string-value';
import { replaceUrlParams } from 'shared/utils/replace-url-params';

import * as Styled from './section-header-life-area.style';

export type ListSectionLifeAreaHeaderProps = {
  id?: ID;
  name: LocalizedString | string;
  image?: Image | null;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  color?: string;
  isFrozen?: boolean;
  hasLargeFont?: boolean;
  openOnTasks?: boolean;
};

export const ListSectionLifeAreaHeader: React.FC<
  ListSectionLifeAreaHeaderProps
> = ({ id, name, image, icon, color, isFrozen, hasLargeFont, openOnTasks }) => {
  const { i18n } = useTranslation();
  const isLink = !isFrozen && id && id !== lifeAreaOtherID;

  return (
    <Styled.Container>
      {(!!image || !!icon) && (
        <Styled.ImageContainer $isFaded={!!isFrozen}>
          {image ? (
            <Styled.BlurImageContainer>
              <BlurHashImage hash={image.blurHash} src={image.url} />
            </Styled.BlurImageContainer>
          ) : (
            <Styled.ImageIconContainer>
              <Icon icon={icon!} />
            </Styled.ImageIconContainer>
          )}
          {!!color && <Styled.ColorDot $color={color} />}
        </Styled.ImageContainer>
      )}

      <Styled.HeaderBody>
        <Styled.TitleContainer
          as={isLink ? undefined : 'span'}
          to={
            isLink
              ? replaceUrlParams(
                  openOnTasks
                    ? Paths.LifeAreaDetailTasks
                    : Paths.LifeAreaDetail,
                  { id },
                )
              : ''
          }
        >
          <Styled.Title $isFaded={!!isFrozen} $hasLargeFont={!!hasLargeFont}>
            {getLocalizedStringValue(name, i18n.language)}
          </Styled.Title>
          {isLink && (
            <Styled.LinkIconContainer $isFaded={!!isFrozen}>
              <Icon icon={isFrozen ? Lock : ChevronLeft} />
            </Styled.LinkIconContainer>
          )}
        </Styled.TitleContainer>
      </Styled.HeaderBody>
    </Styled.Container>
  );
};
