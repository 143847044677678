import { ID } from './id';

export enum PriceInterval {
  Year = 'year',
  Month = 'month',
}

export type Price = {
  id: ID;
  currency: string;
  interval: PriceInterval;
  amountInCents: number;
  saving: number;
  savingPercentage: number;
};

export type Discount = {
  id: ID;
  applicableIntervals: PriceInterval[];
  percentage?: number;
  amount?: number;
};

export const currencySignMap: Record<string, string> = {
  aud: '$',
  brl: 'R$',
  cad: '$',
  chf: 'CHF',
  dkk: 'kr',
  eur: '€',
  gbp: '£',
  inr: '₹',
  jpy: '¥',
  nok: 'kr',
  nzd: '$',
  pln: 'zł',
  sek: 'kr',
  usd: '$',
};
