import React, { useRef, useState } from 'react';
import { LifeAreaInsightsCard as LifeAreaInsightsCardUi } from 'shared/components/ui/life-area-insights-card';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';
import { useContextMenuState } from 'shared/hooks/use-context-menu-state';
import { useUser } from 'shared/hooks/use-user';
import { getWeekStartsOn } from 'shared/utils/get-week-starts-on';

import { ContextMenu } from './context-menu';

export type LifeAreaInsightsCardProps = Omit<
  React.ComponentProps<typeof LifeAreaInsightsCardUi>,
  'onMenuButton' | 'menuButtonRef' | 'weekStartsOn' | 'onPremium'
> & {};

export const LifeAreaInsightsCard: React.FC<LifeAreaInsightsCardProps> = ({
  lifeArea,
  ...rest
}) => {
  const contextRef = useRef<HTMLDivElement>(null);
  const menuButtonRef = React.useRef<HTMLButtonElement>(null);
  const user = useUser();
  const weekStartsOn = getWeekStartsOn(user);

  const openPremiumDialog = useOpenPremiumDialog();

  const [menuButtonUsed, setMenuButtonUsed] = useState(false);
  const {
    open: openMenu,
    close: closeMenu,
    position: menuPosition,
  } = useContextMenuState({
    onClose: () => setMenuButtonUsed(false),
  });

  const openMenuByButton = (e: React.MouseEvent) => {
    setMenuButtonUsed(true);
    openMenu(e);
  };

  return (
    <>
      <LifeAreaInsightsCardUi
        lifeArea={lifeArea}
        onMenuButton={openMenuByButton}
        menuButtonRef={menuButtonRef}
        weekStartsOn={weekStartsOn}
        onContextMenu={openMenu}
        onPremium={openPremiumDialog}
        {...rest}
      />
      {!!menuPosition && (
        <ContextMenu
          ref={contextRef}
          lifeArea={lifeArea}
          onClose={closeMenu}
          referenceElement={menuButtonUsed ? menuButtonRef : undefined}
          location={menuButtonUsed ? undefined : menuPosition}
        />
      )}
    </>
  );
};
