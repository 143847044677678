import styled, { css } from 'styled-components';

export const VirtualListContainer = styled.div``;

export const List = styled.ul<{ $isVirtualList?: boolean }>`
  list-style: none;

  margin: 0;
  padding: 0;

  ${({ $isVirtualList }) =>
    $isVirtualList &&
    css`
      position: relative;
      width: 100%;
      overflow: hidden;
    `};
`;

export const ListItem = styled.li<{ $isVirtualListItem?: boolean }>`
  padding: ${({ theme }) => `0 ${theme.spacing(4)}`};

  ${({ theme }) =>
    theme.isMobile &&
    css`
      padding: 0;
    `};

  ${({ $isVirtualListItem }) =>
    $isVirtualListItem &&
    css`
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    `}
`;
