import { useGoals, useTransferSectionToGoal } from 'features/goals';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconFormIcons, Search, Target } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import {
  PopupMenu,
  PopupMenuButton,
  PopupMenuInput,
  PopupMenuList,
  PopupMenuListGroup,
  PopupMenuListItem,
} from 'shared/components/ui/popup-menu';
import { Goal } from 'shared/types/goal';
import { ID } from 'shared/types/id';
import { getAutoCompleteSuggestions } from 'shared/utils/autocomplete-value-matches-input';
import { groupItems } from 'shared/utils/group-items';

import * as Styled from './move-section-context-menu.style';

export type MoveSectionContextMenuProps = Omit<
  React.ComponentProps<typeof PopupMenu>,
  'children' | 'ref' | 'onOutside'
> & {
  sectionId: ID;
  goal: Goal;
  onClose: () => void;
};

export const MoveSectionContextMenu: React.FC<MoveSectionContextMenuProps> = ({
  sectionId,
  goal,
  onClose,
  ...rest
}) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [containerHeight, setContainerHeight] = useState<number>();

  const [searchValue, setSearchValue] = useState('');

  const transferSection = useTransferSectionToGoal();

  const goals = useGoals();
  const goalSuggestions = useMemo(
    () =>
      groupItems(
        getAutoCompleteSuggestions(
          searchValue,
          goals.filter(({ id }) => id !== goal.id),
          'name',
        ),
      ),
    [goal.id, goals, searchValue],
  );

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearchValue(e.target.value);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  useEffect(() => {
    setContainerHeight(containerRef.current?.clientHeight);
  }, []);

  return (
    <Styled.PopupMenu
      ref={containerRef}
      $height={containerHeight}
      onOutside={onClose}
      {...rest}
    >
      <PopupMenuList hasBottomBorder>
        <PopupMenuListItem>
          <PopupMenuInput
            ref={inputRef}
            startIcon={Search}
            value={searchValue}
            placeholder={t('forms.goal.search.placeholder')}
            onChange={onChangeInput}
          />
        </PopupMenuListItem>
      </PopupMenuList>

      <PopupMenuListGroup isScrollable>
        {!!goalSuggestions.length && (
          <PopupMenuList>
            {goalSuggestions.map((goalSuggestion) => {
              const onSelectItem = () => {
                transferSection({ sectionId, from: goal, to: goalSuggestion });
                onClose();
              };

              return (
                <PopupMenuListItem key={goalSuggestion.id}>
                  <PopupMenuButton
                    start={
                      <Icon
                        icon={
                          goalSuggestion.iconName
                            ? IconFormIcons[goalSuggestion.iconName]
                            : Target
                        }
                      />
                    }
                    onClick={onSelectItem}
                    tabIndex={0}
                    hasIndentation={
                      !!goalSuggestion.parentIds?.length &&
                      !!goalSuggestions.find(
                        ({ id }) =>
                          goalSuggestion.parentIds![
                            goalSuggestion.parentIds!.length - 1
                          ] === id,
                      )
                    }
                    overflowEllipsis
                  >
                    {goalSuggestion.name}
                  </PopupMenuButton>
                </PopupMenuListItem>
              );
            })}
          </PopupMenuList>
        )}
      </PopupMenuListGroup>
    </Styled.PopupMenu>
  );
};
