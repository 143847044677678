import { useGoals } from 'features/goals';
import React, { useMemo, useRef } from 'react';
import { PopupMenu } from 'shared/components/ui/popup-menu';
import { useOpenMenu } from 'shared/hooks/use-open-menu';
import { ID } from 'shared/types/id';

import { EditList } from './edit-list';
import { ManageList } from './manage-list';
import { MoveList } from './move-list';
import { MoveSectionContextMenu } from './move-section-context-menu';

export type GoalSectionContextMenuProps = Omit<
  React.ComponentProps<typeof PopupMenu>,
  'children' | 'ref'
> & {
  id: ID;
  goalId: ID;
  inputRef: React.RefObject<HTMLInputElement>;
  onClose: () => void;
};

export const GoalSectionContextMenu: React.FC<GoalSectionContextMenuProps> = ({
  id,
  goalId,
  inputRef,
  onClose,

  position,
  location,
  referenceElement,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const { menuOpen: goalSelectOpen, openMenu: openGoalSelect } = useOpenMenu();

  const goals = useGoals();
  const goal = useMemo(
    () => goals.find((goal) => goal.id === goalId),
    [goalId, goals],
  );

  const _onClose = () => {
    if (!goalSelectOpen) {
      onClose();
    }
  };

  return goal && !goal.frozenAt ? (
    !goalSelectOpen ? (
      <PopupMenu
        ref={containerRef}
        location={location}
        position={position}
        referenceElement={referenceElement}
        onOutside={_onClose}
      >
        <EditList
          goal={goal}
          sectionId={id}
          inputRef={inputRef}
          openGoalSelect={openGoalSelect}
          onClose={onClose}
        />
        <MoveList goal={goal} sectionId={id} onClose={onClose} />
        <ManageList goal={goal} sectionId={id} onClose={onClose} />
      </PopupMenu>
    ) : (
      <MoveSectionContextMenu
        sectionId={id}
        goal={goal}
        location={location}
        position={position}
        referenceElement={referenceElement}
        onClose={onClose}
      />
    )
  ) : null;
};
