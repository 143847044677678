import { format, isAfter, isSameDay } from 'date-fns';
import React, { useMemo } from 'react';
import {
  ArrowRightCalendar,
  CheckCalendar,
  XCalendar,
} from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import { useToday } from 'shared/contexts/today';
import {
  ScheduleEntryState,
  ScheduleTargetEntry,
} from 'shared/types/habit-schedule';
import { Timestamp } from 'shared/types/timestamp';
import { formatNumberShort } from 'shared/utils/format-number-short';

import * as Styled from './habit-week.style';

export type HabitWeekDayProps = {
  date: Timestamp;
  occurrences: Timestamp[];
  dateEntries: ScheduleTargetEntry[];
  onDate: (
    date: Timestamp,
    type: ScheduleEntryState,
    options?: { value?: number; increment?: number },
  ) => void;
  isPerfectDay?: boolean;
};

export const HabitWeekDay: React.FC<HabitWeekDayProps> = ({
  date,
  occurrences,
  dateEntries,
  onDate,
  isPerfectDay,
}) => {
  const today = useToday();

  const isOccurrence = useMemo(
    () => !!occurrences.find((occurrence) => isSameDay(occurrence, date)),
    [date, occurrences],
  );
  const dateEntry = useMemo(
    () => dateEntries.find((entry) => isSameDay(entry.date, date)),
    [date, dateEntries],
  );

  const completionPercentage = dateEntry?.value
    ? (dateEntry.value / (dateEntry.target ?? 1)) * 100
    : 0;
  const isFailure = dateEntry?.state === ScheduleEntryState.Failure;
  const isSkipped = dateEntry?.state === ScheduleEntryState.Skip;
  const isFuture = isAfter(date, today);

  const _onDate = () => onDate(date, ScheduleEntryState.Complete);

  const displayDate = !dateEntry?.value;
  const displayCheck =
    !displayDate &&
    (dateEntry.target ?? 0) > 1 &&
    dateEntry.value! >= (dateEntry.target ?? 0);

  return (
    <Styled.DayButton
      onClick={_onDate}
      data-value={date.getTime()}
      $isOccurrence={isOccurrence}
      $completionPercentage={completionPercentage}
      $isPerfect={!!isPerfectDay}
      $isSkipped={isSkipped}
      $isFailure={isFailure}
      $isFuture={isFuture}
      $isToday={isSameDay(date, today)}
    >
      {!completionPercentage && (isFailure || isSkipped) ? (
        <Styled.DayIcon>
          <Icon icon={isSkipped ? ArrowRightCalendar : XCalendar} />
        </Styled.DayIcon>
      ) : (
        <Styled.DayLabel $isIcon={displayCheck}>
          {displayDate ? (
            format(date, 'EEEEE')
          ) : displayCheck ? (
            <Icon icon={CheckCalendar} />
          ) : (
            formatNumberShort(dateEntry.value!)
          )}
        </Styled.DayLabel>
      )}
    </Styled.DayButton>
  );
};
