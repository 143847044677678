import { endOfMonth, startOfMonth } from 'date-fns';
import { useTimeframeTime } from 'features/user';
import React, { useMemo } from 'react';
import { HabitInsightsCard } from 'shared/components/connected/habit-insights-card';
import { useToday } from 'shared/contexts/today';
import { useUser } from 'shared/hooks/use-user';
import { BarChartEntry } from 'shared/lib/recharts';
import { Frequency, RRule } from 'shared/lib/rrule';
import { Habit } from 'shared/types/habit';
import { ScheduleEntryState } from 'shared/types/habit-schedule';
import { InsightsTimeframes } from 'shared/types/insights';
import { workWeek } from 'shared/types/week-days';
import { getRruleWeekDays } from 'shared/utils/get-rrule-week-days';
import { getWeekStartsOn } from 'shared/utils/get-week-starts-on';

const target = 10;
const currentValue = 7;

export type HabitInsightsPreviewCardProps = {
  habit: Habit;
};

export const HabitInsightsPreviewCard: React.FC<
  HabitInsightsPreviewCardProps
> = ({ habit }) => {
  const user = useUser();
  const today = useToday();
  const { timeframe } = useTimeframeTime();

  const entries = useMemo<BarChartEntry[]>(
    () => [
      {
        target: 10,
        value: 5,
        isPerfect: false,
      },
      {
        target: 10,
        value: 3,
        isPerfect: false,
      },
      {
        target: 10,
        value: 10,
        isPerfect: true,
      },
      {
        target: 10,
        value: currentValue,
        isPerfect: false,
      },
    ],
    [],
  );

  const occurrences = useMemo(() => {
    const rrule = new RRule({
      freq: Frequency.WEEKLY,
      byweekday: getRruleWeekDays(workWeek),
      dtstart: startOfMonth(today),
      until: endOfMonth(today),
    });
    return rrule.all();
  }, [today]);
  const dateEntries = useMemo(() => {
    const randomOccurrences = occurrences.sort(() => Math.random() - 0.5);
    const amountOfSkips = Math.floor(randomOccurrences.length * 0.1); // 10% of occurrences

    return [
      ...randomOccurrences.slice(0, amountOfSkips).map((date) => ({
        id: window.crypto.randomUUID(),
        date,
        state: ScheduleEntryState.Skip,
        value: null,
        target: 0,
        onDateManual: false,
        showValueAsBadge: false,
      })),
      ...randomOccurrences
        .slice(
          amountOfSkips,
          // 60% of occurrences
          randomOccurrences.length * 0.7,
        )
        .map((date) => ({
          id: window.crypto.randomUUID(),
          date,
          state: ScheduleEntryState.Complete,
          value: 1,
          target: 1,
          onDateManual: false,
          showValueAsBadge: false,
        })),
    ];
  }, [occurrences]);

  return (
    <HabitInsightsCard
      habit={habit}
      entries={entries}
      currentValue={currentValue}
      target={target}
      weekStartsOn={getWeekStartsOn(user)}
      calendarOpensToDate={today}
      dateEntries={dateEntries}
      calendarOccurrences={occurrences}
      displayMonth={timeframe === InsightsTimeframes.Month}
      isPreview
    />
  );
};
