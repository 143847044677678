import { useDeferredValue, useMemo } from 'react';
import { useTasksQuery } from 'shared/hooks/queries/use-tasks-query';
import { useRelationshipIdsById } from 'shared/hooks/use-relationships';
import { useUser } from 'shared/hooks/use-user';
import { ID, IDPrefixes } from 'shared/types/id';

export const useTagTasks = (id: ID | undefined) => {
  const user = useUser();
  const { task: taskIds } = useRelationshipIdsById(id, IDPrefixes.Tag);

  const deferredTaskIds = useDeferredValue(taskIds);

  const { data: tasks } = useTasksQuery(
    user?.uid,
    {
      ids: deferredTaskIds,
      uncompletedOnly: true,
    },
    !!deferredTaskIds.length,
  );

  return useMemo(
    () => tasks?.filter(({ archivedAt }) => !archivedAt) ?? [],
    [tasks],
  );
};
