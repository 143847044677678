import { useUpdateGoalGroupBy, useUpdateGoalSortMode } from 'features/user';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Check } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import {
  PopupMenu,
  PopupMenuButton,
  PopupMenuList,
  PopupMenuListItem,
  PopupMenuTitle,
} from 'shared/components/ui/popup-menu';
import { useUser } from 'shared/hooks/use-user';
import { GoalGroupBy, GoalSortingMode } from 'shared/types/sorting-mode';

import { groupByOptions, sortOptions } from './header-interaction-options';

export type ActiveMenuProps = {
  referenceElement: React.RefObject<HTMLElement>;
  onClose: () => void;
};

export const ActiveMenu: React.FC<ActiveMenuProps> = ({
  referenceElement,
  onClose,
}) => {
  const { t } = useTranslation();

  const user = useUser();
  const setSortMode = useUpdateGoalSortMode();
  const setGroupBy = useUpdateGoalGroupBy();

  const _onGroupBy = (value: GoalGroupBy) => {
    setGroupBy(value);
    onClose();
  };

  const _onSort = (value: GoalSortingMode) => {
    setSortMode(value);
    onClose();
  };

  const sort = user?.settings?.goalSortingMode;
  const groupBy = user?.settings?.goalGroupBy;

  return (
    <PopupMenu
      referenceElement={referenceElement}
      position="bottom-end"
      onOutside={onClose}
    >
      <PopupMenuList hasBottomBorder>
        <PopupMenuTitle title={t('pages.goals.group-by.title')} />

        {groupByOptions.map(({ id, label, value }) => (
          <PopupMenuListItem key={id}>
            <PopupMenuButton
              start={groupBy === id ? <Icon icon={Check} /> : undefined}
              onClick={() => _onGroupBy(value)}
              hasIndentation={groupBy !== id}
            >
              {t(label)}
            </PopupMenuButton>
          </PopupMenuListItem>
        ))}
      </PopupMenuList>
      <PopupMenuList>
        <PopupMenuTitle title={t('general.labels.goals.sort.title')} />

        {sortOptions.map(({ id, label, value }) => (
          <PopupMenuListItem key={id}>
            <PopupMenuButton
              start={sort === id ? <Icon icon={Check} /> : undefined}
              onClick={() => _onSort(value)}
              hasIndentation={sort !== id}
            >
              {t(label)}
            </PopupMenuButton>
          </PopupMenuListItem>
        ))}
      </PopupMenuList>
    </PopupMenu>
  );
};
