import React from 'react';
import { ChevronLeft } from 'shared/assets/icons';
import { Collapse } from 'shared/components/ui/collapse';
import { IconButton, Sizes } from 'shared/components/ui/icon-button';
import { sideNavigationHoverClass } from 'shared/constants';
import { useToggle } from 'shared/hooks/use-toggle';

import * as Styled from './navigation-section-layout.style';

export type NavigationSectionLayoutProps = {
  label: string;
  children: React.ReactNode;
  isFirst?: boolean;
  initialOpen?: boolean;
  actions?: React.ReactNode[];
};

export const NavigationSectionLayout: React.FC<
  NavigationSectionLayoutProps
> = ({ label, children, isFirst, initialOpen, actions = [] }) => {
  const [open, toggleOpen] = useToggle(initialOpen);

  return (
    <Styled.NavigationSectionContainer>
      <Styled.NavigationSectionHeader $isFirst={!!isFirst}>
        <Styled.NavigationSectionTitle>{label}</Styled.NavigationSectionTitle>

        <Styled.NavigationSectionInteractions>
          {actions.map((action, index) => (
            <React.Fragment key={`action-${index}`}>{action}</React.Fragment>
          ))}
          <IconButton
            size={Sizes.Small}
            icon={ChevronLeft}
            rotation={open ? -90 : 0}
            className={sideNavigationHoverClass}
            onClick={toggleOpen}
          />
        </Styled.NavigationSectionInteractions>
      </Styled.NavigationSectionHeader>

      <Collapse isOpen={open}>{children}</Collapse>
    </Styled.NavigationSectionContainer>
  );
};
