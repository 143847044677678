import { useQuery } from '@tanstack/react-query';
import { createSubscriptionQuery } from 'shared/lib/@tanstack-query';
import { getRelationships } from 'shared/services/backend-api';
import { ID } from 'shared/types/id';

export const queryKey = 'relationships';

export const useRelationshipsQuery = (uid: ID | undefined, enabled = true) =>
  useQuery({
    queryKey: [queryKey, uid ?? ''],
    queryFn: createSubscriptionQuery([() => getRelationships()]),
    enabled: !!uid && enabled,
  });
