import { useUpdateHideHabitsAllTasks } from 'features/user';
import { useCallback } from 'react';
import { useUser } from 'shared/hooks/use-user';

export const useToggleHideHabits = () => {
  const user = useUser();
  const updateHideHabits = useUpdateHideHabitsAllTasks();

  return useCallback(() => {
    updateHideHabits(!user?.settings?.allTasksHideHabits);
  }, [updateHideHabits, user?.settings?.allTasksHideHabits]);
};
