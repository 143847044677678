import { useArchiveLifeArea } from 'features/life-areas';
import { useToggleHiddenInsightsItem } from 'features/user';
import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Archive, Edit3, EyeHidden, Trash2 } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import {
  PopupMenu,
  PopupMenuButton,
  PopupMenuList,
  PopupMenuListItem,
} from 'shared/components/ui/popup-menu';
import { useOpenDeleteLifeAreaDialog } from 'shared/contexts/delete-life-area';
import { useOpenEditLifeAreaDialog } from 'shared/contexts/life-area-form';
import { useUser } from 'shared/hooks/use-user';
import { InsightSections } from 'shared/types/insights';
import { LifeArea } from 'shared/types/life-area';

export type ContextMenuProps = Omit<
  React.ComponentProps<typeof PopupMenu>,
  'children' | 'ref' | 'onOutside'
> & {
  lifeArea: LifeArea;
  onClose: () => void;
};

export const ContextMenu = forwardRef<HTMLDivElement, ContextMenuProps>(
  ({ lifeArea, onClose, referenceElement, location }, ref) => {
    const { t } = useTranslation();
    const user = useUser();
    const openEditDialog = useOpenEditLifeAreaDialog();
    const openDeleteDialog = useOpenDeleteLifeAreaDialog();
    const archiveLifeArea = useArchiveLifeArea();
    const toggleInsightsLifeArea = useToggleHiddenInsightsItem(
      InsightSections.Lifescan,
    );
    const isHidden = !!user?.settings?.insights?.sections?.[
      InsightSections.Lifescan
    ]?.hiddenItems?.includes(lifeArea.id);

    const onHide = () => {
      toggleInsightsLifeArea(lifeArea.id);
      onClose();
    };

    const onEdit = () => {
      openEditDialog(lifeArea, lifeArea.id);
      onClose();
    };

    const onArchive = () => {
      archiveLifeArea(lifeArea);
      onClose();
    };

    const onDelete = () => {
      openDeleteDialog(lifeArea);
      onClose();
    };

    return (
      <PopupMenu
        ref={ref}
        location={location}
        referenceElement={referenceElement}
        onOutside={onClose}
        position="bottom-start"
      >
        <PopupMenuList hasBottomBorder>
          <PopupMenuListItem>
            <PopupMenuButton onClick={onHide} start={<Icon icon={EyeHidden} />}>
              {t(
                isHidden
                  ? 'context-menus.insights.show.label'
                  : 'context-menus.insights.hide.label',
              )}
            </PopupMenuButton>
          </PopupMenuListItem>
        </PopupMenuList>
        <PopupMenuList hasBottomBorder>
          <PopupMenuListItem>
            <PopupMenuButton onClick={onEdit} start={<Icon icon={Edit3} />}>
              {t('context-menus.life-area.labels.edit')}
            </PopupMenuButton>
          </PopupMenuListItem>
        </PopupMenuList>
        <PopupMenuList>
          <PopupMenuListItem>
            <PopupMenuButton
              onClick={onArchive}
              start={<Icon icon={Archive} />}
            >
              {t(
                lifeArea.archivedAt
                  ? 'context-menus.life-area.labels.de-archive'
                  : 'context-menus.life-area.labels.archive',
              )}
            </PopupMenuButton>
          </PopupMenuListItem>
          <PopupMenuListItem>
            <PopupMenuButton onClick={onDelete} start={<Icon icon={Trash2} />}>
              {t('context-menus.life-area.labels.delete')}
            </PopupMenuButton>
          </PopupMenuListItem>
        </PopupMenuList>
      </PopupMenu>
    );
  },
);
