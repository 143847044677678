import { DateSectionOptions } from 'shared/types/orderable-section';
import { Headers } from 'src/shared/components/ui/task-card-task-section';

export const sectionTypeToHeaderMap: Record<
  DateSectionOptions,
  Headers | undefined
> = {
  [DateSectionOptions.Day]: Headers.Date,
  [DateSectionOptions.RestOfMonth]: undefined,
  [DateSectionOptions.Month]: Headers.Month,
  [DateSectionOptions.Year]: Headers.Year,
};
