import { useGoals } from 'features/goals';
import React, { useMemo, useRef } from 'react';
import { PopupMenu } from 'shared/components/ui/popup-menu';
import { useOpenMenu } from 'shared/hooks/use-open-menu';
import { ID } from 'shared/types/id';

import { CompleteList } from './complete-list';
import { EditList } from './edit-list';
import { ManageList } from './manage-list';
import { MoveGoalContextMenu } from './move-goal-context-menu';
import { MoveGoalList } from './move-goal-list';

export type GoalContextMenuProps = Omit<
  React.ComponentProps<typeof PopupMenu>,
  'children' | 'ref'
> & {
  id: ID;
  onClose: () => void;
};

export const GoalContextMenu: React.FC<GoalContextMenuProps> = ({
  id,
  onClose,
  position,
  location,
  referenceElement,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const { menuOpen: goalSelectOpen, openMenu: openGoalSelect } = useOpenMenu();

  const goals = useGoals();
  const goal = useMemo(() => goals.find((goal) => goal.id === id), [goals, id]);
  const onOutside = () => {
    if (!goalSelectOpen) {
      onClose();
    }
  };

  return goal ? (
    <>
      {!goalSelectOpen ? (
        <PopupMenu
          ref={containerRef}
          location={location}
          position={position}
          referenceElement={referenceElement}
          onOutside={onOutside}
        >
          <EditList goal={goal} onClose={onClose} />
          <CompleteList goal={goal} onClose={onClose} />
          <MoveGoalList
            goal={goal}
            onClose={onClose}
            openGoalSelect={openGoalSelect}
          />
          <ManageList goal={goal} onClose={onClose} />
        </PopupMenu>
      ) : (
        <MoveGoalContextMenu
          id={id}
          location={location}
          position={position}
          referenceElement={referenceElement}
          onClose={onClose}
        />
      )}
    </>
  ) : null;
};
