import {
  differenceInMilliseconds,
  isAfter,
  isBefore,
  isSameDay,
} from 'date-fns';
import { Timestamp } from 'shared/types/timestamp';

type EntryBase = {
  date: Timestamp;
  value: number;
};

const interpolateValue = <Entry extends EntryBase>(
  dateToInterpolate: Timestamp,
  beforeEntry: Entry,
  afterEntry: Entry,
) => {
  // Calculate the total days between the two entries
  const totalDays = differenceInMilliseconds(afterEntry.date, beforeEntry.date);

  // Calculate the days between the 'beforeEntry' and the target date
  const daysToInterpolate = differenceInMilliseconds(
    dateToInterpolate,
    beforeEntry.date,
  );

  // Calculate the interpolation ratio
  const ratio = daysToInterpolate / totalDays;

  // Interpolated value
  return beforeEntry.value + ratio * (afterEntry.value - beforeEntry.value);
};

const findSurroundingEntries = <Entry extends EntryBase>(
  date: Timestamp,
  entries: Entry[],
) => {
  if (!entries?.length) {
    return { beforeEntry: undefined, afterEntry: undefined };
  }

  // Make sure the entries are sorted by date
  const parsedEntries = entries.toSorted(
    (a, b) => a.date.getTime() - b.date.getTime(),
  );

  let beforeEntry = null;
  let afterEntry = null;

  for (const entry of parsedEntries) {
    if (isBefore(entry.date, date)) {
      beforeEntry = entry; // Update the last 'before' entry
    } else if (isAfter(entry.date, date) || isSameDay(entry.date, date)) {
      afterEntry = entry; // Take the first 'after' entry
      break;
    }
  }

  return { beforeEntry, afterEntry };
};

export const interpolateDataEntries = <Entry extends EntryBase>(
  data: Entry[],
  startDate: Timestamp | undefined | null,
  endDate: Timestamp | undefined | null,
) => {
  // get the entries surrounding the startDate
  const { beforeEntry: beforeStartEntry, afterEntry: afterStartEntry } =
    startDate
      ? findSurroundingEntries(startDate, data)
      : { beforeEntry: undefined, afterEntry: undefined };
  // if there is a before and after entry, create a new entry with interpolated value for the startDate
  const interpolatedStartValue =
    beforeStartEntry && afterStartEntry
      ? {
          date: startDate,
          value: interpolateValue(
            startDate!,
            beforeStartEntry,
            afterStartEntry,
          ),
        }
      : undefined;

  // get the entries surrounding the endDate
  const { beforeEntry: beforeEndEntry, afterEntry: afterEndEntry } = endDate
    ? findSurroundingEntries(endDate, data)
    : { beforeEntry: undefined, afterEntry: undefined };
  // if there is a before and after entry, create a new entry with interpolated value for the endDate
  const interpolatedEndValue =
    beforeEndEntry && afterEndEntry
      ? {
          date: endDate,
          value: interpolateValue(endDate!, beforeEndEntry, afterEndEntry),
        }
      : undefined;

  // filter out the entries from data that are before the startDate and after the endDate
  const filteredData = data.filter(
    ({ date }) =>
      (!startDate || isAfter(date, startDate) || isSameDay(date, startDate)) &&
      (!endDate || isBefore(date, endDate) || isSameDay(date, endDate)),
  );

  // add the interpolatedStartValue and interpolatedEndValue, if they exist
  return [interpolatedStartValue, ...filteredData, interpolatedEndValue].filter(
    Boolean,
  ) as Entry[];
};
