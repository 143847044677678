import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Sizes as ButtonSizes,
  Variants,
} from 'shared/components/ui/button';
import { ColorPickerFormSelect } from 'shared/components/ui/color-picker';
import { FormDialogHeader } from 'shared/components/ui/form-dialog-header';
import { FormInput } from 'shared/components/ui/form-input';
import { Toggle } from 'shared/components/ui/toggle';
import { defaultTagColorOption } from 'shared/constants';
import { useForwardedRef } from 'shared/hooks/use-forwarded-ref';
import { TagFormFields } from 'shared/types/tag-form';

import * as Styled from './tag-form.style';
import { useTagForm } from './use-tag-form';

const defaultInitialValues: TagFormFields = {
  color: defaultTagColorOption.value,
  favorite: false,
};

export type TagFormProps = {
  initialValues?: TagFormFields;
  onSubmit: (tag: TagFormFields) => void;
  onCancel: () => void;
  isEdit?: boolean;
};

export const TagForm: React.FC<TagFormProps> = ({
  initialValues = {},
  onSubmit,
  onCancel,
  isEdit,
}) => {
  const { t } = useTranslation();
  const {
    register,
    onSubmit: submitForm,
    errors,
    isValid,
    setValue,
    watch,
  } = useTagForm(onSubmit, { ...defaultInitialValues, ...initialValues });
  const { ref: nameRefCallback, ...nameProps } = register('name');
  const nameRef = useForwardedRef(nameRefCallback);

  const favorite = watch<'favorite'>('favorite');
  const onChangeFavorite = () => setValue<'favorite'>('favorite', !favorite);

  const color = watch<'color'>('color');
  const onChangeColor = (color?: string) =>
    setValue<'color'>('color', color ?? defaultInitialValues.color);

  useEffect(() => {
    if (nameRef.current && !isEdit) {
      nameRef.current.focus();
    }
  }, [isEdit, nameRef]);

  return (
    <Styled.Form noValidate onSubmit={submitForm}>
      <FormDialogHeader
        onCancel={onCancel}
        title={t(isEdit ? 'forms.tag.edit.title' : 'forms.tag.create.title')}
        canSubmit={isValid}
      />

      <Styled.Body>
        <Styled.Section>
          <Styled.Label>{t('forms.tag.fields.name.label')}</Styled.Label>
          <FormInput
            {...nameProps}
            ref={nameRef}
            placeholder={t('forms.tag.fields.name.placeholder')}
            error={errors.name?.message}
            autoComplete="off"
          />

          <Styled.Label>{t('forms.tag.fields.color.label')}</Styled.Label>
          <ColorPickerFormSelect onChange={onChangeColor} color={color} />

          <Styled.Label>{t('forms.tag.fields.favorite.label')}</Styled.Label>
          <Toggle
            value="favorite"
            checked={!!favorite}
            onChange={onChangeFavorite}
          />
        </Styled.Section>
      </Styled.Body>

      <Styled.Footer>
        <Button
          variant={Variants.Outlined}
          size={ButtonSizes.Medium}
          onClick={onCancel}
          type="button"
        >
          {t('forms.tag.buttons.cancel.label')}
        </Button>
        <Button size={ButtonSizes.Medium} type="submit" disabled={!isValid}>
          {t('forms.tag.buttons.save.label')}
        </Button>
      </Styled.Footer>
    </Styled.Form>
  );
};
