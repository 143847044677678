import { createContext } from 'react';
import { Timestamp } from 'shared/types/timestamp';

export type TimeframeContextProps = {
  onPrev: () => void;
  onNext: () => void;
  onReset: () => void;
  weekDate: Timestamp;
};

export const TimeframeContext = createContext<TimeframeContextProps>({
  onPrev: () => {},
  onNext: () => {},
  onReset: () => {},
  weekDate: new Date(),
});
