import { differenceInDays, isBefore, isSameDay } from 'date-fns';
import {
  FrequencyUnit,
  HabitSchedule,
  ScheduleEntryState,
} from 'shared/types/habit-schedule';
import { Timestamp } from 'shared/types/timestamp';
import { WeekDays } from 'shared/types/week-days';
import { getLastWeekDaysInTimeframe } from 'shared/utils/get-last-week-days-in-timeframe';

export type Options = {
  startDate: Timestamp;
  endDate: Timestamp;
  weekStartsOn: WeekDays;
  calculateFullMonthWeeks: boolean;
};

export const getScheduleTimeframeTarget = (
  schedule: HabitSchedule,
  { startDate, endDate, weekStartsOn, calculateFullMonthWeeks }: Options,
) => {
  // get the start point for the schedule
  const firstCompletion = schedule.entries
    .filter(({ state }) => state === ScheduleEntryState.Complete)
    .toSorted(
      ({ date: dateA }, { date: dateB }) => dateA!.getTime() - dateB!.getTime(),
    )[0]?.date as Date | undefined;
  const scheduleStartPoint = new Date(
    Math.min(
      ...([firstCompletion?.getTime(), schedule.startDate.getTime()].filter(
        Boolean,
      ) as number[]),
    ),
  );
  // check to see if the schedule is within range
  if (
    isBefore(endDate, scheduleStartPoint) ||
    isSameDay(endDate, scheduleStartPoint)
  ) {
    return 0;
  }

  const dayDiff = differenceInDays(endDate, startDate);

  if (dayDiff < 6) {
    return 0;
  }

  if (dayDiff <= 7) {
    return schedule.frequency?.unit === FrequencyUnit.Week
      ? schedule.frequency.count
      : 0;
  }

  if (calculateFullMonthWeeks && dayDiff <= 42) {
    const amountOfLastWeekDays = getLastWeekDaysInTimeframe(
      startDate,
      endDate,
      weekStartsOn,
    );
    return schedule.frequency?.unit === FrequencyUnit.Week
      ? schedule.frequency.count * amountOfLastWeekDays.length
      : 0;
  }

  if (dayDiff <= 31) {
    return schedule.frequency?.unit === FrequencyUnit.Month
      ? schedule.frequency.count
      : 0;
  }

  return schedule.frequency?.unit === FrequencyUnit.Year
    ? schedule.frequency.count
    : 0;
};
