import { useGoals } from 'features/goals';
import { useLocalizedLifeAreas } from 'features/life-areas';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconFormIcons, Target } from 'shared/assets/icons';
import { ListSectionGoalHeader } from 'shared/components/ui/list-section-goal-header';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';
import { ID } from 'shared/types/id';
import { goalInboxID } from 'shared/types/orderable-section';
import { getGoalColor } from 'shared/utils/get-goal-color';

export type GoalHeaderProps = {
  goalId: ID;
};

export const GoalHeader: React.FC<GoalHeaderProps> = ({ goalId }) => {
  const { t } = useTranslation();
  const onPremium = useOpenPremiumDialog();
  const goals = useGoals();
  const lifeAreas = useLocalizedLifeAreas();

  const goal = useMemo(
    () => goals.find(({ id }) => id === goalId),
    [goals, goalId],
  );

  const goalColor = useMemo(
    () =>
      goal?.id
        ? getGoalColor(goal.id, { lifeAreas, goals }) ?? undefined
        : undefined,
    [goal?.id, goals, lifeAreas],
  );

  const goalName =
    goalId === goalInboxID
      ? t('general.sections.goals.other.title')
      : goal?.name ?? '';

  return (
    <ListSectionGoalHeader
      id={goalId}
      name={goalName}
      image={goal?.image}
      imagePlaceholderIcon={
        !goalId || goalId === goalInboxID
          ? Target
          : goal?.iconName
            ? IconFormIcons[goal.iconName]
            : undefined
      }
      color={goalColor}
      onPremium={onPremium}
      isFrozen={!!goal?.frozenAt}
      isLink={goalId !== goalInboxID}
    />
  );
};
