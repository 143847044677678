import Typography from '@mui/material/Typography';
import { addDays, format, isSameDay } from 'date-fns';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useToday } from 'shared/contexts/today';
import { Timestamp } from 'shared/types/timestamp';

import * as Styled from './list-section-date-header.style';

export type ListSectionDateHeaderProps = {
  date: Timestamp;
};

export const ListSectionDateHeader: React.FC<ListSectionDateHeaderProps> =
  React.memo(({ date }) => {
    const { t } = useTranslation();
    const today = useToday();

    const label = useMemo(() => {
      if (isSameDay(date, today)) {
        return t('general.labels.dates.today');
      }
      if (isSameDay(date, addDays(today, 1))) {
        return t('general.labels.dates.tomorrow');
      }

      return format(date, 'EEE');
    }, [date, t, today]);

    return (
      <Styled.Container>
        <Typography variant="h6" component="span">
          {label}
        </Typography>

        <Typography
          variant="h6"
          component="span"
          color={(theme) => theme.palette.text.secondary}
        >
          {format(date, 'd MMM')}
        </Typography>
      </Styled.Container>
    );
  });
