import { useCallback, useRef } from 'react';
import { useDeleteLifeAreaMutation } from 'shared/hooks/queries/use-delete-life-area-mutation';
import { useDeleteFavorites } from 'shared/hooks/use-delete-favorites';
import { useTrackEvents } from 'shared/hooks/use-track-events';
import {
  ActionEvents,
  lifeAreaToTrackingLifeAreaType,
} from 'shared/services/tracking';
import { LifeArea } from 'shared/types/life-area';

export const useDeleteLifeArea = () => {
  const deleteLifeAreaRef = useRef<LifeArea>();

  const track = useTrackEvents();
  const { mutate, isSuccess, reset, error, isPending } =
    useDeleteLifeAreaMutation();
  const removeFavorite = useDeleteFavorites();

  const submit = useCallback(
    (lifeArea: LifeArea) => {
      deleteLifeAreaRef.current = lifeArea;
      track(ActionEvents.LifeAreaDelete, {
        life_area: lifeAreaToTrackingLifeAreaType(lifeArea)!,
      });

      mutate(lifeArea.id);
      removeFavorite([lifeArea.id]);
    },
    [mutate, removeFavorite, track],
  );

  const retry = useCallback(() => {
    const lifeArea = deleteLifeAreaRef.current;
    if (lifeArea) {
      mutate(lifeArea.id);
      removeFavorite([lifeArea.id]);
    }
  }, [mutate, removeFavorite]);

  const resetAll = useCallback(() => {
    reset();
    deleteLifeAreaRef.current = undefined;
  }, [reset]);

  return {
    submit,
    retry,
    reset: resetAll,
    error,
    isLoading: isPending,
    isSuccess,
  };
};
