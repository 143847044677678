import { isSameDay } from 'date-fns';
import { useGoals } from 'features/goals';
import {
  useCreateSubTask,
  useSubTasks,
  useUpdateSubtaskOrder,
  useUpdateTask,
} from 'features/tasks';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useToday } from 'shared/contexts/today';
import { useUser } from 'shared/hooks/use-user';
import { ScheduleEntryState } from 'shared/types/habit-schedule';
import { ID } from 'shared/types/id';
import { Task } from 'shared/types/task';
import { TaskType } from 'shared/types/task-base';
import { Timestamp } from 'shared/types/timestamp';
import { getActiveSchedule } from 'shared/utils/get-active-schedule';
import { getWeekStartsOn } from 'shared/utils/get-week-starts-on';
import { mapTaskToTaskCard } from 'shared/utils/mappers/map-task-to-task-card';
import { orderTaskCardTasksByDate } from 'shared/utils/order-task-card-tasks-by-date';

import { ReorderableSubTaskList } from './reorderable-sub-task-list';

export type SubTasksListProps = {
  task: Task;
};

export const SubTasksList: React.FC<SubTasksListProps> = ({ task }) => {
  const { t } = useTranslation();

  const parentIds = useMemo(
    () => (task ? [...(task.parentIds ?? []), task.id] : undefined),
    [task],
  );

  const subTasks = useSubTasks(task);
  const { updateOrder } = useUpdateSubtaskOrder(task);
  const { submit: createSubTask } = useCreateSubTask({
    parentIds,
    parentChildIds: task.childIds,
  });
  const { submit: updateTask } = useUpdateTask();

  const today = useToday();
  const user = useUser();
  const goals = useGoals();

  const onReorder = (taskIds: ID[]) => !task.frozenAt && updateOrder(taskIds);

  const onCreateSubTask = (name: string) =>
    !task.frozenAt && createSubTask(name);

  const onUpdateTask = (changes: {
    id: ID;
    name?: string;
    completedAt?: Timestamp | null;
  }) => !task.frozenAt && updateTask(changes);

  const isCompletedHabit = useMemo(() => {
    if (![TaskType.Habit, TaskType.Repeating].includes(task.type)) {
      return false;
    }

    const activeSchedule = getActiveSchedule(task.schedules);
    if (!activeSchedule) {
      return false;
    }

    return !!activeSchedule.entries.find(
      (entry) =>
        entry.state === ScheduleEntryState.Complete &&
        !!entry.value &&
        isSameDay(entry.date, today),
    );
  }, [task.schedules, task.type, today]);

  const subTaskTaskCardTasks = useMemo(() => {
    const mappedSubTasks = subTasks.map((subTask) =>
      mapTaskToTaskCard(
        {
          ...subTask,
          // if the main task is a completed habit, all sub-tasks should be displayed as completed as well,
          // whether they are completed or not
          completedAt: isCompletedHabit ? new Date() : subTask.completedAt,
        },
        {
          goals,
          today,
          weekStartsOn: getWeekStartsOn(user),
          t,
        },
      ),
    );

    return orderTaskCardTasksByDate(mappedSubTasks, {
      null: task?.subtaskOrder ?? [],
    });
  }, [goals, isCompletedHabit, subTasks, t, task?.subtaskOrder, today, user]);

  return (
    <ReorderableSubTaskList
      tasks={subTaskTaskCardTasks}
      onReorder={onReorder}
      createSubTask={onCreateSubTask}
      updateSubTask={onUpdateTask}
      isDisabled={!!task.frozenAt}
    />
  );
};
