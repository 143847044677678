import { useMemo } from 'react';
import { useTasksQuery } from 'shared/hooks/queries/use-tasks-query';
import { useUser } from 'shared/hooks/use-user';
import { GetTaskOptions } from 'shared/services/backend-api';
import { Habit } from 'shared/types/habit';
import { TaskType } from 'shared/types/task-base';

export type Options = Omit<GetTaskOptions, 'type'>;

export const useHabits = (options: Options = {}) => {
  const user = useUser();
  const { data } = useTasksQuery(user?.uid, {
    ...options,
    type: TaskType.Habit,
  });

  return useMemo(
    () =>
      (data?.filter(({ schedules }) => !!schedules?.length) ?? []) as Habit[],
    [data],
  );
};
