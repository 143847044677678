import { endOfDay } from 'date-fns';
import { MobileAddButton } from 'shared/components/ui/mobile-add-button';
import { useOpenCreateTask } from 'shared/contexts/task-detail';
import { useToday } from 'shared/contexts/today';
import { DataType } from 'shared/types/data-type';

import * as Styled from './mobile-add-task-button.style';

export const MobileAddTaskButton = () => {
  const today = useToday();

  const openCreateTaskColumn = useOpenCreateTask();
  const onCreate = () =>
    openCreateTaskColumn({ endStrategy: { deadline: endOfDay(today) } });

  return (
    <Styled.Container>
      <MobileAddButton onClick={onCreate} data-component-type={DataType.Task} />
    </Styled.Container>
  );
};
