import { Timestamp as FirebaseTimestamp } from 'firebase/firestore';

import { ID } from './id';
import { Timestamp } from './timestamp';

export enum RruleReferenceDate {
  StartDate = 'startDate',
  LastCompletion = 'lastCompletion',
}

export enum FrequencyUnit {
  Week = 'week',
  Month = 'month',
  Year = 'year',
}
export enum CompletionTargetUnit {
  Day = 'day',
}

export enum ScheduleEntryState {
  Complete = 'complete',
  Skip = 'skip',
  Failure = 'fail',
}

export enum ScheduleCompletionActions {
  LogCompletionValue = 'logCompletionValue',
}

export type ScheduleEntry = {
  id: ID;
  date: Timestamp;
  state: ScheduleEntryState;
  value?: number | null;
};

export type ScheduleTargetEntry = ScheduleEntry & {
  target: number | null;
  onDateManual: boolean;
  showValueAsBadge: boolean;
};

export type ScheduleCompletionTarget = {
  unit: CompletionTargetUnit;
  count: number;
};

export type ScheduleAction = {
  type: ScheduleCompletionActions;
};

type ScheduleBase = {
  startDate: Timestamp;
  rrule: {
    format: string;
    referenceDate: RruleReferenceDate;
  };
  completionTarget: ScheduleCompletionTarget;
  action?: ScheduleAction | null;
  entries: ScheduleEntry[];
  endDate?: Timestamp | null;
  // backwards compatibility
  completions?: Timestamp[] | null;
  skips?: Timestamp[] | null;
};

export type ScheduleFrequency = {
  count: number;
  unit: FrequencyUnit;
  label?: string | null;
};

export type HabitScheduleTimesPer = ScheduleBase & {
  frequency: ScheduleFrequency;
};

export type HabitScheduleSpecificDays = ScheduleBase & {
  frequency: null;
};

export type HabitSchedule = HabitScheduleTimesPer | HabitScheduleSpecificDays;

export type FirebaseReturnSchedule = {
  startDate: FirebaseTimestamp | null;
  endDate: FirebaseTimestamp | null;
  completions: FirebaseTimestamp[] | null;
  skips: FirebaseTimestamp[] | null;
  entries?: ScheduleEntry[];
  completionTarget?: ScheduleCompletionTarget | null;
  action?: ScheduleAction | null;
};
