import { isSameDay } from 'date-fns';
import { useMemo } from 'react';
import { useToday } from 'shared/contexts/today';
import { TaskType } from 'shared/types/task-base';
import { initialWeekStartsOn } from 'shared/types/week-days';
import { getActiveSchedule } from 'shared/utils/get-active-schedule';
import { getTaskDueDate } from 'shared/utils/get-task-due';

import { useHabits } from './use-habits';

export const useTodaysHabits = (weekStartsOn = initialWeekStartsOn) => {
  const today = useToday();
  const habits = useHabits({
    uncompletedOnly: true,
    unArchivedOnly: true,
    unFrozenOnly: true,
  });

  return useMemo(
    () =>
      habits.filter((habit) => {
        if (habit.type === TaskType.Habit && habit.hideOnTodo) {
          return false;
        }

        const dueDate = getTaskDueDate(habit, { today, weekStartsOn });

        if (!dueDate) {
          return false;
        }

        const activeSchedule = getActiveSchedule(habit.schedules);
        if (!activeSchedule) {
          return false;
        }

        return isSameDay(dueDate, today);
      }),
    [habits, today, weekStartsOn],
  );
};
