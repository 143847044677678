import React from 'react';
import { GridContainer } from 'shared/components/ui/grid-container';
import { useTrackPageVisitEvent } from 'shared/hooks/use-track-page-visit-event';
import { useUser } from 'shared/hooks/use-user';
import { PageEvents } from 'shared/services/tracking';
import { TodayGroupBy } from 'shared/types/sorting-mode';

import { ActiveFilterBanner } from './components/active-filter-banner';
import { CompletedTasks } from './components/completed-tasks';
import { GoalGroupingTasksList } from './components/goal-grouping-tasks-list';
import { Header } from './components/header';
import { LifeAreaGroupingTasksList } from './components/life-area-grouping-tasks-list';
import { MobileAddTaskButton } from './components/mobile-add-task-button';
import { NoGroupingTasksList } from './components/no-grouping-tasks-list';
import { PriorityGroupingTasksList } from './components/priority-grouping-tasks-list';
import { TypeGroupingTasksList } from './components/type-grouping-tasks-list';

const groupByLists: Record<TodayGroupBy, React.FC> = {
  [TodayGroupBy.None]: NoGroupingTasksList,
  [TodayGroupBy.Goal]: GoalGroupingTasksList,
  [TodayGroupBy.LifeArea]: LifeAreaGroupingTasksList,
  [TodayGroupBy.Priority]: PriorityGroupingTasksList,
  [TodayGroupBy.Type]: TypeGroupingTasksList,
};

export const Today: React.FC = () => {
  useTrackPageVisitEvent(PageEvents.Today, undefined);
  const user = useUser();

  const TaskListComponent =
    groupByLists[user?.settings?.todayGroupBy ?? TodayGroupBy.None];

  return (
    <GridContainer>
      <Header />

      <ActiveFilterBanner />

      <TaskListComponent />

      <CompletedTasks />

      <MobileAddTaskButton />
    </GridContainer>
  );
};
