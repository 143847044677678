import React from 'react';
import { Menu } from 'shared/assets/icons';
import { Favorites } from 'shared/components/connected/favorites';
import { Tags } from 'shared/components/connected/tags';
import { IconButton, Sizes } from 'shared/components/ui/icon-button';
import { sideNavigationHoverClass } from 'shared/constants';
import { useElectron } from 'shared/hooks/use-is-mac';
import { Paths } from 'shared/routes';
import { mainNavigation } from 'shared/routes/navigation';

import { NavigationSection } from './navigation-section';
import { ResizableContainer } from './resizable-container';
import { ShareFeedbackButton } from './share-feedback-button';
import * as Styled from './side-navigation.style';
import { UserButton } from './user-button';

export type SideNavigationProps = {
  open: boolean;
  mobileOpen: boolean;
  toggleOpen: () => void;
  toggleMobileOpen: () => void;
  onNavigationItem: () => void;
  activePath?: Paths;
};

export const SideNavigation: React.FC<SideNavigationProps> = ({
  open,
  mobileOpen,
  toggleOpen,
  toggleMobileOpen,
  onNavigationItem,
  activePath,
}) => {
  const { isMac } = useElectron();

  return (
    <>
      <Styled.SmallScreenBackdrop
        onClick={toggleMobileOpen}
        $open={mobileOpen}
      />

      <ResizableContainer open={open} smallScreenOpen={mobileOpen}>
        <Styled.ContentContainer>
          {isMac && (
            <Styled.AppNavBar>
              <IconButton
                className={sideNavigationHoverClass}
                icon={Menu}
                onClick={toggleOpen}
                size={Sizes.Large}
                preventDrag
              />
            </Styled.AppNavBar>
          )}
          <Styled.Header>
            <UserButton
              onCloseSideNavigation={isMac ? undefined : toggleOpen}
            />
          </Styled.Header>

          <Styled.List>
            {mainNavigation.map((navigationItem, index) => (
              <Styled.ListItem key={navigationItem.id}>
                <NavigationSection
                  translationLabel={navigationItem.translationLabel}
                  subItems={navigationItem.subItems}
                  initialOpen
                  onNavigationItem={onNavigationItem}
                  activePath={activePath}
                  isFirst={index === 0}
                />
              </Styled.ListItem>
            ))}

            <Favorites />

            <Tags />
          </Styled.List>

          <ShareFeedbackButton />
        </Styled.ContentContainer>
      </ResizableContainer>
    </>
  );
};
