import { typographyToCss } from 'shared/lib/mui';
import { QueryNavLink } from 'shared/lib/react-router-dom';
import styled, { css } from 'styled-components';

export const Content = styled.div<{
  $active: boolean;
  $isMoving: boolean;
}>`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};

  padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(3)}`};

  border-radius: ${({ theme }) => theme.spacing(2)};
  transition:
    background 200ms,
    color 200ms;

  ${({ theme, $active }) =>
    $active
      ? css`
          background: ${theme.palette.primaryExtended
            .opacityBackgroundBackground};
          color: ${theme.palette.primaryExtended.opacityBackgroundText};
        `
      : css`
          &:hover {
            background: ${theme.palette.brown['50']};
          }
        `};

  ${({ theme, $isMoving }) =>
    $isMoving &&
    css`
      background: ${theme.palette.background.default};

      &:hover {
        background: ${theme.palette.background.default};
      }
    `};
`;

export const Link = styled(QueryNavLink)`
  ${({ theme }) => typographyToCss(theme.typography.overline)};
  text-decoration: none;
  color: inherit;
`;

export const IconContainer = styled.button<{ $color: string }>`
  font-size: 1.6rem;
  line-height: 62.5%;

  margin: 0;
  padding: 0;

  border: none;
  background: transparent;

  cursor: pointer;

  color: ${({ $color }) => ($color ? $color : 'inherit')};
`;

export const Title = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.body2)}
  display: block;
  flex: 1;

  overflow: hidden;

  text-overflow: ellipsis;
  white-space: nowrap;
`;
