import {
  useTagById,
  useUpdateTagGroupBy,
  useUpdateTagSortingMode,
} from 'features/tags';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Check } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import {
  PopupMenu,
  PopupMenuButton,
  PopupMenuList,
  PopupMenuListItem,
  PopupMenuTitle,
} from 'shared/components/ui/popup-menu';
import { SortingMode, TagGroupBy } from 'shared/types/sorting-mode';

import { groupByOptions, sortOptions } from './header-interaction-options';

export type MainContextMenuProps = {
  referenceElement?: React.RefObject<HTMLElement>;
  onClose: () => void;
  tagId?: string;
};

export const MainContextMenu: React.FC<MainContextMenuProps> = ({
  referenceElement,
  onClose,
  tagId,
}) => {
  const { t } = useTranslation();
  const tag = useTagById(tagId);

  const setGroupBy = useUpdateTagGroupBy(tagId);
  const setSortMode = useUpdateTagSortingMode(tagId);

  const _onGroupBy = (value: TagGroupBy) => {
    setGroupBy(value);
    onClose();
  };

  const _onSort = (value: SortingMode) => {
    setSortMode(value);
    onClose();
  };

  const groupBy = tag?.taskGroupBy ?? TagGroupBy.None;
  const sort = tag?.taskSortingMode ?? SortingMode.Priority;

  return (
    <PopupMenu
      referenceElement={referenceElement}
      position="bottom-end"
      onOutside={onClose}
    >
      <PopupMenuList hasBottomBorder>
        <PopupMenuTitle title={t('general.labels.tasks.group-by.title')} />

        {groupByOptions.map(({ id, label, value }) => (
          <PopupMenuListItem key={id}>
            <PopupMenuButton
              start={groupBy === id ? <Icon icon={Check} /> : undefined}
              onClick={() => _onGroupBy(value)}
              hasIndentation={groupBy !== id}
            >
              {t(label)}
            </PopupMenuButton>
          </PopupMenuListItem>
        ))}
      </PopupMenuList>

      <PopupMenuList>
        <PopupMenuTitle title={t('general.labels.tasks.sort.title')} />

        {sortOptions.map(({ id, label, value }) => (
          <PopupMenuListItem key={id}>
            <PopupMenuButton
              start={sort === id ? <Icon icon={Check} /> : undefined}
              onClick={() => _onSort(value)}
              hasIndentation={sort !== id}
            >
              {t(label)}
            </PopupMenuButton>
          </PopupMenuListItem>
        ))}
      </PopupMenuList>
    </PopupMenu>
  );
};
