import { useTimeframeTime } from 'features/user';
import React from 'react';
import { GoalInsightsCard } from 'shared/components/connected/goal-insights-card';
import { Goal } from 'shared/types/goal';

export type LoadingInsightsCardProps = {
  goal: Goal;
};

export const LoadingInsightsCard: React.FC<LoadingInsightsCardProps> = ({
  goal,
}) => {
  const { startDate, endDate } = useTimeframeTime({
    weekEndsWithStartOfDay: true,
  });

  return (
    <GoalInsightsCard
      goal={goal}
      entries={[]}
      startDate={startDate}
      endDate={endDate}
      isLoading
    />
  );
};
