import { typographyToCss } from 'shared/lib/mui';
import styled, { css } from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  max-height: 100%;
`;

export const Body = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(8)};

  overflow: auto;

  ${({ theme }) =>
    theme.isMobile &&
    css`
      padding-bottom: ${theme.spacing(3)};
    `};
`;

export const Section = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: ${({ theme }) => theme.spacing(2)};
  grid-row-gap: ${({ theme }) => theme.spacing(2)};
  align-items: center;

  padding: ${({ theme }) => `0 ${theme.spacing(6)}`};
  width: 100%;
`;

export const Label = styled.label<{ $alignTop?: boolean }>`
  ${({ theme }) => typographyToCss(theme.typography.body2)};
  min-width: 9.6rem;
  padding-top: ${({ theme }) => theme.spacing(1.5)};

  ${({ $alignTop }) =>
    $alignTop &&
    css`
      align-self: flex-start;
    `};
`;

export const Footer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
  justify-content: flex-end;

  padding: ${({ theme }) => `${theme.spacing(5)} ${theme.spacing(6)}`};

  ${({ theme }) =>
    theme.isMobile &&
    css`
      display: none;
    `};
`;
