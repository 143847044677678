import { getUser, getUserRef } from './get-user';
import { updateDocWithTimestamp } from './helpers';

export const updateUserTodayHideHabits = async (hideHabits: boolean) => {
  const user = await getUser();

  if (!user) {
    return;
  }

  const userRef = await getUserRef();

  if (!userRef) {
    return;
  }

  updateDocWithTimestamp(userRef, {
    'settings.todayHideHabits': hideHabits,
  });
};
