import { addDays, endOfDay } from 'date-fns';
import { useMemo } from 'react';
import { useToday } from 'shared/contexts/today';
import { useTasksQuery } from 'shared/hooks/queries/use-tasks-query';
import { useUser } from 'shared/hooks/use-user';

export const useUpcomingTenDaysTasks = () => {
  const today = useToday();
  const endDate = endOfDay(addDays(today, 9));
  const user = useUser();

  const { data } = useTasksQuery(user?.uid, {
    deadlineStartDate: addDays(today, 1),
    deadlineEndDate: endDate,
    unFrozenOnly: true,
    uncompletedOnly: true,
  });

  return useMemo(
    () => data?.filter(({ archivedAt }) => !archivedAt) ?? [],
    [data],
  );
};
