import { addWeeks } from 'date-fns';
import { useMemo } from 'react';
import { isBetween } from 'shared/lib/date-fns';
import { HabitSchedule, ScheduleEntryState } from 'shared/types/habit-schedule';
import { Timestamp } from 'shared/types/timestamp';

export type Options = {
  startDate: Timestamp;
  endDate: Timestamp;
};

export const useTimeframeCompletions = (
  schedules: HabitSchedule[],
  { startDate, endDate }: Options,
) => {
  const completions = useMemo(() => {
    // get a bigger range for extra completions to display in the month-view
    const rangeStartDate = addWeeks(startDate, -5);
    const rangeEndDate = addWeeks(endDate, 5);

    // filter all entries that fall within the range and have the complete state with a value
    return schedules
      .map(({ entries }) =>
        entries.filter(
          (entry) =>
            isBetween(entry.date, rangeStartDate, rangeEndDate) &&
            entry.state === ScheduleEntryState.Complete &&
            !!entry.value,
        ),
      )
      .flat(1);
  }, [endDate, schedules, startDate]);

  const timeframeCompletions = useMemo(
    () =>
      completions.filter((entry) => isBetween(entry.date, startDate, endDate)),
    [completions, endDate, startDate],
  );

  return {
    completions,
    timeframeCompletions,
  };
};
