import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;

  margin: ${({ theme }) => `${theme.spacing(6)} 0 ${theme.spacing(2)}`};
  padding: ${({ theme }) => `0 ${theme.spacing(3)} 0 ${theme.spacing(6)}`};
`;

export const IconContainer = styled.span<{ $color: string }>`
  font-size: 2.4rem;
  line-height: 62.5%;
  color: ${({ $color }) => $color ?? 'inherit'};
`;

export const Left = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};
`;

export const Right = styled.div`
  display: flex;

  color: ${({ theme }) => theme.palette.grey['500']};
`;

export const FavoriteButtonContainer = styled.div<{ $isFavorite: boolean }>`
  ${({ theme, $isFavorite }) =>
    $isFavorite &&
    css`
      color: ${theme.palette.warning.main};
      fill: ${theme.palette.warning.main};
    `};

  ${({ theme }) =>
    theme.isMobile &&
    css`
      display: none;
    `};
`;
