import { ID } from 'shared/types/id';

import { getUser, getUserRef } from './get-user';
import { updateDocWithTimestamp } from './helpers';

export const updateAllTasksFilterTags = async (ids: ID[]) => {
  const user = await getUser();

  // without a user we cannot update anything.
  if (!user) {
    return;
  }

  const userRef = await getUserRef();

  if (!userRef) {
    return null;
  }

  updateDocWithTimestamp(userRef, {
    'settings.allTasksFilterByTags': ids,
  });
};
