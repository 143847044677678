import { useDroppable } from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useCreateHabitAllowed } from 'features/tasks';
import { useUserHadTrial } from 'features/user';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PremiumBanner } from 'shared/components/ui/premium-banner';
import { Habit } from 'shared/types/habit';
import { ID } from 'shared/types/id';
import { TaskPriorityOptions } from 'shared/types/task-base';
import { Timestamp } from 'shared/types/timestamp';

import { HabitsList } from '../habits-list';
import { SectionLayout } from '../section-layout';
import * as Styled from './habit-section.style';

export type HabitSectionProps = {
  habits: Habit[];
  weekDate: Timestamp;
  onHabit: (id: ID) => void;
  droppableId?: ID;
  goalId?: ID;
  lifeAreaId?: ID;
  priority?: TaskPriorityOptions;
  onPremium?: () => void;
};

export const HabitSection: React.FC<HabitSectionProps> = ({
  habits,
  weekDate,
  onHabit,
  droppableId,
  goalId,
  lifeAreaId,
  priority,
  onPremium,
}) => {
  const { t } = useTranslation();
  const userHadTrial = useUserHadTrial();
  const {
    enforced: createHabitLimitsEnforced,
    limit: createHabitLimits,
    total: totalHabits,
  } = useCreateHabitAllowed();

  const { setNodeRef } = useDroppable({
    id: droppableId ?? '',
    disabled: !droppableId || !!habits.length,
  });
  const itemIds = useMemo(() => habits.map(({ id }) => id), [habits]);

  const containerProps = droppableId ? { ref: setNodeRef } : {};

  return (
    <Styled.Container {...containerProps}>
      <SectionLayout
        goalId={goalId}
        lifeAreaId={lifeAreaId}
        priority={priority}
      >
        <SortableContext items={itemIds} strategy={verticalListSortingStrategy}>
          <HabitsList
            items={habits}
            weekDate={weekDate}
            onHabit={onHabit}
            isSortable
          />
        </SortableContext>
      </SectionLayout>

      {onPremium && (
        <Styled.PremiumBannerContainer>
          <PremiumBanner
            title={t(
              createHabitLimitsEnforced
                ? 'pages.habits.premium-banner.title'
                : 'pages.habits.premium-banner.not-enforced.title',
              { total: totalHabits, limit: createHabitLimits },
            )}
            description={t(
              createHabitLimitsEnforced
                ? userHadTrial
                  ? 'pages.habits.premium-banner.description'
                  : 'pages.habits.premium-banner.try-for-free.description'
                : 'pages.habits.premium-banner.not-enforced.description',
              { limit: createHabitLimits },
            )}
            onClick={onPremium}
            isTrial={!userHadTrial}
          />
        </Styled.PremiumBannerContainer>
      )}
    </Styled.Container>
  );
};
