import { doc } from 'firebase/firestore';
import { MinimalRelationship } from 'shared/types/relationship';
import { RoleOption } from 'shared/types/roles';

import { newRelationshipConverter } from './converters/relationships';
import {
  BatchDocuments,
  CollectionOptions,
  getAuthentication,
  getCollection,
  getDatabase,
} from './helpers';

export const createRelationships = async (
  relationships: MinimalRelationship[],
) => {
  const batchDocuments = new BatchDocuments(getDatabase());
  const relationshipCollection = getCollection(CollectionOptions.Relationships);

  const user = (await getAuthentication()).currentUser;

  if (!user) {
    throw Error('unauthenticated');
  }

  relationships.forEach((relationship) =>
    batchDocuments.addDocument({
      data: newRelationshipConverter({
        ...relationship,
        roles: { [RoleOption.Owner]: user.uid, [RoleOption.All]: [user.uid] },
      }),
      ref: doc(relationshipCollection, relationship.id),
    }),
  );

  batchDocuments.commit();
};
