import {
  useGoalById,
  useGoalCompletion,
  useGoals,
  useUpdateGoal,
} from 'features/goals';
import { useLocalizedLifeAreas } from 'features/life-areas';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Flag, IconFormIcons } from 'shared/assets/icons';
import {
  Chip,
  Colors,
  Sizes,
  Variants as ChipVariants,
} from 'shared/components/ui/chip';
import { CircleProgress } from 'shared/components/ui/circle-progress';
import {
  EditableTextField,
  Variants,
} from 'shared/components/ui/editable-text-field';
import { Icon } from 'shared/components/ui/icon';
import { TimeLeft } from 'shared/components/ui/time-left';
import { Timeframe } from 'shared/components/ui/timeframe';
import { getGoalColor } from 'shared/utils/get-goal-color';

import { GoalDetailIllustration } from '../goal-detail-illustration';
import * as Styled from './goal-detail-header.style';

export type GoalDetailHeaderProps = {};

export const GoalDetailHeader: React.FC<GoalDetailHeaderProps> = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const goal = useGoalById(id);
  const goals = useGoals();
  const lifeAreas = useLocalizedLifeAreas();
  const { submit: updateGoal } = useUpdateGoal();
  const { completed } = useGoalCompletion(id);

  const lifeArea = useMemo(
    () => lifeAreas.find(({ id }) => id === goal?.lifeAreaId),
    [goal?.lifeAreaId, lifeAreas],
  );

  const color = useMemo(
    () => (goal ? getGoalColor(goal?.id, { goals, lifeAreas }) : undefined),
    [goal, goals, lifeAreas],
  );

  if (!goal) {
    return null;
  }

  const onChangeTitle = (title: string) =>
    !goal.archivedAt &&
    !goal.frozenAt &&
    updateGoal({ id: goal.id, name: title });

  return (
    <Styled.Header>
      <Styled.Container>
        <Styled.IllustrationContainer $hasImage={!!goal.image}>
          <Styled.ImageContainer $hasImage={!!goal.image}>
            <GoalDetailIllustration
              illustration={goal.image ?? goal.iconName}
            />
          </Styled.ImageContainer>
          {!!color && <Styled.ColorDot $color={color} />}
        </Styled.IllustrationContainer>

        <Styled.Body>
          <Styled.Content $completed={!!goal.completedAt}>
            <EditableTextField
              value={goal.name}
              onChange={onChangeTitle}
              variant={Variants.H2}
              isStrikeThrough={!!goal.completedAt}
            />

            <Styled.MetaContainer>
              {!!goal.completedAt && (
                <Chip
                  label={t('pages.goal-detail.header.completed.label')}
                  size={Sizes.Small}
                  variant={ChipVariants.Filled}
                  color={Colors.Success}
                />
              )}
              {lifeArea && (
                <Styled.MetaItem>
                  <Styled.IconContainer>
                    <Icon icon={IconFormIcons[lifeArea.iconName]} />
                  </Styled.IconContainer>

                  <Styled.MetaLabel>{lifeArea.name}</Styled.MetaLabel>
                </Styled.MetaItem>
              )}

              <Styled.MetaItem $noOverflow>
                <Timeframe
                  startDate={goal?.startDate}
                  endDate={goal?.deadline}
                />
              </Styled.MetaItem>

              {!!goal.deadline && (
                <Styled.MetaItem>
                  <TimeLeft startIcon={Flag} date={goal.deadline!} />
                </Styled.MetaItem>
              )}

              {!!goal.metric?.id && (
                <Styled.MetaItem $hideMobile>
                  <CircleProgress percentage={completed} />
                </Styled.MetaItem>
              )}
            </Styled.MetaContainer>
          </Styled.Content>
        </Styled.Body>
      </Styled.Container>
    </Styled.Header>
  );
};
