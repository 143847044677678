import { endOfDay, isAfter, isBefore, isSameDay } from 'date-fns';
import { useCallback } from 'react';
import { useToday } from 'shared/contexts/today';
import { useUpdateTaskMutation } from 'shared/hooks/queries/use-update-task-mutation';
import { useTrackEvents } from 'shared/hooks/use-track-events';
import {
  ActionEvents,
  taskTypeToTrackingTaskType,
} from 'shared/services/tracking';
import { Habit } from 'shared/types/habit';
import { ScheduleEntryState } from 'shared/types/habit-schedule';
import { RepeatingTask } from 'shared/types/repeating-task';
import { Timestamp } from 'shared/types/timestamp';
import { getScheduleFrequencyDayTarget } from 'shared/utils/get-schedule-frequency-day-target';
import { isNumber } from 'shared/utils/is-number';

export type Options = {
  value?: number;
  increment?: number;
  toggle?: boolean;
};

export const useUpdateHabitScheduleEntry = () => {
  const today = useToday();
  const { mutate: updateTask } = useUpdateTaskMutation();
  const track = useTrackEvents();

  return useCallback(
    (
      habit: Habit | RepeatingTask,
      date: Timestamp = new Date(),
      type: ScheduleEntryState = ScheduleEntryState.Complete,
      { value, increment, toggle }: Options = {},
    ) => {
      if (isAfter(date, endOfDay(today))) {
        return;
      }

      // sort the schedules so we are sure that they are in order
      const sortedSchedules = habit.schedules.toSorted(
        (scheduleA, scheduleB) =>
          scheduleA.startDate.getTime() - scheduleB.startDate.getTime(),
      );

      const newSchedules = sortedSchedules.map((schedule, index) => {
        // get the previous schedule to see if the date falls between this and the previous schedule
        const prevSchedule = sortedSchedules[index - 1];
        // get the start point for entries to be added to the current schedule
        const startPointSchedule = prevSchedule?.endDate ?? new Date(0);
        // if the date is not within the schedule timeframe, filter the date out of the entries and early return
        if (
          isBefore(date, startPointSchedule) ||
          (schedule.endDate && isAfter(date, schedule.endDate))
        ) {
          schedule.entries = schedule.entries.filter(
            (entry) => !isSameDay(date, entry.date),
          );
          // backwards compatibility
          schedule.completions =
            schedule.completions?.filter(
              (completion) => !isSameDay(date, completion),
            ) ?? null;
          schedule.skips =
            schedule.skips?.filter((skip) => !isSameDay(date, skip)) ?? null;

          return schedule;
        }

        // get the increment value
        const incrementValue = increment ?? 1;

        // get the current entry for the given date
        let entry = schedule.entries.find((entry) =>
          isSameDay(entry.date, date),
        );
        // if there is no current entry, add an entry to the list.
        if (!entry) {
          entry = {
            id: window.crypto.randomUUID(),
            date,
            value:
              type === ScheduleEntryState.Complete
                ? Math.max(0, value ?? incrementValue)
                : null,
            state: type,
          };
          schedule.entries = [...schedule.entries, entry];
          return schedule;
        }

        // make sure the state is correct
        entry.state = type;
        const entryVal = entry.value ?? 0;
        // check the current value of the entry and change it to the given value, or up it with one if it is a complete.
        const newValue = Math.max(
          0,
          value ??
            (isNumber(increment)
              ? // if increment, add the incremental value
                entryVal + incrementValue
              : // if no frequency and no increment, reset to the start if the target has been reached already
                (!schedule.frequency &&
                    entryVal >= schedule.completionTarget.count) ||
                  // if frequency and day-target is 1, reset to start if the target has been reached already
                  (!!schedule.frequency &&
                    entryVal &&
                    getScheduleFrequencyDayTarget(schedule)) ||
                  (toggle && !!entryVal)
                ? 0
                : entryVal + 1),
        );

        // track event
        if (
          type === ScheduleEntryState.Complete &&
          entryVal < schedule.completionTarget.count &&
          newValue >= schedule.completionTarget.count
        ) {
          track(ActionEvents.TaskComplete, {
            type: taskTypeToTrackingTaskType[habit.type],
          });
        }

        // set the new value and time (date) on the entry
        entry.value = type === ScheduleEntryState.Complete ? newValue : null;
        entry.date = date;

        // backwards compatibility
        if (
          // when the frequency is the old settings
          (schedule.frequency && !!getScheduleFrequencyDayTarget(schedule)) ||
          // when the rrule is 1 per day
          schedule.completionTarget.count > 1
        ) {
          let filteredSkips =
            schedule.skips?.filter((skip) => !isSameDay(skip, date)) ?? null;
          let filteredCompletions =
            schedule.completions?.filter(
              (completion) => !isSameDay(completion, date),
            ) ?? null;

          if (
            type === ScheduleEntryState.Skip &&
            (!schedule.skips ||
              filteredSkips?.length !== schedule.skips?.length)
          ) {
            filteredSkips = [...(filteredSkips ?? []), date];
          }

          if (
            type === ScheduleEntryState.Complete &&
            (!schedule.completions ||
              filteredCompletions?.length === schedule.completions?.length)
          ) {
            filteredCompletions = [...(filteredCompletions ?? []), date];
          }
          schedule.skips = filteredSkips;
          schedule.completions = filteredCompletions;
        }

        return schedule;
      });

      // update the task and finish
      updateTask({ ...habit, schedules: newSchedules });
    },
    [today, track, updateTask],
  );
};
