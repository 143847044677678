import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Target } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import { LinkedItemSearchMenu } from 'shared/components/ui/linked-item-search-menu';
import { TaskMetaInteraction } from 'shared/components/ui/task-meta-interaction';
import { useOpenMenu } from 'shared/hooks/use-open-menu';
import { InteractionType } from 'shared/types/data-type';
import { Goal } from 'shared/types/goal';
import { ID } from 'shared/types/id';
import { LifeArea } from 'shared/types/life-area';
import { LinkOptions } from 'shared/types/task';
import { getGoalColor } from 'shared/utils/get-goal-color';

import * as Styled from './task-meta-interaction-linked-item.style';

export type TaskMetaInteractionGoalProps = Omit<
  React.ComponentProps<typeof TaskMetaInteraction>,
  'start' | 'onClick' | 'label' | 'selected' | 'tooltipLabel'
> & {
  goals: Goal[];
  lifeAreas: LifeArea[];
  value?: ID | null;
  onChange: (link: { id: ID; type: LinkOptions } | null) => void;
  onCreate: (name: string) => void;
  showGoalColor?: boolean;
};

export const TaskMetaInteractionLinkedItem: React.FC<
  TaskMetaInteractionGoalProps
> = ({ goals, lifeAreas, value, onChange, onCreate, ...rest }) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const { menuOpen, openMenu, closeMenu } = useOpenMenu();

  const item = useMemo(
    () =>
      goals.find(({ id }) => id === value) ??
      lifeAreas.find(({ id }) => id === value),
    [goals, lifeAreas, value],
  );

  const goalColor = useMemo(
    () =>
      item?.id
        ? 'lifeAreaId' in item
          ? getGoalColor(item.id, { lifeAreas, goals }) ?? undefined
          : item.color
        : undefined,
    [item, lifeAreas, goals],
  );

  const onCreateGoal = (name: string) => {
    onCreate(name);
    closeMenu();
  };

  const _onChange = (link: { id: ID; type: LinkOptions } | null) => {
    onChange(link);
    closeMenu();
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, [menuOpen]);

  return (
    <Styled.Container ref={containerRef}>
      <TaskMetaInteraction
        start={
          goalColor ? <Styled.Dot $color={goalColor} /> : <Icon icon={Target} />
        }
        onClick={openMenu}
        label={item?.name}
        selected={menuOpen}
        tooltipLabel={t(
          item
            ? 'task.linked-item.tooltip.edit.label'
            : 'task.linked-item.tooltip.add.label',
        )}
        {...rest}
      />

      {menuOpen && (
        <LinkedItemSearchMenu
          goals={goals}
          lifeAreas={lifeAreas}
          onChange={_onChange}
          onCreate={onCreateGoal}
          value={value}
          referenceElement={containerRef}
          onOutside={closeMenu}
          interactionType={InteractionType.Task}
        />
      )}
    </Styled.Container>
  );
};
