import Typography from '@mui/material/Typography';
import { endOfWeek, isAfter, isSameDay } from 'date-fns';
import { useCreateHabitAllowed } from 'features/tasks';
import { useUpdateHabitGroupBy, useUpdateHabitSortMode } from 'features/user';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Plus } from 'shared/assets/icons';
import { Button, Colors, Sizes, Variants } from 'shared/components/ui/button';
import { WeekSelector } from 'shared/components/ui/week-selector';
import { useOpenCreateHabitDialog } from 'shared/contexts/habit-form';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';
import { useToday } from 'shared/contexts/today';
import { useUser } from 'shared/hooks/use-user';
import { getWeekStartsOn } from 'shared/utils/get-week-starts-on';
import { useTheme } from 'styled-components';

import {
  useNextTimeframe,
  usePreviousTimeframe,
  useResetTimeframe,
  useWeekDate,
} from '../../contexts/timeframe';
import * as Styled from './header.style';
import { HeaderSortButton } from './header-sort-button';

export const Header: React.FC = () => {
  const { t } = useTranslation();
  const today = useToday();
  const theme = useTheme();
  const user = useUser();
  const weekStartsOn = getWeekStartsOn(user);

  const openPremiumDialog = useOpenPremiumDialog();
  const openCreateHabitDialog = useOpenCreateHabitDialog();
  const createHabitAllowed = useCreateHabitAllowed();
  const setSortMode = useUpdateHabitSortMode();
  const setGroupBy = useUpdateHabitGroupBy();

  const weekDate = useWeekDate();
  const onPrev = usePreviousTimeframe();
  const onNext = useNextTimeframe();
  const onReset = useResetTimeframe();
  const nextDisabled = useMemo(
    () =>
      isSameDay(endOfWeek(weekDate), endOfWeek(today)) ||
      isAfter(endOfWeek(weekDate), endOfWeek(today)),
    [today, weekDate],
  );

  const createHabit = () =>
    createHabitAllowed ? openCreateHabitDialog() : openPremiumDialog();

  return (
    <Styled.Container>
      <Styled.Top>
        <Styled.Left>
          <Typography variant="h1">{t('pages.habits.title')}</Typography>
        </Styled.Left>

        <Styled.Center>
          <WeekSelector
            date={weekDate}
            onNext={onNext}
            onNextDisabled={nextDisabled}
            onPrev={onPrev}
            onDate={onReset}
            weekStartsOn={weekStartsOn}
          />
        </Styled.Center>

        <Styled.Right>
          <HeaderSortButton
            onSort={setSortMode}
            onGroupBy={setGroupBy}
            sort={user?.settings?.habitSortingMode}
            groupBy={user?.settings?.habitGroupBy}
          />
          {!theme.isMobile && (
            <Button
              onClick={createHabit}
              size={Sizes.Medium}
              variant={Variants.Contained}
              color={Colors.Primary}
              startIcon={Plus}
            >
              {t('pages.habits.header.add-habit')}
            </Button>
          )}
        </Styled.Right>
      </Styled.Top>
      <Styled.Bottom>
        <WeekSelector
          date={weekDate}
          onNext={onNext}
          onNextDisabled={nextDisabled}
          onPrev={onPrev}
          onDate={onReset}
          weekStartsOn={weekStartsOn}
        />
      </Styled.Bottom>
    </Styled.Container>
  );
};
