import React, { useState } from 'react';
import { TaskCard } from 'shared/components/connected/task-card';
import { SortableContainer } from 'shared/components/ui/sortable-container';
import { DraggableOptions } from 'shared/types/draggable-types';

export type TaskCardListItemProps = Omit<
  React.ComponentProps<typeof TaskCard>,
  'onEditChange'
> & {
  hasDnd?: boolean;
};

export const TaskCardListItem: React.FC<TaskCardListItemProps> = ({
  task,
  hasDnd,
  ...rest
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const Wrapper = hasDnd && !task.frozenAt ? SortableContainer : React.Fragment;
  const wrapperProps =
    hasDnd && !task.frozenAt
      ? {
          id: task.id,
          type: DraggableOptions.Task,
          isEditing,
        }
      : ({} as { id: string; type: DraggableOptions });

  return (
    <Wrapper {...wrapperProps}>
      <TaskCard task={task} onEditChange={setIsEditing} {...rest} />
    </Wrapper>
  );
};
