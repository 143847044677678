import {
  autoUpdate,
  flip,
  offset,
  Placement,
  shift,
  size,
  useFloating,
} from '@floating-ui/react';
import React, { forwardRef, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useClickOutside } from 'shared/hooks/use-click-outside';
import { useForwardedRef } from 'shared/hooks/use-forwarded-ref';
import { InteractionType } from 'shared/types/data-type';

import * as Styled from './popup-menu.style';

export type PopupMenuProps = {
  children: React.ReactNode;
  referenceElement?: React.RefObject<HTMLElement>;
  location?: { top: number; left: number };
  position?: Placement;
  fallbackPositions?: Placement[];
  preventReferenceElementOverlap?: boolean;
  hasParentWidth?: boolean;
  onOutside?: () => void;
  className?: string;
  interactionType?: InteractionType;
};

const fallbackPlacements: Placement[] = [
  'right-end',
  'right-start',
  'bottom-end',
  'bottom-start',
  'left-start',
  'left-end',
  'top-end',
  'top-start',
];

export const PopupMenu = forwardRef<HTMLDivElement, PopupMenuProps>(
  (
    {
      children,
      referenceElement,
      location,
      position = 'bottom-start',
      fallbackPositions = fallbackPlacements,
      preventReferenceElementOverlap,
      hasParentWidth,
      onOutside,
      className,
      interactionType,
    },
    ref,
  ) => {
    const menuRef = useForwardedRef(ref);
    const { refs, floatingStyles } = useFloating({
      elements: {
        reference: referenceElement?.current,
      },
      placement: position,
      whileElementsMounted: autoUpdate,
      strategy: 'fixed',
      middleware: [
        offset({ mainAxis: 8 }),
        shift({
          padding: Styled.positioningOffset,
          crossAxis: !preventReferenceElementOverlap,
        }),
        flip({
          fallbackPlacements: fallbackPositions,
        }),
        hasParentWidth
          ? size({
              apply: ({ rects, elements }) => {
                elements.floating.style.width = `${rects.reference.width}px`;
              },
            })
          : undefined,
      ],
    });

    useEffect(() => {
      if (location) {
        refs.setPositionReference({
          getBoundingClientRect: () => ({
            width: 0,
            height: 0,
            x: location.left,
            y: location.top,
            top: location.top,
            right: location.left,
            bottom: location.top,
            left: location.left,
          }),
        });
      }
    }, [location, refs]);

    useEffect(() => {
      refs.setFloating(menuRef.current);
    }, [menuRef, refs]);

    useClickOutside(menuRef, onOutside);

    return createPortal(
      <Styled.Container
        className={className}
        style={floatingStyles}
        ref={menuRef}
        data-no-dnd="true"
        data-interaction-for-type={interactionType}
      >
        {children}
      </Styled.Container>,
      document.body,
    );
  },
);
