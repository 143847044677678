import styled, { css } from 'styled-components';

export const HeaderContainer = styled.div<{
  $smallTopSpacing: boolean;
}>`
  position: relative;
  padding-top: ${({ theme, $smallTopSpacing }) =>
    theme.spacing($smallTopSpacing ? 4 : 6)};
  padding-bottom: ${({ theme }) => theme.spacing(0.5)};

  ${({ theme }) =>
    theme.isMobile &&
    css`
      flex-direction: row-reverse;
      padding-top: ${theme.spacing(2)};
    `};
`;

export const IconButtonContainer = styled.span`
  position: absolute;
  bottom: ${({ theme }) => theme.spacing(2)};
  left: ${({ theme }) => theme.spacing(-5.5)};
  color: ${({ theme }) => theme.palette.grey['400']};
  line-height: 62.5%;

  ${({ theme }) =>
    theme.isMobile &&
    css`
      bottom: ${theme.spacing(0.5)};
      left: unset;
      right: 0;
    `};
`;
