import {
  doc,
  DocumentReference,
  serverTimestamp,
  writeBatch,
} from 'firebase/firestore';
import { ID } from 'shared/types/id';
import { RoleOption } from 'shared/types/roles';
import { Task } from 'shared/types/task';

import {
  CollectionOptions,
  getAuthentication,
  getCollection,
  getDatabase,
} from './helpers';

export const getUniqueTasksEntries = (tasks: Task[]) => {
  const uniqueDays = [];
  const seenDays = new Set();

  for (const task of tasks) {
    if (!seenDays.has(task.id)) {
      seenDays.add(task.id);
      uniqueDays.push(task);
    }
  }

  return uniqueDays;
};

export const duplicateTasks = async (tasks: Task[]) => {
  if (!tasks.length) {
    return [];
  }
  // prevent duplicate copies
  const tasksToDuplicate = getUniqueTasksEntries(tasks);

  const user = (await getAuthentication()).currentUser;

  if (!user) {
    throw Error('unauthenticated');
  }

  // create docReferences for all tasks
  const oldTaskIdNewTaskRefMap: Record<ID, DocumentReference> = {};
  tasksToDuplicate.forEach((task) => {
    oldTaskIdNewTaskRefMap[task.id] = doc(
      getCollection(CollectionOptions.Tasks),
    );
  });

  const newTasks: Task[] = [];
  const batch = writeBatch(getDatabase());
  tasksToDuplicate.forEach((task) => {
    const docRef = oldTaskIdNewTaskRefMap[task.id];
    if (!docRef) {
      return;
    }

    // create a new task for every task
    const newTask: Task = {
      ...task,
      // is server timestamp so the server accepts it, other properties should be as is
      createdAt: serverTimestamp() as unknown as Date,
      // is server timestamp so the server accepts it, other properties should be as is
      updatedAt: serverTimestamp() as unknown as Date,
      roles: { [RoleOption.Owner]: user.uid, [RoleOption.All]: [user.uid] },
      id: docRef.id,
      childIds:
        // parse all childIds, if there are any, to the new tasks
        task.childIds
          ?.map((childId) => oldTaskIdNewTaskRefMap[childId].id)
          .filter(Boolean) ?? null,
      parentIds:
        // parse all parentIds, if there are any, to the new tasks.
        // It could be that only a child-task is duplicated, which means it should keep it structure
        task.parentIds
          ?.map((parentId) => oldTaskIdNewTaskRefMap[parentId]?.id ?? parentId)
          .filter(Boolean) ?? null,
    };

    batch.set(docRef, newTask);
    newTasks.push(newTask);
  });

  // specifically no await, to support offline usage of the app
  batch.commit();

  return newTasks;
};
