import React from 'react';

import * as Styled from './tab-panel.style';

export type TabPanelProps = {
  noHorizontalSpacing?: boolean;
  children: React.ReactNode;
};

export const TabPanel: React.FC<TabPanelProps> = ({
  noHorizontalSpacing,
  children,
}) => (
  <Styled.Container $noHorizontalSpacing={!!noHorizontalSpacing}>
    {children}
  </Styled.Container>
);
