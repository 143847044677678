import { useMemo } from 'react';
import { useUser } from 'shared/hooks/use-user';
import { sortItems } from 'shared/utils/sort-items';

import { useTags } from './use-tags';

export const useSortedTags = () => {
  const user = useUser();
  const tags = useTags();
  const tagSorting = user?.settings?.tagSorting;

  return useMemo(() => {
    if (!tagSorting || tagSorting.length === 0) {
      return tags;
    }

    return sortItems(tags, tagSorting, 'id');
  }, [tags, tagSorting]);
};
