import { useUpdateGoal } from 'features/goals';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Check } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import {
  PopupMenu,
  PopupMenuButton,
  PopupMenuList,
  PopupMenuListItem,
  PopupMenuTitle,
} from 'shared/components/ui/popup-menu';
import { Goal } from 'shared/types/goal';
import { GoalSortingMode, SortingMode } from 'shared/types/sorting-mode';

import { goalSortOptions, taskSortOptions } from './sort-options';

export type ActionPlanSortMenuProps = Omit<
  React.ComponentProps<typeof PopupMenu>,
  'children' | 'ref'
> & {
  goal: Goal;
  onClose?: () => void;
};

export const ActionPlanSortMenu: React.FC<ActionPlanSortMenuProps> = ({
  referenceElement,
  location,
  position,
  goal,
  onClose,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const goalSort = goal.goalSortingMode;
  const taskSort = goal.taskSortingMode;
  const { submit: updateGoal } = useUpdateGoal();

  const _onClose = () => onClose?.();

  const _onSortTask = (value: SortingMode) => {
    updateGoal({ id: goal.id, taskSortingMode: value });
    _onClose();
  };

  const _onSortGoal = (value: GoalSortingMode) => {
    updateGoal({ id: goal.id, goalSortingMode: value });
    _onClose();
  };

  return (
    <PopupMenu
      ref={containerRef}
      referenceElement={referenceElement}
      location={location}
      position={position}
      onOutside={_onClose}
    >
      <PopupMenuList hasBottomBorder>
        <PopupMenuTitle title={t('general.labels.tasks.sort.title')} />

        {taskSortOptions.map(({ id, label, value }) => (
          <PopupMenuListItem key={id}>
            <PopupMenuButton
              start={taskSort === id ? <Icon icon={Check} /> : undefined}
              onClick={() => _onSortTask(value)}
              hasIndentation={taskSort !== id}
            >
              {t(label)}
            </PopupMenuButton>
          </PopupMenuListItem>
        ))}
      </PopupMenuList>

      <PopupMenuList>
        <PopupMenuTitle title={t('general.labels.goals.sort.title')} />

        {goalSortOptions.map(({ id, label, value }) => (
          <PopupMenuListItem key={id}>
            <PopupMenuButton
              start={goalSort === id ? <Icon icon={Check} /> : undefined}
              onClick={() => _onSortGoal(value)}
              hasIndentation={goalSort !== id}
            >
              {t(label)}
            </PopupMenuButton>
          </PopupMenuListItem>
        ))}
      </PopupMenuList>
    </PopupMenu>
  );
};
