import { useGoals } from 'features/goals';
import { useLocalizedLifeAreas } from 'features/life-areas';
import { useUpdateHabitScheduleEntry } from 'features/tasks';
import React from 'react';
import { useOpenTaskStatistics } from 'shared/contexts/task-detail';
import { useUser } from 'shared/hooks/use-user';
import { Habit } from 'shared/types/habit';
import { ID } from 'shared/types/id';
import { InsightsTimeframes } from 'shared/types/insights';
import { Timestamp } from 'shared/types/timestamp';
import { getWeekStartsOn } from 'shared/utils/get-week-starts-on';

import { HabitDetailMobileCardView } from './habit-detail-mobile-card-view';

export type HabitDetailMobileCardProps = {
  habit: Habit;
  weekDate: Timestamp;
  onClick?: (id: ID) => void;
  isSortable?: boolean;
};

export const HabitDetailMobileCard: React.FC<HabitDetailMobileCardProps> = ({
  habit,
  weekDate,
  isSortable,
  onClick,
}) => {
  const goals = useGoals();
  const lifeAreas = useLocalizedLifeAreas();
  const user = useUser();
  const weekStartsOn = getWeekStartsOn(user);
  const updateHabitEntry = useUpdateHabitScheduleEntry();
  const openStatistics = useOpenTaskStatistics();

  const _onDate = (_: ID, date: Timestamp) => updateHabitEntry(habit, date);
  const _openStatistics = () =>
    openStatistics(habit.id, InsightsTimeframes.FourWeeks);

  return (
    <HabitDetailMobileCardView
      habit={habit}
      goals={goals}
      lifeAreas={lifeAreas}
      weekDate={weekDate}
      weekStartsOn={weekStartsOn}
      onDate={_onDate}
      isSortable={isSortable}
      onClick={onClick}
      onStatistics={_openStatistics}
    />
  );
};
