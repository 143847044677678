import { useTaskById } from 'features/tasks';
import React, { forwardRef } from 'react';
import { TaskCardContextMenu } from 'shared/components/connected/task-card-context-menu';
import { ID } from 'shared/types/id';

export type ContextMenuProps = {
  taskId?: ID;
  open?: boolean;
  onClose: () => void;
  referenceElement: React.RefObject<HTMLElement>;
  hideEditButton?: boolean;
};

export const ContextMenu = forwardRef<HTMLDivElement, ContextMenuProps>(
  ({ taskId, open, onClose, referenceElement, hideEditButton }, ref) => {
    const task = useTaskById(taskId);

    if (!task || !open) {
      return;
    }

    return (
      <TaskCardContextMenu
        ref={ref}
        task={task}
        onClose={onClose}
        referenceElement={referenceElement}
        position="bottom-start"
        hideEditButton={hideEditButton}
      />
    );
  },
);
