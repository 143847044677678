import { useGoals } from 'features/goals';
import {
  useOverdueHabits,
  useOverdueRepeatingTasks,
  useOverdueTasks,
  useTodaysHabits,
  useTodaysRepeatingTasks,
  useTodaysTodos,
} from 'features/tasks';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useToday } from 'shared/contexts/today';
import { useUser } from 'shared/hooks/use-user';
import { TypeSections } from 'shared/types/page-sections';
import { SortingMode } from 'shared/types/sorting-mode';
import { TaskType } from 'shared/types/task-base';
import { TaskCardTask } from 'shared/types/task-card-task';
import { getSortedTasks } from 'shared/utils/get-sorted-tasks';
import { getWeekStartsOn } from 'shared/utils/get-week-starts-on';
import { mapTaskToTaskCard } from 'shared/utils/mappers/map-task-to-task-card';

export const useTypeTaskSections = () => {
  const { t } = useTranslation();
  const today = useToday();
  const user = useUser();
  const weekStartsOn = getWeekStartsOn(user);

  const todos = useTodaysTodos();
  const habits = useTodaysHabits(weekStartsOn);
  const repeatingTasks = useTodaysRepeatingTasks(weekStartsOn);
  const overdueTodos = useOverdueTasks();
  const overdueHabits = useOverdueHabits(weekStartsOn);
  const overdueRepeatingTasks = useOverdueRepeatingTasks(weekStartsOn);
  const goals = useGoals();

  const tasks = useMemo(
    () => [
      ...overdueTodos,
      ...overdueRepeatingTasks,
      ...overdueHabits,
      ...todos,
      ...repeatingTasks,
      ...habits,
    ],
    [
      overdueTodos,
      overdueRepeatingTasks,
      overdueHabits,
      todos,
      repeatingTasks,
      habits,
    ],
  );

  const sections = useMemo(
    () => [
      {
        id: TypeSections.Tasks,
        items: getSortedTasks(
          tasks.filter(({ type }) => type !== TaskType.Habit),
          {
            today,
            weekStartsOn,
            mode: Array.from(
              new Set([SortingMode.DueDate, user?.settings?.todoSortingMode]),
            ).filter(Boolean) as SortingMode[],
            order:
              user?.settings?.todayGroupSorting?.type?.sorting?.[
                TypeSections.Tasks
              ] ?? [],
          },
        ).map((task) =>
          mapTaskToTaskCard(task, {
            today,
            goals,
            t,
            showDue: true,
            hideTodayDue: true,
            weekStartsOn,
          }),
        ),
      },
      {
        id: TypeSections.Habits,
        items: getSortedTasks(
          tasks.filter(({ type }) => type === TaskType.Habit),
          {
            today,
            weekStartsOn,
            mode: Array.from(
              new Set([SortingMode.DueDate, user?.settings?.todoSortingMode]),
            ).filter(Boolean) as SortingMode[],
            order:
              user?.settings?.todayGroupSorting?.type?.sorting?.[
                TypeSections.Habits
              ] ?? [],
          },
        ).map((task) =>
          mapTaskToTaskCard(task, {
            today,
            goals,
            t,
            showDue: true,
            hideTodayDue: true,
            weekStartsOn,
          }),
        ),
      },
    ],
    [
      goals,
      t,
      tasks,
      today,
      user?.settings?.todayGroupSorting?.type?.sorting,
      user?.settings?.todoSortingMode,
      weekStartsOn,
    ],
  );

  const taskCardTasks = useMemo(
    () =>
      sections.reduce<TaskCardTask[]>((acc, { items }) => {
        acc.push(...items);
        return acc;
      }, []),
    [sections],
  );

  return {
    sections,
    taskCardTasks,
  };
};
