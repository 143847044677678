import styled from 'styled-components';

export const List = styled.ul`
  display: grid;
  gap: ${({ theme }) => theme.spacing(2)};

  margin: 0;
  padding: ${({ theme }) =>
    theme.isMobile
      ? `0 ${theme.spacing(3)} ${theme.spacing(0.5)}`
      : `0 ${theme.spacing(3)} ${theme.spacing(0.5)} ${theme.spacing(6)}`};

  list-style: none;
`;

export const ListItem = styled.li``;
