import { useTagById, useTagTasks } from 'features/tags';
import { useMemo } from 'react';
import { useToday } from 'shared/contexts/today';
import { useGoalSections } from 'shared/hooks/use-goal-sections';
import { useUser } from 'shared/hooks/use-user';
import { ID } from 'shared/types/id';
import { goalInboxID } from 'shared/types/orderable-section';
import { SortingMode } from 'shared/types/sorting-mode';
import { getSortedTasks } from 'shared/utils/get-sorted-tasks';
import { getWeekStartsOn } from 'shared/utils/get-week-starts-on';

export const useTagGoalTaskSections = (id?: ID) => {
  const today = useToday();
  const user = useUser();
  const tag = useTagById(id);
  const tasks = useTagTasks(id);

  const goalSections = useGoalSections();

  const sections = useMemo(
    () =>
      goalSections
        .map(({ id: goalId }) => ({
          id: goalId,
          items: getSortedTasks(
            tasks.filter(({ goalId: taskGoalId }) =>
              goalId === goalInboxID ? !taskGoalId : taskGoalId === goalId,
            ),
            {
              today,
              weekStartsOn: getWeekStartsOn(user),
              mode: Array.from(
                new Set([SortingMode.DueDate, tag?.taskSortingMode]),
              ).filter(Boolean) as SortingMode[],
              order: tag?.taskSorting ?? [],
            },
          ),
        }))
        .filter(
          ({ id: goalId, items }) => goalId === goalInboxID || !!items.length,
        ),
    [goalSections, tag, tasks, today, user],
  );

  return {
    sections,
    tasks,
  };
};
