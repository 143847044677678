import Typography from '@mui/material/Typography';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ResizableInput } from 'shared/components/ui/resizable-input';
import { HabitSchedule } from 'shared/types/habit-schedule';
import { getActiveSchedule } from 'shared/utils/get-active-schedule';

import * as Styled from './repeat-form-input.style';

export type CompletionPerDayInputProps = {
  schedules: HabitSchedule[];
  onChange: (schedules: HabitSchedule[]) => void;
  shouldReplaceSchedule?: boolean;
};

export const CompletionPerDayInput: React.FC<CompletionPerDayInputProps> = ({
  schedules,
  onChange,
  shouldReplaceSchedule,
}) => {
  const { t } = useTranslation();
  const activeSchedule = useMemo(
    () => getActiveSchedule(schedules),
    [schedules],
  );

  const _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = Math.max(parseInt(e.target.value) || 1, 1);
    const activeSchedule = getActiveSchedule(schedules);

    if (!activeSchedule) {
      return;
    }
    const updatedSchedule = {
      ...activeSchedule,
      completionTarget: { ...activeSchedule.completionTarget, count: val },
    };

    const endedSchedules = schedules.filter(({ endDate }) => !!endDate);
    const updateSchedules = [];

    if (!shouldReplaceSchedule) {
      !!activeSchedule &&
        updateSchedules.push({ ...activeSchedule, endDate: new Date() });
    }

    updateSchedules.push(updatedSchedule);

    onChange([...endedSchedules, ...updateSchedules]);
  };

  return (
    <Styled.FlexRowContainer>
      <ResizableInput
        value={activeSchedule?.completionTarget.count ?? 1}
        type="number"
        onChange={_onChange}
        showAsFormInput
      />
      <Typography variant="body2">
        {t('forms.habit.fields.completions-per-day.description', {
          count: activeSchedule?.completionTarget.count ?? 1,
        })}
      </Typography>
    </Styled.FlexRowContainer>
  );
};
