import Typography from '@mui/material/Typography';
import { isSameDay } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Sizes, Variants } from 'shared/components/ui/button';
import { FormInput } from 'shared/components/ui/form-input';
import { useTimeframeEntries } from 'shared/hooks/use-timeframe-entries';
import { useTimeframeSchedules } from 'shared/hooks/use-timeframe-schedules';
import { Habit } from 'shared/types/habit';
import { Timestamp } from 'shared/types/timestamp';
import { getScheduleLabel } from 'shared/utils/get-schedule-label';
import { isNumber } from 'shared/utils/is-number';

import * as Styled from './manual-entry-log-form.style';

export type ManualEntryLogFormProps = {
  habit: Habit;
  date: Timestamp;
  onSubmit: (habit: Habit, date: Timestamp, value: number) => void;
  onCancel: () => void;
};

export const ManualEntryLogForm: React.FC<ManualEntryLogFormProps> = ({
  habit,
  date,
  onCancel,
  onSubmit,
}) => {
  const { dateEntries } = useTimeframeEntries(habit.schedules, {
    startDate: date,
    endDate: date,
  });
  const { t } = useTranslation();
  const [value, setValue] = useState<number>();

  const initialValue = useMemo(
    () => dateEntries.find((entry) => isSameDay(date, entry.date))?.value ?? 0,
    [date, dateEntries],
  );
  const [schedule] = useTimeframeSchedules(habit.schedules, {
    startDate: date,
    endDate: date,
  });

  const submitForm = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(habit, date, value ?? 0);
    return false;
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value
      ? Math.max(parseInt(e.target.value) || 0, 0)
      : undefined;
    setValue(val);
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <Styled.Form noValidate onSubmit={submitForm}>
      <Styled.Header>
        <Typography variant="h5" component="h1">
          {t('forms.habit-entry.title')}
        </Typography>
        {!!schedule && (
          <Styled.Description>
            {getScheduleLabel(schedule, t)}
          </Styled.Description>
        )}
      </Styled.Header>
      <Styled.Body>
        <FormInput
          value={value ?? ''}
          onChange={onChange}
          type="number"
          postFix={
            schedule?.frequency?.label ??
            t('general.labels.habit.repeat.value.target.default.label', {
              count: value || 0,
            })
          }
        />
      </Styled.Body>
      <Styled.Footer>
        <Button
          variant={Variants.Outlined}
          size={Sizes.Medium}
          onClick={onCancel}
          type="button"
        >
          {t('forms.habit-entry.buttons.cancel.label')}
        </Button>
        <Button
          size={Sizes.Medium}
          type="submit"
          disabled={!isNumber(value) || value! < 0}
        >
          {t('forms.habit-entry.buttons.save.label')}
        </Button>
      </Styled.Footer>
    </Styled.Form>
  );
};
