import React, { forwardRef } from 'react';
import { CheckThick } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';

import * as Styled from './circle-progress.style';

export type CircleProgressProps = {
  percentage?: number;
  hidePercentage?: boolean;
  isPrimary?: boolean;
  isSecondary?: boolean;
  isSmall?: boolean;
  showCheckOnComplete?: boolean;
};

export const CircleProgress = forwardRef<HTMLSpanElement, CircleProgressProps>(
  (
    {
      percentage,
      hidePercentage,
      isPrimary,
      isSecondary,
      isSmall,
      showCheckOnComplete,
    },
    ref,
  ) => {
    const isComplete = (percentage ?? 0) >= 100;
    return (
      <Styled.Container ref={ref}>
        {showCheckOnComplete && isComplete ? (
          <Styled.IconCircle $isSmall={!!isSmall}>
            <Styled.IconContainer>
              <Icon icon={CheckThick} />
            </Styled.IconContainer>
          </Styled.IconCircle>
        ) : (
          <Styled.Circle $percentage={percentage ?? 0} $isSmall={!!isSmall} />
        )}

        {!hidePercentage && (
          <Styled.Label
            $isPrimary={!!isPrimary}
            $isSecondary={!!isSecondary}
            $isComplete={!!showCheckOnComplete && isComplete}
          >
            {percentage ?? '-'}%
          </Styled.Label>
        )}
      </Styled.Container>
    );
  },
);
