import Typography from '@mui/material/Typography';
import React, { forwardRef, useMemo } from 'react';
import { ChevronLeft, Tag, X } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import { TagSearchMenu } from 'shared/components/ui/tag-search-menu';
import { useForwardedRef } from 'shared/hooks/use-forwarded-ref';
import { useOpenMenu } from 'shared/hooks/use-open-menu';
import { ID } from 'shared/types/id';
import { Tag as TagType } from 'shared/types/tag';

import * as Styled from './form-tags-search.style';

export type FormTagsSearchProps = {
  tags: TagType[];
  value?: ID[] | null;
  onChange: (ids: ID[]) => void;
  placeholder?: string;
};

export const FormTagsSearch = forwardRef<HTMLDivElement, FormTagsSearchProps>(
  ({ tags, value, onChange, placeholder }, ref) => {
    const containerRef = useForwardedRef<HTMLDivElement>(ref);

    const { menuOpen, openMenu, closeMenu } = useOpenMenu();

    const onClear = (e: React.MouseEvent) => {
      e.preventDefault();
      onChange([]);
      closeMenu();
    };

    const selectedTags = useMemo(
      () => tags.filter(({ id }) => value?.includes(id)),
      [tags, value],
    );

    return (
      <Styled.InputContainer ref={containerRef}>
        <Styled.Button
          onClick={openMenu}
          $isPlaceholder={!value?.length}
          type="button"
        >
          {selectedTags.length
            ? selectedTags.map(({ id, name, color }) => (
                <Styled.TagContainer key={id}>
                  <Styled.TagIcon $color={color}>
                    <Icon icon={Tag} />
                  </Styled.TagIcon>
                  <Typography variant="body2">{name}</Typography>
                </Styled.TagContainer>
              ))
            : placeholder}
        </Styled.Button>

        <Styled.InteractionContainer>
          {value?.length ? (
            <Styled.ClearButton onClick={onClear} type="button" tabIndex={-1}>
              <Icon icon={X} />
            </Styled.ClearButton>
          ) : (
            <Styled.IconContainer onClick={openMenu}>
              <Icon icon={ChevronLeft} />
            </Styled.IconContainer>
          )}
        </Styled.InteractionContainer>

        {menuOpen && (
          <TagSearchMenu
            tags={tags}
            onChange={onChange}
            value={value}
            referenceElement={containerRef}
            position="bottom"
            onOutside={closeMenu}
          />
        )}
      </Styled.InputContainer>
    );
  },
);
