import {
  fetchSignInMethodsForEmail,
  OAuthProvider,
  signInWithCredential,
} from 'firebase/auth';
import i18n from 'i18next';
import { jwtDecode } from 'jwt-decode';
import { fallbackLocale, Locales } from 'shared/assets/localization';
import { getLoggableError, getRollbar } from 'shared/services/error-tracking';
import { getNonce } from 'shared/services/local-storage';
import { getSearchParamIdToken } from 'shared/utils/get-search-param-id-token';

import { finishUserSignup } from './finish-user-signup';
import { getUser } from './get-user';
import { getAuthentication } from './helpers';
import { setPushTokenOnUser } from './set-push-token-on-user';
import { setUserCountry } from './set-user-country';

type AppleJwt = {
  iss?: string;
  email: string;
  user?: { firstName: string; lastName: string };
  state?: string;
};

const signInWithApple = async (token: string) => {
  // get the nonce used for sign-in
  const nonce = getNonce();
  const rawNonce = nonce?.raw;

  try {
    // get the email that is signed in with
    const decryptedToken = jwtDecode<AppleJwt>(token);
    const { email, user, state } = decryptedToken;

    const auth = await getAuthentication();

    // check if it is a signup instead of login
    const authProviders = await fetchSignInMethodsForEmail(auth, email);
    const isSignup = !authProviders.includes('apple.com');

    // actually log in
    const provider = new OAuthProvider('apple.com');
    const credential = provider.credential({
      idToken: token,
      rawNonce,
    });

    await signInWithCredential(auth, credential);

    // if signup, finish signup
    if (isSignup) {
      await finishUserSignup({
        uid: auth.currentUser?.uid,
        name: user ? `${user?.firstName} ${user.lastName}` : undefined,
        locale: i18n.language as Locales,
        queryParameters: state ? JSON.parse(state) : undefined,
      });
    }
  } catch (error) {
    getRollbar().error('Error logging in with apple', error as Error, {
      ...getLoggableError(error),
      nonce: nonce?.hashed,
    });
  }
};

export const signInWithToken = async () => {
  const token = getSearchParamIdToken();

  if (!token) {
    return;
  }

  const auth = await getAuthentication();
  // make sure there is no user active
  await auth.signOut();

  try {
    await signInWithApple(token);

    const user = await getUser();

    if (!user) {
      return;
    }

    await setPushTokenOnUser(
      user.uid,
      user.settings?.language || fallbackLocale,
    );
  } catch (error) {
    getRollbar().error(
      'error logging in with apple - set pushTokenOnUser',
      getLoggableError(error),
    );
  }

  try {
    await setUserCountry();
  } catch (error) {
    getRollbar().error(
      'setting country on user didnt work',
      getLoggableError(error),
    );
  }
};
