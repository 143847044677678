import Typography from '@mui/material/Typography';
import { isSameDay } from 'date-fns';
import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Archive,
  CheckCircle,
  DotsMenu,
  IconFormNames,
  Trash2,
} from 'shared/assets/icons';
import { Button, Colors, Sizes, Variants } from 'shared/components/ui/button';
import { CustomFormSelect } from 'shared/components/ui/custom-form-select';
import { FormDialogHeader } from 'shared/components/ui/form-dialog-header';
import { FormIcon } from 'shared/components/ui/form-icon';
import { FormInput } from 'shared/components/ui/form-input';
import { FormLinkedItemSearch } from 'shared/components/ui/form-linked-item-search';
import { FormTagsSearch } from 'shared/components/ui/form-tags-search/form-tags-search';
import { CompletionPerDayInput } from 'shared/components/ui/habit-form/completion-per-day-input';
import { CompletionPerTapInput } from 'shared/components/ui/habit-form/completion-per-tap-input';
import { HideOnTodoToggle } from 'shared/components/ui/hide-on-todo-toggle';
import { Icon } from 'shared/components/ui/icon';
import {
  PopupMenu,
  PopupMenuButton,
  PopupMenuList,
  PopupMenuListItem,
} from 'shared/components/ui/popup-menu';
import { TaskTypeToggle } from 'shared/components/ui/task-type-toggle';
import { TimePicker } from 'shared/components/ui/time-picker';
import { reminderTimes } from 'shared/constants';
import { useToday } from 'shared/contexts/today';
import { useForwardedRef } from 'shared/hooks/use-forwarded-ref';
import { useOpenMenu } from 'shared/hooks/use-open-menu';
import { DateFormatOptions } from 'shared/types/date-format-options';
import { Goal } from 'shared/types/goal';
import { HabitFormFields } from 'shared/types/habit-form';
import { HabitSchedule } from 'shared/types/habit-schedule';
import { ID } from 'shared/types/id';
import { LifeArea } from 'shared/types/life-area';
import { Tag } from 'shared/types/tag';
import { LinkOptions } from 'shared/types/task';
import { TaskPriorityOptions, TaskType } from 'shared/types/task-base';
import { WeekDays } from 'shared/types/week-days';
import { getActiveSchedule } from 'shared/utils/get-active-schedule';

import * as Styled from './habit-form.style';
import { RepeatFormInput } from './repeat-form-input';
import { ScheduleStartDateInput } from './schedule-start-date-input';
import { useHabitForm } from './use-habit-form';

const defaultInitialValues: HabitFormFields = {
  name: '',
  type: TaskType.Habit,
  iconName: IconFormNames.Repeat,
  goalId: null,
};

export type HabitFormProps = {
  initialValues?: Partial<HabitFormFields>;
  goals: Goal[];
  lifeAreas: LifeArea[];
  tags: Tag[];
  dateFormat: DateFormatOptions;
  weekStartsOn: WeekDays;
  isEdit?: boolean;
  isActive?: boolean;
  isCustomIconAllowed?: boolean;
  isCompleted?: boolean;
  isArchived?: boolean;
  isRepeatTargetAllowed: boolean;
  isHabitCreationAllowed: boolean;
  onSubmit: (habit: HabitFormFields) => void;
  onCancel: () => void;
  onPremium: () => void;
  onComplete?: () => void;
  onArchive?: () => void;
  onDelete?: () => void;
};

export const HabitForm: React.FC<HabitFormProps> = ({
  initialValues,
  goals,
  lifeAreas,
  tags,
  dateFormat,
  weekStartsOn,
  isEdit,
  isActive,
  isCustomIconAllowed,
  isCompleted,
  isArchived,
  isRepeatTargetAllowed,
  isHabitCreationAllowed,
  onSubmit,
  onCancel,
  onPremium,
  onComplete,
  onArchive,
  onDelete,
}) => {
  const { t } = useTranslation();
  const today = useToday();
  const {
    register,
    onSubmit: submitForm,
    isValid,
    errors,
    watch,
    setValue,
    trigger,
  } = useHabitForm(onSubmit, {
    ...defaultInitialValues,
    ...(initialValues ?? {}),
    iconName: initialValues?.iconName ?? defaultInitialValues.iconName,
  });
  const { menuOpen, openMenu, closeMenu } = useOpenMenu();
  const editButtonRef = useRef<HTMLDivElement>(null);
  const { ref: nameRefCallback, ...nameProps } = register('name');
  const nameRef = useForwardedRef(nameRefCallback);

  const iconValue = watch<'iconName'>('iconName');
  const onChangeIcon = (iconName: IconFormNames) =>
    setValue<'iconName'>('iconName', iconName);

  const scheduleValue = watch<'schedules'>('schedules');
  const activeSchedule = useMemo(
    () => getActiveSchedule(scheduleValue),
    [scheduleValue],
  );
  const shouldReplaceSchedule = useMemo(
    () =>
      activeSchedule?.startDate && isSameDay(activeSchedule?.startDate, today),
    [activeSchedule?.startDate, today],
  );

  const onChangeSchedule = (schedules: HabitSchedule[] | null) => {
    setValue<'schedules'>('schedules', schedules ?? undefined);
    trigger('schedules');
  };

  const typeValue = watch<'type'>('type');
  const onChangeType = (type: TaskType.Habit | TaskType.Repeating) => {
    if (type === TaskType.Habit && !isHabitCreationAllowed) {
      onPremium();
      return;
    }

    if (type === TaskType.Repeating) {
      setValue<'hideOnTodo'>('hideOnTodo', false);
    }

    setValue<'type'>('type', type);
    trigger('type');
  };

  const hideOnTodoValue = watch<'hideOnTodo'>('hideOnTodo');
  const onChangeHideOnTodo = (hideOnTodo: boolean) => {
    setValue<'hideOnTodo'>('hideOnTodo', hideOnTodo);
    trigger('hideOnTodo');
  };

  const reminderTime = watch<'reminderTime'>('reminderTime');
  const onChangeReminderTime = (time?: number) => {
    setValue<'reminderTime'>('reminderTime', time ?? null);
    trigger('reminderTime');
  };

  const priorityOptions = useMemo(
    () =>
      Object.values(TaskPriorityOptions).map((priority) => ({
        label: t(`task.priority.options.${priority}.label`),
        value: priority,
      })),
    [t],
  );

  const priority = watch<'priority'>('priority');
  const onChangePriority = (priority?: string) => {
    setValue<'priority'>('priority', (priority as TaskPriorityOptions) ?? null);
    trigger('priority');
  };

  const goalId = watch<'goalId'>('goalId');
  const lifeAreaId = watch<'lifeAreaId'>('lifeAreaId');
  const onChangeLinkedItem = (link: { id: ID; type: LinkOptions } | null) => {
    const goalId = link?.type === LinkOptions.Goal ? link.id : null;
    const lifeAreaId = link?.type === LinkOptions.LifeArea ? link.id : null;
    setValue<'goalId'>('goalId', goalId);
    setValue<'lifeAreaId'>('lifeAreaId', lifeAreaId);
    trigger('goalId');
    trigger('lifeAreaId');
  };

  const tagIds = watch<'tagIds'>('tagIds');
  const onChangeTagIds = (ids: ID[]) => {
    setValue<'tagIds'>('tagIds', ids);
    trigger('tagIds');
  };

  const completeHabit = () => {
    onComplete?.();
    closeMenu();
  };

  const archiveHabit = () => {
    onArchive?.();
    closeMenu();
  };

  const deleteHabit = () => {
    onDelete?.();
    closeMenu();
  };

  useEffect(() => {
    if (nameRef.current && !isEdit && isActive) {
      nameRef.current.focus();
    }
  }, [isActive, isEdit, nameRef]);

  return (
    <>
      <Styled.Form noValidate onSubmit={submitForm}>
        <FormDialogHeader
          onCancel={onCancel}
          title={t(
            isEdit ? 'forms.habit.edit.title' : 'forms.habit.create.title',
          )}
          canSubmit={isValid}
        />

        <Styled.Body>
          <Styled.Section>
            <Styled.Label>{t('forms.habit.fields.name.label')}</Styled.Label>
            <FormInput
              {...nameProps}
              ref={nameRef}
              placeholder={t('forms.habit.fields.name.placeholder')}
              error={errors.name?.message}
              autoComplete="off"
            />

            <Styled.Label>{t('forms.habit.fields.icon.label')}</Styled.Label>
            <FormIcon
              onChange={onChangeIcon}
              value={iconValue!}
              isPremium={!isCustomIconAllowed}
              onPremium={onPremium}
            />
          </Styled.Section>

          <Styled.Section>
            <Styled.FullSectionLine>
              <Typography variant="h6" component="h2">
                {t('forms.habit.sections.schedule.title')}
              </Typography>
            </Styled.FullSectionLine>

            <Styled.Label>{t('forms.habit.fields.repeat.label')}</Styled.Label>
            <RepeatFormInput
              onChange={onChangeSchedule}
              value={scheduleValue ?? []}
              type={typeValue}
              onChangeType={onChangeType}
              weekStartsOn={weekStartsOn}
              targetAllowed={isRepeatTargetAllowed}
              createHabitAllowed={isHabitCreationAllowed}
              onPremium={onPremium}
              error={errors.schedules?.message}
              shouldReplaceSchedule={shouldReplaceSchedule}
            />

            {!!activeSchedule && !activeSchedule?.frequency && (
              <>
                <Styled.Label>
                  {t('forms.habit.fields.completions-per-day.label')}
                </Styled.Label>
                <CompletionPerDayInput
                  schedules={scheduleValue ?? []}
                  onChange={onChangeSchedule}
                  shouldReplaceSchedule={shouldReplaceSchedule}
                />
              </>
            )}

            <Styled.Label>
              {t('forms.habit.fields.start-date.label')}
            </Styled.Label>
            <ScheduleStartDateInput
              today={today}
              schedules={scheduleValue ?? []}
              weekStartsOn={weekStartsOn}
              dateFormat={dateFormat}
              onChange={onChangeSchedule}
            />

            {!!activeSchedule &&
              (activeSchedule.frequency ||
                activeSchedule.completionTarget.count > 1) && (
                <>
                  <CompletionPerTapInput
                    schedules={scheduleValue ?? []}
                    onChange={onChangeSchedule}
                    shouldReplaceSchedule
                  />
                </>
              )}
          </Styled.Section>

          <Styled.Section>
            <Styled.FullSectionLine>
              <Typography variant="h6" component="h2">
                {t('forms.habit.sections.settings.title')}
              </Typography>
            </Styled.FullSectionLine>

            <Styled.Label>
              {t('forms.habit.fields.reminder-time.label')}
            </Styled.Label>
            <TimePicker
              options={reminderTimes}
              value={reminderTime}
              onChange={onChangeReminderTime}
              placeholder={t('forms.habit.fields.reminder-time.placeholder')}
              showAsFormInput
            />
            <Styled.Label>
              {t('forms.habit.fields.priority.label')}
            </Styled.Label>
            <CustomFormSelect
              value={priority ?? undefined}
              placeholder={t('forms.habit.fields.priority.placeholder')}
              options={priorityOptions}
              onChange={onChangePriority}
              canClear
            />

            <Styled.Label>{t('forms.habit.fields.link-to.label')}</Styled.Label>
            <FormLinkedItemSearch
              goals={goals}
              lifeAreas={lifeAreas}
              value={goalId ?? lifeAreaId}
              onChange={onChangeLinkedItem}
              placeholder={t('forms.habit.fields.link-to.placeholder')}
            />

            <Styled.Label>{t('forms.habit.fields.tags.label')}</Styled.Label>
            <FormTagsSearch
              tags={tags}
              value={tagIds}
              onChange={onChangeTagIds}
              placeholder={t('forms.habit.fields.tags.placeholder')}
            />
          </Styled.Section>

          <Styled.Section>
            <Styled.FullSectionLine>
              <Typography variant="h6" component="h2">
                {t('forms.habit.sections.show-on.title')}
              </Typography>
            </Styled.FullSectionLine>
            <Styled.FullSectionLine $hasSectionLineHeight>
              <TaskTypeToggle
                type={typeValue}
                onChange={onChangeType}
                fullWidth
              />
            </Styled.FullSectionLine>
            <Styled.FullSectionLine $hasSectionLineHeight>
              <HideOnTodoToggle
                value={!!hideOnTodoValue}
                onChange={onChangeHideOnTodo}
                disabled={typeValue !== TaskType.Habit}
                fullWidth
              />
            </Styled.FullSectionLine>
          </Styled.Section>
        </Styled.Body>

        <Styled.Interactions>
          {!!isEdit && (
            <Styled.EditButtonContainer ref={editButtonRef}>
              <Button
                variant={Variants.Outlined}
                size={Sizes.Medium}
                endIcon={DotsMenu}
                color={Colors.Inherit}
                onClick={openMenu}
                type="button"
              >
                {t('forms.habit.buttons.more-options.label')}
              </Button>
              {menuOpen && (!!onComplete || !!onArchive || !!onDelete) && (
                <PopupMenu
                  referenceElement={editButtonRef}
                  position="top-start"
                  onOutside={closeMenu}
                >
                  {!!onComplete && (
                    <PopupMenuList hasBottomBorder={!!onArchive || !!onDelete}>
                      <PopupMenuListItem>
                        <PopupMenuButton
                          onClick={completeHabit}
                          start={<Icon icon={CheckCircle} />}
                        >
                          {t(
                            isCompleted
                              ? 'forms.habit.context-menu.un-complete.label'
                              : 'forms.habit.context-menu.complete.label',
                          )}
                        </PopupMenuButton>
                      </PopupMenuListItem>
                    </PopupMenuList>
                  )}
                  {(!!onArchive || !!onDelete) && (
                    <PopupMenuList>
                      {!!onArchive && (
                        <PopupMenuListItem>
                          <PopupMenuButton
                            onClick={archiveHabit}
                            start={<Icon icon={Archive} />}
                          >
                            {t(
                              isArchived
                                ? 'forms.habit.context-menu.de-archive.label'
                                : 'forms.habit.context-menu.archive.label',
                            )}
                          </PopupMenuButton>
                        </PopupMenuListItem>
                      )}
                      {!!onDelete && (
                        <PopupMenuListItem>
                          <PopupMenuButton
                            onClick={deleteHabit}
                            start={<Icon icon={Trash2} />}
                          >
                            {t('forms.habit.context-menu.delete.label')}
                          </PopupMenuButton>
                        </PopupMenuListItem>
                      )}
                    </PopupMenuList>
                  )}
                </PopupMenu>
              )}
            </Styled.EditButtonContainer>
          )}

          <Styled.FormButtons>
            <Button
              variant={Variants.Outlined}
              size={Sizes.Medium}
              onClick={onCancel}
              type="button"
            >
              {t('forms.habit.buttons.cancel.label')}
            </Button>
            <Button size={Sizes.Medium} type="submit" disabled={!isValid}>
              {t('forms.habit.buttons.save.label')}
            </Button>
          </Styled.FormButtons>
        </Styled.Interactions>
      </Styled.Form>
    </>
  );
};
