import { FirestoreDataConverter, serverTimestamp } from 'firebase/firestore';
import { MinimalRelationship, Relationship } from 'shared/types/relationship';

import { firebaseTimestampToDate } from './firebase-timestamp-to-date';

export const relationshipConverter: FirestoreDataConverter<Relationship> = {
  fromFirestore: (snapshot) => {
    const data = snapshot.data();
    return {
      ...data,
      createdAt: firebaseTimestampToDate(data.createdAt),
      updatedAt: firebaseTimestampToDate(data.updatedAt),
    } as Relationship;
  },
  toFirestore: (relationship) => relationship,
};

export const newRelationshipConverter = (
  relationship: MinimalRelationship,
) => ({
  ...relationship,
  createdAt: serverTimestamp(),
  updatedAt: serverTimestamp(),
});
