import styled, { css } from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};

  padding-left: ${({ theme }) => theme.spacing(0.5)};
  padding-right: ${({ theme }) => theme.spacing(3)};

  ${({ theme }) =>
    theme.isMobile &&
    css`
      flex-direction: row-reverse;
      padding: ${`${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(1)} ${theme.spacing(3)}`};
    `};
`;

export const IconButtonContainer = styled.span`
  color: ${({ theme }) => theme.palette.grey['400']};
  line-height: 62.5%;
`;

export const ChildrenContainer = styled.div`
  padding-top: ${({ theme }) => theme.spacing(1.5)};
`;
