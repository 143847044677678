import React from 'react';
import { Icon } from 'shared/components/ui/icon';
import { formatNumberShort } from 'shared/utils/format-number-short';
import { isNumber } from 'shared/utils/is-number';

import * as Styled from './statistic-card.style';
import { Colors } from './types';

export type StatisticCardProps = {
  value?: number;
  target?: number;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  iconColor?: Colors;
  description?: string;
  isPercentage?: boolean;
  isFormatted?: boolean;
};

export const StatisticCard: React.FC<StatisticCardProps> = ({
  value,
  target,
  icon,
  iconColor = Colors.Primary,
  description,
  isPercentage,
  isFormatted,
}) => (
  <Styled.Container>
    <Styled.Header>
      <Styled.Data>
        <Styled.Value>
          {`${value ? (isFormatted ? formatNumberShort(value) : value) : '-'}${isPercentage && isNumber(value) ? '%' : ''}`}
        </Styled.Value>
        {isNumber(target) && (
          <Styled.Target>
            / {isFormatted ? formatNumberShort(target!) : target}
          </Styled.Target>
        )}
      </Styled.Data>

      <Styled.IconContainer $color={iconColor}>
        <Icon icon={icon} />
      </Styled.IconContainer>
    </Styled.Header>

    <Styled.Body>
      {!!description && <Styled.Description>{description}</Styled.Description>}
    </Styled.Body>
  </Styled.Container>
);
