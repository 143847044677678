import { useLocalizedLifeAreas } from 'features/life-areas';
import { useMemo } from 'react';
import { useUser } from 'shared/hooks/use-user';
import { lifeAreaOtherID } from 'shared/types/orderable-section';
import { sortItems } from 'shared/utils/sort-items';

export const useLifeAreaSections = () => {
  const user = useUser();

  const lifeAreas = useLocalizedLifeAreas();

  return useMemo(
    () => [
      ...sortItems(lifeAreas, user?.settings?.lifeAreaSorting ?? [], 'id').map(
        (lifeArea) => ({
          id: lifeArea.id,
          lifeArea,
          items: [],
        }),
      ),
      {
        id: lifeAreaOtherID,
        lifeArea: null,
        items: [],
      },
    ],
    [lifeAreas, user],
  );
};
