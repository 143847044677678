import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Heart, IconFormIcons } from 'shared/assets/icons';
import { ListSectionLifeAreaHeader } from 'shared/components/ui/section-header-life-area';
import { ID } from 'shared/types/id';
import { LifeArea } from 'shared/types/life-area';

export type LifeAreaHeaderProps = {
  lifeAreaId?: ID;
  lifeAreas: LifeArea[];
};

export const LifeAreaHeader: React.FC<LifeAreaHeaderProps> = React.memo(
  ({ lifeAreaId, lifeAreas }) => {
    const { t } = useTranslation();
    const lifeArea = useMemo(
      () => lifeAreas.find(({ id }) => id === lifeAreaId),
      [lifeAreas, lifeAreaId],
    );

    const lifeAreaName =
      lifeArea?.name ?? t('general.sections.life-areas.other.title');

    return (
      <ListSectionLifeAreaHeader
        id={lifeAreaId}
        icon={lifeArea ? IconFormIcons[lifeArea.iconName] : Heart}
        name={lifeAreaName}
        color={lifeArea?.color ?? undefined}
        isFrozen={!!lifeArea?.frozenAt}
        image={lifeArea?.visionPreview?.image}
      />
    );
  },
);
