import { useGoals } from 'features/goals';
import { useLocalizedLifeAreas } from 'features/life-areas';
import { useInboxTodos } from 'features/tasks';
import { useUpdateInboxTaskOrder } from 'features/user';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';
import { useActiveTaskId } from 'shared/contexts/task-detail';
import { useToday } from 'shared/contexts/today';
import { useUser } from 'shared/hooks/use-user';
import { SortingMode } from 'shared/types/sorting-mode';
import { getSortedTasks } from 'shared/utils/get-sorted-tasks';
import { getWeekStartsOn } from 'shared/utils/get-week-starts-on';
import { mapTaskToTaskCard } from 'shared/utils/mappers/map-task-to-task-card';
import { SelfSortingTaskCardTaskSection } from 'src/shared/components/ui/task-card-task-section';

export const TasksList: React.FC = () => {
  const { t } = useTranslation();
  const user = useUser();
  const today = useToday();
  const selectedTask = useActiveTaskId();

  const goals = useGoals();
  const lifeAreas = useLocalizedLifeAreas();
  const tasks = useInboxTodos();

  const { updateOrder } = useUpdateInboxTaskOrder();
  const openPremiumDialog = useOpenPremiumDialog();

  const taskCardTasks = useMemo(() => {
    const sortedTasks = getSortedTasks(tasks, {
      today,
      order: user?.settings?.inboxSorting ?? [],
      mode: [SortingMode.Custom],
      weekStartsOn: getWeekStartsOn(user),
    });

    return sortedTasks.map((task) =>
      mapTaskToTaskCard(task, {
        goals,
        today,
        t,
        showDue: true,
        weekStartsOn: getWeekStartsOn(user),
      }),
    );
  }, [goals, t, tasks, today, user]);

  return (
    <SelfSortingTaskCardTaskSection
      tasks={taskCardTasks}
      goals={goals}
      lifeAreas={lifeAreas}
      onReorder={updateOrder}
      selectedTask={selectedTask}
      onPremium={openPremiumDialog}
      canCreateTask
    />
  );
};
