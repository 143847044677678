import styled from 'styled-components';

export const Container = styled.div``;

export const PremiumBannerContainer = styled.div`
  padding: ${({ theme }) => `0 ${theme.spacing(3)} 0 ${theme.spacing(6)}`};
`;

export const FrozenListContainer = styled.div`
  padding: ${({ theme }) => `${theme.spacing(2)} 0 0`};
`;
