import { DefaultError, useMutation } from '@tanstack/react-query';
import { updateTodayTypeTaskOrder } from 'shared/services/backend-api';
import { ID } from 'shared/types/id';
import { TypeSections } from 'shared/types/page-sections';

export const useUpdateTodayTypeTaskOrderMutation = () =>
  useMutation<void, DefaultError, { typeSection: TypeSections; taskIds: ID[] }>(
    {
      mutationFn: async ({ typeSection, taskIds }) =>
        updateTodayTypeTaskOrder(typeSection, taskIds),
    },
  );
