import { typographyToCss } from 'shared/lib/mui';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(5)};
  min-width: 20.8rem;

  border: ${({ theme }) => `1px solid ${theme.palette.grey['100']}`};
  border-radius: ${({ theme }) => theme.spacing(3)};
`;

export const Button = styled.button`
  ${({ theme }) => typographyToCss(theme.typography.buttonSmall)};

  flex: 1;

  padding: 0;
  margin: 0;
  height: 3.2rem;

  border: none;
  background: transparent;

  text-align: center;
  color: ${({ theme }) => theme.palette.text.primary};

  ${({ as }) =>
    !as &&
    css`
      cursor: pointer;
      border-radius: ${({ theme }) => theme.spacing(2)};
    `};
`;
