import React, { useMemo } from 'react';
import { TaskDetailCheckbox } from 'shared/components/ui/task-detail-checkbox';
import { useActiveSchedule } from 'shared/hooks/use-active-schedule';
import {
  ScheduleCompletionActions,
  ScheduleEntryState,
} from 'shared/types/habit-schedule';
import { Task, TaskCheckOptions } from 'shared/types/task';
import { Timestamp } from 'shared/types/timestamp';
import { WeekDays } from 'shared/types/week-days';
import { getScheduleFrequencyDayTarget } from 'shared/utils/get-schedule-frequency-day-target';
import { getTaskCheckOption } from 'shared/utils/get-task-check-option';
import { getTaskDayCompletionPercentage } from 'shared/utils/get-task-day-completion-percentage';

import * as Styled from './task-card-header.style';
import { TaskCardTitle } from './task-card-title';

export type TaskCardHeaderProps = {
  task: Task;
  onComplete: () => void;
  onEntryChange: (
    type: ScheduleEntryState,
    options?: { value?: number; increment?: number },
  ) => void;
  onEntryManual?: () => void;

  onChangeName: (name: string) => void;
  onClickName?: (e: React.MouseEvent) => void;
  nameInputRef?: React.RefObject<HTMLTextAreaElement>;

  showRepeatingAsTodo?: boolean;
  showRepeatingAsRepeatingOnEntry?: boolean;
  isDisabled?: boolean;
  weekStartsOn: WeekDays;
  today: Timestamp;
};

export const TaskCardHeader: React.FC<TaskCardHeaderProps> = ({
  task,
  onComplete,
  onEntryChange,
  onEntryManual,

  onChangeName,
  onClickName,
  nameInputRef,

  showRepeatingAsTodo,
  showRepeatingAsRepeatingOnEntry,
  isDisabled,
  weekStartsOn,
  today,
}) => {
  const activeSchedule = useActiveSchedule(task.schedules);
  const checked = useMemo(
    () =>
      getTaskCheckOption(task, {
        today,
        showRepeatingAsTodo,
        showRepeatingAsRepeatingOnEntry,
        weekStartsOn,
      }),
    [
      showRepeatingAsRepeatingOnEntry,
      showRepeatingAsTodo,
      task,
      today,
      weekStartsOn,
    ],
  );

  const completionPercentage = useMemo(
    () =>
      task.schedules?.length
        ? getTaskDayCompletionPercentage(task, { day: today, weekStartsOn })
        : undefined,
    [task, today, weekStartsOn],
  );

  const target = useMemo(() => {
    if (!activeSchedule) {
      return;
    }

    return activeSchedule.frequency
      ? getScheduleFrequencyDayTarget(activeSchedule)
      : activeSchedule.completionTarget.count;
  }, [activeSchedule]);

  const _onComplete = () =>
    checked !== TaskCheckOptions.Checked &&
    activeSchedule?.action?.type ===
      ScheduleCompletionActions.LogCompletionValue &&
    onEntryManual
      ? onEntryManual()
      : onComplete();

  return (
    <Styled.Header>
      <TaskDetailCheckbox
        checked={checked}
        completionPercentage={completionPercentage}
        target={target}
        priority={task.priority}
        isFrozen={!!task.frozenAt}
        onChange={_onComplete}
        onContextChange={onEntryChange}
        onContextManual={onEntryManual}
        isDisabled={isDisabled}
      />

      <Styled.HeaderContent $isFaded={isDisabled}>
        <Styled.TitleContainer $isFaded={!!task.frozenAt}>
          <TaskCardTitle
            ref={nameInputRef}
            value={task.name}
            onChange={onChangeName}
            onClick={onClickName}
            isDisabled={isDisabled || !!task.frozenAt}
          />
        </Styled.TitleContainer>

        {task.description && (
          <Styled.Description>{task.description}</Styled.Description>
        )}
      </Styled.HeaderContent>
    </Styled.Header>
  );
};
