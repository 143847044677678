import { useFavoriteIds, useToggleFavorite } from 'features/user';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Edit3, Star } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import {
  PopupMenuButton,
  PopupMenuList,
  PopupMenuListItem,
} from 'shared/components/ui/popup-menu';
import { useOpenEditGoalDialog } from 'shared/contexts/goal-form';
import { Goal } from 'shared/types/goal';
import { FavoriteType } from 'shared/types/user-settings';

export type EditListProps = {
  goal: Goal;
  onClose: () => void;
};

export const EditList: React.FC<EditListProps> = ({ goal, onClose }) => {
  const { t } = useTranslation();

  const favoriteIds = useFavoriteIds();
  const isFavorite = useMemo(
    () => favoriteIds.includes(goal.id),
    [favoriteIds, goal.id],
  );

  const openEditDialog = useOpenEditGoalDialog();
  const toggleFavorite = useToggleFavorite();

  const onEditGoal = (e: React.MouseEvent) => {
    e.preventDefault();
    openEditDialog(goal, goal.id);
    onClose();
  };
  const onFavorite = (e: React.MouseEvent) => {
    e.preventDefault();
    toggleFavorite(goal.id, FavoriteType.Goal);
    onClose();
  };

  return (
    <PopupMenuList hasBottomBorder>
      {!goal?.frozenAt && !goal?.archivedAt && (
        <PopupMenuListItem>
          <PopupMenuButton onClick={onEditGoal} start={<Icon icon={Edit3} />}>
            {t('context-menus.goal.labels.edit')}
          </PopupMenuButton>
        </PopupMenuListItem>
      )}
      <PopupMenuListItem>
        <PopupMenuButton onClick={onFavorite} start={<Icon icon={Star} />}>
          {t(
            isFavorite
              ? 'context-menus.goal.labels.remove-favorite'
              : 'context-menus.goal.labels.add-favorite',
          )}
        </PopupMenuButton>
      </PopupMenuListItem>
    </PopupMenuList>
  );
};
