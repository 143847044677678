import React from 'react';
import { GridContainer } from 'shared/components/ui/grid-container';

import { ActiveFilterBanner } from './components/active-filter-banner';
import { Header } from './components/header';
import { LifeAreaGroupingTasksList } from './components/life-area-grouping-tasks-list';

export const AllTasks: React.FC = () => (
  <GridContainer>
    <Header />

    <ActiveFilterBanner />

    <LifeAreaGroupingTasksList />
  </GridContainer>
);
