import { useCallback, useRef } from 'react';
import { useCreateTagMutation } from 'shared/hooks/queries/use-create-tag-mutation';
import { useTrackEvents } from 'shared/hooks/use-track-events';
import { ActionEvents } from 'shared/services/tracking';
import { NewTag, Tag } from 'shared/types/tag';

export const useCreateTag = () => {
  const track = useTrackEvents();
  const createTagRef = useRef<NewTag>();
  const {
    mutateAsync: createTag,
    error,
    isPending,
    isSuccess,
    reset,
  } = useCreateTagMutation();

  const submit = useCallback(
    async (tag: NewTag) => {
      createTagRef.current = tag;
      const { id } = await createTag(tag);

      track(ActionEvents.TagCreate, undefined);

      return {
        ...tag,
        id,
      } as Tag;
    },
    [createTag, track],
  );

  const retry = useCallback(async () => {
    const tag = createTagRef.current;

    if (tag) {
      const { id } = await createTag(tag);

      track(ActionEvents.TagCreate, undefined);

      return {
        ...tag,
        id,
      } as Tag;
    }
  }, [createTag, track]);

  const resetAll = useCallback(() => {
    reset();
    createTagRef.current = undefined;
  }, [reset]);

  return {
    submit,
    retry,
    reset: resetAll,
    error,
    isLoading: isPending,
    isSuccess,
  };
};
