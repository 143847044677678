import { useTags } from 'features/tags';
import { useMemo } from 'react';
import { ID } from 'shared/types/id';

export const useTagById = (id?: ID) => {
  const tags = useTags();

  return useMemo(
    () => (id ? tags.find((tag) => tag.id === id) : undefined),
    [id, tags],
  );
};
