import { endOfMonth, isSameDay, isSameMonth, startOfMonth } from 'date-fns';
import React, { useMemo } from 'react';
import {
  HabitProgressCalendar,
  SurroundingMonths,
} from 'shared/components/ui/habit-progress-calendar';
import { isBetween } from 'shared/lib/date-fns';
import {
  HabitSchedule,
  ScheduleEntryState,
  ScheduleTargetEntry,
} from 'shared/types/habit-schedule';
import { Timeframe } from 'shared/types/timeframe';
import { Timestamp } from 'shared/types/timestamp';
import { WeekDays } from 'shared/types/week-days';

export type TimeframeMonthProps = {
  referenceDate: Timestamp;
  dataStartDate: Timestamp;
  dataEndDate: Timestamp;
  occurrences: Timestamp[];
  dateEntries: ScheduleTargetEntry[];
  perfectSlots?: Timeframe[];
  scheduleChanges?: {
    date: Timestamp;
    oldSchedule: HabitSchedule;
    newSchedule: HabitSchedule;
  }[];
  target?: number;
  weekTarget?: number;
  weekStartsOn: WeekDays;
  onDate?: (
    date: Timestamp,
    type: ScheduleEntryState,
    options?: { value?: number; increment?: number },
  ) => void;
  onDateManual: (date: Timestamp) => void;
  onInfo?: () => void;
  showCompletionPercentage?: boolean;
};

export const TimeframeMonth: React.FC<TimeframeMonthProps> = ({
  referenceDate,
  dataStartDate,
  dataEndDate,
  occurrences,
  dateEntries,
  perfectSlots,
  scheduleChanges,
  target,
  weekTarget,
  weekStartsOn,
  onDate,
  onDateManual,
  onInfo,
  showCompletionPercentage,
}) => {
  const dataOccurrences = useMemo(
    () =>
      occurrences.filter((occurrence) =>
        isBetween(occurrence, dataStartDate, dataEndDate),
      ),
    [dataEndDate, dataStartDate, occurrences],
  );

  const dataDateEntries = useMemo(
    () =>
      dateEntries.filter(({ date }) =>
        isBetween(date, dataStartDate, dataEndDate),
      ),
    [dateEntries, dataEndDate, dataStartDate],
  );

  const monthCompletionsCount = useMemo(
    () =>
      dateEntries
        .filter(
          ({ state, date }) =>
            state === ScheduleEntryState.Complete &&
            isBetween(
              date,
              startOfMonth(referenceDate),
              endOfMonth(referenceDate),
            ),
        )
        .reduce((acc, { value }) => acc + (value ?? 0), 0),
    [dateEntries, referenceDate],
  );

  const monthTarget = useMemo(
    () =>
      target ??
      (dataDateEntries.reduce((acc, entry) => acc + (entry.target ?? 0), 0) ||
        1),
    [dataDateEntries, target],
  );

  const monthFailures = useMemo(
    () =>
      dateEntries.filter(
        ({ state, date }) =>
          state === ScheduleEntryState.Failure &&
          isBetween(
            date,
            startOfMonth(referenceDate),
            endOfMonth(referenceDate),
          ),
      ),
    [referenceDate, dateEntries],
  );

  const completionPercentage = useMemo(() => {
    const percentage = showCompletionPercentage
      ? Math.round((monthCompletionsCount / monthTarget) * 100)
      : undefined;

    return percentage && percentage >= 10 ? Math.round(percentage) : percentage;
  }, [monthCompletionsCount, monthTarget, showCompletionPercentage]);

  const monthScheduleChanges = useMemo(
    () =>
      scheduleChanges?.filter(({ date }) => isSameMonth(date, referenceDate)),
    [referenceDate, scheduleChanges],
  );

  return (
    <HabitProgressCalendar
      openToDate={referenceDate}
      occurrences={dataOccurrences}
      dateEntries={dataDateEntries}
      completionCount={monthCompletionsCount}
      failureCount={monthFailures.length}
      completionPercentage={completionPercentage}
      weekStartsOn={weekStartsOn}
      weekTarget={weekTarget}
      perfectSlots={perfectSlots}
      scheduleChanges={monthScheduleChanges}
      showSurroundingMonths={
        [
          !isSameDay(dataStartDate, startOfMonth(referenceDate))
            ? SurroundingMonths.Before
            : undefined,
          !isSameDay(dataEndDate, endOfMonth(referenceDate))
            ? SurroundingMonths.After
            : undefined,
        ].filter(Boolean) as SurroundingMonths[]
      }
      onChange={onDate}
      onDateManual={onDateManual}
      onInfo={onInfo}
      showDates
      showHeader
      isCard
    />
  );
};
