import { useGoals } from 'features/goals';
import { useLocalizedLifeAreas } from 'features/life-areas';
import { useTypeTodayReorder } from 'features/user';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';
import { useActiveTaskId } from 'shared/contexts/task-detail';
import { useToday } from 'shared/contexts/today';
import { ID } from 'shared/types/id';
import { TypeSections } from 'shared/types/page-sections';
import { DueDisplayOptions } from 'shared/types/task-base';
import { SelfSortingTaskCardTaskSection } from 'src/shared/components/ui/task-card-task-section';

import { useTypeTaskSections } from '../../hooks/use-type-task-sections';
import { SectionHeaderToday } from '../section-header-today';
import * as Styled from './type-grouping-tasks-list.style';

const displayDue = [DueDisplayOptions.Past, DueDisplayOptions.Future];

export const TypeGroupingTasksList: React.FC = () => {
  const { t } = useTranslation();
  const today = useToday();
  const { sections } = useTypeTaskSections();
  const reorderType = useTypeTodayReorder();

  const selectedTask = useActiveTaskId();

  const goals = useGoals();
  const lifeAreas = useLocalizedLifeAreas();

  const openPremiumDialog = useOpenPremiumDialog();

  return (
    <Styled.Container>
      {sections.map(({ id, items }) => {
        const onReorder = (ids: ID[]) => reorderType(id, ids);

        return (
          <Styled.Section key={id}>
            <SelfSortingTaskCardTaskSection
              goals={goals}
              lifeAreas={lifeAreas}
              tasks={items}
              selectedTask={selectedTask}
              date={today}
              displayDue={displayDue}
              customHeader={
                <SectionHeaderToday
                  title={t(`general.sections.type.${id}.title`)}
                  titleCount={items.length}
                />
              }
              onReorder={onReorder}
              canCreateTask={id !== TypeSections.Habits}
              canCreateHabit={id === TypeSections.Habits}
              onPremium={openPremiumDialog}
              canCollapse
            />
          </Styled.Section>
        );
      })}
    </Styled.Container>
  );
};
