import {
  and,
  orderBy,
  query,
  QueryFieldFilterConstraint,
  where,
} from 'firebase/firestore';
import { ID } from 'shared/types/id';

import { tagConverter } from './converters/tag';
import { CollectionOptions, getAuthentication, getCollection } from './helpers';

export type Options = {
  ids?: ID[];
};

export const getTagsQuery = async (options: Options = {}) => {
  const auth = await getAuthentication();

  const conditions = [
    where('roles.all', 'array-contains', auth.currentUser?.uid),

    !!options.ids?.length && where('id', 'in', options.ids),
  ].filter(Boolean) as QueryFieldFilterConstraint[];

  return query(
    getCollection(CollectionOptions.Tags).withConverter(tagConverter),
    and(...conditions),
    orderBy('createdAt', 'asc'),
  );
};
