import { useCallback, useRef } from 'react';
import { useUpdateTagMutation } from 'shared/hooks/queries/use-update-tag-mutation';
import { Tag } from 'shared/types/tag';

export const useUpdateTag = () => {
  const updateTagRef = useRef<Tag>();
  const {
    mutate: updateTag,
    error,
    isPending,
    isSuccess,
    reset,
  } = useUpdateTagMutation();

  const submit = useCallback(
    async (tag: Tag) => {
      updateTagRef.current = tag;
      updateTag(tag);
    },
    [updateTag],
  );

  const retry = useCallback(() => {
    const tag = updateTagRef.current;

    if (tag) {
      updateTag(tag);
    }
  }, [updateTag]);

  const resetAll = useCallback(() => {
    reset();
    updateTagRef.current = undefined;
  }, [reset]);

  return {
    submit,
    retry,
    reset: resetAll,
    error,
    isLoading: isPending,
    isSuccess,
  };
};
