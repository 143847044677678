import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronLeft } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import { PopupMenu } from 'shared/components/ui/popup-menu';
import { RepeatForm } from 'shared/components/ui/repeat-rrule-form';
import { useContextMenuState } from 'shared/hooks/use-context-menu-state';
import { HabitSchedule } from 'shared/types/habit-schedule';
import { TaskType } from 'shared/types/task-base';
import { WeekDays } from 'shared/types/week-days';
import { getActiveSchedule } from 'shared/utils/get-active-schedule';
import { getScheduleLabel } from 'shared/utils/get-schedule-label';

import * as Styled from './repeat-form-input.style';

export type RepeatFormInputProps = {
  onChange: (schedules: HabitSchedule[] | null) => void;
  onChangeType: (type: TaskType.Habit | TaskType.Repeating) => void;
  value: HabitSchedule[];
  type?: TaskType.Habit | TaskType.Repeating;
  onPremium: () => void;
  targetAllowed: boolean;
  createHabitAllowed: boolean;
  weekStartsOn: WeekDays;
  error?: string;
  shouldReplaceSchedule?: boolean;
};

export const RepeatFormInput: React.FC<RepeatFormInputProps> = ({
  onChange,
  onChangeType,
  value,
  type,
  weekStartsOn,
  onPremium,
  targetAllowed,
  createHabitAllowed,
  error,
  shouldReplaceSchedule,
}) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const {
    open: openOptions,
    close: closeOptions,
    position: optionsPosition,
  } = useContextMenuState();

  const _onChange = (schedule: HabitSchedule | null) => {
    if (!schedule) {
      onChange(null);
      closeOptions();
      return;
    }

    const endedSchedules = value.filter(({ endDate }) => !!endDate);
    const updateSchedules = [];
    const activeSchedule = getActiveSchedule(value);

    if (!shouldReplaceSchedule) {
      !!activeSchedule &&
        updateSchedules.push({ ...activeSchedule, endDate: new Date() });
    }

    const newSchedule = schedule;
    if (shouldReplaceSchedule && activeSchedule) {
      newSchedule.entries = activeSchedule.entries;
      newSchedule.completions = activeSchedule.completions;
      newSchedule.skips = activeSchedule.skips;
    }

    updateSchedules.push(newSchedule);

    onChange([...endedSchedules, ...updateSchedules]);
    closeOptions();
  };

  const val = useMemo(() => {
    const activeSchedule = getActiveSchedule(value);
    return activeSchedule ?? value[value.length - 1];
  }, [value]);

  return (
    <Styled.Container ref={containerRef}>
      <Styled.Button
        $placeholder={!val}
        onClick={openOptions}
        $hasError={!!error}
        type="button"
      >
        <Styled.Label>
          {val
            ? getScheduleLabel(val, t, true)
            : t('forms.habit.fields.repeat.placeholder')}
        </Styled.Label>

        <Styled.ChevronContainer>
          <Icon icon={ChevronLeft} />
        </Styled.ChevronContainer>
      </Styled.Button>

      {optionsPosition && (
        <PopupMenu
          referenceElement={containerRef}
          onOutside={closeOptions}
          position="bottom-start"
          hasParentWidth
        >
          <RepeatForm
            initialRrule={val?.rrule?.format}
            initialFrequency={val?.frequency}
            weekStartsOn={weekStartsOn}
            type={type!}
            onChange={_onChange}
            onChangeType={onChangeType}
            onPremium={onPremium}
            isTargetPremium={!targetAllowed}
            isCreateHabitPremium={!createHabitAllowed}
          />
        </PopupMenu>
      )}
    </Styled.Container>
  );
};
