import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Sliders } from 'shared/assets/icons';
import { IconButton, Variants } from 'shared/components/ui/icon-button';
import { useOpenMenu } from 'shared/hooks/use-open-menu';
import { useTheme } from 'styled-components';

import { MainContextMenu } from './main-context-menu';

export type HeaderInteractionsProps = {};

export const HeaderInteractions: React.FC<HeaderInteractionsProps> = () => {
  const theme = useTheme();
  const containerRef = useRef<HTMLDivElement>(null);
  const { menuOpen, openMenu, closeMenu } = useOpenMenu();
  const { id } = useParams();

  return (
    <div ref={containerRef}>
      <IconButton
        icon={Sliders}
        onClick={openMenu}
        variant={theme.isMobile ? Variants.Outlined : Variants.Contained}
      />

      {menuOpen && (
        <MainContextMenu
          onClose={closeMenu}
          referenceElement={containerRef}
          tagId={id}
        />
      )}
    </div>
  );
};
