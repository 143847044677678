import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};

  padding: ${({ theme }) =>
    theme.isMobile
      ? `${theme.spacing(6)} ${theme.spacing(3)} ${theme.spacing(2)}`
      : `${theme.spacing(6)} ${theme.spacing(3)} ${theme.spacing(2)} ${theme.spacing(6)}`};
`;

export const Center = styled.div`
  display: ${({ theme }) => (theme.isMobile ? 'none' : 'block')};
`;

const Side = css`
  flex-grow: 1;
  flex-basis: 0;
`;

export const Left = styled.div`
  ${Side};
`;

export const Right = styled.div`
  ${Side};
  display: flex;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacing(2)};

  text-align: right;
`;

export const Bottom = styled.div`
  display: ${({ theme }) => (theme.isMobile ? 'block' : 'none')};
  padding: ${({ theme }) => `0 ${theme.spacing(3)}`};
`;

export const SortContainer = styled.div`
  position: relative;
  color: ${({ theme }) => theme.palette.text.secondary};
`;
