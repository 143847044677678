import styled, { css } from 'styled-components';

export const Container = styled.div<{ $noHorizontalSpacing: boolean }>`
  height: 100%;
  padding: ${({ theme, $noHorizontalSpacing }) =>
    `${theme.spacing(2)} ${$noHorizontalSpacing ? 0 : theme.spacing(3)} ${theme.spacing(8)}`};

  overflow: auto;

  ${({ theme }) =>
    theme.isMobile &&
    css`
      padding: ${`${theme.spacing(2)} 0 ${theme.spacing(8)}`};
    `};
`;
