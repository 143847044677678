import {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
} from 'firebase/firestore';
import { NewTag, Tag } from 'shared/types/tag';

import { firebaseTimestampToDate } from './firebase-timestamp-to-date';

export const tagConverter: FirestoreDataConverter<Tag> = {
  fromFirestore: (snapshot: QueryDocumentSnapshot): Tag => {
    const data = snapshot.data();
    return {
      ...data,
      id: snapshot.id,
      createdAt: firebaseTimestampToDate(data.createdAt),
      updatedAt: data.updatedAt
        ? firebaseTimestampToDate(data.updatedAt)
        : undefined,
    } as Tag;
  },
  toFirestore: (tag) => tag,
};

export const newTagConverter = (tag: NewTag) => ({
  ...tag,
  createdAt: new Date(),
});
