import { createContext } from 'react';
import { ID } from 'shared/types/id';
import { Timestamp } from 'shared/types/timestamp';

export type HabitManualEntryLoggingContextProps = {
  openManualEntryLogging: (habitId: ID, date: Timestamp) => void;
};

export const HabitManualEntryLoggingContext =
  createContext<HabitManualEntryLoggingContextProps>({
    openManualEntryLogging: () => {},
  });
