import { useDroppable } from '@dnd-kit/core';
import React from 'react';
import { TasksList } from 'shared/components/ui/tasks-list';

import * as Styled from './task-section.style';
import { TaskSectionLayout } from './task-section-layout';

export type TaskSectionProps = Omit<
  React.ComponentProps<typeof TaskSectionLayout>,
  'children' | 'count'
> &
  React.ComponentProps<typeof TasksList> & {
    droppableId?: string;
  };

export const TaskSection: React.FC<TaskSectionProps> = ({
  tasks,
  selectedTask,
  displayDue,
  onClick,
  hideGoal,
  hideLifeArea,
  hideMeta,
  canReorder,
  isMoving,
  noHover,
  showDetailOnSelect,
  showRepeatingAsTodo,
  showRepeatingAsRepeatingOnEntry,
  onUnfocus,

  goals,
  lifeAreas,
  onPremium,
  goalId,
  lifeAreaId,
  date,
  priority,
  tagIds,
  type,
  canCreateTask,
  canCreateHabit,
  canCollapse,
  headerToDisplay,
  customHeader,
  headerHasImage,
  headerIsLink,
  newTaskInputRef,

  droppableId,
}) => {
  const { setNodeRef } = useDroppable({
    id: droppableId ?? '',
    disabled: !droppableId || !!tasks.length,
  });

  const containerProps = droppableId ? { ref: setNodeRef } : {};

  return (
    <Styled.Container {...containerProps}>
      <TaskSectionLayout
        goals={goals}
        lifeAreas={lifeAreas}
        onPremium={onPremium}
        goalId={goalId}
        lifeAreaId={lifeAreaId}
        date={date}
        priority={priority}
        count={tasks.length}
        tagIds={tagIds}
        type={type}
        canCreateTask={canCreateTask}
        canCreateHabit={canCreateHabit}
        canCollapse={canCollapse}
        headerToDisplay={headerToDisplay}
        customHeader={customHeader}
        headerHasImage={headerHasImage}
        headerIsLink={headerIsLink}
        newTaskInputRef={newTaskInputRef}
        hideMeta={hideMeta}
      >
        <TasksList
          tasks={tasks}
          selectedTask={selectedTask}
          displayDue={displayDue}
          onClick={onClick}
          hideGoal={hideGoal}
          hideLifeArea={hideLifeArea}
          hideMeta={hideMeta}
          canReorder={canReorder}
          isMoving={isMoving}
          noHover={noHover}
          showDetailOnSelect={showDetailOnSelect}
          showRepeatingAsTodo={showRepeatingAsTodo}
          showRepeatingAsRepeatingOnEntry={showRepeatingAsRepeatingOnEntry}
          onUnfocus={onUnfocus}
        />
      </TaskSectionLayout>
    </Styled.Container>
  );
};
