import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Image as ImageIcon } from 'shared/assets/icons';

import * as Styled from './blur-hash-image.style';
import { Img, LoadingImg } from './image';

export type BlurHashImageProps = React.ComponentProps<'img'> & {
  hash?: string | null;
  placeholderIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
};

const PlaceholderIcon: React.FC<{
  placeholder: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}> = ({ placeholder }) => (
  <Styled.PlaceholderContainer>
    <Styled.PlaceholderIcon icon={placeholder} width="80%" height="80%" />
  </Styled.PlaceholderContainer>
);

export const BlurHashImage: React.FC<BlurHashImageProps> = ({
  src,
  alt,
  hash,
  placeholderIcon = ImageIcon,
  ref: _ref,
  ...rest
}) =>
  src ? (
    <ErrorBoundary fallback={<PlaceholderIcon placeholder={placeholderIcon} />}>
      <Suspense fallback={<LoadingImg />}>
        <Img src={src} alt={alt} hash={hash} {...rest} />
      </Suspense>
    </ErrorBoundary>
  ) : (
    <PlaceholderIcon placeholder={placeholderIcon} />
  );
