import { useCallback } from 'react';
import { useUpdateTodayFilterTagsMutation } from 'shared/hooks/queries/use-update-today-filter-tags-mutation';
import { useUser } from 'shared/hooks/use-user';
import { ID } from 'shared/types/id';

export const useToggleTodayFilterTag = () => {
  const user = useUser();
  const { mutate: updateFilterTags } = useUpdateTodayFilterTagsMutation();

  return useCallback(
    (id: ID) => {
      const newFilterTags =
        user?.settings?.todayFilterByTags?.filter((tagId) => tagId !== id) ??
        [];
      if (
        user?.settings?.todayFilterByTags &&
        user?.settings?.todayFilterByTags.length === newFilterTags.length
      ) {
        newFilterTags.push(id);
      }

      updateFilterTags(newFilterTags);
    },
    [updateFilterTags, user?.settings?.todayFilterByTags],
  );
};
