import React from 'react';
import { useTheme } from 'styled-components';

import { HabitDetailDesktopCard } from '../habit-detail-desktop-card';
import { HabitDetailMobileCard } from '../habit-detail-mobile-card';

export type HabitDetailCardProps = React.ComponentProps<
  typeof HabitDetailDesktopCard
> &
  React.ComponentProps<typeof HabitDetailMobileCard>;

export const HabitDetailCard: React.FC<HabitDetailCardProps> = ({
  habit,
  onClick,
  weekDate,
  isSortable,
}) => {
  const theme = useTheme();

  return theme.isMobile ? (
    <HabitDetailMobileCard
      habit={habit}
      onClick={onClick}
      weekDate={weekDate}
      isSortable={isSortable}
    />
  ) : (
    <HabitDetailDesktopCard
      habit={habit}
      onClick={onClick}
      weekDate={weekDate}
      isSortable={isSortable}
    />
  );
};
