import { useLocalizedLifeAreas } from 'features/life-areas';
import React, { useMemo } from 'react';
import { NewTaskRow } from 'shared/components/connected/new-task-row';
import { DroppableContainer } from 'shared/components/ui/droppable-container';
import { VirtualizedTasksList } from 'shared/components/ui/tasks-list';
import { ID } from 'shared/types/id';
import { Task } from 'shared/types/task';

import * as Styled from './all-tasks-life-area-tasks-list.style';

export type AllTasksLifeAreaTasksListProps = {
  id: ID;
  tasks: Task[];
  hasDnd?: boolean;
  canCreateTask?: boolean;
};

export const AllTasksLifeAreaTasksList: React.FC<
  AllTasksLifeAreaTasksListProps
> = ({ id, tasks, hasDnd, canCreateTask }) => {
  const lifeAreas = useLocalizedLifeAreas();

  const lifeArea = useMemo(
    () => lifeAreas.find((lifeArea) => lifeArea.id === id),
    [id, lifeAreas],
  );

  return (
    <DroppableContainer id={id} disabled={!hasDnd || !!lifeArea?.frozenAt}>
      <Styled.ListContainer>
        <VirtualizedTasksList
          tasks={tasks}
          canReorder={!!hasDnd}
          hideLifeArea
        />
      </Styled.ListContainer>

      {canCreateTask && !lifeArea?.frozenAt && (
        <Styled.NewTaskContainer>
          <NewTaskRow lifeArea={id} />
        </Styled.NewTaskContainer>
      )}
    </DroppableContainer>
  );
};
