import { useGoals } from 'features/goals';
import { useLocalizedLifeAreas } from 'features/life-areas';
import { useTasks } from 'features/tasks';
import { Section } from 'pages/all-tasks/types';
import { useMemo } from 'react';
import { useToday } from 'shared/contexts/today';
import { useRelationshipsMap } from 'shared/hooks/use-relationships';
import { useUser } from 'shared/hooks/use-user';
import { Goal } from 'shared/types/goal';
import { ID, IDPrefixes } from 'shared/types/id';
import {
  goalInboxID,
  unlinkedPlannedTasksID,
} from 'shared/types/orderable-section';
import { SortingMode } from 'shared/types/sorting-mode';
import { Task } from 'shared/types/task';
import { TaskType } from 'shared/types/task-base';
import { getSortedTasks } from 'shared/utils/get-sorted-tasks';
import { getWeekStartsOn } from 'shared/utils/get-week-starts-on';
import {
  createPrefixId,
  getPrefixedId,
  getPrefixedIdPrefix,
} from 'shared/utils/prefix-id';
import {
  Options as SortGoalsOptions,
  sortGoals,
} from 'shared/utils/sort-goals';
import { sortItems } from 'shared/utils/sort-items';

export const useLifeAreaGroupingSections = () => {
  const today = useToday();
  const lifeAreas = useLocalizedLifeAreas();
  const goals = useGoals();
  const allTasks = useTasks({ uncompletedOnly: true });
  const user = useUser();
  const weekStartsOn = getWeekStartsOn(user);
  const relationships = useRelationshipsMap();

  // Filter active tasks and group them correctly
  const { goalTasks, lifeAreaTasks, inboxTasks, unlinkedPlannedTasks, tasks } =
    useMemo(() => {
      const tagTaskIds = user?.settings?.allTasksFilterByTags?.length
        ? user.settings.allTasksFilterByTags
            .map((tagId) =>
              relationships[createPrefixId(tagId, IDPrefixes.Tag)]
                ?.reduce<ID[]>((acc, prefixedId) => {
                  if (getPrefixedIdPrefix(prefixedId) === IDPrefixes.Task) {
                    acc.push(getPrefixedId(prefixedId));
                  }

                  return acc;
                }, [])
                .filter(Boolean),
            )
            .flat(1)
        : undefined;

      return allTasks.reduce<{
        goalTasks: Task[];
        lifeAreaTasks: Task[];
        inboxTasks: Task[];
        unlinkedPlannedTasks: Task[];
        tasks: Task[];
      }>(
        (acc, task) => {
          if (task.archivedAt || task.completedAt) {
            return acc;
          }
          // if filters on tags are on, prevent tasks from being added that are not part of the filter
          if (tagTaskIds && !tagTaskIds.includes(task.id)) {
            return acc;
          }
          // if the hideHabits is turned on, prevent habits from being added (repeating tasks are allowed)
          if (
            task.type === TaskType.Habit &&
            user?.settings?.allTasksHideHabits
          ) {
            return acc;
          }
          acc.tasks.push(task);

          if (!task.goalId && !task.lifeAreaId && !task.parentIds?.length) {
            !!task.schedules?.length || !!task.endStrategy?.deadline
              ? acc.unlinkedPlannedTasks.push(task)
              : acc.inboxTasks.push(task);
          }
          if (task.goalId) {
            acc.goalTasks.push(task);
          }
          if (task.lifeAreaId) {
            acc.lifeAreaTasks.push(task);
          }

          return acc;
        },
        {
          goalTasks: [],
          lifeAreaTasks: [],
          inboxTasks: [],
          unlinkedPlannedTasks: [],
          tasks: [],
        },
      );
    }, [
      allTasks,
      relationships,
      user?.settings?.allTasksFilterByTags,
      user?.settings?.allTasksHideHabits,
    ]);

  const sections = useMemo(() => {
    // get all goal ids that have a connection to a task
    const taskGoalIds = Array.from(
      new Set(goalTasks.map(({ goalId }) => goalId!)),
    );

    // get all goals that are not archived or completed and connected with one of the tasks
    const taskGoals = goals.filter(
      (item) =>
        !item.archivedAt && !item.completedAt && taskGoalIds.includes(item.id),
    );

    // make sure we have all layers of goals, so also parent goals
    const allTaskGoalIds = Array.from(
      new Set(
        taskGoals.reduce<ID[]>((acc, goal) => {
          acc.push(goal.id, ...(goal.parentIds ?? []));
          return acc;
        }, []),
      ),
    );

    // get a list of all the goals that should be reflected as section
    const activeGoals = goals.filter(({ id }) => allTaskGoalIds.includes(id));

    const mainGoals = activeGoals.filter((goal) => !goal.parentIds?.length);

    // get a list of life-area-ids that have a main-goal or a task connected to it
    const activeLifeAreaIds = Array.from(
      new Set([
        ...lifeAreaTasks.map(({ lifeAreaId }) => lifeAreaId!),
        ...mainGoals.map(({ lifeAreaId }) => lifeAreaId),
      ]),
    );
    // Sort life areas
    const activeLifeAreas = sortItems(
      lifeAreas,
      user?.settings?.lifeAreaSorting ?? [],
      'id',
      // and filter those that should be shown
    ).filter(({ id }) => activeLifeAreaIds.includes(id));

    // Helper function to create goal sections with their tasks
    const createGoalSections = (
      goalList: Goal[],
      sortConfig: SortGoalsOptions,
    ) =>
      sortGoals(goalList, sortConfig)
        .reduce<Section[]>((acc, goal) => {
          // Get and sort tasks for this goal
          const sortedGoalTasks = getSortedTasks(
            goalTasks.filter((task) => task.goalId === goal.id),
            {
              today,
              order: goal.taskSorting ?? [],
              mode: goal.taskSortingMode ? [goal.taskSortingMode] : undefined,
              weekStartsOn,
            },
          );

          // Find and sort subgoals
          const subGoals = activeGoals.filter(
            (subGoal) =>
              !subGoal.frozenAt &&
              subGoal.parentIds?.[subGoal.parentIds.length - 1] === goal.id,
          );

          const subGoalSections = sortGoals(subGoals, {
            allGoals: goals,
            sortMode: goal.goalSortingMode,
            customOrder: goal.goalSorting,
          }).map((subGoal) => ({
            id: subGoal.id,
            items: getSortedTasks(
              goalTasks.filter((task) => task.goalId === subGoal.id),
              {
                today,
                order: subGoal.taskSorting ?? [],
                mode: subGoal.taskSortingMode
                  ? [subGoal.taskSortingMode]
                  : undefined,
                weekStartsOn,
              },
            ),
          }));

          acc.push({ id: goal.id, items: sortedGoalTasks }, ...subGoalSections);
          return acc;
        }, [])
        .filter(({ items }) => items.length);

    // Create life area sections
    const lifeAreaSections = activeLifeAreas.reduce<Section[]>(
      (acc, { id, goalSorting, taskSorting }) => {
        const goalSections = createGoalSections(
          mainGoals.filter((goal) => !goal.frozenAt && goal.lifeAreaId === id),
          {
            allGoals: goals,
            customOrder: goalSorting ?? [],
          },
        );

        acc.push(
          {
            id,
            goalIds: goalSections.map(({ id }) => id),
            items: getSortedTasks(
              lifeAreaTasks.filter((task) => task.lifeAreaId === id),
              {
                today,
                order: taskSorting ?? [],
                mode: [SortingMode.Custom],
                weekStartsOn,
              },
            ),
          },
          ...goalSections,
        );

        return acc;
      },
      [],
    );

    // if there are any unlinked goals, inbox tasks or unplanned tasks, add the sections to the bottom
    const unconnectedGoals = mainGoals.filter((goal) => !goal.lifeAreaId);
    if (unconnectedGoals.length) {
      lifeAreaSections.push(
        ...createGoalSections(unconnectedGoals, {
          allGoals: goals,
          sortMode: user?.settings?.goalSortingMode,
          customOrder: user?.settings?.goalRoadmapSorting?.inProgress,
        }),
      );
    }

    if (unlinkedPlannedTasks.length) {
      lifeAreaSections.push({
        id: unlinkedPlannedTasksID,
        items: getSortedTasks(unlinkedPlannedTasks, {
          today,
          order: user?.settings?.allTasks?.unlinkedPlannedTasksSorting ?? [],
          mode: [SortingMode.Custom],
          weekStartsOn,
        }),
      });
    }

    if (inboxTasks.length) {
      lifeAreaSections.push({
        id: goalInboxID,
        items: getSortedTasks(inboxTasks, {
          today,
          order: user?.settings?.inboxSorting ?? [],
          mode: [SortingMode.Custom],
          weekStartsOn,
        }),
      });
    }

    return lifeAreaSections;
  }, [
    goalTasks,
    goals,
    lifeAreaTasks,
    lifeAreas,
    user?.settings?.lifeAreaSorting,
    user?.settings?.goalSortingMode,
    user?.settings?.goalRoadmapSorting?.inProgress,
    user?.settings?.allTasks?.unlinkedPlannedTasksSorting,
    user?.settings?.inboxSorting,
    unlinkedPlannedTasks,
    inboxTasks,
    today,
    weekStartsOn,
  ]);

  return {
    sections,
    tasks,
  };
};
