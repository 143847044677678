import { useGoals } from 'features/goals';
import { useHabits } from 'features/tasks';
import { useMemo } from 'react';
import { useToday } from 'shared/contexts/today';
import { useLifeAreaSections } from 'shared/hooks/use-life-area-sections';
import { useUser } from 'shared/hooks/use-user';
import { Goal } from 'shared/types/goal';
import { Habit } from 'shared/types/habit';
import { ID } from 'shared/types/id';
import { lifeAreaOtherID } from 'shared/types/orderable-section';
import { SortingMode } from 'shared/types/sorting-mode';
import { getSortedTasks } from 'shared/utils/get-sorted-tasks';
import { getWeekStartsOn } from 'shared/utils/get-week-starts-on';

export const useLifeAreaHabitSections = () => {
  const today = useToday();
  const user = useUser();

  const habits = useHabits({ uncompletedOnly: true, unArchivedOnly: true });
  const goals = useGoals();

  const lifeAreaSections = useLifeAreaSections();

  const sections = useMemo(() => {
    const filteredGoals = goals.filter(
      ({ id }) => !!habits.find(({ goalId }) => goalId === id),
    );
    const goalsMap = filteredGoals.reduce<Record<ID, Goal>>((acc, goal) => {
      acc[goal.id] = goal;
      return acc;
    }, {});

    const otherGoalIds = goals
      .filter(({ lifeAreaId }) => !lifeAreaId)
      .map(({ id }) => id);

    return lifeAreaSections
      .map(({ id, lifeArea }) => ({
        id,
        items: getSortedTasks(
          habits.filter(({ goalId, lifeAreaId }) =>
            id === lifeAreaOtherID
              ? (!goalId || otherGoalIds.includes(goalId)) && !lifeAreaId
              : (goalId && goalsMap[goalId]?.lifeAreaId === lifeArea?.id) ||
                lifeAreaId === lifeArea?.id,
          ),
          {
            today,
            weekStartsOn: getWeekStartsOn(user),
            mode: user?.settings?.habitSortingMode
              ? ([user?.settings?.habitSortingMode] as unknown as SortingMode[])
              : undefined,
            order:
              user?.settings?.habitGroupSorting?.lifeArea?.sorting?.[
                lifeAreaOtherID === id || !id ? 'null' : id
              ] ?? [],
          },
        ) as Habit[],
      }))
      .filter(({ items }) => !!items.length);
  }, [goals, habits, lifeAreaSections, today, user]);

  return {
    sections,
    habits,
  };
};
