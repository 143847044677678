import { useGoals } from 'features/goals';
import { useLocalizedLifeAreas } from 'features/life-areas';
import { useCreateTag, useTags } from 'features/tags';
import {
  useCreateHabitAllowed,
  useCreateTask,
  useUpdateTask,
} from 'features/tasks';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NewTaskCard } from 'shared/components/ui/task-card';
import { defaultTagColorOption } from 'shared/constants';
import { useOpenCreateGoalDialog } from 'shared/contexts/goal-form';
import { useOpenCreateHabitDialog } from 'shared/contexts/habit-form';
import { useOpenCreateTagDialog } from 'shared/contexts/tag-form';
import { useOpenCreateTask } from 'shared/contexts/task-detail';
import { useClickOutside } from 'shared/hooks/use-click-outside';
import { useForwardedRef } from 'shared/hooks/use-forwarded-ref';
import { useKeysDown } from 'shared/hooks/use-keys-down';
import { useNewTaskState } from 'shared/hooks/use-new-task-state';
import { useUser } from 'shared/hooks/use-user';
import { InteractionType } from 'shared/types/data-type';
import { initialDateFormat } from 'shared/types/date-format-options';
import { Habit } from 'shared/types/habit';
import { ID, IDPrefixes } from 'shared/types/id';
import { NewTask } from 'shared/types/task';
import { TaskPriorityOptions, TaskType } from 'shared/types/task-base';
import { Timestamp } from 'shared/types/timestamp';
import { eventIsFromInteractionType } from 'shared/utils/event-is-from-interaction-type';
import { getWeekStartsOn } from 'shared/utils/get-week-starts-on';

export type NewTaskRowProps = {
  date?: Timestamp;
  goal?: ID;
  section?: ID;
  lifeArea?: ID;
  tags?: ID[];
  parentIds?: ID[] | null;
  parentChildIds?: ID[] | null;
  priority?: TaskPriorityOptions;
  newTaskInputRef?: React.RefObject<HTMLTextAreaElement>;
  hideMeta?: boolean;
};

export const NewTaskRow: React.FC<NewTaskRowProps> = React.memo(
  ({
    date,
    goal,
    section,
    lifeArea,
    tags: initialTags,
    parentIds,
    parentChildIds,
    priority,
    newTaskInputRef,
    hideMeta,
  }) => {
    const inputRef = useForwardedRef(newTaskInputRef);
    const { t } = useTranslation();
    const taskRef = useRef<HTMLDivElement>(null);
    const [isSelected, setIsSelected] = useState(false);

    const user = useUser();
    const goals = useGoals();
    const lifeAreas = useLocalizedLifeAreas();
    const tags = useTags();
    const { allowed: createHabitAllowed } = useCreateHabitAllowed();

    const openCreateGoalDialog = useOpenCreateGoalDialog();
    const openCreateTagDialog = useOpenCreateTagDialog();
    const openCreateTaskColumn = useOpenCreateTask();
    const openCreateHabitDialog = useOpenCreateHabitDialog();
    const { submit: createTask } = useCreateTask();
    const { submit: updateTask } = useUpdateTask();
    const { submit: createTag } = useCreateTag();

    const onCreateTask = async (
      newTask: NewTask,
      { tag }: Partial<Record<IDPrefixes, ID[] | null>>,
    ) => {
      const goal = goals.find(({ id }) => id === newTask.goalId);
      const task = await createTask(newTask, {
        goal,
        sectionId: section,
        tags: tag,
      });
      const parentId = parentIds?.[parentIds.length - 1];

      if (parentId) {
        updateTask({
          id: parentId,
          childIds: [...(parentChildIds ?? []), task.id],
        });
      }
    };

    const onCreateGoal = (name: string) => openCreateGoalDialog({ name });

    const { submit, updateNewTask, newTask } = useNewTaskState(onCreateTask, {
      endStrategy: { deadline: date },
      goalId: goal ?? null,
      sectionId: section ?? null,
      lifeAreaId: lifeArea ?? null,
      parentIds: parentIds ?? null,
      tagIds: initialTags ?? null,
      priority: priority,
    });

    const onCreateTag = async (name: string) => {
      const newTag = await createTag({
        name,
        color: defaultTagColorOption.value,
      });
      updateNewTask({ tagIds: [...(newTask.tagIds ?? []), newTag.id] });
    };

    const openHabitDialog = () => {
      // prevent new task to be created by changing the name to an empty string
      updateNewTask({ name: '' });

      openCreateHabitDialog({
        ...newTask,
        endStrategy: null,
        type: createHabitAllowed ? TaskType.Habit : TaskType.Repeating,
        schedules: undefined,
      } as Partial<Habit>);
    };

    const submitTask = () => {
      if (newTask.name) {
        submit();
        return;
      }

      setIsSelected(false);
    };

    const onFocus = () => {
      openCreateTaskColumn(newTask);
      setIsSelected(true);
    };

    useClickOutside(taskRef, (e) => {
      if (isSelected && !eventIsFromInteractionType(e, InteractionType.Task)) {
        submitTask();
      }
    });

    useKeysDown(['Enter'], (e) => {
      if (isSelected && document.activeElement === inputRef.current) {
        // prevent the enter value from entering the text-area
        e.preventDefault();

        submitTask();
      }
    });

    return (
      <NewTaskCard
        nameInputRef={inputRef}
        ref={taskRef}
        name={newTask.name}
        placeholder={t(
          parentIds?.length
            ? 'forms.new-task.sub-task.title.placeholder'
            : 'forms.new-task.title.placeholder',
        )}
        goalId={newTask.goalId}
        lifeAreaId={newTask.lifeAreaId}
        tagIds={newTask.tagIds}
        goals={goals}
        lifeAreas={lifeAreas}
        tags={tags}
        deadline={newTask.endStrategy?.deadline ?? null}
        priority={newTask.priority}
        dateFormat={user?.settings?.dateFormat ?? initialDateFormat}
        weekStartsOn={getWeekStartsOn(user)}
        schedules={newTask.schedules ?? null}
        reminderTime={newTask.reminderTime}
        onChange={updateNewTask}
        isSelected={isSelected}
        onClick={onFocus}
        onRepeat={openHabitDialog}
        onCreateGoal={onCreateGoal}
        onCreateTag={onCreateTag}
        noMeta={hideMeta}
      />
    );
  },
);
