import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1.5)};

  flex: 1;
  min-width: 0;
  width: 0;

  border: none;
  background: transparent;
  cursor: ${({ onClick }) => (!!onClick ? 'pointer' : 'inherit')};

  text-align: left;
  color: inherit;
`;
