import { DndContext } from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useReorderTags, useSortedTags, useUpdateTag } from 'features/tags';
import React from 'react';
import { useLocation } from 'react-router';
import { OverlayComponentWrapper } from 'shared/components/ui/overlay-component-wrapper';
import { SortableOverlay } from 'shared/components/ui/sortable-overlay';
import { TagSideMenuItem } from 'shared/components/ui/tag-side-menu-item';
import { useSortableItemsControls } from 'shared/hooks/use-sortable-items-controls';
import { useSortableSensors } from 'shared/hooks/use-sortable-sensors';

import * as Styled from './tags-list.style';

export const TagsList: React.FC = () => {
  const { pathname: currentPathname } = useLocation();
  const tags = useSortedTags();
  const { submit: updateTag } = useUpdateTag();

  const onReorder = useReorderTags();
  const sensors = useSortableSensors();
  const {
    items,
    activeItem,
    handleDragStart,
    handleDragCancel,
    handleDragEnd,
  } = useSortableItemsControls({
    items: tags,
    onDragEnd: onReorder,
  });

  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
      sensors={sensors}
      measuring={{
        draggable: {
          measure: (node) => node.getBoundingClientRect(),
        },
      }}
    >
      <SortableContext
        items={items.map(({ id }) => id)}
        strategy={verticalListSortingStrategy}
      >
        <Styled.List>
          {items.map((tag) => (
            <Styled.ListItem key={tag.id}>
              <TagSideMenuItem
                tag={tag}
                currentPath={currentPathname}
                onChangeTag={updateTag}
                hasDnd
              />
            </Styled.ListItem>
          ))}
        </Styled.List>

        <SortableOverlay>
          {!!activeItem && (
            <OverlayComponentWrapper>
              <TagSideMenuItem
                isMoving
                tag={activeItem}
                currentPath={currentPathname}
              />
            </OverlayComponentWrapper>
          )}
        </SortableOverlay>
      </SortableContext>
    </DndContext>
  );
};
