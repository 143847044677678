import { useMemo } from 'react';
import { useActiveSchedule } from 'shared/hooks/use-active-schedule';
import { useSchedulesPerfectSlots } from 'shared/hooks/use-schedules-perfect-slots';
import { FrequencyUnit, HabitSchedule } from 'shared/types/habit-schedule';
import { InsightsTimeframes } from 'shared/types/insights';
import { Timestamp } from 'shared/types/timestamp';
import { WeekDays } from 'shared/types/week-days';
import { getScheduleFrequency } from 'shared/utils/get-schedule-frequency';

const frequencyTimeframeMap = {
  [FrequencyUnit.Week]: [
    InsightsTimeframes.Week,
    InsightsTimeframes.Month,
    InsightsTimeframes.Quarter,
    InsightsTimeframes.Year,
    InsightsTimeframes.FourWeeks,
    InsightsTimeframes.TwelveWeeks,
    InsightsTimeframes.TwelveMonths,
  ],
  [FrequencyUnit.Month]: [
    InsightsTimeframes.Month,
    InsightsTimeframes.Quarter,
    InsightsTimeframes.Year,
    InsightsTimeframes.TwelveMonths,
  ],
  [FrequencyUnit.Year]: [InsightsTimeframes.Year],
};

export type Options = {
  timeframe: InsightsTimeframes;
  startDate: Timestamp;
  endDate: Timestamp;
  weekStartsOn: WeekDays;
};

export const useInsightTimeframePerfectSlots = (
  schedules: HabitSchedule[],
  { timeframe, startDate, endDate, weekStartsOn }: Options,
) => {
  const activeSchedule = useActiveSchedule(schedules);
  const perfectSlots = useSchedulesPerfectSlots(schedules, {
    startDate,
    endDate,
    weekStartsOn,
  });

  return useMemo(() => {
    if (!activeSchedule) {
      return [];
    }

    const frequency = getScheduleFrequency(activeSchedule);
    if (!frequencyTimeframeMap[frequency].includes(timeframe)) {
      return [];
    }

    return perfectSlots;
  }, [activeSchedule, perfectSlots, timeframe]);
};
