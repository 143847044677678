import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  DotsMenu,
  IconFormIcons,
  IconFormNames,
  Lock,
  SlimArrowUp,
} from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import { IconButton, Sizes } from 'shared/components/ui/icon-button';
import { TimeProgressChart } from 'shared/components/ui/time-progress-chart';
import { DateEntry } from 'shared/lib/recharts';
import { Paths } from 'shared/routes';
import { LifeArea } from 'shared/types/life-area';
import { Timestamp } from 'shared/types/timestamp';
import { WeekDays } from 'shared/types/week-days';
import { replaceUrlParams } from 'shared/utils/replace-url-params';

import * as Styled from './life-area-insights-card.style';

export type LifeAreaInsightsCardProps = {
  lifeArea: LifeArea;
  entries: DateEntry[];
  average: number;
  currentValue: number;
  target: number;
  timeframeDifference?: number;
  startDate: Timestamp;
  endDate: Timestamp;
  onPremium: () => void;
  menuButtonRef?: React.RefObject<HTMLButtonElement>;
  onMenuButton?: (e: React.MouseEvent) => void;
  onContextMenu?: (e: React.MouseEvent) => void;
  weekStartsOn: WeekDays;
  isLink?: boolean;
  isPreview?: boolean;
};

export const LifeAreaInsightsCard: React.FC<LifeAreaInsightsCardProps> = ({
  lifeArea,
  entries,
  average,
  currentValue,
  target,
  timeframeDifference,
  startDate,
  endDate,
  onPremium,
  menuButtonRef,
  onMenuButton,
  onContextMenu,
  weekStartsOn,
  isLink,
  isPreview,
}) => {
  const { t } = useTranslation();

  const _onMenuButton = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onMenuButton?.(e);
  };

  return (
    <Styled.Container
      as={!isLink || lifeArea.frozenAt ? 'span' : undefined}
      to={
        lifeArea.frozenAt
          ? ''
          : replaceUrlParams(Paths.LifeAreaDetail, { id: lifeArea.id })
      }
      onClick={lifeArea.frozenAt ? onPremium : undefined}
      onContextMenu={lifeArea.frozenAt ? undefined : onContextMenu}
      $isPreview={!!isPreview}
    >
      <Styled.Header>
        <Styled.GoalInformationContainer>
          <Styled.IllustrationContainer>
            <Styled.ImageContainer>
              {lifeArea.visionPreview?.image ? (
                <Styled.Image
                  src={lifeArea.visionPreview?.image.url}
                  alt={lifeArea.name}
                  hash={lifeArea.visionPreview?.image.blurHash}
                />
              ) : (
                <Icon
                  icon={
                    IconFormIcons[lifeArea.iconName] ??
                    IconFormIcons[IconFormNames.PlaceholderIcon]
                  }
                />
              )}
            </Styled.ImageContainer>

            {!!lifeArea.color && <Styled.ColorDot $color={lifeArea.color} />}
          </Styled.IllustrationContainer>

          <Styled.Title $isFrozen={!!lifeArea.frozenAt}>
            {lifeArea.name}
          </Styled.Title>
        </Styled.GoalInformationContainer>

        <Styled.IconButtonContainer>
          <IconButton
            icon={lifeArea.frozenAt ? Lock : DotsMenu}
            onClick={lifeArea.frozenAt ? undefined : _onMenuButton}
            size={Sizes.ExtraSmall}
            ref={menuButtonRef}
          />
        </Styled.IconButtonContainer>
      </Styled.Header>

      <Styled.Body $isFrozen={!!lifeArea.frozenAt}>
        <Styled.CurrentProgress>
          <Styled.CurrentProgressValue>
            {currentValue}
          </Styled.CurrentProgressValue>
          {` / ${target}`}
        </Styled.CurrentProgress>

        <Styled.Progress>
          <Typography variant="overline">
            {t('general.labels.lifescan.average.label', { average })}
          </Typography>

          {!!timeframeDifference && (
            <Styled.Difference $difference={timeframeDifference}>
              <Styled.DifferenceIconContainer>
                <Icon
                  icon={SlimArrowUp}
                  rotate={timeframeDifference > 0 ? 180 : 0}
                />
              </Styled.DifferenceIconContainer>
              {timeframeDifference.toFixed(1)}%
            </Styled.Difference>
          )}
        </Styled.Progress>
      </Styled.Body>

      <Styled.Footer $isFrozen={!!lifeArea.frozenAt}>
        <TimeProgressChart
          data={entries}
          chartStartDate={startDate}
          chartEndDate={endDate}
          endValue={target}
          weekStartsOn={weekStartsOn}
          hideYAxis
          hasSmallHeight
        />
      </Styled.Footer>
    </Styled.Container>
  );
};
