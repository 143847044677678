import { useArchiveGoal } from 'features/goals';
import { useToggleHiddenInsightsItem } from 'features/user';
import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Archive,
  BusinessChart,
  Edit3,
  EyeHidden,
  Trash2,
} from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import {
  PopupMenu,
  PopupMenuButton,
  PopupMenuList,
  PopupMenuListItem,
} from 'shared/components/ui/popup-menu';
import { useOpenDeleteGoalDialog } from 'shared/contexts/delete-goal';
import { useOpenEditGoalDialog } from 'shared/contexts/goal-form';
import { useOpenGoalProgress } from 'shared/contexts/goal-progress';
import { useUser } from 'shared/hooks/use-user';
import { Goal } from 'shared/types/goal';
import { InsightSections } from 'shared/types/insights';
import { DefaultMetricOptions } from 'shared/types/metric-template';

export type ContextMenuProps = Omit<
  React.ComponentProps<typeof PopupMenu>,
  'children' | 'ref' | 'onOutside'
> & {
  goal: Goal;
  onClose: () => void;
};

export const ContextMenu = forwardRef<HTMLDivElement, ContextMenuProps>(
  ({ goal, onClose, referenceElement, location }, ref) => {
    const { t } = useTranslation();
    const user = useUser();
    const openEditDialog = useOpenEditGoalDialog();
    const openProgressDialog = useOpenGoalProgress();
    const openDeleteGoalDialog = useOpenDeleteGoalDialog();
    const archiveGoal = useArchiveGoal();
    const toggleInsightsHabit = useToggleHiddenInsightsItem(
      InsightSections.Goals,
    );
    const isHidden = !!user?.settings?.insights?.sections?.[
      InsightSections.Goals
    ]?.hiddenItems?.includes(goal.id);

    const canUpdateProgress =
      !!goal.metric?.id &&
      ![
        DefaultMetricOptions.SubGoalProgress,
        DefaultMetricOptions.CompletedTasks,
        DefaultMetricOptions.CompletedSubGoals,
      ].includes(goal.metric.id as DefaultMetricOptions);

    const onUpdateProgress = () => {
      openProgressDialog(goal.id, goal.metric!);
      onClose();
    };

    const onHide = () => {
      toggleInsightsHabit(goal.id);
      onClose();
    };

    const onEdit = () => {
      openEditDialog(goal, goal.id);
      onClose();
    };

    const onArchive = () => {
      archiveGoal(goal);
      onClose();
    };

    const onDelete = () => {
      openDeleteGoalDialog(goal);
      onClose();
    };

    return (
      <PopupMenu
        ref={ref}
        location={location}
        referenceElement={referenceElement}
        position="bottom-start"
        onOutside={onClose}
      >
        <PopupMenuList hasBottomBorder>
          {canUpdateProgress && (
            <PopupMenuListItem>
              <PopupMenuButton
                onClick={onUpdateProgress}
                start={<Icon icon={BusinessChart} />}
              >
                {t('context-menus.goal.progress.labels.update-progress')}
              </PopupMenuButton>
            </PopupMenuListItem>
          )}
          <PopupMenuListItem>
            <PopupMenuButton onClick={onHide} start={<Icon icon={EyeHidden} />}>
              {t(
                isHidden
                  ? 'context-menus.insights.show.label'
                  : 'context-menus.insights.hide.label',
              )}
            </PopupMenuButton>
          </PopupMenuListItem>
        </PopupMenuList>
        <PopupMenuList hasBottomBorder>
          <PopupMenuListItem>
            <PopupMenuButton onClick={onEdit} start={<Icon icon={Edit3} />}>
              {t('context-menus.goal.labels.edit')}
            </PopupMenuButton>
          </PopupMenuListItem>
        </PopupMenuList>
        <PopupMenuList>
          <PopupMenuListItem>
            <PopupMenuButton
              onClick={onArchive}
              start={<Icon icon={Archive} />}
            >
              {t(
                goal.archivedAt
                  ? 'context-menus.goal.labels.de-archive'
                  : 'context-menus.goal.labels.archive',
              )}
            </PopupMenuButton>
          </PopupMenuListItem>
          <PopupMenuListItem>
            <PopupMenuButton onClick={onDelete} start={<Icon icon={Trash2} />}>
              {t('context-menus.goal.labels.delete')}
            </PopupMenuButton>
          </PopupMenuListItem>
        </PopupMenuList>
      </PopupMenu>
    );
  },
);
