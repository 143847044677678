import { typographyToCss } from 'shared/lib/mui';
import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;

  max-height: 100%;

  overflow: auto;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(0.5)};

  padding: ${({ theme }) => `${theme.spacing(5)} ${theme.spacing(6)}`};
`;

export const Description = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.overline)};

  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const Body = styled.div`
  padding: ${({ theme }) => `0 ${theme.spacing(6)}`};
`;

export const Footer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
  justify-content: flex-end;

  padding: ${({ theme }) => `${theme.spacing(5)} ${theme.spacing(6)}`};
`;
