import { isSameDay } from 'date-fns';
import React, { Suspense, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SubTasksList } from 'shared/components/connected/sub-tasks-list';
import { TaskMetaInteractions } from 'shared/components/connected/task-meta-interactions';
import { TaskDetailCheckbox } from 'shared/components/ui/task-detail-checkbox';
import { TaskDetailColumnDescription } from 'shared/components/ui/task-detail-column-description';
import { TaskDetailColumnResizableTitle } from 'shared/components/ui/task-detail-column-resizable-title';
import { subTaskLevel } from 'shared/constants';
import { useToday } from 'shared/contexts/today';
import { useActiveSchedule } from 'shared/hooks/use-active-schedule';
import { ScheduleEntryState } from 'shared/types/habit-schedule';
import { Task, TaskCheckOptions } from 'shared/types/task';
import { WeekDays } from 'shared/types/week-days';
import { getActiveSchedule } from 'shared/utils/get-active-schedule';
import { getFrequencyTimeframeCompletions } from 'shared/utils/get-frequency-timeframe-completions';
import { getScheduleFrequencyDayTarget } from 'shared/utils/get-schedule-frequency-day-target';
import { getTaskDayCompletionPercentage } from 'shared/utils/get-task-day-completion-percentage';

import * as Styled from './task-details.style';

export type TaskDetailsProps = {
  task: Task;
  onComplete: () => void;
  onEntryChange: (
    type: ScheduleEntryState,
    options?: { value?: number; increment?: number },
  ) => void;
  onEntryManual?: () => void;
  onChangeTitle: (value: string) => void;
  onChangeNotes: (value: string | null) => void;
  weekStartsOn: WeekDays;
};

export const TaskDetails: React.FC<TaskDetailsProps> = ({
  task,
  onComplete,
  onEntryManual,
  onEntryChange,
  onChangeTitle,
  onChangeNotes,
  weekStartsOn,
}) => {
  const { t } = useTranslation();
  const today = useToday();
  const activeSchedule = useActiveSchedule(task.schedules);

  const completed = useMemo(() => {
    if (task.completedAt) {
      return true;
    }

    if (!activeSchedule) {
      return false;
    }

    if (activeSchedule.frequency) {
      const { completionsCount, target } = getFrequencyTimeframeCompletions(
        activeSchedule,
        { referenceDate: today, weekStartsOn },
      );

      return completionsCount >= target;
    }

    const habitEntries = getActiveSchedule(task.schedules)?.entries;

    const completedToday = habitEntries?.find(
      (entry) =>
        entry.state === ScheduleEntryState.Complete &&
        !!entry.value &&
        isSameDay(entry.date, today),
    );

    return (
      !!completedToday &&
      (completedToday.value ?? 0) >= activeSchedule.completionTarget.count
    );
  }, [activeSchedule, task.completedAt, task.schedules, today, weekStartsOn]);

  const completionPercentage = useMemo(
    () =>
      task.schedules?.length
        ? getTaskDayCompletionPercentage(task, { day: today, weekStartsOn })
        : undefined,
    [task, today, weekStartsOn],
  );

  const target = useMemo(() => {
    if (!activeSchedule) {
      return;
    }

    return activeSchedule.frequency
      ? getScheduleFrequencyDayTarget(activeSchedule)
      : activeSchedule.completionTarget.count;
  }, [activeSchedule]);

  const preventPropagation = (e: React.MouseEvent) => e.stopPropagation();

  return (
    <Styled.Container>
      <Styled.Header>
        <Styled.TitleBar>
          <Styled.CheckboxContainer
            onClick={preventPropagation}
            $isFrozen={!!task.frozenAt}
          >
            <TaskDetailCheckbox
              checked={
                completed
                  ? TaskCheckOptions.Checked
                  : TaskCheckOptions.Unchecked
              }
              onChange={onComplete}
              completionPercentage={completionPercentage}
              target={target}
              priority={task.priority}
              isFrozen={!!task.frozenAt}
              onContextManual={onEntryManual}
              onContextChange={onEntryChange}
            />
          </Styled.CheckboxContainer>

          <Styled.TitleContainer>
            <TaskDetailColumnResizableTitle
              onChange={onChangeTitle}
              value={task.name}
              completed={completed}
              isFrozen={!!task.frozenAt}
            />
          </Styled.TitleContainer>
        </Styled.TitleBar>

        {!task.parentIds?.length && (
          <Styled.MetaContainer>
            <TaskMetaInteractions
              task={task}
              isFaded={!!task.frozenAt}
              highlight
              hasTooltips
              isMobileOutlined
            />
          </Styled.MetaContainer>
        )}
      </Styled.Header>

      <Styled.Body>
        <Styled.DescriptionContainer>
          <TaskDetailColumnDescription
            value={task.description ?? null}
            onChange={onChangeNotes}
            isFrozen={!!task.frozenAt}
            placeholder={t('general.labels.task-detail.notes.placeholder')}
          />
        </Styled.DescriptionContainer>

        {(task.parentIds?.length ?? 0) <= subTaskLevel && (
          <Styled.SubTasks>
            <Suspense>
              <SubTasksList task={task} />
            </Suspense>
          </Styled.SubTasks>
        )}
      </Styled.Body>
    </Styled.Container>
  );
};
