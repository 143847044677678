import { useCreateTag, useUpdateTag } from 'features/tags';
import { useIsFavorite, useToggleFavorite } from 'features/user';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TagFormDialog } from 'shared/dialogs/tag-form';
import { Paths } from 'shared/routes';
import { ID } from 'shared/types/id';
import { NewTag, Tag } from 'shared/types/tag';
import { TagFormFields } from 'shared/types/tag-form';
import { FavoriteType } from 'shared/types/user-settings';
import { replaceUrlParams } from 'shared/utils/replace-url-params';

import { TagFormContext, TagFormContextProps } from './tag-form-context';

export type TagFormProviderProps = {
  children: React.ReactNode;
};

export const TagFormProvider: React.FC<TagFormProviderProps> = ({
  children,
}) => {
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [tag, setTag] = useState<Partial<Tag>>();
  const [tagId, setTagId] = useState<ID>();
  const create = useCreateTag();
  const update = useUpdateTag();
  const { retry, isLoading, error, isSuccess, reset } = useMemo(
    () => (tagId ? update : create),
    [create, tagId, update],
  );
  const isFavorite = useIsFavorite(tagId);
  const toggleFavorite = useToggleFavorite();

  const openDialog = useCallback((tag?: Partial<Tag>, id?: ID) => {
    setTagId(id);
    setTag(tag ?? {});
    setDialogOpen(true);
  }, []);

  const closeDialog = useCallback(() => {
    reset();
    setTag(undefined);
    setTagId(undefined);
    setDialogOpen(false);
  }, [reset]);

  const submitForm = useCallback(
    async ({ favorite, ...rest }: TagFormFields) => {
      if (tagId) {
        await update.submit({ ...rest, id: tagId } as Tag);
        isFavorite !== favorite && toggleFavorite(tagId, FavoriteType.Tag);
        return;
      }

      const createdTag = await create.submit(rest as NewTag);
      !!favorite && toggleFavorite(createdTag.id, FavoriteType.Tag);

      navigate(replaceUrlParams(Paths.TagDetail, { id: createdTag.id }));
    },
    [create, isFavorite, navigate, tagId, toggleFavorite, update],
  );

  useEffect(() => {
    if (isSuccess) {
      closeDialog();
    }
  }, [closeDialog, isSuccess]);

  const value = useMemo<TagFormContextProps>(
    () => ({ onCreateTag: openDialog, onEditTag: openDialog }),
    [openDialog],
  );

  return (
    <TagFormContext.Provider value={value}>
      {children}
      {dialogOpen && (
        <TagFormDialog
          initialValues={tag}
          open={dialogOpen}
          onClose={closeDialog}
          onSubmit={submitForm}
          onRetry={retry}
          isLoading={isLoading}
          isError={!!error}
          isEdit={!!tagId}
        />
      )}
    </TagFormContext.Provider>
  );
};
