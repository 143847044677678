import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  IconFormIcons,
  IconFormNames,
  Image as ImageIcon,
  X,
} from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import { IconMenu } from 'shared/components/ui/icon-menu';
import { ImageLocations, useUploadImage } from 'shared/hooks/use-upload-image';
import { Image as ImageType } from 'shared/types/image';

import * as Styled from './form-illustration.style';
import { ImagePreview } from './image-preview';
import { SourcePickerMenu } from './source-picker-menu';
import { Sources } from './types';
import { UnsplashMenu } from './unsplash-menu';

const allSources = Object.values(Sources);

enum MenuOptions {
  SourcePicker = 'source-picker',
  Unsplash = 'unsplash',
  Icon = 'icon',
  None = 'none',
}

const sourcesToMenuOptions: Record<Sources, MenuOptions> = {
  [Sources.Upload]: MenuOptions.None,
  [Sources.Unsplash]: MenuOptions.Unsplash,
  [Sources.Icon]: MenuOptions.Icon,
};

export type FormIllustrationProps = {
  value: ImageType | IconFormNames | null;
  onChange: (illustration: ImageType | IconFormNames | null) => void;
  placeholder?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  sources?: Sources[];
  premiumSources?: Sources[];
  uploadLocation: ImageLocations;
  onUploadStatusChanged?: (isLoading: boolean) => void;
  onPremium?: () => void;
  isRounded?: boolean;
  clearOnUploadFinish?: boolean;
};

export const FormIllustration: React.FC<FormIllustrationProps> = ({
  value,
  onChange,
  placeholder = ImageIcon,
  sources = allSources,
  premiumSources = [],
  uploadLocation,
  onUploadStatusChanged,
  onPremium,
  isRounded,
  clearOnUploadFinish,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [menuOpen, setMenuOpen] = useState(MenuOptions.None);

  const { uploadImage, temporaryUrl, imageUrl, reset } = useUploadImage(
    uploadLocation,
    (image) => {
      onChange(image);
      onUploadStatusChanged?.(false);
    },
  );

  const image = useMemo(
    () =>
      value && typeof value !== 'string'
        ? {
            url: imageUrl ?? temporaryUrl ?? value.url,
            blurHash: imageUrl || temporaryUrl ? '' : value.blurHash,
          }
        : undefined,
    [imageUrl, temporaryUrl, value],
  );

  const closeMenu = () => setMenuOpen(MenuOptions.None);

  const openSourcePicker = () => setMenuOpen(MenuOptions.SourcePicker);

  const onSource = (source: Sources) =>
    setMenuOpen(sourcesToMenuOptions[source]);

  const onUpload = (image: File) => {
    onUploadStatusChanged?.(true);
    uploadImage(image);
    closeMenu();
  };

  const _onChange = (image: ImageType | IconFormNames | null) => {
    onChange(image);
    closeMenu();
  };

  const _onPremium = () => {
    onPremium?.();
    closeMenu();
  };

  const onClear = (e: React.MouseEvent) => {
    e.stopPropagation();
    onChange(null);
    closeMenu();
    reset();
  };

  useEffect(() => {
    if (clearOnUploadFinish && imageUrl) {
      reset();
    }
  }, [clearOnUploadFinish, imageUrl, reset]);

  return (
    <Styled.Container ref={containerRef}>
      <Styled.Button
        $rounded={!!isRounded}
        $hasImage={!!value && typeof value !== 'string'}
        onClick={openSourcePicker}
        type="button"
      >
        <Styled.IllustrationContainer $rounded={!!isRounded}>
          {image ? (
            <ImagePreview image={image} isLoading={!!temporaryUrl} />
          ) : (
            <Styled.IconContainer>
              <Icon
                icon={
                  value ? IconFormIcons[value as IconFormNames] : placeholder
                }
              />
            </Styled.IconContainer>
          )}
        </Styled.IllustrationContainer>

        {!!value && (
          <Styled.ClearButton onClick={onClear}>
            <Icon icon={X} />
          </Styled.ClearButton>
        )}
      </Styled.Button>

      {menuOpen === MenuOptions.SourcePicker && (
        <SourcePickerMenu
          referenceElement={containerRef}
          position="right-start"
          onSource={onSource}
          sources={sources}
          premiumSources={premiumSources}
          onPremium={_onPremium}
          onUpload={onUpload}
          onOutside={closeMenu}
        />
      )}
      {menuOpen === MenuOptions.Unsplash && (
        <UnsplashMenu
          referenceElement={containerRef}
          position="right-start"
          onImage={_onChange}
          onOutside={closeMenu}
        />
      )}
      {menuOpen === MenuOptions.Icon && (
        <IconMenu
          referenceElement={containerRef}
          position="right-start"
          selectedIcon={typeof value === 'string' ? value : undefined}
          onChange={_onChange}
          onOutside={closeMenu}
        />
      )}
    </Styled.Container>
  );
};
