import { FrequencyUnit, HabitSchedule } from 'shared/types/habit-schedule';

export const getScheduleFrequencyDayTarget = (schedule: HabitSchedule) => {
  if (
    schedule.frequency?.unit === FrequencyUnit.Week &&
    schedule.frequency.count <= 7
  ) {
    return 1;
  }
  if (
    schedule.frequency?.unit === FrequencyUnit.Month &&
    schedule.frequency.count <= 28
  ) {
    return 1;
  }
  if (
    schedule.frequency?.unit === FrequencyUnit.Year &&
    schedule.frequency.count <= 365
  ) {
    return 1;
  }

  return null;
};
