import { useCreateTag, useTags } from 'features/tags';
import { useUpdateTaskRelationships } from 'features/tasks';
import React from 'react';
import { TaskMetaInteractionTags as TaskMetaInteraction } from 'shared/components/ui/task-meta-interaction-tags';
import { defaultTagColorOption } from 'shared/constants';
import { useRelationshipIdsById } from 'shared/hooks/use-relationships';
import { ID, IDPrefixes } from 'shared/types/id';

import * as Styled from './task-meta-interactions.style';

export type TaskMetaInteractionTagsProps = {
  id: ID;
  hideEmpty?: boolean;
  isMobileOutlined?: boolean;
};

export const TaskMetaInteractionTags: React.FC<
  TaskMetaInteractionTagsProps
> = ({ id, hideEmpty, isMobileOutlined }) => {
  const tags = useTags();
  const { tag: tagIds } = useRelationshipIdsById(id, IDPrefixes.Task);
  const updateRelationship = useUpdateTaskRelationships(id, IDPrefixes.Tag);
  const { submit: createTag } = useCreateTag();

  const onCreate = async (name: string) => {
    const newTag = await createTag({
      name,
      color: defaultTagColorOption.value,
    });
    updateRelationship([...tagIds, newTag.id]);
  };

  return (
    <Styled.ListItem
      $hasValue={!!tagIds.length}
      $isMobileOutlined={!!isMobileOutlined}
      $hide={!!hideEmpty && !tagIds.length}
    >
      <TaskMetaInteraction
        tags={tags}
        value={tagIds}
        onChange={updateRelationship}
        onCreate={onCreate}
      />
    </Styled.ListItem>
  );
};
