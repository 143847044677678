import { DndContext } from '@dnd-kit/core';
import { useGoals } from 'features/goals';
import { useLocalizedLifeAreas } from 'features/life-areas';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RescheduleButton } from 'shared/components/connected/reschedule-button';
import { TaskCard } from 'shared/components/connected/task-card';
import { OverlayComponentWrapper } from 'shared/components/ui/overlay-component-wrapper';
import { SortableOverlay } from 'shared/components/ui/sortable-overlay';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';
import { useActiveTaskId } from 'shared/contexts/task-detail';
import { useDroppableDatesControls } from 'shared/hooks/use-droppable-dates-controls';
import { useHandleDateSectionDrop } from 'shared/hooks/use-handle-date-section-drop';
import { useSectionCollisionDetection } from 'shared/hooks/use-section-collision-detection';
import { useSortableSensors } from 'shared/hooks/use-sortable-sensors';
import { overdueSectionID } from 'shared/types/orderable-section';
import { DueDisplayOptions } from 'shared/types/task-base';
import { TaskCardTaskSection } from 'src/shared/components/ui/task-card-task-section';
import { useTheme } from 'styled-components';

import { useNoGroupingTaskSections } from '../../hooks/use-no-grouping-task-sections';
import { SectionHeaderToday } from '../section-header-today';
import * as Styled from './no-grouping-tasks-list.style';

const displayDue = [DueDisplayOptions.Past, DueDisplayOptions.Future];

export const NoGroupingTasksList: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const openPremiumDialog = useOpenPremiumDialog();

  const { taskCardTasks, sections: initialSections } =
    useNoGroupingTaskSections();
  const onDragEnd = useHandleDateSectionDrop(initialSections);
  const {
    handleDragStart,
    handleDragOver,
    handleDragEnd,
    handleDragCancel,
    activeId,
    sections,
  } = useDroppableDatesControls({ initialSections, onDragEnd });
  const sensors = useSortableSensors();
  const collisionStrategy = useSectionCollisionDetection(activeId, sections);

  const selectedTask = useActiveTaskId();

  const goals = useGoals();
  const lifeAreas = useLocalizedLifeAreas();

  const activeItem = useMemo(
    () =>
      activeId ? taskCardTasks.find(({ id }) => id === activeId) : undefined,
    [activeId, taskCardTasks],
  );

  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragMove={handleDragOver}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
      sensors={sensors}
      collisionDetection={collisionStrategy}
    >
      <Styled.Container>
        {sections.map(({ id, items, deadline }) => (
          <Styled.Section key={id}>
            <TaskCardTaskSection
              date={deadline}
              tasks={items}
              displayDue={displayDue}
              selectedTask={selectedTask}
              goals={goals}
              lifeAreas={lifeAreas}
              customHeader={
                sections.length > 1 ? (
                  <SectionHeaderToday
                    title={t(
                      id === overdueSectionID
                        ? 'general.sections.overdue.title'
                        : 'pages.today.title',
                    )}
                    titleCount={items.length}
                    actions={
                      id === overdueSectionID
                        ? [
                            <RescheduleButton
                              tasks={items.map(({ raw }) => raw.task)}
                            />,
                          ]
                        : undefined
                    }
                  />
                ) : undefined
              }
              droppableId={id}
              canCollapse={
                id === overdueSectionID ? !theme.isMobile : sections.length > 1
              }
              canCreateTask={id !== overdueSectionID}
              taskIsMoving={!!activeItem}
              onPremium={openPremiumDialog}
              canReorder
            />
          </Styled.Section>
        ))}
      </Styled.Container>

      <SortableOverlay>
        {!!activeItem && (
          <OverlayComponentWrapper>
            <TaskCard
              task={activeItem.raw.task}
              displayDue={displayDue}
              noHover
            />
          </OverlayComponentWrapper>
        )}
      </SortableOverlay>
    </DndContext>
  );
};
