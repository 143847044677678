import { typographyToCss } from 'shared/lib/mui';
import styled from 'styled-components';

export const NavigationSectionContainer = styled.div``;

export const NavigationSectionHeader = styled.div<{ $isFirst: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};
  padding: ${({ $isFirst, theme }) =>
    $isFirst
      ? `0 ${theme.spacing(0.5)} 0 ${theme.spacing(3)}`
      : `${theme.spacing(3)} ${theme.spacing(0.5)} 0 ${theme.spacing(3)}`};

  width: 100%;

  color: ${({ theme }) => theme.palette.grey['500']};
`;

export const NavigationSectionInteractions = styled.div``;

export const NavigationSectionTitle = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.caption)};
  color: ${({ theme }) => theme.palette.text.primary};
`;
