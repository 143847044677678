import Dialog from '@mui/material/Dialog';
import React, { useMemo } from 'react';
import { TagForm } from 'shared/components/ui/tag-form';
import { TagFormFields } from 'shared/types/tag-form';

import { TagFormError } from './tag-form-error';
import { TagFormLoading } from './tag-form-loading';

export type TagFormDialogProps = {
  initialValues?: TagFormFields;
  open?: boolean;
  onClose: () => void;
  onSubmit: (tag: TagFormFields) => void;
  onRetry: () => void;
  isLoading?: boolean;
  isError?: boolean;
  isEdit?: boolean;
};

export const TagFormDialog: React.FC<TagFormDialogProps> = ({
  initialValues,
  open,
  onClose,
  onSubmit,
  onRetry,
  isLoading,
  isError,
  isEdit,
}) => {
  const content = useMemo(() => {
    if (isError) {
      return (
        <TagFormError onRetry={onRetry} onCancel={onClose} isEdit={isEdit} />
      );
    }
    if (isLoading) {
      return <TagFormLoading />;
    }

    return (
      <TagForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        onCancel={onClose}
        isEdit={isEdit}
      />
    );
  }, [initialValues, isEdit, isError, isLoading, onClose, onRetry, onSubmit]);

  return <Dialog open={!!open}>{content}</Dialog>;
};
