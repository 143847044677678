import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useWindowVirtualizer } from '@tanstack/react-virtual';
import React, { useMemo, useRef } from 'react';
import { ID } from 'shared/types/id';
import { Task } from 'shared/types/task';

import { TaskCardListItem } from './task-card-list-item';
import * as Styled from './tasks-list.style';

export type VirtualizedTasksListProps = Omit<
  React.ComponentProps<typeof TaskCardListItem>,
  'task' | 'hasDnd' | 'isSelected' | 'isDisabled'
> & {
  tasks: Task[];
  selectedTask?: ID;
  offsetTop?: number;
  canReorder?: boolean;
};

export const VirtualizedTasksList: React.FC<VirtualizedTasksListProps> = ({
  tasks,
  selectedTask,
  offsetTop = 0,
  canReorder,
  ...rest
}) => {
  const listContainerRef = useRef<HTMLDivElement>(null);

  const virtualizer = useWindowVirtualizer({
    count: tasks.length,
    estimateSize: () => 34,
    overscan: 5,
    scrollMargin: (listContainerRef.current?.offsetTop ?? 0) + offsetTop,
  });

  const ReorderWrapper = canReorder ? SortableContext : React.Fragment;
  const reorderWrapperProps = useMemo(
    () =>
      canReorder
        ? {
            items: tasks.map(({ id }) => id),
            strategy: verticalListSortingStrategy,
          }
        : ({} as { items: string[] }),
    [canReorder, tasks],
  );

  return (
    <ReorderWrapper {...reorderWrapperProps}>
      <Styled.VirtualListContainer ref={listContainerRef}>
        <Styled.List
          $isVirtualList
          style={{ height: `${virtualizer.getTotalSize()}px` }}
        >
          {virtualizer.getVirtualItems().map((item) => {
            const task = tasks[item.index];

            return (
              <Styled.ListItem
                key={item.key}
                data-index={item.index}
                ref={virtualizer.measureElement}
                $isVirtualListItem
                style={{
                  transform: `translateY(${
                    item.start - virtualizer.options.scrollMargin
                  }px)`,
                }}
              >
                <TaskCardListItem
                  task={task}
                  hasDnd={canReorder}
                  isSelected={selectedTask === task.id}
                  {...rest}
                />
              </Styled.ListItem>
            );
          })}
        </Styled.List>
      </Styled.VirtualListContainer>
    </ReorderWrapper>
  );
};
