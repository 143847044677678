import { Skeleton } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Button, Colors, Sizes, Variants } from 'shared/components/ui/button';

import { LoadingHeader } from './header';
import { LoadingPaymentOptions } from './payment-options';
import * as Styled from './purchase-premium.style';
import { UspList } from './usp-list';

export type LoadingPurchasePremiumProps = {
  onClose: () => void;
};

export const LoadingPurchasePremium: React.FC<LoadingPurchasePremiumProps> = ({
  onClose,
}) => (
  <Styled.Container>
    <LoadingHeader onClose={onClose} />

    <Styled.Body>
      <UspList />

      <Styled.PaymentForm>
        <Styled.PaymentOptions>
          <LoadingPaymentOptions />
        </Styled.PaymentOptions>

        <Styled.Interactions>
          <Button
            variant={Variants.Contained}
            size={Sizes.Large}
            color={Colors.Primary}
            disabled
            fullWidth
            alignCenter
            disabledBackgroundOnly
          >
            <Styled.Loader color="primary" size={20} />
          </Button>
          <Typography variant="overline">
            <Skeleton animation="wave" />
          </Typography>
        </Styled.Interactions>
      </Styled.PaymentForm>
    </Styled.Body>
  </Styled.Container>
);
