import { useGoals } from 'features/goals';
import { useLocalizedLifeAreas } from 'features/life-areas';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CalendarUpcoming, IconFormIcons, Inbox } from 'shared/assets/icons';
import { ListSectionGoalHeader } from 'shared/components/ui/list-section-goal-header';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';
import { ID } from 'shared/types/id';
import {
  goalInboxID,
  unlinkedPlannedTasksID,
} from 'shared/types/orderable-section';
import { getGoalColor } from 'shared/utils/get-goal-color';

export type GoalHeaderProps = {
  goalId: ID;
  showInboxAsInbox?: boolean;
};

export const GoalHeader: React.FC<GoalHeaderProps> = ({
  goalId,
  showInboxAsInbox,
}) => {
  const { t } = useTranslation();
  const onPremium = useOpenPremiumDialog();
  const goals = useGoals();
  const lifeAreas = useLocalizedLifeAreas();

  const goal = useMemo(
    () => goals.find(({ id }) => id === goalId),
    [goals, goalId],
  );

  const goalColor = useMemo(
    () =>
      goal?.id
        ? getGoalColor(goal.id, { lifeAreas, goals }) ?? undefined
        : undefined,
    [goal?.id, goals, lifeAreas],
  );

  const goalName =
    goalId === goalInboxID
      ? t(
          showInboxAsInbox
            ? 'general.sections.inbox.title'
            : 'general.sections.goals.other.title',
        )
      : goalId === unlinkedPlannedTasksID
        ? t('general.sections.unlinked-planned-tasks.title')
        : goal?.name ?? '';

  const goalIcon = goal?.iconName
    ? IconFormIcons[goal.iconName]
    : goalId === goalInboxID
      ? Inbox
      : goalId === unlinkedPlannedTasksID
        ? CalendarUpcoming
        : undefined;

  return (
    <ListSectionGoalHeader
      id={goalId}
      name={goalName}
      image={goal?.image}
      imagePlaceholderIcon={goalIcon}
      color={goalColor}
      onPremium={onPremium}
      isFrozen={!!goal?.frozenAt}
      isLink={
        (showInboxAsInbox && goalId === goalInboxID) ||
        ![goalInboxID, unlinkedPlannedTasksID].includes(goalId)
      }
    />
  );
};
