import { HabitSortingMode } from 'shared/types/sorting-mode';

import { getUser, getUserRef } from './get-user';
import { updateDocWithTimestamp } from './helpers';

export const updateUserHabitSortMode = async (sort: HabitSortingMode) => {
  const user = await getUser();

  if (!user) {
    return;
  }

  const userRef = await getUserRef();

  if (!userRef) {
    return;
  }

  updateDocWithTimestamp(userRef, {
    'settings.habitSortingMode': sort,
  });
};
