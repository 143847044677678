import React, { useEffect, useState } from 'react';
import { ResizableInput } from 'shared/components/ui/resizable-input';
import { isNumber } from 'shared/utils/is-number';

export type IntervalInputProps = {
  value: number;
  onChange: (value: number | undefined) => void;
};

export const IntervalInput: React.FC<IntervalInputProps> = ({
  value,
  onChange,
}) => {
  const [val, setVal] = useState(value || '');

  const _onBlur = () => {
    onChange(isNumber(val) ? (val as number) : undefined);
  };

  const _onChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setVal(Math.abs(parseInt(e.target.value)) || '');

  useEffect(() => {
    setVal(value);
  }, [value]);

  return (
    <ResizableInput
      type="number"
      value={val}
      onChange={_onChange}
      onBlur={_onBlur}
      minWidth="2rem"
      showAsSmallFormInput
    />
  );
};
