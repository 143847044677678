import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TaskPriorityOptions } from 'shared/types/task-base';

import * as Styled from './priority-section-header.style';

export type PrioritySectionHeaderProps = {
  priority?: TaskPriorityOptions;
  count?: number;
};

export const PrioritySectionHeader: React.FC<PrioritySectionHeaderProps> =
  React.memo(({ priority = TaskPriorityOptions.None, count }) => {
    const { t } = useTranslation();

    return (
      <Styled.Container>
        <Styled.TitleContainer>
          <Typography variant="h6" component="span">
            {t(`general.sections.priority.${priority}.title`)}
          </Typography>
          {count !== undefined && (
            <Styled.TitleCount>{count}</Styled.TitleCount>
          )}
        </Styled.TitleContainer>
      </Styled.Container>
    );
  });
