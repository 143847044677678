import { useSortable } from '@dnd-kit/sortable';
import { endOfWeek, startOfWeek } from 'date-fns';
import React, { useMemo, useRef } from 'react';
import {
  BusinessChart2,
  Check,
  IconFormIcons,
  IconFormNames,
  Lock,
  Text,
} from 'shared/assets/icons';
import { CircleProgress } from 'shared/components/ui/circle-progress';
import { Icon } from 'shared/components/ui/icon';
import { IconButton, Sizes, Variants } from 'shared/components/ui/icon-button';
import { Tooltip } from 'shared/components/ui/tooltip';
import { weekdaysNumberMap } from 'shared/constants';
import { Goal } from 'shared/types/goal';
import { Habit } from 'shared/types/habit';
import { ScheduleEntryState } from 'shared/types/habit-schedule';
import { ID } from 'shared/types/id';
import { LifeArea } from 'shared/types/life-area';
import { Timestamp } from 'shared/types/timestamp';
import { WeekDays } from 'shared/types/week-days';
import { getGoalColor } from 'shared/utils/get-goal-color';
import { isNumber } from 'shared/utils/is-number';

import { useWeekStatistics } from '../../hooks/use-week-statistics';
import * as Styled from './habit-detail-desktop-card-view.style';
import { HabitWeek } from './habit-week';

type SortableContainerProps = React.ComponentProps<typeof Styled.Container> & {
  id: ID;
};

const SortableContainer: React.FC<SortableContainerProps> = ({
  id,
  ...rest
}) => {
  const {
    setNodeRef,
    attributes,
    listeners,
    isDragging,
    transform,
    transition,
  } = useSortable({ id });

  return (
    <Styled.DndContainer
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      $isDragging={isDragging}
      $transform={transform}
      $transition={transition}
      role="presentation"
    >
      <Styled.Container {...rest} />
    </Styled.DndContainer>
  );
};

export type HabitDetailDesktopCardViewProps = {
  habit: Habit;
  goals: Goal[];
  lifeAreas: LifeArea[];
  onClick?: (id: ID) => void;
  weekDate: Timestamp;
  weekStartsOn: WeekDays;
  onDate: (
    id: ID,
    date: Timestamp,
    type: ScheduleEntryState,
    options?: { value?: number; increment?: number },
  ) => void;
  onDateManual: (id: ID, date: Timestamp) => void;
  onStatistics?: (id: ID) => void;
  onContextMenu?: (e: React.MouseEvent) => void;
  isSortable?: boolean;
};

export const HabitDetailDesktopCardView: React.FC<
  HabitDetailDesktopCardViewProps
> = ({
  habit,
  goals,
  lifeAreas,
  onClick,
  weekDate,
  weekStartsOn,
  onDate,
  onDateManual,
  onStatistics,
  onContextMenu,
  isSortable,
}) => {
  const percentageRef = useRef<HTMLSpanElement>(null);
  const activeLinkedItem = useMemo(
    () =>
      goals.find(({ id }) => habit.goalId === id) ??
      lifeAreas.find(({ id }) => id === habit.lifeAreaId),
    [goals, lifeAreas, habit.goalId, habit.lifeAreaId],
  );

  const linkedItemColor = useMemo(
    () =>
      activeLinkedItem
        ? getGoalColor(activeLinkedItem.id, { goals, lifeAreas })
        : undefined,
    [activeLinkedItem, goals, lifeAreas],
  );

  const { weekStartDate, weekEndDate } = useMemo(
    () => ({
      weekStartDate: startOfWeek(weekDate, {
        weekStartsOn: weekdaysNumberMap[weekStartsOn],
      }),
      weekEndDate: endOfWeek(weekDate, {
        weekStartsOn: weekdaysNumberMap[weekStartsOn],
      }),
    }),
    [weekDate, weekStartsOn],
  );

  const {
    occurrences,
    dateEntries,
    perfectWeek,
    completionsCount,
    target,
    completionPercentage,
  } = useWeekStatistics(habit.schedules, {
    startDate: weekStartDate,
    endDate: weekEndDate,
    weekStartsOn,
  });

  const _onClick = () => onClick?.(habit.id);

  const _onDate = (
    date: Timestamp,
    type: ScheduleEntryState,
    options?: { value?: number; increment?: number },
  ) => onDate(habit.id, date, type, options);

  const _onDateManual = (date: Timestamp) => onDateManual(habit.id, date);

  const _onStatistics = () => onStatistics?.(habit.id);

  const Container = isSortable ? SortableContainer : Styled.Container;

  return (
    <Container
      id={habit.id}
      $isDisabled={!!habit.frozenAt || !!habit.archivedAt}
      onContextMenu={onContextMenu}
    >
      <Styled.Details onClick={_onClick} $isFrozen={!!habit.frozenAt}>
        {/* Icon */}
        <Styled.IconContainer>
          <Icon
            icon={
              IconFormIcons[habit.iconName!] ??
              IconFormIcons[IconFormNames.Repeat]
            }
          />
          {!!habit.completedAt && (
            <Styled.CompletedContainer>
              <Icon icon={Check} />
            </Styled.CompletedContainer>
          )}
          {!!linkedItemColor && <Styled.ColorDot $color={linkedItemColor} />}
        </Styled.IconContainer>

        {/* Title */}
        <Styled.TitleContainer>
          <Styled.Title>{habit.name}</Styled.Title>

          {(!!habit.description || !!habit.childIds?.length) && (
            <Styled.TitleIconContainer $secondary>
              <Icon icon={Text} />
            </Styled.TitleIconContainer>
          )}

          {!!habit.frozenAt && (
            <Styled.TitleIconContainer>
              <Icon icon={Lock} />
            </Styled.TitleIconContainer>
          )}
        </Styled.TitleContainer>
      </Styled.Details>

      {!habit.completedAt && (
        <Styled.WeeksContainer
          $disabled={!!habit.archivedAt || !!habit.frozenAt}
        >
          <HabitWeek
            startDate={weekDate}
            weekStartsOn={weekStartsOn}
            occurrences={occurrences}
            dateEntries={dateEntries}
            isPerfectWeek={!!perfectWeek}
            onDate={_onDate}
            onDateManual={_onDateManual}
          />
        </Styled.WeeksContainer>
      )}

      <Styled.MetaContainer
        $isProgress
        $completed={(completionPercentage ?? 0) >= 100}
        $disabled={!!habit.archivedAt || !!habit.frozenAt}
      >
        {isNumber(completionsCount) && isNumber(target) && (
          <Tooltip
            label={`${completionsCount}/${target}`}
            referenceElement={percentageRef}
          />
        )}
        <CircleProgress
          ref={percentageRef}
          percentage={completionPercentage}
          isSmall
          isPrimary
          showCheckOnComplete
        />
      </Styled.MetaContainer>

      {!!onStatistics && (
        <Styled.MetaContainer
          $disabled={!!habit.archivedAt || !!habit.frozenAt}
        >
          <IconButton
            icon={BusinessChart2}
            onClick={_onStatistics}
            size={Sizes.Small}
            variant={Variants.Contained}
          />
        </Styled.MetaContainer>
      )}
    </Container>
  );
};
