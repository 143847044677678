import { useTaskById, useTaskTodayControls } from 'features/tasks';
import React, { useEffect, useRef } from 'react';
import { useBlockMobileScrollOutsideElement } from 'shared/hooks/use-block-mobile-scroll-outside-element';
import { useUser } from 'shared/hooks/use-user';
import { ScheduleEntryState } from 'shared/types/habit-schedule';
import { ID } from 'shared/types/id';
import { InsightsTimeframes } from 'shared/types/insights';
import { TaskType } from 'shared/types/task-base';
import { getWeekStartsOn } from 'shared/utils/get-week-starts-on';

import * as Styled from './task-detail-column.style';
import { TaskDetails } from './task-details';
import { TaskStatistics } from './task-statistics';

export type TaskDetailColumnProps = {
  taskId: ID;
  showStatistics: boolean;
  statisticsTimeframe?: InsightsTimeframes;
  onClose: () => void;
};

export const TaskDetailColumn: React.FC<TaskDetailColumnProps> = ({
  taskId,
  showStatistics,
  statisticsTimeframe,
  onClose,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { onComplete, onUpdate, openManualEntryLogging } =
    useTaskTodayControls();
  const task = useTaskById(taskId);
  const user = useUser();
  const weekStartsOn = getWeekStartsOn(user);

  const onChangeComplete = () => !!task && !task.frozenAt && onComplete(task);
  const _onEntryChange = (
    type: ScheduleEntryState,
    options?: { value?: number; increment?: number },
  ) => (task ? onComplete(task, type, options) : undefined);

  const onManual =
    task && [TaskType.Habit, TaskType.Repeating].includes(task.type)
      ? () => openManualEntryLogging(task.id, new Date())
      : undefined;

  const onChangeTitle = (name: string) =>
    !!task && !task.frozenAt && onUpdate({ ...task, name });

  const onChangeNotes = (description: string | null) =>
    !!task && !task.frozenAt && onUpdate({ ...task, description });

  useEffect(() => {
    if (!task) {
      onClose();
    }
  }, [onClose, task]);
  useBlockMobileScrollOutsideElement(containerRef);

  return !!task ? (
    <Styled.Container ref={containerRef}>
      {showStatistics && task.type === TaskType.Habit ? (
        <TaskStatistics task={task} initialTimeframe={statisticsTimeframe} />
      ) : (
        <TaskDetails
          task={task}
          onComplete={onChangeComplete}
          onEntryChange={_onEntryChange}
          onEntryManual={onManual}
          onChangeTitle={onChangeTitle}
          onChangeNotes={onChangeNotes}
          weekStartsOn={weekStartsOn}
        />
      )}
    </Styled.Container>
  ) : null;
};
