import Dialog from '@mui/material/Dialog';
import { useHabits } from 'features/tasks';
import React, { useMemo } from 'react';
import { Habit } from 'shared/types/habit';
import { ID } from 'shared/types/id';
import { Timestamp } from 'shared/types/timestamp';

import { ManualEntryLogForm } from './manual-entry-log-form';

export type HabitManualEntryLogDialogProps = {
  habitId?: ID;
  date?: Timestamp;
  onClose: () => void;
  onSubmit: (habit: Habit, date: Timestamp, value: number) => void;
};

export const HabitManualEntryLogDialog: React.FC<
  HabitManualEntryLogDialogProps
> = ({ habitId, date, onSubmit, onClose }) => {
  const habits = useHabits();
  const habit = useMemo(
    () => habits.find(({ id }) => id === habitId),
    [habitId, habits],
  );

  const open = !!habit && !!date;

  return (
    <Dialog open={open} fullWidth onClose={onClose}>
      {open && (
        <ManualEntryLogForm
          habit={habit!}
          date={date!}
          onSubmit={onSubmit}
          onCancel={onClose}
        />
      )}
    </Dialog>
  );
};
