import styled from 'styled-components';

export const Container = styled.div``;

export const Divider = styled.div`
  padding: ${({ theme }) =>
    `${theme.spacing(6)} ${theme.spacing(6)} ${theme.spacing(4)} ${theme.spacing(6)}`};
  width: 100%;

  &::before {
    content: '';
    display: block;
    height: 1px;
    width: 100%;

    background: ${({ theme }) => theme.palette.divider};
  }

  &:last-child {
    padding: 0;

    &:before {
      content: none;
    }
  }
`;
