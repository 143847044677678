import { useMemo } from 'react';
import { HabitSchedule } from 'shared/types/habit-schedule';
import { Timestamp } from 'shared/types/timestamp';
import { getTimeframeSchedules } from 'shared/utils/get-timeframe-schedules';

export type Options = {
  startDate: Timestamp;
  endDate: Timestamp;
};

export const useTimeframeSchedules = (
  schedules: HabitSchedule[],
  { startDate, endDate }: Options,
) =>
  useMemo(
    () => getTimeframeSchedules(schedules, { startDate, endDate }),
    [endDate, schedules, startDate],
  );
