import { useCallback } from 'react';
import { useUpdateHabitLifeAreaTaskOrderMutation } from 'shared/hooks/queries/use-update-habit-life-area-task-order-mutation';
import { ID } from 'shared/types/id';

export const useLifeAreaHabitReorder = () => {
  const { mutate } = useUpdateHabitLifeAreaTaskOrderMutation();

  return useCallback(
    (id: ID, taskIds: ID[]) => mutate({ id, taskIds }),
    [mutate],
  );
};
