import { useGoals } from 'features/goals';
import { useLocalizedLifeAreas } from 'features/life-areas';
import { useTagTasks } from 'features/tags';
import React from 'react';
import { useParams } from 'react-router';
import { TaskSection } from 'shared/components/ui/task-section';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';
import { useActiveTaskId } from 'shared/contexts/task-detail';

import * as Styled from './no-grouping-tasks-list.style';

export const NoGroupingTasksList: React.FC = () => {
  const { id } = useParams();
  const tasks = useTagTasks(id);

  const openPremiumDialog = useOpenPremiumDialog();

  const selectedTask = useActiveTaskId();

  const goals = useGoals();
  const lifeAreas = useLocalizedLifeAreas();

  return (
    <Styled.Container>
      <TaskSection
        goals={goals}
        lifeAreas={lifeAreas}
        onPremium={openPremiumDialog}
        tasks={tasks}
        tagIds={id ? [id] : undefined}
        selectedTask={selectedTask}
        canCreateTask
      />
    </Styled.Container>
  );
};
