import Typography from '@mui/material/Typography';
import React, { forwardRef, useMemo, useRef } from 'react';
import { ChevronLeft, X } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import {
  Colors,
  PopupMenu,
  PopupMenuButton,
  PopupMenuList,
  PopupMenuListItem,
} from 'shared/components/ui/popup-menu';
import { useForwardedRef } from 'shared/hooks/use-forwarded-ref';
import { useOpenMenu } from 'shared/hooks/use-open-menu';

import * as Styled from './custom-form-select.style';

const defaultRenderOption: React.FC<Option> = ({ label }) => label;

export type Option = {
  value: string;
  label: string;
  description?: string;
  start?: React.ReactNode;
  hasBottomSeparator?: boolean;
};

export type CustomFormSelectProps = {
  options: Option[];
  value?: string;
  placeholder?: string;
  interActionOption?: Option;
  onInteractionOption?: () => void;
  onChange?: (value?: string) => void;
  startIconColor?: Colors;
  endColor?: Colors;
  canClear?: boolean;
  renderValue?: React.FC<Option>;
};

export const CustomFormSelect = forwardRef<
  HTMLDivElement,
  CustomFormSelectProps
>(
  (
    {
      options,
      value,
      placeholder,
      onChange,
      interActionOption,
      onInteractionOption,
      startIconColor,
      endColor,
      canClear,
      renderValue: RenderValue = defaultRenderOption,
    },
    ref,
  ) => {
    const containerRef = useForwardedRef<HTMLDivElement>(ref);
    const menuRef = useRef<HTMLDivElement>(null);
    const { menuOpen, openMenu, closeMenu } = useOpenMenu();

    const optionsLists = useMemo(
      () =>
        options.reduce<Option[][]>(
          (acc, option) => {
            acc[acc.length - 1].push(option);

            if (option.hasBottomSeparator) {
              acc.push([]);
            }

            return acc;
          },
          [[]],
        ),
      [options],
    );

    const _onChange = (e: React.MouseEvent) => {
      const id = e.currentTarget?.getAttribute('data-value');
      if (id) {
        onChange?.(id);
      }

      closeMenu();
    };

    const onClear = (e: React.MouseEvent) => {
      e.preventDefault();
      onChange?.(undefined);
      closeMenu();
    };

    const selectedOption = useMemo(
      () => options.find((option) => option.value === value),
      [options, value],
    );

    return (
      <Styled.InputContainer ref={containerRef}>
        <Styled.Button
          $isPlaceholder={!selectedOption}
          onClick={openMenu}
          type="button"
        >
          {selectedOption ? <RenderValue {...selectedOption} /> : placeholder}
        </Styled.Button>

        <Styled.InteractionContainer>
          {selectedOption && canClear ? (
            <Styled.ClearButton onClick={onClear} type="button" tabIndex={-1}>
              <Icon icon={X} />
            </Styled.ClearButton>
          ) : (
            <Styled.IconContainer onClick={openMenu}>
              <Icon icon={ChevronLeft} />
            </Styled.IconContainer>
          )}
        </Styled.InteractionContainer>

        {menuOpen && (
          <PopupMenu
            ref={menuRef}
            referenceElement={containerRef}
            onOutside={closeMenu}
            position="bottom"
            fallbackPositions={[
              'top',
              'right-end',
              'right-start',
              'bottom-end',
              'bottom-start',
              'left-start',
              'left-end',
              'top-end',
              'top-start',
            ]}
            hasParentWidth
          >
            {optionsLists.map((optionsList, index) => (
              <PopupMenuList
                key={`options-${index}`}
                hasBottomBorder={index !== optionsLists.length - 1}
              >
                {optionsList.map((option) => (
                  <PopupMenuListItem key={option.value}>
                    <PopupMenuButton
                      start={option.start}
                      startColor={startIconColor}
                      onClick={_onChange}
                      data-value={option.value}
                      end={
                        option.description ? (
                          <Typography variant="overline">
                            {option.description}
                          </Typography>
                        ) : undefined
                      }
                      endColor={endColor}
                    >
                      {option.label}
                    </PopupMenuButton>
                  </PopupMenuListItem>
                ))}
              </PopupMenuList>
            ))}

            {!!interActionOption && (
              <PopupMenuList>
                <PopupMenuListItem>
                  <PopupMenuButton
                    start={interActionOption.start}
                    end={
                      <Typography variant="overline">
                        {interActionOption.description}
                      </Typography>
                    }
                    onClick={onInteractionOption}
                  >
                    {interActionOption.label}
                  </PopupMenuButton>
                </PopupMenuListItem>
              </PopupMenuList>
            )}
          </PopupMenu>
        )}
      </Styled.InputContainer>
    );
  },
);
