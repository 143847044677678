import { ID } from 'shared/types/id';
import { lifeAreaOtherID } from 'shared/types/orderable-section';
import { HabitSortingMode } from 'shared/types/sorting-mode';

import { getUser, getUserRef } from './get-user';
import { updateDocWithTimestamp } from './helpers';

export const updateHabitLifeAreaTaskOrder = async (
  lifeAreaSorting: { lifeAreaId: ID; taskIds: ID[] }[],
) => {
  const user = await getUser();
  // without a user we cannot update anything.
  if (!user) {
    return;
  }

  const userRef = await getUserRef();

  if (!userRef) {
    return;
  }

  const settingsToUpdate = lifeAreaSorting.reduce<
    Record<string, ID[] | HabitSortingMode>
  >(
    (acc, { lifeAreaId, taskIds }) => {
      acc[
        `settings.habitGroupSorting.lifeArea.sorting.${lifeAreaId === lifeAreaOtherID ? null : lifeAreaId}`
      ] = taskIds;
      return acc;
    },
    {
      'settings.habitSortingMode': HabitSortingMode.Custom,
    },
  );

  updateDocWithTimestamp(userRef, settingsToUpdate);
};
