import React, { useMemo, useRef } from 'react';
import { Tag } from 'shared/assets/icons';
import { TagContextMenu } from 'shared/components/connected/tag-context-menu';
import { ColorPickerContextMenu } from 'shared/components/ui/color-picker';
import { Icon } from 'shared/components/ui/icon';
import {
  SortableContainer,
  SortableContainerProps,
} from 'shared/components/ui/sortable-container';
import { useContextMenuState } from 'shared/hooks/use-context-menu-state';
import { useOpenMenu } from 'shared/hooks/use-open-menu';
import { Paths } from 'shared/routes';
import { DraggableOptions } from 'shared/types/draggable-types';
import { Tag as TagType } from 'shared/types/tag';
import { replaceUrlParams } from 'shared/utils/replace-url-params';
import { useTheme } from 'styled-components';

import * as Styled from './tag-side-menu-item.style';

export type TagSideMenuItemProps = {
  tag: TagType;
  currentPath: string;
  onChangeTag?: (tag: TagType) => void;
  isMoving?: boolean;
  hasDnd?: boolean;
};

export const TagSideMenuItem: React.FC<TagSideMenuItemProps> = ({
  tag,
  currentPath,
  isMoving,
  onChangeTag,
  hasDnd,
}) => {
  const theme = useTheme();
  const containerRef = useRef<HTMLAnchorElement>(null);
  const iconButtonRef = useRef<HTMLButtonElement>(null);

  const { position, open, close } = useContextMenuState({
    disabled: theme.isMobile,
  });

  const {
    menuOpen: colorPickerMenuOpen,
    openMenu: openColorPickerMenu,
    closeMenu: closeColorPickerMenu,
  } = useOpenMenu();

  const onChangeColor = (color: string) => {
    onChangeTag?.({ ...tag, color });
    closeColorPickerMenu();
  };

  const url = replaceUrlParams(Paths.TagDetail, { id: tag.id });
  const isActive = !!currentPath && currentPath.includes(url);

  const Container = hasDnd ? SortableContainer : React.Fragment;

  const containerProps = useMemo(
    () =>
      (hasDnd
        ? { id: tag.id, type: DraggableOptions.Tag }
        : {}) as SortableContainerProps,
    [hasDnd, tag.id],
  );

  return (
    <Container {...containerProps}>
      <Styled.Content $active={isActive} $isMoving={!!isMoving}>
        <Styled.IconContainer
          $color={tag.color}
          ref={iconButtonRef}
          onClick={openColorPickerMenu}
        >
          <Icon icon={Tag} />
        </Styled.IconContainer>

        <Styled.Link to={url} ref={containerRef} onContextMenu={open}>
          <Styled.Title>{tag.name}</Styled.Title>
        </Styled.Link>
      </Styled.Content>

      {!!position && (
        <TagContextMenu id={tag.id} onClose={close} location={position} />
      )}

      {colorPickerMenuOpen && !!onChangeTag && (
        <ColorPickerContextMenu
          position="bottom-start"
          onClose={closeColorPickerMenu}
          value={tag.color}
          onChange={onChangeColor}
        />
      )}
    </Container>
  );
};
