import { BlurHashImage } from 'shared/components/connected/blur-hash-image';
import { typographyToCss } from 'shared/lib/mui';
import { QueryNavLink } from 'shared/lib/react-router-dom';
import styled, { css, keyframes } from 'styled-components';

const loadingAnimation = keyframes`
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
`;

export const Title = styled.span<{ $isFrozen: boolean; $isCompleted: boolean }>`
  ${({ theme }) => typographyToCss(theme.typography.buttonSmall)};
  flex: 1;
  min-width: 0;

  overflow: hidden;

  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${({ theme }) => theme.palette.text.primary};
  text-decoration: none;

  ${({ $isCompleted }) =>
    $isCompleted &&
    css`
      text-decoration: line-through;
    `};

  ${({ $isFrozen }) =>
    $isFrozen &&
    css`
      color: ${({ theme }) => theme.palette.text.secondary};
    `};
`;

export const Container = styled(QueryNavLink)`
  display: block;
  width: 100%;

  border-radius: ${({ theme }) => theme.spacing(3)};
  border: 1px solid ${({ theme }) => theme.palette.grey[100]};
  box-shadow: ${({ theme }) => theme.shadowBox.card};
  background: ${({ theme }) => theme.palette.backgroundExtended.card};
  cursor: pointer;

  text-decoration: none;
  color: inherit;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(1)};
  padding: ${({ theme }) =>
    `${theme.spacing(4)} ${theme.spacing(4)} ${theme.spacing(2)}`};

  overflow: hidden;
  min-width: 0;
  width: 100%;
`;

export const GoalInformationContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};

  min-width: 0;
`;

export const IllustrationContainer = styled.span`
  position: relative;
  display: block;
`;

export const ImageContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 2rem;
  width: 2rem;
  min-width: 2rem;

  border-radius: ${({ theme }) => theme.spacing(1.5)};
  background: ${({ theme }) => theme.palette.brown['100']};
  overflow: hidden;

  font-size: 1.2rem;
  line-height: 62.5%;
  color: ${({ theme }) => theme.palette.brown['300']};
`;

export const Image = styled(BlurHashImage)`
  display: block;
  width: 100%;
  height: 100%;

  object-fit: cover;
`;

export const CompletedContainer = styled.span`
  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 1.2rem;
  height: 1.2rem;

  transform: translate(33%, -33%);
  background: ${({ theme }) => theme.palette.success.main};
  border-radius: 50%;

  font-size: 0.8rem;
  color: ${({ theme }) => theme.palette.success.contrastText};
`;

export const ColorDot = styled.span<{ $color: string }>`
  position: absolute;
  bottom: ${({ theme }) => `-${theme.spacing(0.75)}`};
  right: ${({ theme }) => `-${theme.spacing(0.75)}`};

  display: block;
  height: 1rem;
  width: 1rem;

  border: ${({ theme }) =>
    `${theme.spacing(0.5)} solid ${theme.palette.background.default};`};
  border-radius: 50%;
  background: ${({ $color }) => $color};
`;

export const IconButtonContainer = styled.div`
  color: ${({ theme }) => theme.palette.grey['400']};
`;

export const LoadingBody = styled.div`
  width: 100%;
  height: 5.2rem;
  padding: ${({ theme }) =>
    `${theme.spacing(0.5)} ${theme.spacing(4)} ${theme.spacing(1)}`};

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;

    background: ${({ theme }) =>
      `linear-gradient(90deg, ${theme.palette.grey['100']} 25%, ${theme.palette.grey['50']} 50%, ${theme.palette.grey['100']} 75%)`};
    background-size: 200% 100%;
    border-radius: ${({ theme }) => theme.spacing(1)};
    animation: ${loadingAnimation} 1.5s infinite linear;
  }
`;

export const Body = styled.div<{ $isFrozen: boolean }>`
  padding: ${({ theme }) =>
    `${theme.spacing(0.5)} ${theme.spacing(4)} ${theme.spacing(1)}`};
  min-width: 0;

  color: ${({ theme }) => theme.palette.text.primary};

  ${({ $isFrozen }) =>
    $isFrozen &&
    css`
      opacity: 0.5;
    `}
`;

export const CurrentProgress = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.subtitle2)};
  display: block;
  height: 2.8rem;

  overflow: hidden;

  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const CurrentProgressValue = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.h3)};
`;

export const ProgressContainer = styled.span`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)};
  justify-content: space-between;
`;

export const Progress = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.overline)};
  display: inline-flex;
  gap: ${({ theme }) => theme.spacing(1.5)};
  align-items: center;

  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const Dot = styled.span`
  display: inline-block;

  min-width: ${({ theme }) => theme.spacing(1)};
  width: ${({ theme }) => theme.spacing(1)};
  height: ${({ theme }) => theme.spacing(1)};

  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.grey['300']};
`;

export const Difference = styled.span<{ $positive?: boolean }>`
  ${({ theme }) => typographyToCss(theme.typography.caption)};
  display: flex;
  align-items: center;

  color: ${({ $positive, theme }) =>
    $positive ? theme.palette.success.dark : theme.palette.error.dark};
`;

export const DifferenceIconContainer = styled.span<{ $up: boolean }>`
  font-size: 1.2rem;
  line-height: 62.5%;

  transform: rotate(${({ $up }) => ($up ? 0 : 180)}deg);
`;

export const LoadingFooter = styled.div`
  width: 100%;
  height: 12rem;
  padding: ${({ theme }) =>
    `${theme.spacing(1)} ${theme.spacing(4)} ${theme.spacing(4)}`};

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;

    background: ${({ theme }) =>
      `linear-gradient(90deg, ${theme.palette.grey['100']} 25%, ${theme.palette.grey['50']} 50%, ${theme.palette.grey['100']} 75%)`};
    background-size: 200% 100%;
    border-radius: ${({ theme }) => theme.spacing(1)};
    animation: ${loadingAnimation} 1.5s infinite linear;
  }
`;

export const Footer = styled.div<{ $isLink: boolean; $isFrozen: boolean }>`
  display: flex;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacing(2)};

  padding: ${({ theme }) => `${theme.spacing(1)} ${theme.spacing(2)} 0`};

  ${({ $isLink }) =>
    $isLink &&
    css`
      cursor: pointer;
    `};

  ${({ $isFrozen }) =>
    $isFrozen &&
    css`
      opacity: 0.5;
    `}
`;
