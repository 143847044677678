import React, { forwardRef } from 'react';
import { Icon } from 'shared/components/ui/icon';

import * as Styled from './icon-button.style';
import { Sizes, Variants } from './types';

export type IconButtonProps = React.ComponentProps<'button'> & {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  variant?: Variants;
  size?: Sizes;
  rotation?: number;
  as?: string;
  preventDrag?: boolean;
  noHover?: boolean;
};

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      icon,
      variant = Variants.Contained,
      size = Sizes.Medium,
      rotation,
      preventDrag,
      noHover,
      ...rest
    },
    ref,
  ) => (
    <Styled.Button
      {...rest}
      $variant={variant}
      $size={size}
      $preventDrag={!!preventDrag}
      $hasHover={!noHover}
      ref={ref}
    >
      <Styled.IconContainer $rotation={rotation}>
        <Icon icon={icon} />
      </Styled.IconContainer>
    </Styled.Button>
  ),
);
