import { useCallback } from 'react';
import { useUpdateInboxFilterTagsMutation } from 'shared/hooks/queries/use-update-inbox-filter-tags-mutation';
import { useUser } from 'shared/hooks/use-user';
import { ID } from 'shared/types/id';

export const useToggleInboxFilterTag = () => {
  const user = useUser();
  const { mutate: updateFilterTags } = useUpdateInboxFilterTagsMutation();

  return useCallback(
    (id: ID) => {
      const newFilterTags =
        user?.settings?.inboxFilterByTags?.filter((tagId) => tagId !== id) ??
        [];
      if (
        user?.settings?.inboxFilterByTags &&
        user?.settings?.inboxFilterByTags.length === newFilterTags.length
      ) {
        newFilterTags.push(id);
      }

      updateFilterTags(newFilterTags);
    },
    [updateFilterTags, user?.settings?.inboxFilterByTags],
  );
};
