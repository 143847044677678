import { useCreateHabitAllowed, useHabits } from 'features/tasks';
import { useUpdateHabitOrder, useUserHadTrial } from 'features/user';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PremiumBanner } from 'shared/components/ui/premium-banner';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';
import { useOpenTaskDetail } from 'shared/contexts/task-detail';
import { useToday } from 'shared/contexts/today';
import { useUser } from 'shared/hooks/use-user';
import { Habit } from 'shared/types/habit';
import { ID } from 'shared/types/id';
import { SortingMode } from 'shared/types/sorting-mode';
import { getSortedTasks } from 'shared/utils/get-sorted-tasks';
import { getWeekStartsOn } from 'shared/utils/get-week-starts-on';

import { useWeekDate } from '../../contexts/timeframe';
import { SortableHabitsList } from '../sortable-habits-list';
import * as Styled from './no-grouping-habits-list.style';

export const NoGroupingHabitsList: React.FC = () => {
  const { t } = useTranslation();
  const today = useToday();
  const user = useUser();
  const habits = useHabits({ uncompletedOnly: true, unArchivedOnly: true });
  const weekDate = useWeekDate();

  const updateOrder = useUpdateHabitOrder();
  const openTaskDetail = useOpenTaskDetail();

  const {
    allowed: createHabitsAllowed,
    enforced: createHabitLimitsEnforced,
    limit: createHabitLimits,
    total: totalHabits,
  } = useCreateHabitAllowed();
  const openPremiumDialog = useOpenPremiumDialog();
  const userHadTrial = useUserHadTrial();

  const onHabit = (id: ID) => {
    const habit = habits.find((habit) => habit.id === id);
    if (habit && !habit.frozenAt) {
      openTaskDetail(id);
    }
  };

  const activeHabits = useMemo(
    () =>
      getSortedTasks(
        habits.filter(({ frozenAt }) => !frozenAt),
        {
          today,
          weekStartsOn: getWeekStartsOn(user),
          mode: Array.from(
            new Set([SortingMode.DueDate, user?.settings?.habitSortingMode]),
          ).filter(Boolean) as SortingMode[],
          order: user?.settings?.habitSorting ?? [],
        },
      ) as Habit[],
    [habits, today, user],
  );

  const frozenHabits = useMemo(
    () =>
      getSortedTasks(
        habits.filter(({ frozenAt }) => !!frozenAt),
        {
          today,
          weekStartsOn: getWeekStartsOn(user),
          mode: Array.from(
            new Set([SortingMode.DueDate, user?.settings?.habitSortingMode]),
          ).filter(Boolean) as SortingMode[],
          order: user?.settings?.habitSorting ?? [],
        },
      ) as Habit[],
    [habits, today, user],
  );

  const showPremiumBanner =
    !createHabitsAllowed ||
    (!createHabitLimitsEnforced && createHabitLimits < totalHabits);

  return (
    <>
      <SortableHabitsList
        onReorder={updateOrder}
        habits={activeHabits}
        onHabit={onHabit}
        weekDate={weekDate}
      />

      {showPremiumBanner && (
        <Styled.PremiumBannerContainer>
          <PremiumBanner
            title={t(
              createHabitLimitsEnforced
                ? 'pages.habits.premium-banner.title'
                : 'pages.habits.premium-banner.not-enforced.title',
              { total: totalHabits, limit: createHabitLimits },
            )}
            description={t(
              createHabitLimitsEnforced
                ? userHadTrial
                  ? 'pages.habits.premium-banner.description'
                  : 'pages.habits.premium-banner.try-for-free.description'
                : 'pages.habits.premium-banner.not-enforced.description',
              { limit: createHabitLimits },
            )}
            onClick={openPremiumDialog}
            isTrial={!userHadTrial}
          />
        </Styled.PremiumBannerContainer>
      )}

      {!!frozenHabits.length && (
        <Styled.FrozenListContainer>
          <SortableHabitsList
            habits={frozenHabits}
            onHabit={openPremiumDialog}
            weekDate={weekDate}
          />
        </Styled.FrozenListContainer>
      )}
    </>
  );
};
