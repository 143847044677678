import { useGoals } from 'features/goals';
import {
  useLocalizedLifeAreas,
  useUpdateLifeAreaTaskOrder,
} from 'features/life-areas';
import React from 'react';
import { useParams } from 'react-router';
import { SelfSortingTaskCardTaskSection } from 'shared/components/ui/task-card-task-section';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';
import { useActiveTaskId } from 'shared/contexts/task-detail';

import { useLifeAreaTasks } from '../../hooks/use-life-area-tasks';

export const TasksOverview: React.FC = () => {
  const openPremiumDialog = useOpenPremiumDialog();

  const { id } = useParams();
  const { taskCardTasks } = useLifeAreaTasks(id);
  const { updateOrder } = useUpdateLifeAreaTaskOrder(id);

  const selectedTask = useActiveTaskId();

  const goals = useGoals();
  const lifeAreas = useLocalizedLifeAreas();

  return (
    <SelfSortingTaskCardTaskSection
      goals={goals}
      lifeAreas={lifeAreas}
      tasks={taskCardTasks}
      selectedTask={selectedTask}
      lifeAreaId={id}
      onReorder={updateOrder}
      onPremium={openPremiumDialog}
      hideLifeArea
      canCreateTask
    />
  );
};
