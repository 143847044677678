import { useCallback, useRef } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useDeleteTagMutation } from 'shared/hooks/queries/use-delete-tag-mutation';
import { useDeleteFavorites } from 'shared/hooks/use-delete-favorites';
import { useTrackEvents } from 'shared/hooks/use-track-events';
import { Paths } from 'shared/routes';
import { ActionEvents } from 'shared/services/tracking';
import { Tag } from 'shared/types/tag';
import { replaceUrlParams } from 'shared/utils/replace-url-params';

export const useDeleteTag = () => {
  const deleteTagRef = useRef<Tag>();
  const { mutate, isSuccess, reset, error, isPending } = useDeleteTagMutation();
  const track = useTrackEvents();
  const removeFavorite = useDeleteFavorites();
  const { pathname: activePath } = useLocation();
  const navigate = useNavigate();

  const submit = useCallback(
    (tag: Tag) => {
      deleteTagRef.current = tag;
      track(ActionEvents.TagDelete, undefined);
      const tagUrl = replaceUrlParams(Paths.TagDetail, { id: tag.id });

      mutate(tag.id);
      removeFavorite([tag.id]);

      if (tagUrl === activePath) {
        navigate(Paths.Home);
      }
    },
    [activePath, mutate, navigate, removeFavorite, track],
  );

  const retry = useCallback(() => {
    const tag = deleteTagRef.current;
    if (tag) {
      mutate(tag.id);
      removeFavorite([tag.id]);
    }
  }, [mutate, removeFavorite]);

  const resetAll = useCallback(() => {
    reset();
    deleteTagRef.current = undefined;
  }, [reset]);

  return {
    submit,
    retry,
    reset: resetAll,
    error,
    isLoading: isPending,
    isSuccess,
  };
};
