import { useUpdateHideHabitsToday } from 'features/user';
import { useCallback } from 'react';
import { useUser } from 'shared/hooks/use-user';

export const useToggleHideHabits = () => {
  const user = useUser();
  const updateHideHabits = useUpdateHideHabitsToday();

  return useCallback(() => {
    updateHideHabits(!user?.settings?.todayHideHabits);
  }, [updateHideHabits, user?.settings?.todayHideHabits]);
};
