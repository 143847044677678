import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Warning } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import { Toggle } from 'shared/components/ui/toggle';
import { Tooltip } from 'shared/components/ui/tooltip';
import {
  HabitSchedule,
  ScheduleCompletionActions,
} from 'shared/types/habit-schedule';
import { getActiveSchedule } from 'shared/utils/get-active-schedule';

import * as Styled from './habit-form.style';

export type CompletionPerTapInputProps = {
  schedules: HabitSchedule[];
  onChange: (schedules: HabitSchedule[]) => void;
  shouldReplaceSchedule?: boolean;
};

export const CompletionPerTapInput: React.FC<CompletionPerTapInputProps> = ({
  schedules,
  onChange,
  shouldReplaceSchedule,
}) => {
  const infoContainerRef = useRef<HTMLSpanElement>(null);
  const { t } = useTranslation();
  const activeSchedule = useMemo(
    () => getActiveSchedule(schedules),
    [schedules],
  );

  const _onChange = () => {
    if (!activeSchedule) {
      return;
    }
    const updatedSchedule = {
      ...activeSchedule,
      action: activeSchedule.action
        ? null
        : { type: ScheduleCompletionActions.LogCompletionValue },
    };

    const endedSchedules = schedules.filter(({ endDate }) => !!endDate);
    const updateSchedules = [];

    if (!shouldReplaceSchedule) {
      !!activeSchedule &&
        updateSchedules.push({ ...activeSchedule, endDate: new Date() });
    }

    updateSchedules.push(updatedSchedule);

    onChange([...endedSchedules, ...updateSchedules]);
  };

  return (
    <Styled.FullSectionLine $spaceBetween>
      <Tooltip
        label={t('forms.habit.fields.completions-per-checkbox.tooltip.label')}
        referenceElement={infoContainerRef}
      />
      <Styled.LabelContainer>
        <Styled.Label>
          {t('forms.habit.fields.completions-per-checkbox.label')}
        </Styled.Label>
        <Styled.InfoIconContainer ref={infoContainerRef}>
          <Icon icon={Warning} />
        </Styled.InfoIconContainer>
      </Styled.LabelContainer>
      <Toggle
        value="manualCompletionTarget"
        checked={
          activeSchedule?.action?.type ===
          ScheduleCompletionActions.LogCompletionValue
        }
        onChange={_onChange}
      />
    </Styled.FullSectionLine>
  );
};
