import { typographyToCss } from 'shared/lib/mui';
import { QueryNavLink } from 'shared/lib/react-router-dom';
import styled, { css } from 'styled-components';

export const Container = styled.span`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1.5)};
  width: 100%;

  ${({ theme }) =>
    theme.isMobile &&
    css`
      padding-right: ${theme.spacing(9)};
    `};
`;

export const ImageContainer = styled.span<{ $isFaded: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 2.4rem;
  width: 2.4rem;
  min-width: 2.4rem;
  margin: ${({ theme }) =>
    `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(1)} 0`};

  ${({ $isFaded }) =>
    $isFaded &&
    css`
      opacity: 0.4;
    `};
`;

export const BlurImageContainer = styled.div`
  width: 100%;
  height: 100%;

  border-radius: ${({ theme }) => theme.spacing(2)};
  overflow: hidden;
`;

export const ImageIconContainer = styled.span`
  display: block;
  padding: ${({ theme }) => theme.spacing(1)};

  background: ${({ theme }) => theme.palette.brown['100']};
  border-radius: ${({ theme }) => theme.spacing(2)};

  font-size: 1.6rem;
  line-height: 62.5%;
  color: ${({ theme }) => theme.palette.brown['300']};
`;

export const ColorDot = styled.span<{ $color: string }>`
  position: absolute;
  bottom: ${({ theme }) => `-${theme.spacing(0.75)}`};
  right: ${({ theme }) => `-${theme.spacing(0.75)}`};

  display: block;
  height: 1rem;
  width: 1rem;

  border: ${({ theme }) =>
    `${theme.spacing(0.5)} solid ${theme.palette.background.default};`};
  border-radius: 50%;
  background: ${({ $color }) => $color};
`;

export const HeaderBody = styled.span`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(0.5)};

  min-width: 0;
  width: 0;
`;

export const LinkIconContainer = styled.span<{ $isFaded: boolean }>`
  display: block;

  opacity: 1;
  transform: rotate(180deg);
  transition: opacity 200ms;

  font-size: 1.6rem;
  line-height: 62.5%;
  color: ${({ theme }) => theme.palette.grey['500']};

  ${({ $isFaded, theme }) =>
    $isFaded &&
    css`
      margin-left: ${theme.spacing(2)};
      opacity: 1;
      transform: rotate(0deg);
    `};
`;

export const TitleContainer = styled(QueryNavLink)`
  display: flex;
  align-items: center;
  min-width: 0;

  text-decoration: none;
  color: inherit;

  ${({ to }) =>
    to &&
    css`
      &:hover {
        ${LinkIconContainer} {
          opacity: 1;
        }
      }
    `}
`;

export const Title = styled.span<{
  $isFaded: boolean;
  $hasLargeFont?: boolean;
}>`
  ${({ theme, $hasLargeFont }) =>
    typographyToCss($hasLargeFont ? theme.typography.h5 : theme.typography.h6)};

  overflow: hidden;

  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ $isFaded, theme }) =>
    $isFaded &&
    css`
      color: ${theme.palette.text.secondary};
    `};
`;
