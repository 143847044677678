import React, { forwardRef } from 'react';
import { PopupMenu } from 'shared/components/ui/popup-menu';
import { InteractionType } from 'shared/types/data-type';
import { Task } from 'shared/types/task';
import { TaskType } from 'shared/types/task-base';

import { CompleteList } from './complete-list';
import { DateList } from './date-list';
import { EditList } from './edit-list';
import { HabitList } from './habit-list';
import { RemoveList } from './remove-list';

export type TaskCardContextMenuProps = Omit<
  React.ComponentProps<typeof PopupMenu>,
  'children' | 'ref' | 'onOutside'
> & {
  task: Task;
  onClose: () => void;
  nameRef?: React.RefObject<HTMLInputElement>;
  hideMeta?: boolean;
  hideEditButton?: boolean;
};

export const TaskCardContextMenu = forwardRef<
  HTMLDivElement,
  TaskCardContextMenuProps
>(
  (
    {
      task,
      onClose,
      referenceElement,
      location,
      nameRef,
      hideMeta,
      hideEditButton,
    },
    ref,
  ) => {
    const isHabit = [TaskType.Habit, TaskType.Repeating].includes(task.type);

    return (
      <PopupMenu
        ref={ref}
        location={location}
        referenceElement={referenceElement}
        onOutside={onClose}
        interactionType={InteractionType.Task}
        position="bottom-start"
      >
        <EditList
          task={task}
          onClose={onClose}
          hideEditButton={hideEditButton}
          nameRef={nameRef}
        />
        {!isHabit && !hideMeta && <DateList task={task} onClose={onClose} />}
        <CompleteList task={task} onClose={onClose} />
        <RemoveList task={task} onClose={onClose} hasListBelow={isHabit} />
        {isHabit && <HabitList onClose={onClose} task={task} />}
      </PopupMenu>
    );
  },
);
