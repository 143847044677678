import { useCreateHabitAllowed } from 'features/tasks';
import React from 'react';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';
import { useOpenTaskDetail } from 'shared/contexts/task-detail';
import { ID } from 'shared/types/id';

import { useWeekDate } from '../../contexts/timeframe';
import { useLifeAreaHabitReorder } from '../../hooks/use-life-area-habit-reorder';
import { useLifeAreaHabitSections } from '../../hooks/use-life-area-habit-sections';
import { SelfSortingHabitSection } from '../habit-section';
import * as Styled from './life-area-grouping-habits-list.style';

export const LifeAreaGroupingHabitsList: React.FC = () => {
  const weekDate = useWeekDate();

  const openTaskDetail = useOpenTaskDetail();

  const {
    allowed: createHabitsAllowed,
    enforced: createHabitLimitsEnforced,
    limit: createHabitLimits,
    total: totalHabits,
  } = useCreateHabitAllowed();
  const openPremiumDialog = useOpenPremiumDialog();

  const { habits, sections } = useLifeAreaHabitSections();
  const reorderLifeArea = useLifeAreaHabitReorder();

  const onHabit = (id: ID) => {
    const habit = habits.find((habit) => habit.id === id);
    if (habit && !habit.frozenAt) {
      openTaskDetail(id);
    }
  };

  const showPremiumBanner =
    !createHabitsAllowed ||
    (!createHabitLimitsEnforced && createHabitLimits < totalHabits);

  return (
    <Styled.Container>
      {sections.map(({ id, items }, index) => {
        const onReorder = (ids: ID[]) => reorderLifeArea(id, ids);

        return (
          <Styled.Section key={id}>
            <SelfSortingHabitSection
              lifeAreaId={id}
              onReorder={onReorder}
              habits={items}
              onHabit={onHabit}
              weekDate={weekDate}
              onPremium={
                !index && showPremiumBanner ? openPremiumDialog : undefined
              }
            />
          </Styled.Section>
        );
      })}
    </Styled.Container>
  );
};
