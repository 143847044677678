import { useUpdateGoal } from 'features/goals';
import { useCallback, useRef } from 'react';
import { useCreateTaskMutation } from 'shared/hooks/queries/use-create-task-mutation';
import { useTrackEvents } from 'shared/hooks/use-track-events';
import { useUpdateRelationshipsReferences } from 'shared/hooks/use-update-relationships-references';
import {
  ActionEvents,
  taskTypeToTrackingTaskType,
} from 'shared/services/tracking';
import { Goal } from 'shared/types/goal';
import { ID, IDPrefixes } from 'shared/types/id';
import { goalInboxID } from 'shared/types/orderable-section';
import { NewTask, Task } from 'shared/types/task';
import { createPrefixId } from 'shared/utils/prefix-id';

export type ExtraOptions = {
  sectionId?: string;
  goal?: Goal;
  tags?: ID[] | null;
};

export const useCreateTask = () => {
  const track = useTrackEvents();
  const createTaskRef = useRef<NewTask>();
  const {
    mutateAsync: createTask,
    error,
    isPending,
    isSuccess,
    reset,
  } = useCreateTaskMutation();
  // todo: find a better solution for this (cannot use other features here)
  const { submit: updateGoal } = useUpdateGoal();
  const updateRelationships = useUpdateRelationshipsReferences();

  const submit = useCallback(
    async (
      task: NewTask,
      { goal, sectionId, tags }: ExtraOptions = {},
    ): Promise<Task> => {
      // filter out inbox ID
      task.goalId = task.goalId === goalInboxID ? null : task.goalId;
      createTaskRef.current = task;
      const { id } = await createTask(task);

      // update the goal if the task is bound to a goal
      if (task.goalId) {
        // if a section is provided, update the section
        const sections =
          sectionId && goal?.sections?.find(({ id }) => id === sectionId)
            ? structuredClone(goal?.sections)
            : undefined;
        const sectionToUpdate = sections?.find(({ id }) => id === sectionId);
        sectionToUpdate?.tasks?.push(id);

        updateGoal({ id: task.goalId, taskCount: 1, sections });
      }

      if (tags?.length) {
        updateRelationships(
          tags.map((tagId) => ({
            id: createPrefixId(tagId, IDPrefixes.Tag),
            add: [createPrefixId(id, IDPrefixes.Task)],
          })),
        );
      }

      track(ActionEvents.TaskCreate, {
        type: taskTypeToTrackingTaskType[task.type],
        deadline_added: !!task.endStrategy?.deadline,
        linked_goal: !!task.goalId,
      });

      return {
        ...task,
        id,
      } as Task;
    },
    [createTask, track, updateGoal, updateRelationships],
  );

  const retry = useCallback(async () => {
    const task = createTaskRef.current;

    if (task) {
      const { id } = await createTask(task);

      track(ActionEvents.TaskCreate, {
        type: taskTypeToTrackingTaskType[task.type],
        deadline_added: !!task.endStrategy?.deadline,
        linked_goal: !!task.goalId,
      });

      return {
        ...task,
        id,
      } as Task;
    }
  }, [createTask, track]);

  const resetAll = useCallback(() => {
    reset();
    createTaskRef.current = undefined;
  }, [reset]);

  return {
    submit,
    retry,
    reset: resetAll,
    error,
    isLoading: isPending,
    isSuccess,
  };
};
