import { useMemo } from 'react';
import { useTimeframeCompletions } from 'shared/hooks/use-timeframe-completions';
import { useTimeframeEntries } from 'shared/hooks/use-timeframe-entries';
import { useTimeframeOccurrences } from 'shared/hooks/use-timeframe-occurrences';
import { Habit } from 'shared/types/habit';
import { HabitSchedule } from 'shared/types/habit-schedule';
import { Timestamp } from 'shared/types/timestamp';

export type Options = {
  startDate: Timestamp;
  endDate: Timestamp;
};

export const useHabitProgress = (
  habit: Habit,
  { startDate, endDate }: Options,
) => {
  const { occurrences, timeframeOccurrences } = useTimeframeOccurrences(
    habit.schedules,
    { startDate, endDate },
  );
  const { completions, timeframeCompletions } = useTimeframeCompletions(
    habit.schedules,
    { startDate, endDate },
  );
  const { dateEntries, timeframeDateEntries } = useTimeframeEntries(
    habit.schedules,
    {
      startDate,
      endDate,
    },
  );

  const scheduleChanges = useMemo(
    () =>
      habit.schedules
        .toSorted(
          (scheduleA, scheduleB) =>
            scheduleA.startDate.getTime() - scheduleB.startDate.getTime(),
        )
        .map((schedule, index, arr) => {
          if (!schedule.endDate) {
            return undefined;
          }

          const newSchedule = arr[index + 1];
          if (!newSchedule) {
            return undefined;
          }

          return {
            date: schedule.endDate,
            oldSchedule: schedule,
            newSchedule: newSchedule,
          };
        })
        .filter(Boolean) as {
        date: Timestamp;
        oldSchedule: HabitSchedule;
        newSchedule: HabitSchedule;
      }[],
    [habit.schedules],
  );

  return {
    dateEntries,
    timeframeDateEntries,
    occurrences,
    timeframeOccurrences,
    completions,
    timeframeCompletions,
    scheduleChanges,
  };
};
