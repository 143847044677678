import { useGoals } from 'features/goals';
import { useLocalizedLifeAreas } from 'features/life-areas';
import { useMemo } from 'react';
import { useUser } from 'shared/hooks/use-user';
import { Goal } from 'shared/types/goal';
import { goalInboxID } from 'shared/types/orderable-section';
import { GoalGroupBy } from 'shared/types/sorting-mode';
import { sortGoals } from 'shared/utils/sort-goals';
import { sortItems } from 'shared/utils/sort-items';

export const useGoalSections = () => {
  const user = useUser();

  const goals = useGoals();
  const lifeAreas = useLocalizedLifeAreas();

  const goalOrder = useMemo(() => {
    const mainGoals = goals.filter(({ parentIds }) => !parentIds?.length);
    const subGoals = goals.filter(({ parentIds }) => !!parentIds?.length);
    // default sort the goals on roadmap inProgress sorting
    let sortedMainGoals = sortGoals(mainGoals, {
      allGoals: goals,
      sortMode: user?.settings?.goalSortingMode,
      customOrder: user?.settings?.goalRoadmapSorting?.inProgress,
    });

    if (user?.settings?.goalGroupBy === GoalGroupBy.LifeArea) {
      // sort life-areas
      const sortedLifeAreas = sortItems(
        lifeAreas,
        user?.settings?.lifeAreaSorting ?? [],
        'id',
      );
      // group goals on life-area
      sortedMainGoals = [
        ...sortedLifeAreas
          .map(({ id }) =>
            sortGoals(
              mainGoals.filter(({ lifeAreaId }) => lifeAreaId === id),
              {
                allGoals: goals,
                sortMode: user?.settings?.goalSortingMode,
                customOrder:
                  user?.settings?.goalGroupSorting?.lifeArea?.sorting?.[id] ??
                  [],
              },
            ),
          )
          .flat(1),
        ...sortGoals(
          mainGoals.filter(({ lifeAreaId }) => !lifeAreaId),
          {
            allGoals: goals,
            sortMode: user?.settings?.goalSortingMode,
            customOrder:
              user?.settings?.goalGroupSorting?.lifeArea?.sorting?.null ?? [],
          },
        ),
      ];
    }

    return sortedMainGoals
      .reduce<Goal[]>((acc, goal) => {
        acc.push(goal);
        const goalSubGoals = subGoals.filter(
          ({ parentIds }) => parentIds?.[parentIds.length - 1] === goal.id,
        );
        if (goalSubGoals.length) {
          acc.push(
            ...sortGoals(goalSubGoals, {
              allGoals: goals,
              sortMode: goal.goalSortingMode,
              customOrder: goal.goalSorting,
            }),
          );
        }

        return acc;
      }, [])
      .map(({ id }) => id);
  }, [
    goals,
    lifeAreas,
    user?.settings?.goalGroupBy,
    user?.settings?.goalGroupSorting?.lifeArea?.sorting,
    user?.settings?.goalRoadmapSorting?.inProgress,
    user?.settings?.goalSortingMode,
    user?.settings?.lifeAreaSorting,
  ]);

  return useMemo(
    () => [
      // sort goals before mapping to sections
      ...sortItems(
        goals,
        goalOrder,
        'id',
        // map to sections
      ).map(({ id }) => ({
        id,
        items: [],
      })),
      // add inbox as group as well
      {
        id: goalInboxID,
        items: [],
      },
      // filter out the empty sections, except inbox
    ],
    [goalOrder, goals],
  );
};
