import { useUpdateAppToUserSettings } from 'features/user';
import React, { Suspense } from 'react';
import { Outlet } from 'react-router';
import { ScrollRestoration } from 'react-router-dom';
import { DownloadIosBanner } from 'shared/components/ui/download-ios-banner';
import { CompleteGoalProvider } from 'shared/contexts/complete-goal';
import { CreateGoalTemplateProvider } from 'shared/contexts/create-goal-template';
import { DeleteGoalProvider } from 'shared/contexts/delete-goal';
import { DeleteGoalSectionProvider } from 'shared/contexts/delete-goal-section';
import { DeleteHabitProvider } from 'shared/contexts/delete-habit';
import { DeleteLifeAreaProvider } from 'shared/contexts/delete-life-area';
import { FeedbackDialogProvider } from 'shared/contexts/feedback-dialog';
import { GoalFormProvider } from 'shared/contexts/goal-form';
import { GoalProgressProvider } from 'shared/contexts/goal-progress';
import { GoalTemplatePreviewProvider } from 'shared/contexts/goal-template-preview';
import { HabitFormProvider } from 'shared/contexts/habit-form';
import { HabitManualEntryLoggingProvider } from 'shared/contexts/habit-manual-entry-logging';
import { HistoryProvider } from 'shared/contexts/history';
import { InsightsCalculationsProvider } from 'shared/contexts/insights-calculations/insights-calculations-provider';
import { LifeAreaFormProvider } from 'shared/contexts/life-area-form';
import { PremiumDialogProvider } from 'shared/contexts/premium-dialog';
import { RemoveHabitScheduleProvider } from 'shared/contexts/remove-habit-schedule';
import { RequestPushNotificationsProvider } from 'shared/contexts/request-push-notifications';
import { TagFormProvider } from 'shared/contexts/tag-form';
import { TaskDetailProvider } from 'shared/contexts/task-detail';
import { UserSettingsProvider } from 'shared/contexts/user-settings';
import { VersionProvider } from 'shared/contexts/version';
import { isIos } from 'shared/utils/is-ios';

import { AppLayout } from './components/app-layout';
import { PagesLayout } from './components/pages-layout';

export const Root: React.FC = () => {
  useUpdateAppToUserSettings();

  return (
    <Suspense>
      <PremiumDialogProvider>
        {isIos() ? (
          <DownloadIosBanner />
        ) : (
          <Suspense>
            <HistoryProvider>
              <RequestPushNotificationsProvider>
                <VersionProvider>
                  <FeedbackDialogProvider>
                    <InsightsCalculationsProvider>
                      <UserSettingsProvider>
                        <TagFormProvider>
                          <DeleteLifeAreaProvider>
                            <LifeAreaFormProvider>
                              <CreateGoalTemplateProvider>
                                <GoalTemplatePreviewProvider>
                                  <CompleteGoalProvider>
                                    <DeleteGoalProvider>
                                      <DeleteGoalSectionProvider>
                                        <GoalFormProvider>
                                          <GoalProgressProvider>
                                            <RemoveHabitScheduleProvider>
                                              <DeleteHabitProvider>
                                                <HabitFormProvider>
                                                  <HabitManualEntryLoggingProvider>
                                                    <AppLayout>
                                                      <TaskDetailProvider>
                                                        <PagesLayout>
                                                          <ScrollRestoration />
                                                          <Outlet />
                                                        </PagesLayout>
                                                      </TaskDetailProvider>
                                                    </AppLayout>
                                                  </HabitManualEntryLoggingProvider>
                                                </HabitFormProvider>
                                              </DeleteHabitProvider>
                                            </RemoveHabitScheduleProvider>
                                          </GoalProgressProvider>
                                        </GoalFormProvider>
                                      </DeleteGoalSectionProvider>
                                    </DeleteGoalProvider>
                                  </CompleteGoalProvider>
                                </GoalTemplatePreviewProvider>
                              </CreateGoalTemplateProvider>
                            </LifeAreaFormProvider>
                          </DeleteLifeAreaProvider>
                        </TagFormProvider>
                      </UserSettingsProvider>
                    </InsightsCalculationsProvider>
                  </FeedbackDialogProvider>
                </VersionProvider>
              </RequestPushNotificationsProvider>
            </HistoryProvider>
          </Suspense>
        )}
      </PremiumDialogProvider>
    </Suspense>
  );
};
