import Typography from '@mui/material/Typography';
import { useCreateHabitAllowed } from 'features/tasks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Colors } from 'shared/components/ui/checkbox';
import { useOpenCreateHabitDialog } from 'shared/contexts/habit-form';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';
import { ID } from 'shared/types/id';
import { TaskPriorityOptions } from 'shared/types/task-base';

import * as Styled from './new-habit-row.style';

const priorityColorMap: Record<TaskPriorityOptions, Colors> = {
  [TaskPriorityOptions.Low]: Colors.Info,
  [TaskPriorityOptions.Medium]: Colors.Warning,
  [TaskPriorityOptions.High]: Colors.Error,
  [TaskPriorityOptions.None]: Colors.Default,
};

export type NewTaskRowProps = {
  goal?: ID;
  lifeArea?: ID;
  priority?: TaskPriorityOptions;
  tags?: ID[];
};

export const NewHabitRow: React.FC<NewTaskRowProps> = React.memo(
  ({ goal, lifeArea, priority, tags }) => {
    const { t } = useTranslation();

    const isCreateHabitAllowed = useCreateHabitAllowed();
    const openCreateHabitDialog = useOpenCreateHabitDialog();
    const openPremiumDialog = useOpenPremiumDialog();

    const onCreateHabit = () =>
      isCreateHabitAllowed
        ? openCreateHabitDialog({
            goalId: goal,
            lifeAreaId: lifeArea,
            tagIds: tags,
            priority,
          })
        : openPremiumDialog();

    return (
      <Styled.Container onClick={onCreateHabit}>
        <Styled.CheckboxContainer>
          <Checkbox
            color={priority ? priorityColorMap[priority] : undefined}
            checked={false}
            readOnly
            isPlaceholder
          />
        </Styled.CheckboxContainer>

        <Typography variant="body2">
          {t('forms.new-task.habit.title.placeholder')}
        </Typography>
      </Styled.Container>
    );
  },
);
