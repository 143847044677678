import React from 'react';
import { useTranslation } from 'react-i18next';
import { Section } from 'shared/components/ui/section';
import { usePremiumFeatureAllowed } from 'shared/hooks/use-premium-feature-allowed';
import { LimitKeys } from 'shared/types/limit-keys';

import { useInsightsLifeAreas } from '../../hooks/use-insights-life-areas';
import { useListColumns } from '../../hooks/use-list-columns';
import {
  InsightsPremiumBanner,
  PremiumBannerTypes,
} from '../insights-premium-banner';
import { LifescanInsightsCard } from '../lifescan-insights-card';
import * as Styled from './lifescan-insights.style';

export const LifescanInsights: React.FC = () => {
  const widthRef = React.useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const lifeAreas = useInsightsLifeAreas();
  const { allowed } = usePremiumFeatureAllowed(LimitKeys.Insights, {});

  const listColumns = useListColumns(widthRef);

  return lifeAreas.length ? (
    <>
      <Styled.WidthRef ref={widthRef} />
      <Section
        title={t('pages.insights.sections.life-areas.title')}
        canCollapse
        initialOpen
      >
        <Styled.List $columns={listColumns}>
          {lifeAreas.map((lifeArea) => (
            <Styled.ListItem key={lifeArea.id}>
              <LifescanInsightsCard lifeArea={lifeArea} />
            </Styled.ListItem>
          ))}

          {!allowed && (
            <Styled.PremiumBannerContainer>
              <InsightsPremiumBanner type={PremiumBannerTypes.LifeArea} />
            </Styled.PremiumBannerContainer>
          )}
        </Styled.List>
      </Section>
    </>
  ) : null;
};
