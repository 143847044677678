import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Option } from 'shared/components/ui/custom-form-select';
import { PredefinedColorOptions } from 'shared/constants';
import { InheritColors } from 'shared/types/inherit-colors';

import * as Styled from './color-picker.style';

export type Options = {
  parentColor?: string;
  lifeAreaColor?: string;
};

type ColorOption = {
  label: string;
  start: React.ReactNode;
  value: string;
};

export const useColorOptions = ({
  parentColor,
  lifeAreaColor,
}: Options = {}) => {
  const { t } = useTranslation();

  return useMemo<Option[]>(() => {
    const options: ColorOption[] = [];

    if (lifeAreaColor) {
      options.push({
        label: t('general.labels.colors.inherit.life-area'),
        value: InheritColors.LifeArea,
        start: <Styled.ColorCircle $color={lifeAreaColor} />,
      });
    }

    if (parentColor) {
      options.push({
        label: t('general.labels.colors.inherit.goal'),
        value: InheritColors.Goal,
        start: <Styled.ColorCircle $color={parentColor} />,
      });
    }

    PredefinedColorOptions.forEach((colorOption) =>
      options.push({
        ...colorOption,
        label: t(`general.labels.colors.${colorOption.label}`),
        start: <Styled.ColorCircle $color={colorOption.value} />,
      }),
    );

    return options;
  }, [lifeAreaColor, parentColor, t]);
};
