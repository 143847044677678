import { useDroppable } from '@dnd-kit/core';
import React from 'react';
import { TaskCardTasksList } from 'shared/components/ui/task-card-tasks-list';

import * as Styled from './task-card-task-section.style';
import { TaskCardTaskSectionLayout } from './task-card-task-section-layout';

export type TaskCardTaskSectionProps = Omit<
  React.ComponentProps<typeof TaskCardTaskSectionLayout>,
  'children'
> &
  Omit<React.ComponentProps<typeof TaskCardTasksList>, 'canDragTasks'> & {
    droppableId?: string;
    canReorder?: boolean;
  };

export const TaskCardTaskSection: React.FC<TaskCardTaskSectionProps> = ({
  goalId,
  date,
  priority,
  tasks,
  selectedTask,
  showRepeatingAsTodo,
  showRepeatingAsRepeatingOnEntry,
  displayDue,
  onTask,
  onUnfocus,
  canCreateTask,
  goals,
  lifeAreas,
  customHeader,
  headerToDisplay,
  headerIsLink,
  hideGoal,
  hideMeta,
  parentChildIds,
  parentIds,
  onHeader,
  droppableId,
  canReorder,
  canCollapse,
  onPremium,
  taskIsMoving,
}) => {
  const { setNodeRef } = useDroppable({
    id: droppableId ?? '',
    disabled: !droppableId || !!tasks.length,
  });

  const containerProps = droppableId ? { ref: setNodeRef } : {};

  return (
    <Styled.Container {...containerProps}>
      <TaskCardTaskSectionLayout
        goalId={goalId}
        canCreateTask={canCreateTask}
        goals={goals}
        lifeAreas={lifeAreas}
        date={date}
        priority={priority}
        headerToDisplay={headerToDisplay}
        onHeader={onHeader}
        customHeader={customHeader}
        canCollapse={canCollapse}
        parentIds={parentIds}
        parentChildIds={parentChildIds}
        hideMeta={hideMeta}
        headerIsLink={headerIsLink}
        onPremium={onPremium}
      >
        <TaskCardTasksList
          tasks={tasks}
          onTask={onTask}
          onUnfocus={onUnfocus}
          selectedTask={selectedTask}
          showRepeatingAsTodo={showRepeatingAsTodo}
          showRepeatingAsRepeatingOnEntry={showRepeatingAsRepeatingOnEntry}
          displayDue={displayDue}
          canReorder={canReorder}
          canDragTasks={!!droppableId}
          hideMeta={hideMeta}
          hideGoal={hideGoal}
          taskIsMoving={taskIsMoving}
        />
      </TaskCardTaskSectionLayout>
    </Styled.Container>
  );
};
