import React from 'react';

import * as Styled from './bar-chart.style';

export type BarProps = {
  x: number;
  y?: number;
  index: number;
  maxHeight: number;
  targetHeight: number;
  onHover: (index: number, options?: { left: number; top: number }) => void;
  isCompleted?: boolean;
  isFaded?: boolean;
};

const baseProps = {
  width: '0.8rem',
  // rounded borders
  rx: '0.4rem',
  ry: '0.4rem',
  // translate the bar a bit down to move the bottom out of view (the same amount as the height is increased
  transform: 'translate(0, 4)',
};

export const Bar: React.FC<BarProps> = ({
  x,
  y = 0,
  index,
  maxHeight,
  targetHeight,
  onHover,
  isCompleted,
  isFaded,
}) => {
  const _onHover = (e: React.MouseEvent) => {
    const rect = e.currentTarget.getBoundingClientRect();
    onHover(index, { left: rect.left + rect.width / 2, top: rect.top });
  };

  const _onUnHover = () => {
    onHover(index);
  };

  return (
    <>
      <Styled.Bar
        {...baseProps}
        $isTarget
        $isFaded={!!isFaded}
        // make the bar centered on the line by subtracting half the width
        x={x - 4}
        // add a bit of length to prevent the bottom rounded borders to be shown
        height={Math.max(0, maxHeight - targetHeight! + 4)}
        // compensate the transform by removing the same portion from the top
        y={targetHeight! - 4}
        onMouseEnter={_onHover}
        onMouseLeave={_onUnHover}
      />
      <Styled.Bar
        {...baseProps}
        $isCompleted={isCompleted}
        $isFaded={!!isFaded}
        // make the bar centered on the line by subtracting half the width
        x={x - 4}
        // add a bit of length to prevent the bottom rounded borders to be shown
        height={Math.max(0, maxHeight - y + 4)}
        // compensate the transform by removing the same portion from the top
        y={y - 4}
        onMouseEnter={_onHover}
        onMouseLeave={_onUnHover}
      />
    </>
  );
};
