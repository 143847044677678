import { createContext } from 'react';
import { ID } from 'shared/types/id';
import { Tag } from 'shared/types/tag';

export type TagFormContextProps = {
  onCreateTag: (initialValues?: Partial<Tag>) => void;
  onEditTag: (initialValues: Partial<Tag>, id: ID) => void;
};

export const TagFormContext = createContext<TagFormContextProps>({
  onCreateTag: () => {},
  onEditTag: () => {},
});
