import { SortMenuOptions } from 'shared/types/sort-menu-options';
import { GoalSortingMode, SortingMode } from 'shared/types/sorting-mode';

export const goalSortOptions: SortMenuOptions<GoalSortingMode>[] = [
  {
    id: GoalSortingMode.Custom,
    value: GoalSortingMode.Custom,
    label: `general.labels.goals.sort.options.${GoalSortingMode.Custom}`,
  },
  {
    id: GoalSortingMode.StartDate,
    value: GoalSortingMode.StartDate,
    label: `general.labels.goals.sort.options.${GoalSortingMode.StartDate}`,
  },
  {
    id: GoalSortingMode.Deadline,
    value: GoalSortingMode.Deadline,
    label: `general.labels.goals.sort.options.${GoalSortingMode.Deadline}`,
  },
  {
    id: GoalSortingMode.Progress,
    value: GoalSortingMode.Progress,
    label: `general.labels.goals.sort.options.${GoalSortingMode.Progress}`,
  },
];

export const taskSortOptions: SortMenuOptions<SortingMode>[] = [
  {
    id: SortingMode.DueDate,
    value: SortingMode.DueDate,
    label: `general.labels.tasks.sort.options.${SortingMode.DueDate}`,
  },
  {
    id: SortingMode.Priority,
    value: SortingMode.Priority,
    label: `general.labels.tasks.sort.options.${SortingMode.Priority}`,
  },
  {
    id: SortingMode.Custom,
    value: SortingMode.Custom,
    label: `general.labels.tasks.sort.options.${SortingMode.Custom}`,
  },
];
