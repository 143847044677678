import { rgba } from 'polished';
import { typographyToCss } from 'shared/lib/mui';
import styled, { css } from 'styled-components';

export const List = styled.ul`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};

  margin: 0;
  padding: 0;

  list-style: none;
`;

export const ListItem = styled.li``;

export const DayContainer = styled.div``;

export const DayButton = styled.button<{
  $isOccurrence: boolean;
  $completionPercentage: number;
  $isPerfect: boolean;
  $isSkipped: boolean;
  $isFailure: boolean;
  $isFuture: boolean;
  $isToday: boolean;
}>`
  ${({ theme }) => typographyToCss(theme.typography.buttonSmall)};
  position: relative;
  margin: 0;
  padding: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 2.4rem;
  height: 2.4rem;

  border-radius: ${({ theme }) => theme.spacing(2)};
  border: none;
  background: transparent;
  cursor: pointer;
  overflow: hidden;
  transition: background 200ms;

  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;

  color: ${({ theme }) => theme.palette.text.secondary};

  &:hover {
    background: ${({ theme }) => theme.palette.brown['100']};
  }

  ${({ $isOccurrence, theme }) =>
    $isOccurrence &&
    css`
      color: ${theme.palette.text.primary};
    `};

  ${({ $isToday, theme }) =>
    $isToday &&
    css`
      color: ${theme.palette.text.primary};
    `};

  ${({ $isSkipped, theme }) =>
    $isSkipped &&
    css`
      background-color: ${theme.palette.warning.light};
      color: ${theme.palette.warning.main};

      &:hover {
        background: ${theme.palette.warning.light};
      }
    `};

  ${({ $isFailure, theme }) =>
    $isFailure &&
    css`
      background-color: ${theme.palette.error.light};
      color: ${theme.palette.error.main};

      &:hover {
        background: ${theme.palette.error.light};
      }
    `};

  ${({ theme, $completionPercentage, $isPerfect }) =>
    $completionPercentage > 0 &&
    css`
      background: ${rgba(
        $isPerfect ? theme.palette.success.dark : theme.palette.primary.main,
        0.2,
      )};
      color: ${$isPerfect
        ? theme.palette.success.contrastText
        : theme.palette.primary.contrastText};

      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        height: ${Math.min(Math.max($completionPercentage, 0), 100)}%;
        width: 100%;

        background: ${$isPerfect
          ? theme.palette.success.dark
          : theme.palette.primary.main};
      }

      &:hover {
        background: ${rgba(
          $isPerfect ? theme.palette.success.dark : theme.palette.primary.main,
          0.2,
        )};
      }
    `};

  ${({ $isFuture }) =>
    $isFuture &&
    css`
      opacity: 0.5;
      cursor: default;
    `};
`;

export const DayLabel = styled.span<{ $isIcon?: boolean }>`
  position: relative;

  overflow: hidden;

  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ $isIcon }) =>
    $isIcon &&
    css`
      font-size: 1.4rem;
      line-height: 62.5%;
    `}
`;

export const DayIcon = styled.span`
  position: relative;
  font-size: 1.4rem;
  line-height: 62.5%;
`;
