import { useHabits } from 'features/tasks';
import { useMemo } from 'react';
import { useToday } from 'shared/contexts/today';
import { useUser } from 'shared/hooks/use-user';
import { Habit } from 'shared/types/habit';
import { SortingMode } from 'shared/types/sorting-mode';
import { noPriorityOption, TaskPriorityOptions } from 'shared/types/task-base';
import { getSortedTasks } from 'shared/utils/get-sorted-tasks';
import { getWeekStartsOn } from 'shared/utils/get-week-starts-on';

export const usePriorityHabitSections = () => {
  const today = useToday();
  const user = useUser();

  const habits = useHabits({ uncompletedOnly: true, unArchivedOnly: true });

  const sections = useMemo(
    () =>
      [
        {
          id: TaskPriorityOptions.High,
          items: getSortedTasks(
            habits.filter(
              ({ priority }) => priority === TaskPriorityOptions.High,
            ),
            {
              today,
              weekStartsOn: getWeekStartsOn(user),
              mode: user?.settings?.habitSortingMode
                ? ([
                    user?.settings?.habitSortingMode,
                  ] as unknown as SortingMode[])
                : undefined,
              order:
                user?.settings?.habitGroupSorting?.priority?.sorting?.[
                  TaskPriorityOptions.High
                ] ?? [],
            },
          ) as Habit[],
        },
        {
          id: TaskPriorityOptions.Medium,
          items: getSortedTasks(
            habits.filter(
              ({ priority }) => priority === TaskPriorityOptions.Medium,
            ),
            {
              today,
              weekStartsOn: getWeekStartsOn(user),
              mode: user?.settings?.habitSortingMode
                ? ([
                    user?.settings?.habitSortingMode,
                  ] as unknown as SortingMode[])
                : undefined,
              order:
                user?.settings?.habitGroupSorting?.priority?.sorting?.[
                  TaskPriorityOptions.Medium
                ] ?? [],
            },
          ) as Habit[],
        },
        {
          id: TaskPriorityOptions.Low,
          items: getSortedTasks(
            habits.filter(
              ({ priority }) => priority === TaskPriorityOptions.Low,
            ),
            {
              today,
              weekStartsOn: getWeekStartsOn(user),
              mode: user?.settings?.habitSortingMode
                ? ([
                    user?.settings?.habitSortingMode,
                  ] as unknown as SortingMode[])
                : undefined,
              order:
                user?.settings?.habitGroupSorting?.priority?.sorting?.[
                  TaskPriorityOptions.Low
                ] ?? [],
            },
          ) as Habit[],
        },
        {
          id: noPriorityOption,
          items: getSortedTasks(
            habits.filter(
              ({ priority }) =>
                !priority || priority === TaskPriorityOptions.None,
            ),
            {
              today,
              weekStartsOn: getWeekStartsOn(user),
              mode: user?.settings?.habitSortingMode
                ? ([
                    user?.settings?.habitSortingMode,
                  ] as unknown as SortingMode[])
                : undefined,
              order:
                user?.settings?.habitGroupSorting?.priority?.sorting?.[
                  TaskPriorityOptions.None
                ] ?? [],
            },
          ) as Habit[],
        },
      ].filter(({ items }) => !!items.length),
    [habits, today, user],
  );

  return {
    sections,
    habits,
  };
};
