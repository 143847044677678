import { endOfDay, isAfter, isSameDay, startOfDay } from 'date-fns';
import { RRule } from 'shared/lib/rrule';
import { HabitSchedule, ScheduleEntryState } from 'shared/types/habit-schedule';
import { getFrequencyTimeframeCompletions } from 'shared/utils/get-frequency-timeframe-completions';

import { Options } from './types';

const frequencyDue = (
  schedule: HabitSchedule,
  { today, weekStartsOn }: Options,
) => {
  // if skipped till the future it's not due
  const lastSkip = schedule.entries.findLast(
    ({ state }) => state === ScheduleEntryState.Skip,
  );
  if (
    lastSkip &&
    (isAfter(lastSkip.date, today) || isSameDay(lastSkip.date, today))
  ) {
    return false;
  }

  // no completions mean its due
  const completionsOrFailures = schedule.entries.filter(
    ({ state, value }) =>
      (state === ScheduleEntryState.Complete && !!value) ||
      state === ScheduleEntryState.Failure,
  );
  if (!completionsOrFailures?.length) {
    return true;
  }

  // check if today is completed or failed
  const completionOrFailureToday = completionsOrFailures.find(({ date }) =>
    isSameDay(date, today),
  );

  if (completionOrFailureToday) {
    return false;
  }

  const { completionsCount, target } = getFrequencyTimeframeCompletions(
    schedule,
    {
      referenceDate: today,
      weekStartsOn,
    },
  );

  return completionsCount < target;
};

const rruleDue = (schedule: HabitSchedule, { today }: Options) => {
  // if skipped till the future it's not due
  const lastSkip = schedule.entries.findLast(
    ({ state }) => state === ScheduleEntryState.Skip,
  );
  if (
    lastSkip &&
    (isAfter(lastSkip.date, today) || isSameDay(lastSkip.date, today))
  ) {
    return false;
  }

  // check if today is completed
  const completionOrFailureToday = schedule.entries.find(
    ({ date, state, value }) =>
      isSameDay(date, today) &&
      ((state === ScheduleEntryState.Complete &&
        (value ?? 0) >= schedule.completionTarget.count) ||
        ScheduleEntryState.Failure === state),
  );

  if (!!completionOrFailureToday) {
    return false;
  }

  // check if day is an occurrence in the rrule
  const rrule = RRule.fromString(schedule.rrule.format);
  rrule.options.dtstart = startOfDay(schedule.startDate);
  const [dueOnDay] = rrule.between(startOfDay(today), endOfDay(today), true);

  return !!dueOnDay;
};

export const isScheduleDue = (
  schedule: HabitSchedule,
  { today, weekStartsOn }: Options,
) => {
  if (
    !isSameDay(schedule.startDate, today) &&
    isAfter(schedule.startDate, today)
  ) {
    return false;
  }

  if (schedule.frequency?.count) {
    return frequencyDue(schedule, { today, weekStartsOn });
  }

  return rruleDue(schedule, { today, weekStartsOn });
};
