import React from 'react';
import { Toggle } from 'shared/components/ui/toggle';

import * as Styled from './popup-menu.style';
import { Colors } from './types';

export type PopupMenuToggleProps = {
  start?: React.ReactNode;
  startColor?: Colors;
  label: string;
  onChange: (checked: boolean) => void;
  checked: boolean;
  id?: string;
};

export const PopupMenuToggle: React.FC<PopupMenuToggleProps> = ({
  start,
  startColor,
  label,
  checked,
  onChange,
  id = window.crypto.randomUUID(),
}) => {
  const _onChange = () => onChange(!checked);

  return (
    <Styled.Toggle htmlFor={id}>
      {!!start && (
        <Styled.AffixContainer $color={startColor}>
          {start}
        </Styled.AffixContainer>
      )}
      <Styled.ToggleContent>
        {label}

        <Toggle value="" id={id} checked={checked} onChange={_onChange} />
      </Styled.ToggleContent>
    </Styled.Toggle>
  );
};
