import { useUpdateTodayGroupBy, useUpdateTodoSortMode } from 'features/user';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Check, Repeat, Tag } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import {
  PopupMenu,
  PopupMenuButton,
  PopupMenuList,
  PopupMenuListItem,
  PopupMenuTitle,
  PopupMenuToggle,
} from 'shared/components/ui/popup-menu';
import { useUser } from 'shared/hooks/use-user';
import { SortingMode, TodayGroupBy } from 'shared/types/sorting-mode';

import { useToggleHideHabits } from '../../hooks/use-toggle-hide-habits';
import { groupByOptions, sortOptions } from './header-interaction-options';

export type MainContextMenuProps = {
  referenceElement?: React.RefObject<HTMLElement>;
  onClose: () => void;
  onFilter?: () => void;
};

export const MainContextMenu: React.FC<MainContextMenuProps> = ({
  referenceElement,
  onClose,
  onFilter,
}) => {
  const { t } = useTranslation();
  const user = useUser();

  const toggleHideHabitsToday = useToggleHideHabits();
  const setSortMode = useUpdateTodoSortMode();
  const setGroupBy = useUpdateTodayGroupBy();

  const _onGroupBy = (value: TodayGroupBy) => {
    setGroupBy(value);
    onClose();
  };

  const _onSort = (value: SortingMode) => {
    setSortMode(value);
    onClose();
  };

  const sort = user?.settings?.todoSortingMode;
  const groupBy = user?.settings?.todayGroupBy;

  return (
    <PopupMenu
      referenceElement={referenceElement}
      position="bottom-end"
      onOutside={onClose}
    >
      <PopupMenuList hasBottomBorder>
        <PopupMenuTitle title={t('pages.today.view-options.title')} />

        <PopupMenuListItem>
          <PopupMenuToggle
            start={<Icon icon={Repeat} />}
            label={t('pages.today.show-habits.label')}
            onChange={toggleHideHabitsToday}
            checked={!user?.settings?.todayHideHabits}
          />
        </PopupMenuListItem>

        {onFilter && (
          <PopupMenuListItem>
            <PopupMenuButton start={<Icon icon={Tag} />} onClick={onFilter}>
              {t('pages.today.open-filters.tags.label')}
            </PopupMenuButton>
          </PopupMenuListItem>
        )}
      </PopupMenuList>
      <PopupMenuList hasBottomBorder>
        <PopupMenuTitle title={t('general.labels.tasks.group-by.title')} />

        {groupByOptions.map(({ id, label, value }) => (
          <PopupMenuListItem key={id}>
            <PopupMenuButton
              start={groupBy === id ? <Icon icon={Check} /> : undefined}
              onClick={() => _onGroupBy(value)}
              hasIndentation={groupBy !== id}
            >
              {t(label)}
            </PopupMenuButton>
          </PopupMenuListItem>
        ))}
      </PopupMenuList>

      <PopupMenuList>
        <PopupMenuTitle title={t('general.labels.tasks.sort.title')} />

        {sortOptions.map(({ id, label, value }) => (
          <PopupMenuListItem key={id}>
            <PopupMenuButton
              start={sort === id ? <Icon icon={Check} /> : undefined}
              onClick={() => _onSort(value)}
              hasIndentation={sort !== id}
            >
              {t(label)}
            </PopupMenuButton>
          </PopupMenuListItem>
        ))}
      </PopupMenuList>
    </PopupMenu>
  );
};
