import React from 'react';
import { ChevronLeft } from 'shared/assets/icons';
import { NewHabitRow } from 'shared/components/connected/new-habit-row';
import { NewTaskRow } from 'shared/components/connected/new-task-row';
import { Collapse } from 'shared/components/ui/collapse';
import {
  IconButton,
  Sizes as IconButtonSizes,
} from 'shared/components/ui/icon-button';
import { ListSectionDateHeader } from 'shared/components/ui/list-section-date-header';
import { ListSectionMonthHeader } from 'shared/components/ui/list-section-month-header';
import { ListSectionYearHeader } from 'shared/components/ui/list-section-year-header';
import { PrioritySectionHeader } from 'shared/components/ui/priority-section-header';
import { GoalHeader } from 'shared/components/ui/task-card-task-section/goal-header';
import { LifeAreaHeader } from 'shared/components/ui/task-card-task-section/life-area-header';
import * as Styled from 'shared/components/ui/task-card-task-section/task-card-task-section-layout.style';
import { TypeSectionHeader } from 'shared/components/ui/type-section-header';
import { useToggle } from 'shared/hooks/use-toggle';
import { Goal } from 'shared/types/goal';
import { ID } from 'shared/types/id';
import { LifeArea } from 'shared/types/life-area';
import { TypeSections } from 'shared/types/page-sections';
import { TaskPriorityOptions } from 'shared/types/task-base';
import { Timestamp } from 'shared/types/timestamp';
import { useTheme } from 'styled-components';

export enum Headers {
  Date = 'date',
  Month = 'month',
  Year = 'year',
  Goal = 'goal',
  LifeArea = 'lifeArea',
  Priority = 'priority',
  Type = 'type',
}

export type TaskSectionLayoutProps = {
  customHeader?: React.ReactNode;
  date?: Timestamp;
  goalId?: ID;
  lifeAreaId?: ID;
  priority?: TaskPriorityOptions;
  count?: number;
  tagIds?: ID[];
  type?: TypeSections;
  headerToDisplay?: Headers;
  headerIsLink?: boolean;
  goals: Goal[];
  lifeAreas: LifeArea[];
  headerHasImage?: boolean;
  canCreateTask?: boolean;
  canCreateHabit?: boolean;
  canCollapse?: boolean;
  hideMeta?: boolean;
  newTaskInputRef?: React.RefObject<HTMLTextAreaElement>;
  onPremium: () => void;
  children: React.ReactNode;
};

export const TaskSectionLayout: React.FC<TaskSectionLayoutProps> = ({
  customHeader,
  date,
  goalId,
  lifeAreaId,
  priority,
  count,
  tagIds,
  type,
  headerToDisplay,
  headerIsLink,
  goals,
  lifeAreas,
  headerHasImage = headerToDisplay === Headers.Goal,
  canCreateTask,
  canCreateHabit,
  canCollapse,
  hideMeta,
  newTaskInputRef,
  onPremium,
  children,
}) => {
  const theme = useTheme();
  const [collapseOpen, toggleCollapse] = useToggle(true);

  const CollapseContainer = canCollapse ? Collapse : React.Fragment;
  const collapseProps = canCollapse ? { isOpen: collapseOpen } : {};

  return (
    <>
      <Styled.HeaderContainer $canCollapse={!!canCollapse}>
        {!!canCollapse && (
          <Styled.IconButtonContainer>
            <IconButton
              icon={ChevronLeft}
              rotation={collapseOpen ? 270 : theme.isMobile ? 90 : 180}
              size={
                theme.isMobile
                  ? IconButtonSizes.Large
                  : IconButtonSizes.ExtraSmall
              }
              onClick={canCollapse ? toggleCollapse : undefined}
            />
          </Styled.IconButtonContainer>
        )}
        {customHeader}
        {!customHeader && !!date && (
          <>
            {headerToDisplay === Headers.Date && (
              <ListSectionDateHeader date={date} />
            )}
            {headerToDisplay === Headers.Month && (
              <ListSectionMonthHeader date={date} />
            )}
            {headerToDisplay === Headers.Year && (
              <ListSectionYearHeader date={date} />
            )}
          </>
        )}
        {!customHeader && headerToDisplay === Headers.Goal && (
          <GoalHeader
            goalId={goalId}
            isLink={headerIsLink}
            onPremium={onPremium}
            goals={goals}
            lifeAreas={lifeAreas}
          />
        )}
        {!customHeader && headerToDisplay === Headers.LifeArea && (
          <LifeAreaHeader lifeAreaId={lifeAreaId} lifeAreas={lifeAreas} />
        )}
        {!customHeader && headerToDisplay === Headers.Priority && (
          <PrioritySectionHeader priority={priority} count={count} />
        )}
        {!customHeader && headerToDisplay === Headers.Type && (
          <TypeSectionHeader type={type} count={count} />
        )}
      </Styled.HeaderContainer>
      <CollapseContainer {...collapseProps}>
        <Styled.ChildrenContainer $hasExtraSpacing={headerHasImage}>
          {children}
        </Styled.ChildrenContainer>
        {!!canCreateTask && (
          <Styled.NewTaskContainer $hasExtraSpacing={headerHasImage}>
            <NewTaskRow
              date={date}
              goal={goalId}
              lifeArea={lifeAreaId}
              priority={priority}
              tags={tagIds}
              newTaskInputRef={newTaskInputRef}
              hideMeta={hideMeta}
            />
          </Styled.NewTaskContainer>
        )}
        {!!canCreateHabit && (
          <Styled.NewTaskContainer $hasExtraSpacing={headerHasImage}>
            <NewHabitRow
              goal={goalId}
              lifeArea={lifeAreaId}
              priority={priority}
              tags={tagIds}
            />
          </Styled.NewTaskContainer>
        )}
      </CollapseContainer>
    </>
  );
};
