import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import React, { useMemo } from 'react';

import { TasksListView } from './tasks-list-view';

export type TasksListProps = Omit<
  React.ComponentProps<typeof TasksListView>,
  'hasDnd'
> & {
  canReorder?: boolean;
  canDragTasks?: boolean;
};

export const TaskCardTasksList: React.FC<TasksListProps> = ({
  canReorder,
  canDragTasks,
  tasks,
  ...rest
}) => {
  const Wrapper = canReorder ? SortableContext : React.Fragment;
  const wrapperProps = useMemo(
    () =>
      canReorder
        ? {
            items: tasks.map(({ id }) => id),
            strategy: verticalListSortingStrategy,
          }
        : ({} as { items: string[] }),
    [canReorder, tasks],
  );

  return (
    <Wrapper {...wrapperProps}>
      <TasksListView
        tasks={tasks}
        {...rest}
        hasDnd={canReorder || canDragTasks}
      />
    </Wrapper>
  );
};
