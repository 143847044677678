import { useCallback, useMemo } from 'react';
import { useUpdateHabitPriorityTaskOrderMutation } from 'shared/hooks/queries/use-update-habit-priority-task-order-mutation';
import { useUpdateTaskMutation } from 'shared/hooks/queries/use-update-task-mutation';
import { Habit } from 'shared/types/habit';
import { SectionBase } from 'shared/types/orderable-section';
import { TaskPriorityOptions } from 'shared/types/task-base';

export const usePriorityHabitReorder = (sections: SectionBase<Habit>[]) => {
  const { mutate: updatePrioritySorting } =
    useUpdateHabitPriorityTaskOrderMutation();
  const { mutate: updateTask } = useUpdateTaskMutation();

  const oldSections = useMemo(() => structuredClone(sections), [sections]);

  return useCallback(
    (newSections: SectionBase<Habit>[], movedItem: Habit) => {
      const newSection = newSections.find(({ items }) =>
        items.find(({ id }) => id === movedItem.id),
      );
      const oldSection = oldSections.find(({ items }) =>
        items.find(({ id }) => id === movedItem.id),
      );

      if (!newSection || !oldSection) {
        return;
      }

      const prioritiesToUpdate = [
        {
          priority: newSection.id as TaskPriorityOptions,
          taskIds: newSection.items.map(({ id }) => id),
        },
      ];

      // if the section changed, get the new oldSection variant and add it to the prioritiesToUpdate list
      if (newSection.id !== oldSection.id) {
        const newOldSection = newSections.find(
          ({ id }) => id === oldSection.id,
        );

        if (!newOldSection) {
          // should not be possible, just check for type-safety
          return;
        }

        prioritiesToUpdate.push({
          priority: newOldSection.id as TaskPriorityOptions,
          taskIds: newOldSection.items.map(({ id }) => id),
        });

        updateTask({
          id: movedItem.id,
          priority: newSection.id as TaskPriorityOptions,
        });
      }

      updatePrioritySorting(prioritiesToUpdate);
    },
    [oldSections, updatePrioritySorting, updateTask],
  );
};
