import React from 'react';
import { Plus } from 'shared/assets/icons';
import { IconButton, Sizes } from 'shared/components/ui/icon-button';
import { sideNavigationHoverClass } from 'shared/constants';
import { useOpenCreateTagDialog } from 'shared/contexts/tag-form';

export const AddTagButton: React.FC = () => {
  const openCreateTagDialog = useOpenCreateTagDialog();
  const openDialog = () => openCreateTagDialog();

  return (
    <IconButton
      icon={Plus}
      size={Sizes.Small}
      onClick={openDialog}
      className={sideNavigationHoverClass}
    />
  );
};
