import { useMemo } from 'react';
import { useSchedulesPerfectSlots } from 'shared/hooks/use-schedules-perfect-slots';
import { useTimeframeCompletions } from 'shared/hooks/use-timeframe-completions';
import { useTimeframeEntries } from 'shared/hooks/use-timeframe-entries';
import { useTimeframeOccurrences } from 'shared/hooks/use-timeframe-occurrences';
import { useTimeframeSchedules } from 'shared/hooks/use-timeframe-schedules';
import { HabitSchedule } from 'shared/types/habit-schedule';
import { Timestamp } from 'shared/types/timestamp';
import { WeekDays } from 'shared/types/week-days';
import { getFrequencyTimeframeCompletions } from 'shared/utils/get-frequency-timeframe-completions';
import { isNumber } from 'shared/utils/is-number';

export type Options = {
  startDate: Timestamp;
  endDate: Timestamp;
  weekStartsOn: WeekDays;
};

export const useWeekStatistics = (
  schedules: HabitSchedule[],
  { startDate, endDate, weekStartsOn }: Options,
) => {
  const timeframeSchedules = useTimeframeSchedules(schedules, {
    startDate,
    endDate,
  });
  const { timeframeOccurrences } = useTimeframeOccurrences(schedules, {
    startDate,
    endDate,
  });
  const { dateEntries, timeframeDateEntries } = useTimeframeEntries(schedules, {
    startDate,
    endDate,
  });
  const { timeframeCompletions } = useTimeframeCompletions(schedules, {
    startDate,
    endDate,
  });
  const [perfectWeek] = useSchedulesPerfectSlots(schedules, {
    startDate,
    endDate,
    weekStartsOn,
  });

  const { completionsCount, target } = useMemo(() => {
    const lastSchedule = timeframeSchedules[timeframeSchedules.length - 1];
    if (!lastSchedule) {
      return {};
    }

    if (lastSchedule.frequency) {
      const lastTimeframeCompletion = timeframeCompletions.toSorted(
        (completionA, completionB) =>
          completionB.date.getTime() - completionA.date.getTime(),
      )[0];
      const referenceDate = lastTimeframeCompletion?.date ?? startDate;

      const { completionsCount, target } = getFrequencyTimeframeCompletions(
        lastSchedule,
        { referenceDate, weekStartsOn },
      );

      return { completionsCount, target };
    }

    return {
      completionsCount: timeframeCompletions.reduce(
        (acc, { value }) => acc + (value ?? 0),
        0,
      ),
      target: timeframeDateEntries.reduce(
        (acc, entry) => acc + (entry.target ?? 0),
        0,
      ),
    };
  }, [
    startDate,
    timeframeCompletions,
    timeframeDateEntries,
    timeframeSchedules,
    weekStartsOn,
  ]);

  const completionPercentage =
    isNumber(completionsCount) && isNumber(target)
      ? Math.floor((completionsCount! / Math.max(1, target!)) * 100)
      : 0;

  return {
    occurrences: timeframeOccurrences,
    dateEntries,
    perfectWeek,
    completionsCount,
    target,
    completionPercentage,
  };
};
