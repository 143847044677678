import { useCallback } from 'react';
import { useUpdateTodayTypeTaskOrderMutation } from 'shared/hooks/queries/use-update-today-type-task-order-mutation';
import { ID } from 'shared/types/id';
import { TypeSections } from 'shared/types/page-sections';

export const useTypeTodayReorder = () => {
  const { mutate } = useUpdateTodayTypeTaskOrderMutation();

  return useCallback(
    (typeSection: TypeSections, taskIds: ID[]) =>
      mutate({ typeSection, taskIds }),
    [mutate],
  );
};
