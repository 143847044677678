import { useMemo } from 'react';
import { useTasksQuery } from 'shared/hooks/queries/use-tasks-query';
import { useShouldHaveRestOfMonth } from 'shared/hooks/use-should-have-rest-of-month';
import { useUser } from 'shared/hooks/use-user';

export const useRestOfMonthTasks = () => {
  const user = useUser();
  const { restOfMonthStartDate, restOfMonthEndDate, shouldHaveRestOfMonth } =
    useShouldHaveRestOfMonth();

  const { data } = useTasksQuery(
    user?.uid,
    {
      deadlineStartDate: restOfMonthStartDate,
      deadlineEndDate: restOfMonthEndDate,
      unFrozenOnly: true,
      uncompletedOnly: true,
    },
    shouldHaveRestOfMonth,
  );

  return useMemo(
    () =>
      shouldHaveRestOfMonth
        ? data?.filter(({ archivedAt }) => !archivedAt) ?? []
        : [],
    [data, shouldHaveRestOfMonth],
  );
};
