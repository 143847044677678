import { useMemo } from 'react';
import { useTasksQuery } from 'shared/hooks/queries/use-tasks-query';
import { useUser } from 'shared/hooks/use-user';
import { GetTaskOptions } from 'shared/services/backend-api';
import { RepeatingTask } from 'shared/types/repeating-task';
import { TaskType } from 'shared/types/task-base';

export type Options = Omit<GetTaskOptions, 'type'>;

export const useRepeatingTasks = (options: Options = {}) => {
  const user = useUser();
  const { data } = useTasksQuery(user?.uid, {
    ...options,
    type: TaskType.Repeating,
  });

  return useMemo(
    () =>
      (data?.filter(({ schedules }) => !!schedules?.length) ??
        []) as RepeatingTask[],
    [data],
  );
};
