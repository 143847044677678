import React, { useMemo } from 'react';
import { Collapse } from 'shared/components/ui/collapse';
import { GoalSectionHeader } from 'shared/components/ui/goal-section-header';
import { useToggle } from 'shared/hooks/use-toggle';
import { GoalSection } from 'shared/types/goal-section';
import {
  GoalTemplateInstance,
  TaskTemplateInstance,
} from 'shared/types/goal-template';
import { LifeArea } from 'shared/types/life-area';

import { GoalTemplatePreviewActionPlan } from './goal-template-preview-action-plan';
import * as Styled from './goal-template-preview-action-plan.style';

type SectionProps = {
  section: GoalSection;
  goal: GoalTemplateInstance;
  tasks: TaskTemplateInstance[];

  noRightSpacing?: boolean;

  lifeAreas: LifeArea[];
};

const Section: React.FC<SectionProps> = ({
  section,
  goal,
  tasks,
  noRightSpacing,
  lifeAreas,
}) => {
  const [collapseOpen, toggleCollapse] = useToggle(true);

  const sectionTasks = useMemo(
    () =>
      section.tasks
        .map((id) => tasks.find((task) => task.id === id))
        .filter(Boolean) as TaskTemplateInstance[],
    [tasks, section],
  );

  return (
    <>
      <GoalSectionHeader
        id={section.id}
        goalId={goal.id}
        name={section.name}
        taskCount={section.tasks.length}
        onOpen={toggleCollapse}
        isOpen={collapseOpen}
        onPremium={() => {}}
        isPreview
      />
      <Collapse isOpen={collapseOpen}>
        <Styled.SublistContainer $minimalIndentation>
          <GoalTemplatePreviewActionPlan
            tasks={sectionTasks}
            lifeAreas={lifeAreas}
            goals={[]}
            mainGoal={goal}
            sectionId={section.id}
            noRightSpacing={noRightSpacing}
          />
        </Styled.SublistContainer>
      </Collapse>
    </>
  );
};

export type GoalSectionListProps = {
  sections: GoalSection[];
  goal: GoalTemplateInstance;
  tasks: TaskTemplateInstance[];

  noRightSpacing?: boolean;

  lifeAreas: LifeArea[];
};

export const GoalSectionList: React.FC<GoalSectionListProps> = ({
  sections,
  goal,
  tasks,
  noRightSpacing,
  lifeAreas,
}) => (
  <Styled.List $noRightSpacing={!!noRightSpacing}>
    {sections.map((section) => (
      <Styled.ListItem key={section.id}>
        <Section
          section={section}
          goal={goal}
          tasks={tasks}
          noRightSpacing={noRightSpacing}
          lifeAreas={lifeAreas}
        />
      </Styled.ListItem>
    ))}
  </Styled.List>
);
