import { useMetricTemplates } from 'features/app-config';
import { useCreateGoal, useGoals, useUpdateGoal } from 'features/goals';
import { useLocalizedLifeAreas } from 'features/life-areas';
import { useIsFavorite, useToggleFavorite } from 'features/user';
import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Locales } from 'shared/assets/localization';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';
import { useToday } from 'shared/contexts/today';
import { usePremiumFeatureAllowed } from 'shared/hooks/use-premium-feature-allowed';
import { useUser } from 'shared/hooks/use-user';
import { Paths } from 'shared/routes';
import { initialDateFormat } from 'shared/types/date-format-options';
import { Goal, NewGoal } from 'shared/types/goal';
import { GoalFormFields } from 'shared/types/goal-form';
import { ID } from 'shared/types/id';
import { LimitKeys } from 'shared/types/limit-keys';
import { FavoriteType } from 'shared/types/user-settings';
import { getWeekStartsOn } from 'shared/utils/get-week-starts-on';
import { replaceUrlParams } from 'shared/utils/replace-url-params';

import { GoalFormContext, GoalFormContextProps } from './goal-form-context';
import { mapGoalFieldsToGoal } from './map-goal-fields-to-goal';
import { mapGoalToGoalFields } from './map-goal-to-goal-fields';

const GoalFormDialog = React.lazy(async () => ({
  default: (await import('shared/dialogs/goal-form')).GoalFormDialog,
}));

export type GoalFormProviderProps = {
  children: React.ReactNode;
};

export const GoalFormProvider: React.FC<GoalFormProviderProps> = ({
  children,
}) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const user = useUser();
  const today = useToday();
  const lifeAreas = useLocalizedLifeAreas();
  const goals = useGoals();
  const metricTemplates = useMetricTemplates();

  // useOpenPremiumDialog is part of a higher level context which should be kept in mind (probably different setup needed)
  const openPremiumDialog = useOpenPremiumDialog();
  const { allowed: uploadImageAllowed } = usePremiumFeatureAllowed(
    LimitKeys.GoalUserPhoto,
    {},
  );
  const [dialogOpen, setDialogOpen] = useState(false);
  const [goal, setGoal] = useState<Partial<Goal>>();
  const [goalId, setGoalId] = useState<ID>();
  const create = useCreateGoal();
  const update = useUpdateGoal();
  const { retry, isLoading, error, isSuccess, reset } = useMemo(
    () => (goalId ? update : create),
    [create, goalId, update],
  );
  const isFavorite = useIsFavorite(goalId);
  const toggleFavorite = useToggleFavorite();

  const filteredLifeAreas = useMemo(
    () => lifeAreas.filter(({ archivedAt }) => !archivedAt),
    [lifeAreas],
  );

  const openDialog = useCallback(
    (goal?: Partial<Goal>, id?: ID) => {
      setGoalId(id);
      setGoal(
        id ? goal : { ...(goal ?? {}), startDate: goal?.startDate ?? today },
      );
      setDialogOpen(true);
    },
    [today],
  );

  const closeDialog = useCallback(() => {
    reset();
    setGoal(undefined);
    setGoalId(undefined);
    setDialogOpen(false);
  }, [reset]);

  const submitForm = useCallback(
    async (values: GoalFormFields) => {
      const processedValues = mapGoalFieldsToGoal(values, {
        metricTemplates,
        metricEntries: goal?.metric?.entries ?? [],
      });

      if (!processedValues) {
        return;
      }

      if (goalId) {
        update.submit({ ...processedValues, id: goalId }, true);
        isFavorite !== values.favorite &&
          toggleFavorite(goalId, FavoriteType.Goal);
        return;
      }

      const createdGoal = await create.submit(processedValues as NewGoal, {
        lifeArea: filteredLifeAreas.find(({ id }) => id === values.lifeAreaId)!,
      });

      !!values.favorite && toggleFavorite(createdGoal.id, FavoriteType.Goal);

      if (!createdGoal.parentIds?.length) {
        navigate(replaceUrlParams(Paths.GoalDetail, { id: createdGoal.id }));
      }
    },
    [
      create,
      filteredLifeAreas,
      goal?.metric?.entries,
      goalId,
      isFavorite,
      metricTemplates,
      navigate,
      toggleFavorite,
      update,
    ],
  );

  const initialVals = useMemo(() => {
    const fieldValues = goal
      ? mapGoalToGoalFields(goal, { locale: i18n.language as Locales })
      : {};
    // startDate should be today for a new goal
    const startDate = goalId ? fieldValues?.startDate : today;
    return {
      startDate,
      ...fieldValues,
      favorite: isFavorite,
    };
  }, [goal, goalId, i18n.language, isFavorite, today]);

  useEffect(() => {
    if (isSuccess) {
      closeDialog();
    }
  }, [closeDialog, isSuccess]);

  const value = useMemo<GoalFormContextProps>(
    () => ({ onCreateGoal: openDialog, onEditGoal: openDialog }),
    [openDialog],
  );

  return (
    <GoalFormContext.Provider value={value}>
      {children}
      <Suspense>
        <GoalFormDialog
          initialValues={initialVals}
          metricTemplates={metricTemplates}
          lifeAreas={filteredLifeAreas}
          goals={goals}
          dateFormat={user?.settings?.dateFormat ?? initialDateFormat}
          weekStartsOn={getWeekStartsOn(user)}
          open={dialogOpen}
          onClose={closeDialog}
          onSubmit={submitForm}
          onRetry={retry}
          isLoading={isLoading}
          isError={!!error}
          isEdit={!!goalId}
          isPremiumImageUploadAllowed={uploadImageAllowed}
          onPremium={openPremiumDialog}
        />
      </Suspense>
    </GoalFormContext.Provider>
  );
};
