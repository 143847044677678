import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TaskPriorityOptions } from 'shared/types/task-base';
import { isNumber } from 'shared/utils/is-number';

import * as Styled from './priority-header.style';

export type PriorityHeaderProps = {
  priority: TaskPriorityOptions;
  amount?: number;
};

export const PriorityHeader: React.FC<PriorityHeaderProps> = ({
  priority,
  amount,
}) => {
  const { t } = useTranslation();

  return (
    <Styled.Container>
      <Styled.TitleContainer>
        <Typography variant="h6" component="span">
          {t(`general.sections.priority.${priority}.title`)}
        </Typography>
        {isNumber(amount) && <Styled.TitleCount>{amount}</Styled.TitleCount>}
      </Styled.TitleContainer>
    </Styled.Container>
  );
};
