import { Timestamp } from 'shared/types/timestamp';
import { GoalRoadmapSortingKeys } from 'shared/types/user-settings';

export const formatDateKey = (date: Timestamp) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const formatQuarterlyDateKey = (date: Timestamp) =>
  // unplanned
  date.getTime() === new Date(0).getTime()
    ? GoalRoadmapSortingKeys.Unplanned
    : // in progress
      date.getTime() === new Date(1).getTime()
      ? GoalRoadmapSortingKeys.InProgress
      : (() => {
          const quarter = Math.floor(date.getMonth() / 3) + 1;
          const year = date.getFullYear();
          return `Q${quarter}-${year}`;
        })();
