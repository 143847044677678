import { endOfYear } from 'date-fns';
import { useMemo } from 'react';
import { useTasksQuery } from 'shared/hooks/queries/use-tasks-query';
import { useUser } from 'shared/hooks/use-user';
import { Timestamp } from 'shared/types/timestamp';

export const useYearTasks = (startDate: Timestamp) => {
  const user = useUser();
  const endDate = useMemo(() => endOfYear(startDate), [startDate]);

  const { data } = useTasksQuery(user?.uid, {
    deadlineStartDate: startDate,
    deadlineEndDate: endDate,
    unFrozenOnly: true,
    uncompletedOnly: true,
  });

  return useMemo(
    () => data?.filter(({ archivedAt }) => !archivedAt) ?? [],
    [data],
  );
};
