import React from 'react';
import { useTranslation } from 'react-i18next';
import { Repeat, Tag } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import {
  PopupMenu,
  PopupMenuButton,
  PopupMenuList,
  PopupMenuListItem,
  PopupMenuToggle,
} from 'shared/components/ui/popup-menu';
import { useUser } from 'shared/hooks/use-user';

import { useToggleHideHabits } from '../../hooks/use-toggle-hide-habits';

export type MainContextMenuProps = {
  referenceElement?: React.RefObject<HTMLElement>;
  onClose: () => void;
  onFilter?: () => void;
};

export const MainContextMenu: React.FC<MainContextMenuProps> = ({
  referenceElement,
  onClose,
  onFilter,
}) => {
  const { t } = useTranslation();
  const user = useUser();

  const toggleHideHabits = useToggleHideHabits();

  return (
    <PopupMenu
      referenceElement={referenceElement}
      position="bottom-end"
      onOutside={onClose}
    >
      <PopupMenuList hasBottomBorder>
        <PopupMenuListItem>
          <PopupMenuToggle
            start={<Icon icon={Repeat} />}
            label={t('pages.all-tasks.show-habits.label')}
            onChange={toggleHideHabits}
            checked={!user?.settings?.allTasksHideHabits}
          />
        </PopupMenuListItem>

        {onFilter && (
          <PopupMenuListItem>
            <PopupMenuButton start={<Icon icon={Tag} />} onClick={onFilter}>
              {t('pages.all-tasks.open-filters.tags.label')}
            </PopupMenuButton>
          </PopupMenuListItem>
        )}
      </PopupMenuList>
    </PopupMenu>
  );
};
