import { useTagById, useUpdateTag } from 'features/tags';
import { useIsFavorite, useToggleFavorite } from 'features/user';
import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { MoreHorizontal, Star, Tag } from 'shared/assets/icons';
import { TagContextMenu } from 'shared/components/connected/tag-context-menu';
import {
  EditableTextField,
  Variants,
} from 'shared/components/ui/editable-text-field';
import { Icon } from 'shared/components/ui/icon';
import { IconButton, Sizes } from 'shared/components/ui/icon-button';
import { useOpenMenu } from 'shared/hooks/use-open-menu';
import { FavoriteType } from 'shared/types/user-settings';

import { HeaderInteractions } from '../header-interactions';
import * as Styled from './tag-detail-header.style';

export type TagDetailHeaderProps = {};

export const TagDetailHeader: React.FC<TagDetailHeaderProps> = () => {
  const moreRef = useRef<HTMLButtonElement>(null);
  const { menuOpen, openMenu, closeMenu } = useOpenMenu();

  const { id } = useParams<{ id: string }>();
  const tag = useTagById(id);
  const { submit: updateTag } = useUpdateTag();
  const isFavorite = useIsFavorite(id);

  const toggleFavorite = useToggleFavorite();

  const onChangeTitle = (name: string) => {
    tag && updateTag({ ...tag, name });
  };

  const onFavorite = () => {
    tag && toggleFavorite(tag.id, FavoriteType.Tag);
  };

  if (!tag) {
    return null;
  }

  return (
    <Styled.Container>
      <Styled.Left>
        <Styled.IconContainer $color={tag.color}>
          <Icon icon={Tag} />
        </Styled.IconContainer>
        <EditableTextField
          value={tag.name}
          onChange={onChangeTitle}
          variant={Variants.H1}
        />
      </Styled.Left>

      <Styled.Right>
        <HeaderInteractions />

        <Styled.FavoriteButtonContainer $isFavorite={isFavorite}>
          <IconButton icon={Star} size={Sizes.Large} onClick={onFavorite} />
        </Styled.FavoriteButtonContainer>

        <IconButton
          ref={moreRef}
          icon={MoreHorizontal}
          size={Sizes.Large}
          onClick={openMenu}
        />

        {menuOpen && (
          <TagContextMenu
            id={id!}
            onClose={closeMenu}
            referenceElement={moreRef}
          />
        )}
      </Styled.Right>
    </Styled.Container>
  );
};
