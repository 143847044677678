import { useGoals } from 'features/goals';
import { useCreateHabitAllowed, useCreateTask } from 'features/tasks';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowUp } from 'shared/assets/icons';
import { Checkbox, Colors } from 'shared/components/ui/checkbox';
import { Icon } from 'shared/components/ui/icon';
import {
  ResizableTextArea,
  Variants,
} from 'shared/components/ui/resizable-text-area';
import { useOpenCreateHabitDialog } from 'shared/contexts/habit-form';
import { useBlockMobileScrollOutsideElement } from 'shared/hooks/use-block-mobile-scroll-outside-element';
import { useKeysDown } from 'shared/hooks/use-keys-down';
import { useNewTaskState } from 'shared/hooks/use-new-task-state';
import { Habit } from 'shared/types/habit';
import { ID, IDPrefixes } from 'shared/types/id';
import { CreateTask, NewTask } from 'shared/types/task';
import { TaskPriorityOptions, TaskType } from 'shared/types/task-base';

import { Description } from './description';
import { LinkedItemSelect } from './linked-item-select';
import { MetaInteractions } from './meta-interactions';
import * as Styled from './task-create-column.style';

const priorityColorMap: Record<TaskPriorityOptions, Colors> = {
  [TaskPriorityOptions.Low]: Colors.Info,
  [TaskPriorityOptions.Medium]: Colors.Warning,
  [TaskPriorityOptions.High]: Colors.Error,
  [TaskPriorityOptions.None]: Colors.Default,
};

export type TaskDetailColumnProps = {
  initialValues?: Partial<CreateTask>;
  onClose: () => void;
};

export const TaskCreateColumn: React.FC<TaskDetailColumnProps> = ({
  initialValues = {},
  onClose,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const titleRef = useRef<HTMLTextAreaElement>(null);
  const goals = useGoals();
  const { submit: createTask } = useCreateTask();
  const { allowed: createHabitAllowed } = useCreateHabitAllowed();
  const openCreateHabitDialog = useOpenCreateHabitDialog();

  const onCreateTask = async (
    newTask: NewTask,
    { tag }: Partial<Record<IDPrefixes, ID[] | null>>,
  ) => {
    const goal = goals.find(({ id }) => id === newTask.goalId);
    await createTask(newTask, {
      goal,
      sectionId: initialValues?.sectionId ?? undefined,
      tags: tag,
    });
    onClose();
  };

  const { submit, updateNewTask, newTask } = useNewTaskState(
    onCreateTask,
    initialValues,
  );

  const preventPropagation = (e: React.MouseEvent) => e.stopPropagation();

  const onChangeTitle = (name: string) => updateNewTask({ name });

  const onChangeDescription = (description: string | null) =>
    updateNewTask({ description: description ?? undefined });

  const onRepeat = () => {
    // prevent new task to be created by changing the name to an empty string
    onClose();
    openCreateHabitDialog({
      ...newTask,
      endStrategy: null,
      type: createHabitAllowed ? TaskType.Habit : TaskType.Repeating,
      schedules: undefined,
    } as Partial<Habit>);
  };

  useEffect(() => {
    if (titleRef.current) {
      titleRef.current.focus();
    }
  }, []);

  useKeysDown(['Enter'], (e) => {
    if (document.activeElement === titleRef.current) {
      // no enters allowed in title
      e.preventDefault();
      titleRef.current?.blur();
    }
  });

  useBlockMobileScrollOutsideElement(containerRef);

  return (
    <Styled.Container ref={containerRef}>
      <Styled.Header>
        <Styled.TitleBar>
          <Styled.CheckboxContainer onClick={preventPropagation}>
            <Checkbox
              color={
                newTask.priority
                  ? priorityColorMap[newTask.priority]
                  : undefined
              }
              checked={false}
              readOnly
            />
          </Styled.CheckboxContainer>

          <Styled.TitleContainer>
            <ResizableTextArea
              ref={titleRef}
              value={newTask.name}
              onChange={onChangeTitle}
              placeholder={t('forms.new-task.title.placeholder')}
              variant={Variants.H5}
            />
          </Styled.TitleContainer>
        </Styled.TitleBar>
      </Styled.Header>

      <Styled.Body>
        <Styled.DescriptionContainer>
          <Description
            value={newTask.description ?? null}
            onChange={onChangeDescription}
            placeholder={t('general.labels.task-detail.notes.placeholder')}
          />
        </Styled.DescriptionContainer>

        <Styled.MetaInteractionsContainer>
          <MetaInteractions
            newTask={newTask}
            onChange={updateNewTask}
            onRepeat={onRepeat}
          />
        </Styled.MetaInteractionsContainer>
      </Styled.Body>

      <Styled.Footer>
        <Styled.LinkedItemContainer>
          <LinkedItemSelect
            onChange={updateNewTask}
            goalId={newTask.goalId}
            lifeAreaId={newTask.lifeAreaId}
          />
        </Styled.LinkedItemContainer>

        <Styled.SubmitButton disabled={!newTask.name}>
          <Icon icon={ArrowUp} onClick={submit} />
        </Styled.SubmitButton>
      </Styled.Footer>
    </Styled.Container>
  );
};
