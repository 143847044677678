import { rgba } from 'polished';
import { typographyToCss } from 'shared/lib/mui';
import { ReactDatePicker } from 'shared/lib/react-datepicker';
import styled, { css } from 'styled-components';

export const Container = styled.div<{
  $clickableDays: boolean;
  $showDates: boolean;
  $isCard: boolean;
}>`
  .react-datepicker__header {
    margin: 0;
    padding: 0;

    background: transparent;
    border-bottom: none;
  }

  .react-datepicker__aria-live {
    display: none;
  }

  .react-datepicker__day-names,
  .react-datepicker__week {
    display: grid;
    gap: ${({ theme }) => theme.spacing(2.5)};
    grid-template-columns: repeat(7, 1fr);
    margin: 0;
    padding-bottom: ${({ theme }) => theme.spacing(2)};
  }

  .react-datepicker__month {
    margin: 0;
    padding: 0;
  }

  .react-datepicker__day-name {
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  .react-datepicker__day-name {
    ${({ theme }) => typographyToCss(theme.typography.body2)};
    height: 1.4rem;

    text-align: center;
  }

  .react-datepicker__day {
    height: ${({ $showDates }) => ($showDates ? '2.4rem' : '1.4rem')};
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    width: ${({ $showDates }) => ($showDates ? '2.4rem' : '1.4rem')};
    display: block;
    margin: 0;
  }

  .react-datepicker__day-name {
    ${({ theme }) => typographyToCss(theme.typography.overline)};
  }

  .react-datepicker__day--today {
    ${({ theme }) => typographyToCss(theme.typography.subtitle2)};
    color: ${({ theme }) => theme.palette.primary.main};
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  }

  .react-datepicker__day--selected {
    border-radius: ${({ theme }) => theme.spacing(2)};
    background: ${({ theme }) => theme.palette.primary.light};
    color: ${({ theme }) => theme.palette.primary.main};

    &:hover {
      background: ${({ theme }) => theme.palette.primary.main};
      color: ${({ theme }) => theme.palette.primary.contrastText};
    }
  }

  .react-datepicker__day--keyboard-selected {
    background: transparent;
  }

  ${({ theme, $isCard }) =>
    $isCard &&
    css`
      padding: ${`${theme.spacing(3)} ${theme.spacing(4)} ${theme.spacing(2)}`};
      border: 1px solid ${theme.palette.grey['100']};
      border-radius: ${theme.spacing(3)};
      box-shadow: ${theme.shadowBox.card};
      background: ${theme.palette.backgroundExtended.card};
    `};
`;

export const DatePicker = styled(ReactDatePicker)``;

export const DayContainer = styled.span`
  position: relative;
  display: block;
`;

export const Day = styled.span<{
  $isOccurrence: boolean;
  $isToday: boolean;
  $completionPercentage: number;
  $isPerfectDay: boolean;
  $isSkipped: boolean;
  $isFailure: boolean;
  $isFuture: boolean;
  $showDate: boolean;
}>`
  ${({ theme }) => typographyToCss(theme.typography.buttonSmall)};
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 1.4rem;
  height: 1.4rem;

  border-radius: ${({ theme }) => theme.spacing(1)};
  border: 2px solid ${({ theme }) => theme.palette.divider};
  background: transparent;
  overflow: hidden;

  color: ${({ theme }) => theme.palette.text.secondary};

  ${({ onClick }) =>
    !!onClick &&
    css`
      cursor: pointer;

      &:hover {
        background: ${({ theme }) => theme.palette.brown['100']};
      }
    `};

  ${({ $showDate, theme }) =>
    $showDate &&
    css`
      width: 2.4rem;
      height: 2.4rem;

      border-radius: ${theme.spacing(2)};
      border: none;
    `};

  ${({ $isOccurrence, theme }) =>
    $isOccurrence &&
    css`
      border-color: ${theme.palette.brown['200']};
    `};

  ${({ theme, $isToday, $showDate }) =>
    $isToday &&
    css`
      border: ${$showDate ? '1px' : '2px'} solid ${theme.palette.text.primary};
      color: ${theme.palette.text.primary};
    `};

  ${({ theme, $completionPercentage, $isPerfectDay }) =>
    $completionPercentage > 0 &&
    css`
      background: ${rgba(
        $isPerfectDay ? theme.palette.success.dark : theme.palette.primary.main,
        0.2,
      )};
      color: ${$isPerfectDay
        ? theme.palette.success.contrastText
        : theme.palette.primary.contrastText};

      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        height: ${Math.min(Math.max($completionPercentage, 0), 100)}%;
        width: 100%;

        background: ${$isPerfectDay
          ? theme.palette.success.dark
          : theme.palette.primary.main};
      }

      &:hover {
        background: ${rgba(
          $isPerfectDay
            ? theme.palette.success.dark
            : theme.palette.primary.main,
          0.2,
        )};
        color: ${$isPerfectDay
          ? theme.palette.success.contrastText
          : theme.palette.primary.contrastText};
      }
    `};

  ${({ $isSkipped, $showDate, onClick, theme }) =>
    $isSkipped &&
    css`
      border-color: transparent;
      color: ${theme.palette.warning.main};

      ${$showDate &&
      css`
        background-color: ${theme.palette.warning.light};
      `};

      ${!$showDate &&
      !!onClick &&
      css`
        &:hover {
          background-color: ${theme.palette.warning.light};
        }
      `};
    `};

  ${({ $isFailure, $showDate, onClick, theme }) =>
    $isFailure &&
    css`
      border-color: transparent;
      color: ${theme.palette.error.main};

      ${$showDate &&
      css`
        background-color: ${theme.palette.error.light};
      `};

      ${!$showDate &&
      !!onClick &&
      css`
        &:hover {
          background-color: ${theme.palette.error.light};
        }
      `};
    `};

  ${({ $isFuture }) =>
    $isFuture &&
    css`
      opacity: 0.5;

      &:hover {
        background: transparent;
      }
    `};
`;

export const ValueBadge = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.overline)};
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0;
  right: 0;

  padding: ${({ theme }) => `0 ${theme.spacing(0.75)}`};
  height: 1.6rem;
  min-width: 1.6rem;

  border-radius: ${({ theme }) => theme.spacing(2)};
  border: 1px solid ${({ theme }) => theme.palette.divider};
  background: ${({ theme }) => theme.palette.backgroundExtended.card};
  transform: ${({ theme }) =>
    `translate(${theme.spacing(2)}, ${theme.spacing(-2)})`};
`;

export const RescheduleDot = styled.span`
  position: absolute;
  top: 100%;
  left: 50%;

  height: 1rem;
  width: 1rem;

  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.palette.backgroundExtended.card};
  transform: translate(-50%, -50%);
  background: ${({ theme }) => theme.palette.warning.main};
`;

export const DayLabel = styled.span`
  position: relative;
  line-height: 62.5%;
`;

export const DayIcon = styled.span`
  position: relative;
  font-size: 1.6rem;
  line-height: 62.5%;
`;
