import Typography from '@mui/material/Typography';
import React, { useMemo } from 'react';
import {
  Check,
  CheckCircle,
  DotsMenu,
  IconFormIcons,
  IconFormNames,
  SlimArrowUp,
} from 'shared/assets/icons';
import { EvenlyDistributedBarChart } from 'shared/components/ui/evenly-distributed-bar-chart';
import { Icon } from 'shared/components/ui/icon';
import { IconButton, Sizes } from 'shared/components/ui/icon-button';
import { MonthProgressPreview } from 'shared/components/ui/month-progress-preview';
import { useActiveSchedule } from 'shared/hooks/use-active-schedule';
import { BarChartEntry } from 'shared/lib/recharts';
import { Goal } from 'shared/types/goal';
import { Habit } from 'shared/types/habit';
import {
  FrequencyUnit,
  ScheduleEntryState,
  ScheduleTargetEntry,
} from 'shared/types/habit-schedule';
import { ID } from 'shared/types/id';
import { LifeArea } from 'shared/types/life-area';
import { Timeframe } from 'shared/types/timeframe';
import { Timestamp } from 'shared/types/timestamp';
import { WeekDays } from 'shared/types/week-days';
import { getGoalColor } from 'shared/utils/get-goal-color';
import { isNumber } from 'shared/utils/is-number';

import * as Styled from './habit-insights-card.style';

export type HabitInsightsCardProps = {
  habit: Habit;
  entries: BarChartEntry[];
  entriesTarget?: number;
  calendarOccurrences: Timestamp[];
  dateEntries: ScheduleTargetEntry[];
  perfectSlots?: Timeframe[];
  completionPercentage?: number;
  currentValue: number;
  target?: number;
  timeframeIncrease?: number;
  goals: Goal[];
  lifeAreas: LifeArea[];
  calendarOpensToDate: Timestamp;
  weekStartsOn: WeekDays;
  menuButtonRef?: React.RefObject<HTMLButtonElement>;
  onMenuButton?: (e: React.MouseEvent) => void;
  onContextMenu?: (e: React.MouseEvent) => void;
  onClick?: (id: ID) => void;
  onDate?: (
    id: ID,
    date: Timestamp,
    type: ScheduleEntryState,
    options?: { value?: number; increment?: number },
  ) => void;
  onDateManual?: (habit: Habit, date: Timestamp) => void;
  isPreview?: boolean;
  displayMonth?: boolean;
};

export const HabitInsightsCard: React.FC<HabitInsightsCardProps> = ({
  habit,
  entries,
  entriesTarget,
  calendarOccurrences,
  dateEntries,
  perfectSlots,
  completionPercentage,
  currentValue,
  target,
  timeframeIncrease,
  goals,
  lifeAreas,
  calendarOpensToDate,
  weekStartsOn,
  menuButtonRef,
  onMenuButton,
  onContextMenu,
  onClick,
  onDate,
  onDateManual,
  isPreview,
  displayMonth,
}) => {
  const activeSchedule = useActiveSchedule(habit.schedules);
  const color = useMemo(
    () => getGoalColor(habit.id, { goals, lifeAreas }),
    [habit.id, goals, lifeAreas],
  );

  const onHabit = () => onClick?.(habit.id);

  const _onDate = (
    date: Timestamp,
    type: ScheduleEntryState,
    options?: { value?: number; increment?: number },
  ) => onDate?.(habit.id, date, type, options);

  const _onDateManual = (date: Timestamp) => onDateManual?.(habit, date);

  const _onMenuButton = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onMenuButton?.(e);
  };

  return (
    <Styled.Container
      $isPreview={!!isPreview}
      onClick={onHabit}
      onContextMenu={onContextMenu}
    >
      <Styled.Header>
        <Styled.GoalInformationContainer>
          <Styled.IllustrationContainer>
            <Styled.ImageContainer>
              <Icon
                icon={
                  IconFormIcons[habit.iconName!] ??
                  IconFormIcons[IconFormNames.Repeat]
                }
              />
            </Styled.ImageContainer>

            {!!habit.completedAt && (
              <Styled.CompletedContainer>
                <Icon icon={Check} />
              </Styled.CompletedContainer>
            )}

            {!!color && <Styled.ColorDot $color={color} />}
          </Styled.IllustrationContainer>

          <Styled.Title $isCompleted={!!habit.completedAt}>
            {habit.name}
          </Styled.Title>
        </Styled.GoalInformationContainer>

        <Styled.IconButtonContainer>
          <IconButton
            icon={DotsMenu}
            onClick={_onMenuButton}
            size={Sizes.ExtraSmall}
            ref={menuButtonRef}
          />
        </Styled.IconButtonContainer>
      </Styled.Header>

      <Styled.Body>
        <Styled.CurrentProgress>
          <Typography variant="h3" component="span">
            {currentValue}
          </Typography>
          {!!target && ` / ${target}`}{' '}
        </Styled.CurrentProgress>

        <Styled.ProgressContainer>
          {isNumber(completionPercentage) && (
            <Styled.Progress $isPositive={!!completionPercentage}>
              <Icon icon={CheckCircle} />

              <Typography variant="caption">
                {isNumber(completionPercentage)
                  ? `${completionPercentage}%`
                  : '-'}
              </Typography>
            </Styled.Progress>
          )}

          {!!timeframeIncrease && (
            <Styled.Difference $difference={timeframeIncrease}>
              <Styled.DifferenceIconContainer $positive={timeframeIncrease > 0}>
                <Icon icon={SlimArrowUp} />
              </Styled.DifferenceIconContainer>
              {timeframeIncrease.toFixed(1)}%
            </Styled.Difference>
          )}
        </Styled.ProgressContainer>
      </Styled.Body>

      <Styled.Footer $smallSpacing={!displayMonth}>
        {displayMonth ? (
          <MonthProgressPreview
            openToDate={calendarOpensToDate}
            dateEntries={dateEntries}
            perfectSlots={perfectSlots}
            occurrences={calendarOccurrences}
            onChange={_onDate}
            onDateManual={_onDateManual}
            weekStartsOn={weekStartsOn}
            showSurroundingMonths={
              activeSchedule?.frequency?.unit === FrequencyUnit.Week
            }
          />
        ) : (
          <EvenlyDistributedBarChart
            data={entries}
            target={entriesTarget}
            showYAxis
            hasLowHeight
          />
        )}
      </Styled.Footer>
    </Styled.Container>
  );
};
