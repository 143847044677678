import { endOfMonth, isSameMonth, startOfMonth } from 'date-fns';
import React, { useCallback, useMemo } from 'react';
import { useToday } from 'shared/contexts/today';
import { isBetween } from 'shared/lib/date-fns';
import {
  HabitSchedule,
  ScheduleEntryState,
  ScheduleTargetEntry,
} from 'shared/types/habit-schedule';
import { Timeframe } from 'shared/types/timeframe';
import { Timestamp } from 'shared/types/timestamp';
import { WeekDays } from 'shared/types/week-days';

import { Day } from './day';
import * as Styled from './habit-progress-calendar.style';
import { Header } from './header';
import { ScheduleChangesAnnouncement } from './schedule-changes-announcement';

const weekDayFormat = (day: string) => day.slice(0, 1);

export enum SurroundingMonths {
  Before = 'before',
  After = 'after',
}

export type HabitProgressCalendarProps = {
  openToDate?: Timestamp;
  weekStartsOn: WeekDays;
  completionCount?: number;
  failureCount?: number;
  completionPercentage?: number;
  occurrences: Timestamp[];
  dateEntries: ScheduleTargetEntry[];
  perfectSlots?: Timeframe[];
  scheduleChanges?: {
    date: Timestamp;
    oldSchedule: HabitSchedule;
    newSchedule: HabitSchedule;
  }[];
  weekTarget?: number;
  showSurroundingMonths?: SurroundingMonths[];
  onChange?: (
    date: Timestamp,
    type: ScheduleEntryState,
    options?: { value?: number; increment?: number },
  ) => void;
  onDateManual: (date: Timestamp) => void;
  onInfo?: () => void;
  showDates?: boolean;
  showHeader?: boolean;
  showPerfectWeeks?: boolean;
  isCard?: boolean;
};

export const HabitProgressCalendar: React.FC<HabitProgressCalendarProps> = ({
  openToDate = new Date(),
  weekStartsOn,
  completionCount,
  failureCount,
  completionPercentage,
  occurrences,
  dateEntries,
  perfectSlots = [],
  scheduleChanges = [],
  showSurroundingMonths = [SurroundingMonths.Before, SurroundingMonths.After],
  onChange,
  onDateManual,
  onInfo,
  showDates,
  showHeader,
  isCard,
}) => {
  const today = useToday();
  const _onChange = (_: Timestamp, e: React.ChangeEvent) => {
    e.stopPropagation();
    e.preventDefault();
  };

  // Function to filter out dates not in the current month
  const isCurrentMonth = useCallback(
    (date: Timestamp) => isSameMonth(date, today),
    [today],
  );

  const filteredPerfectSlots = useMemo(() => {
    const som = startOfMonth(openToDate);
    const eom = endOfMonth(openToDate);

    return perfectSlots.filter(({ endDate }) => isBetween(endDate, som, eom));
  }, [openToDate, perfectSlots]);

  return (
    <Styled.Container
      $clickableDays={!!onChange}
      $showDates={!!showDates}
      $isCard={!!isCard}
    >
      <Styled.DatePicker
        weekStartsOn={weekStartsOn}
        onChange={_onChange}
        renderCustomHeader={({ date }) =>
          showHeader && (
            <Header
              date={date}
              completionCount={completionCount}
              completionsPercentage={completionPercentage}
              perfectSlots={filteredPerfectSlots.length}
              failureCount={failureCount}
              onInfo={onInfo}
            />
          )
        }
        openToDate={openToDate}
        renderDayContents={(_, date) =>
          date ? (
            <Day
              date={date}
              monthCompareDate={openToDate}
              occurrences={occurrences}
              dateEntries={dateEntries}
              perfectSlots={perfectSlots}
              scheduleChanges={scheduleChanges}
              onDate={onChange}
              onDateManual={onDateManual}
              showDate={showDates}
              showPrevMonthDate={showSurroundingMonths.includes(
                SurroundingMonths.Before,
              )}
              showNextMonthDate={showSurroundingMonths.includes(
                SurroundingMonths.After,
              )}
            />
          ) : null
        }
        filterDate={isCurrentMonth}
        formatWeekDay={weekDayFormat}
        shouldCloseOnSelect={false}
        inline
      />
      {!!scheduleChanges?.length && (
        <ScheduleChangesAnnouncement scheduleChanges={scheduleChanges} />
      )}
    </Styled.Container>
  );
};
