import React, { useRef } from 'react';
import { Sliders } from 'shared/assets/icons';
import { IconButton, Variants } from 'shared/components/ui/icon-button';
import { useActivePath } from 'shared/hooks/use-active-path';
import { useOpenMenu } from 'shared/hooks/use-open-menu';
import { Paths } from 'shared/routes';
import { useTheme } from 'styled-components';

import { ActiveMenu } from './active-menu';
import * as Styled from './header-interactions.style';
import { RoadmapMenu } from './roadmap-menu';

const pathMenuMap = {
  [Paths.Goals]: ActiveMenu,
  [Paths.GoalsRoadmap]: RoadmapMenu,
};

export const HeaderInteractions: React.FC = () => {
  const theme = useTheme();
  const containerRef = useRef<HTMLDivElement>(null);
  const { menuOpen, openMenu, closeMenu } = useOpenMenu();
  const activePath = useActivePath();

  // @ts-ignore
  const MenuComponent = activePath ? pathMenuMap[activePath] : undefined;

  return MenuComponent ? (
    <Styled.Container ref={containerRef}>
      <IconButton
        icon={Sliders}
        onClick={openMenu}
        variant={theme.isMobile ? Variants.Outlined : Variants.Contained}
      />

      {menuOpen && (
        <MenuComponent referenceElement={containerRef} onClose={closeMenu} />
      )}
    </Styled.Container>
  ) : null;
};
