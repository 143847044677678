import { Bar as VisxBar } from '@visx/shape';
import { rgba } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const SVG = styled.svg``;

export const Bar = styled(VisxBar)<{
  $isTarget?: boolean;
  $isCompleted?: boolean;
  $isFaded: boolean;
}>`
  fill: ${({ $isFaded, $isTarget, $isCompleted, theme }) =>
    $isCompleted
      ? rgba(theme.palette.success.dark, $isFaded ? 0.3 : 1)
      : $isTarget
        ? rgba(theme.palette.divider, $isFaded ? 0.3 : 0.6)
        : rgba(theme.palette.primary.main, $isFaded ? 0.3 : 1)};
`;
