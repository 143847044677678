import { useGoals } from 'features/goals';
import React, { useMemo } from 'react';
import { NewTaskRow } from 'shared/components/connected/new-task-row';
import { DroppableContainer } from 'shared/components/ui/droppable-container';
import { VirtualizedTasksList } from 'shared/components/ui/tasks-list';
import { useToday } from 'shared/contexts/today';
import { ID } from 'shared/types/id';
import {
  goalInboxID,
  unlinkedPlannedTasksID,
} from 'shared/types/orderable-section';
import { Task } from 'shared/types/task';

import * as Styled from './all-tasks-goal-tasks-list.style';

export type AllTasksGoalTasksListProps = {
  id: ID;
  tasks: Task[];
  offsetTop?: number;
  hasDnd?: boolean;
  canCreateTask?: boolean;
};

export const AllTasksGoalTasksList: React.FC<AllTasksGoalTasksListProps> = ({
  id,
  tasks,
  offsetTop,
  hasDnd,
  canCreateTask,
}) => {
  const goals = useGoals();
  const today = useToday();

  const goal = useMemo(() => goals.find((goal) => goal.id === id), [goals, id]);

  const _tasks = useMemo(
    () =>
      goal?.frozenAt
        ? tasks.map((task) => ({
            ...task,
            frozenAt: goal?.frozenAt ? goal.frozenAt : task.frozenAt,
          }))
        : tasks,
    [goal?.frozenAt, tasks],
  );

  return (
    <DroppableContainer id={id} disabled={!hasDnd || !!goal?.frozenAt}>
      <Styled.ListContainer>
        <VirtualizedTasksList
          tasks={_tasks}
          canReorder={!!hasDnd}
          offsetTop={offsetTop}
          hideLifeArea
          hideGoal
        />
      </Styled.ListContainer>

      {canCreateTask && !goal?.frozenAt && (
        <Styled.NewTaskContainer>
          <NewTaskRow
            goal={
              ![goalInboxID, unlinkedPlannedTasksID].includes(id)
                ? id
                : undefined
            }
            date={id === unlinkedPlannedTasksID ? today : undefined}
          />
        </Styled.NewTaskContainer>
      )}
    </DroppableContainer>
  );
};
