import { useDroppable } from '@dnd-kit/core';
import React from 'react';
import { ID } from 'shared/types/id';

export type DroppableContainerProps = {
  id: ID;
  disabled?: boolean;
  children: React.ReactNode;
};

export const DroppableContainer: React.FC<DroppableContainerProps> = ({
  id,
  disabled,
  children,
}) => {
  const { setNodeRef } = useDroppable({ id, disabled });

  return <div ref={id ? setNodeRef : undefined}>{children}</div>;
};
