import { useGoals, useUpdateGoal } from 'features/goals';
import React, { useMemo, useRef } from 'react';
import { Check, Target } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import {
  PopupMenu,
  PopupMenuButton,
  PopupMenuList,
  PopupMenuListItem,
} from 'shared/components/ui/popup-menu';
import { ID } from 'shared/types/id';

export type MoveGoalContextMenuProps = Omit<
  React.ComponentProps<typeof PopupMenu>,
  'children' | 'ref'
> & {
  id: ID;
  onClose: () => void;
};

export const MoveGoalContextMenu: React.FC<MoveGoalContextMenuProps> = ({
  id,
  onClose,
  position,
  location,
  referenceElement,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const { submit: updateGoal } = useUpdateGoal();
  const goals = useGoals();
  const filteredGoals = useMemo(
    () => goals.filter((goal) => !goal.parentIds?.length && goal.id !== id),
    [goals, id],
  );
  const goal = useMemo(() => goals.find((goal) => goal.id === id), [goals, id]);
  const parentId = goal?.parentIds?.[goal.parentIds.length - 1];

  const onGoal = (parentIds: ID[]) => {
    updateGoal({ id, parentIds });
  };

  return (
    <PopupMenu
      ref={containerRef}
      location={location}
      position={position}
      referenceElement={referenceElement}
      onOutside={onClose}
    >
      <PopupMenuList>
        {filteredGoals.map((goalOption) => {
          const onItem = () =>
            onGoal([...(goalOption.parentIds ?? []), goalOption.id]);

          return (
            <PopupMenuListItem key={goalOption.id}>
              <PopupMenuButton
                onClick={onItem}
                isSelected={goalOption.id === parentId}
                start={<Icon icon={Target} />}
                end={
                  goalOption.id === parentId ? <Icon icon={Check} /> : undefined
                }
              >
                {goalOption.name}
              </PopupMenuButton>
            </PopupMenuListItem>
          );
        })}
      </PopupMenuList>
    </PopupMenu>
  );
};
