import { typographyToCss } from 'shared/lib/mui';
import styled, { css } from 'styled-components';

export const Header = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
  padding-top: ${({ theme }) => theme.spacing(0.75)};
  padding-bottom: ${({ theme }) => theme.spacing(0.75)};
`;

export const HeaderContent = styled.div<{ $isFaded?: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(0.75)};
  padding-top: ${({ theme }) => theme.spacing(0.75)};
  min-width: 0;
  width: 0; // makes the overflow ellipsis work correctly

  ${({ $isFaded }) =>
    $isFaded &&
    css`
      opacity: 0.5;
    `}

  ${({ theme }) =>
    theme.isMobile &&
    css`
      padding-top: ${({ theme }) => theme.spacing(1.5)};
      pointer-events: none;
    `};
`;

export const TitleContainer = styled.span<{ $isFaded: boolean }>`
  flex: 1;
  line-height: 1;

  ${({ $isFaded, theme }) =>
    $isFaded &&
    css`
      color: ${theme.palette.text.secondary};
    `};
`;

export const Description = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.overline)};
  display: block;

  overflow: hidden;

  color: ${({ theme }) => theme.palette.text.secondary};
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Label = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
