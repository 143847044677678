import { endOfDay } from 'date-fns';
import { useUpdateGoal } from 'features/goals';
import { useLocalizedLifeAreas, useUpdateLifeArea } from 'features/life-areas';
import { useUpdateTask } from 'features/tasks';
import { useCallback, useMemo } from 'react';
import { useRemoveHabitScheduleDialog } from 'shared/contexts/remove-habit-schedule';
import { useToday } from 'shared/contexts/today';
// eslint-disable-next-line max-len -- long function name
import { useUpdateAllTasksUnlinkedPlannedTasksOrderMutation } from 'shared/hooks/queries/use-update-all-tasks-unlinked-planned-tasks-order-mutation';
import { useUpdateInboxOrderMutation } from 'shared/hooks/queries/use-update-inbox-order-mutation';
import { Goal } from 'shared/types/goal';
import { Habit } from 'shared/types/habit';
import { LifeArea } from 'shared/types/life-area';
import {
  goalInboxID,
  unlinkedPlannedTasksID,
} from 'shared/types/orderable-section';
import { SortingMode } from 'shared/types/sorting-mode';
import { Task } from 'shared/types/task';

import { Section } from '../types';

export const useHandleLifeAreaGoalSectionReorder = (sections: Section[]) => {
  const today = useToday();
  const lifeAreas = useLocalizedLifeAreas();

  const { submit: updateGoal } = useUpdateGoal();
  const { submit: updateLifeArea } = useUpdateLifeArea();
  const { submit: updateTask } = useUpdateTask();
  const { mutate: updateInboxOrder } = useUpdateInboxOrderMutation();
  const { mutate: updateUnlinkedPlannedTasksOrder } =
    useUpdateAllTasksUnlinkedPlannedTasksOrderMutation();

  const showRemoveScheduleDialog = useRemoveHabitScheduleDialog();

  const oldSections = useMemo(() => structuredClone(sections), [sections]);

  return useCallback(
    (newSections: Section[], movedTask: Task) => {
      const newSection = newSections.find(({ items }) =>
        items.find(({ id }) => id === movedTask.id),
      );
      const oldSection = oldSections.find(({ items }) =>
        items.find(({ id }) => id === movedTask.id),
      );

      if (!newSection || !oldSection) {
        // invalid data if we can't find the movedItem within the sections
        return;
      }

      // create lists for life-areas and goals to update, as it could be changed from goal to life-area and the other way around.
      const lifeAreasToUpdate: Pick<LifeArea, 'id' | 'taskSorting'>[] = [];
      const goalsToUpdate: Pick<
        Goal,
        'id' | 'taskSorting' | 'taskSortingMode' | 'taskCount'
      >[] = [];

      // prepare a function to update the sections
      const updateSections = () => {
        // update the life areas (if there are any)
        lifeAreasToUpdate.forEach((partialLifeArea) => {
          const lifeArea = lifeAreas.find(
            ({ id }) => id === partialLifeArea.id,
          );
          if (!lifeArea) {
            // invalid data, should not be possible to get here
            return;
          }

          updateLifeArea({
            ...lifeArea,
            taskSorting: partialLifeArea.taskSorting,
          });
        });

        // update the goals (of there are any)
        goalsToUpdate.forEach((partialGoal) => {
          // set the inbox order, if the goal is `inbox`
          if (partialGoal.id === goalInboxID && partialGoal.taskSorting) {
            updateInboxOrder(partialGoal.taskSorting);
            return;
          }

          // set the unlinked-planned-tasks sorting, if the goal is `planned tasks`
          if (
            partialGoal.id === unlinkedPlannedTasksID &&
            partialGoal.taskSorting
          ) {
            updateUnlinkedPlannedTasksOrder(partialGoal.taskSorting);
            return;
          }

          updateGoal(partialGoal);
        });
      };

      // add the newSection to either goals or lifeAreas to update, as it needs to be updated
      if (newSection.goalIds) {
        lifeAreasToUpdate.push({
          id: newSection.id,
          taskSorting: newSection.items.map(({ id }) => id),
        });
      }
      if (!newSection.goalIds) {
        goalsToUpdate.push({
          id: newSection.id,
          taskSorting: newSection.items.map(({ id }) => id),
          taskSortingMode: SortingMode.Custom,
          taskCount: newSection.id !== oldSection.id ? 1 : null,
        });
      }

      // add the old section in there as well, if the old and new section are different.
      if (newSection.id !== oldSection.id) {
        const newOldSection = newSections.find(
          ({ id }) => id === oldSection.id,
        );

        if (!newOldSection) {
          // invalid data again, should be impossible to get here
          return;
        }

        // add the new old-section to the life-areas or goals to update, depending on the properties of the sections
        if (newOldSection.goalIds) {
          lifeAreasToUpdate.push({
            id: newOldSection.id,
            taskSorting: newOldSection.items.map(({ id }) => id),
          });
        }
        if (!newOldSection.goalIds) {
          goalsToUpdate.push({
            id: newOldSection.id,
            taskSorting: newOldSection.items.map(({ id }) => id),
            taskCount: -1,
          });
        }

        // update the moved task with the new section it is located in
        const updatedTask: Partial<Task> & Required<Pick<Task, 'id'>> = {
          id: movedTask.id,
          goalId:
            [goalInboxID, unlinkedPlannedTasksID].includes(newSection.id) ||
            newSection.goalIds
              ? null
              : newSection.id,
          lifeAreaId:
            [goalInboxID, unlinkedPlannedTasksID].includes(newSection.id) ||
            !newSection.goalIds
              ? null
              : newSection.id,
        };

        // unlinked-planned-tasks need a deadline or schedule, so if the task does not have it, we add today as deadline
        if (
          newSection.id === unlinkedPlannedTasksID &&
          (!movedTask.schedules || !movedTask.endStrategy?.deadline)
        ) {
          updatedTask.endStrategy = {
            deadline: endOfDay(today),
            completionCount: null,
          };
        }

        // inbox tasks do not have any schedule or deadline
        if (newSection.id === goalInboxID) {
          updatedTask.endStrategy = null;

          if (movedTask.schedules) {
            showRemoveScheduleDialog(updatedTask as Habit, updateSections);
            return;
          }
        }

        updateTask(updatedTask);
      }

      updateSections();
    },
    [
      lifeAreas,
      oldSections,
      showRemoveScheduleDialog,
      today,
      updateGoal,
      updateInboxOrder,
      updateLifeArea,
      updateTask,
      updateUnlinkedPlannedTasksOrder,
    ],
  );
};
