import { useWindowVirtualizer } from '@tanstack/react-virtual';
import React, { useRef } from 'react';

import { Section } from '../../types';
import { GoalSection } from './goal-section';
import * as Styled from './life-area-sub-sections.style';

export type LifeAreaSubSectionsProps = {
  subSections: Section[];
};

export const LifeAreaSubSections: React.FC<LifeAreaSubSectionsProps> = ({
  subSections,
}) => {
  const listContainerRef = useRef<HTMLDivElement>(null);

  const virtualizer = useWindowVirtualizer({
    count: subSections.length,
    estimateSize: (index) =>
      56 + (subSections[index]?.items?.length ?? 10) * 34,
    overscan: 5,
    scrollMargin: listContainerRef.current?.offsetTop ?? 0,
  });

  return (
    <Styled.ListContainer ref={listContainerRef}>
      <Styled.List style={{ height: `${virtualizer.getTotalSize()}px` }}>
        {virtualizer.getVirtualItems().map((item) => {
          const section = subSections[item.index];

          return (
            <Styled.ListItem
              key={item.key}
              data-index={item.index}
              ref={virtualizer.measureElement}
              style={{
                transform: `translateY(${
                  item.start - virtualizer.options.scrollMargin
                }px)`,
              }}
            >
              <GoalSection
                id={section.id}
                items={section.items}
                offsetTop={item.start}
              />
            </Styled.ListItem>
          );
        })}
      </Styled.List>
    </Styled.ListContainer>
  );
};
