import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import React from 'react';
import { GoalSection as GoalSectionType } from 'shared/types/goal-section';

import { GoalSection } from './goal-section';
import * as Styled from './task-goal-section-list.style';

export type GoalSectionsListProps = Omit<
  React.ComponentProps<typeof GoalSection>,
  'section' | 'initialOpen'
> & {
  sections: GoalSectionType[];
  noRightSpacing?: boolean;
};

export const GoalSectionsList: React.FC<GoalSectionsListProps> = ({
  sections,
  noRightSpacing,

  goalId,
  items,

  allGoals,
  lifeAreas,
  onPremium,
  onUpdateSection,
  onTask,
  onUnfocus,
  hideGoal,
  showRepeatingAsTodo,
  selectedItem,
  isFrozen,

  canCreateTask,
  overwriteCollapse,
  canCollapse,
  hasDnd,
}) => {
  const SortableContainer = hasDnd ? SortableContext : React.Fragment;
  const sortableProps = hasDnd
    ? {
        items: sections.map(({ id }) => id),
        strategy: verticalListSortingStrategy,
      }
    : {};

  return (
    // @ts-ignore
    <SortableContainer {...sortableProps}>
      <Styled.List $noRightSpacing={!!noRightSpacing}>
        {sections.map((section) => (
          <Styled.ListItem key={section.id}>
            <GoalSection
              goalId={goalId}
              section={section}
              items={items}
              allGoals={allGoals}
              lifeAreas={lifeAreas}
              onPremium={onPremium}
              onUpdateSection={onUpdateSection}
              onTask={onTask}
              onUnfocus={onUnfocus}
              hideGoal={hideGoal}
              showRepeatingAsTodo={showRepeatingAsTodo}
              selectedItem={selectedItem}
              isFrozen={isFrozen}
              canCreateTask={canCreateTask}
              overwriteCollapse={overwriteCollapse}
              canCollapse={canCollapse}
              hasDnd={hasDnd}
              initialOpen
            />
          </Styled.ListItem>
        ))}
      </Styled.List>
    </SortableContainer>
  );
};
