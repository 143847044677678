import React from 'react';
import { Habit } from 'shared/types/habit';
import { ID } from 'shared/types/id';
import { Timestamp } from 'shared/types/timestamp';

import { HabitDetailCard } from '../habit-detail-card';
import * as Styled from './habits-list.style';

export type HabitsListProps = {
  items: Habit[];
  onHabit?: (id: ID) => void;
  weekDate: Timestamp;
  isSortable?: boolean;
};

export const HabitsList: React.FC<HabitsListProps> = ({
  items,
  weekDate,
  onHabit,
  isSortable,
}) => (
  <Styled.List>
    {items.map((habit) => (
      <Styled.ListItem key={habit.id}>
        <HabitDetailCard
          habit={habit}
          weekDate={weekDate}
          onClick={onHabit}
          isSortable={isSortable}
        />
      </Styled.ListItem>
    ))}
  </Styled.List>
);
